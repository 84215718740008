export default function FlagIcon({ locale, icons }) {
  let icon = null;
  if (locale.flagIconUrl) {
    icon = <img src={locale.flagIconUrl} alt={locale.name} width={20} height={20} />;
  } else if (icons[locale.isoCode]) {
    const Icon = icons[locale.isoCode];
    icon = <Icon width={20} height={20} />;
  }

  return icon;
}
