import { FadeTransition } from "@/components/animations/FadeTransition";
import BasketIcon from "@/images/tpo/basket.svg?react";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import { ActionIcon } from "@/v2/Buttons";

export default function BasketButtonUi({ basketCount, setBasketOpen, basketOpen }) {
  return (
    <Group position="relative" height="100%" justifyContent="center">
      <ActionIcon
        onClick={() => setBasketOpen(!basketOpen)}
        data-component-name="BasketButton"
        gap={10}
        p={0}
        size={null}
      >
        <BasketIcon height={20} />
      </ActionIcon>
      <FadeTransition in={basketCount > 0}>
        <Center
          bg="green"
          fontSize={12}
          fontFamily="gilroyBold"
          py={0}
          px={0}
          width={24}
          height={24}
          position="absolute"
          top={[-18, -18, -12]}
          right={[-12]}
          borderRadius={"50%"}
          data-component-name="BasketButtonCount"
        >
          {basketCount}
        </Center>
      </FadeTransition>
    </Group>
  );
}
