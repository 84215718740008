import { useState } from "react";

import Group from "@/tpo/Group";
import Menu, { Button } from "@/tpo/Menu";

import FlagIcon from "./FlagIcon";
import ICONS from "./IsoCodeFlagIcons";

export default function CountrySelect({ value, onChange, shippingCountries = [] }) {
  const [showOrgMenu, setShowOrgMenu] = useState(false);
  const placeholder = "Country";

  let buttonContent = placeholder;
  if (value && shippingCountries.find(l => l.name === value)) {
    const localInstance = shippingCountries.find(l => l.name === value);

    buttonContent = (
      <Group alignItems="center" gap={10} color="white">
        <FlagIcon
          locale={{
            flagIconUrl: undefined,
            name: localInstance.name,
            isoCode: localInstance.isoCode
          }}
          icons={ICONS}
        />
        {localInstance.name}
      </Group>
    );
  }

  const options = [...shippingCountries].sort((a, b) => {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <Menu opened={showOrgMenu} onChange={setShowOrgMenu} trigger="hover">
      <Menu.Target>
        <Button
          open={showOrgMenu}
          color="dark"
          data-component-name="OrganisationLocaleButton"
          sx={{
            color: "white",
            px: [20, 20, 40]
          }}
        >
          {buttonContent}
        </Button>
      </Menu.Target>
      <Menu.Dropdown
        mt={2}
        py={2}
        zIndex={2}
        minWidth={200}
        minHeight={300}
        overflow="auto"
        bg="dark"
        data-component-name="CountryMenuDropdown"
      >
        {options.map(sc => {
          return (
            <Menu.Item
              onClick={() => {
                onChange(sc.name);
              }}
              selected={sc.name === value}
              key={sc.id}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              gap={10}
              alignItems="center"
            >
              <FlagIcon
                locale={{
                  flagIconUrl: undefined,
                  name: sc.name,
                  isoCode: sc.isoCode
                }}
                icons={ICONS}
              />
              {sc.name}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
