import { useCallback, useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";

import ValidatedQueryParameters from "@/utils/validatedQueryParameters";

export default function useQueryParams({ DEFAULT_FILTERS, ALL_FILTERS }) {
  /**
   * We want arrays to be comma separated values.
   *
   * {
   *    gender: 'Male',
   *    fruits: ['apple', 'pear']
   * }
   *
   * Should become ?gender=Male&fruits=apple,pear
   *
   * So it knows whether to replace or add a new param
   * to a filter use the following defaults:
   *
   * {
   *  gender: undefined,
   *  fruits: []
   * }
   *
   * This way if a param is to added to "gender" it knows to replace
   * what it has already, otherwise if it's a list it knows to add.
   *
   */

  const history = useHistory();
  const location = useLocation();

  const applyFilter = useCallback(
    (key, value) => {
      return history.push({
        path: location.path,
        search: new ValidatedQueryParameters({
          search: location.search,
          defaultFilters: DEFAULT_FILTERS,
          allFilters: ALL_FILTERS
        }).applyFilter(key, value)
      });
    },
    [history, location, DEFAULT_FILTERS, ALL_FILTERS]
  );

  const removeFilter = useCallback(
    (key, value) => {
      return history.push({
        path: location.path,
        search: new ValidatedQueryParameters({
          search: location.search,
          defaultFilters: DEFAULT_FILTERS,
          allFilters: ALL_FILTERS
        }).removeFilter(key, value)
      });
    },
    [history, location, DEFAULT_FILTERS, ALL_FILTERS]
  );

  const toggleFilter = useCallback(
    (group, value) => {
      return history.push({
        path: location.path,
        search: new ValidatedQueryParameters({
          search: location.search,
          allFilters: ALL_FILTERS,
          defaultFilters: DEFAULT_FILTERS
        }).toggleFilter(group, value)
      });
    },
    [history, location, ALL_FILTERS, DEFAULT_FILTERS]
  );

  const resetFilters = useCallback(() => {
    return history.push({
      path: location.path
    });
  }, [history, location]);

  const validatedFilters = useMemo(() => {
    return new ValidatedQueryParameters({
      search: location.search,
      defaultFilters: DEFAULT_FILTERS,
      allFilters: ALL_FILTERS
    }).validatedFilters;
  }, [location.search, ALL_FILTERS, DEFAULT_FILTERS]);

  const api = useMemo(
    () => ({
      applyFilter,
      removeFilter,
      toggleFilter,
      resetFilters,
      validatedFilters
    }),
    [applyFilter, removeFilter, toggleFilter, resetFilters, validatedFilters]
  );

  return api;
}
