import Box from "@/components/Box";

import Center from "./Center";
import Stack from "./Stack";

export default function NothingToShow({ children, header, bodyCopy, mx, mt, mb, headerCentered }) {
  return (
    <Center
      mt={mt}
      mb={mb}
      py={[40, 40, 60]}
      px={[20, 20, 40]}
      bg="white"
      maxWidth={1024}
      width="100%"
      mx={mx}
      borderRadius={20}
    >
      <Stack stacked gap={[12, 12, 24]} maxWidth={720} width="100%">
        <Box
          fontFamily="gilroyBold"
          fontSize={[24, 24, 36]}
          lineHeight="46px"
          textAlign={headerCentered ? "center" : "auto"}
        >
          {header}
        </Box>
        {children
          ? children
          : !!bodyCopy && (
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="24px">
                {bodyCopy}
              </Box>
            )}
      </Stack>
    </Center>
  );
}
