import Box from "@/components/Box";

export default function Strip({ y, r, o }) {
  const width = 25;
  const height = 3;

  return (
    <Box
      width={width}
      height={height}
      position="absolute"
      opacity={o}
      bg="white"
      top="50%"
      left="50%"
      ml={`-${width / 2}px`}
      mt={`-${height / 2}px`}
      borderRadius="999999px"
      style={{
        transition: "transform 250ms, opacity 250ms",
        transform: `translateY(${y}px) rotate(${r}deg)`
      }}
    />
  );
}

Strip.defaultProps = {
  y: 0,
  r: 0,
  o: 100
};
