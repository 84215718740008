import { useState } from "react";

import Box from "@/components/Box";
import Slider from "@/components/Slider";
import { theme } from "@/core/theme";
import useWindowSize from "@/hooks/use-window-size";

export default function Carousel({ images, bg }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const windowSize = useWindowSize();

  let testProductImageHeight = 300;
  if (windowSize.width > 600) {
    testProductImageHeight = 600;
  }
  return (
    <>
      <Slider
        showArrowButtons={false}
        currentIndex={slideIndex}
        width="100%"
        inset="0px"
        handleNext={() => {
          if (slideIndex < images.length - 1) {
            setSlideIndex(slideIndex + 1);
          } else {
            setSlideIndex(0);
          }
        }}
        handlePrev={() => {
          if (slideIndex > 0) {
            setSlideIndex(slideIndex - 1);
          } else {
            setSlideIndex(images.length - 1);
          }
        }}
      >
        {images.map(image => (
          <Box
            height={testProductImageHeight}
            key={image}
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain"
            }}
            minHeight={300}
            maxHeight={440}
          />
        ))}
      </Slider>
      <SliderPips images={images} slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
    </>
  );
}

function SliderPips({ images, slideIndex, setSlideIndex }) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="end" pt={theme.spacing.small}>
      {images.map((_, index) => {
        return (
          <Box
            width={20}
            height={20}
            borderRadius="50%"
            border="solid 1px"
            backgroundColor={slideIndex === index ? "black" : "white"}
            key={index}
            ml={20}
            onClick={() => {
              setSlideIndex(index);
            }}
          />
        );
      })}
    </Box>
  );
}
