import { createContext, useEffect, useMemo, useState } from "react";

import { useExecutionContext } from "@/contexts/ExecutionContext";
import { useLazyQuery } from "@apollo/client";
import { isFunction } from "lodash";

export const TabsContext = createContext();

export function useLazyTabs({
  tabs,
  defaultTab,
  fetchPolicy = "no-cache",
  nextFetchPolicy = "no-cache"
}) {
  // network policy now configurable since i observed some strange behaviour
  // where fetchPolicy was set to "no-cache" and a fetchMore call meant it
  // was re-firing the original query.  Switching to "network-first" seems to fix this.

  const [selected, setSelected] = useState(defaultTab === undefined ? tabs[0].id : defaultTab);
  const executionContext = useExecutionContext();

  const selectedTab = tabs.find(tab => tab.id === selected);

  const [fetch, { data, loading, fetchMore }] = useLazyQuery(selectedTab.query, {
    fetchPolicy,
    nextFetchPolicy,
    variables: isFunction(selectedTab.variables)
      ? selectedTab.variables(executionContext)
      : selectedTab.variables
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const api = useMemo(
    () => ({
      tabs,
      tab: tabs.find(tab => tab.id === selected),
      setTab: setSelected,
      isSelected: tab => tab.id === selected,
      data,
      loading,
      fetchMore,
      tabId: selected
    }),
    [tabs, selected, setSelected, data, loading, fetchMore]
  );

  return api;
}

function EmptyTabs() {
  return null;
}

// TODO - move TabsContext into this module out of Recommendations
function NonEmptyTabs({ children, tabs: initialTabs, fetchPolicy, nextFetchPolicy }) {
  const tabsApi = useLazyTabs({ tabs: initialTabs, fetchPolicy, nextFetchPolicy });

  return <TabsContext.Provider value={tabsApi}>{children}</TabsContext.Provider>;
}

export function LazyTabs({ children, tabs, nextFetchPolicy, fetchPolicy }) {
  if (tabs.length) {
    return (
      <NonEmptyTabs fetchPolicy={fetchPolicy} nextFetchPolicy={nextFetchPolicy} tabs={tabs}>
        {children}
      </NonEmptyTabs>
    );
  }

  return <EmptyTabs />;
}
