import Svg from "@/components/Svg";

export default function PractitionerFavourite(props) {
  return (
    <Svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="24" fill="#FFDE9A" />
      <path
        d="M11.8004 20.1447L19.1147 19.5595C19.4393 19.5337 19.7255 19.3352 19.85 19.0426L22.6886 12.5188C22.9899 11.8271 24.0055 11.8271 24.3057 12.5188L27.1443 19.0426C27.2699 19.333 27.555 19.5337 27.8796 19.5595L35.1938 20.1447C35.9686 20.2075 36.2839 21.138 35.6941 21.6258L30.1331 26.2403C29.8865 26.4466 29.776 26.7684 29.8527 27.0756L31.535 33.9582C31.7141 34.6869 30.8916 35.2643 30.2273 34.8741L23.9555 31.2035C23.6751 31.0399 23.3237 31.0399 23.0445 31.2035L16.7727 34.8741C16.1084 35.2643 15.287 34.688 15.465 33.9582L17.1473 27.0756C17.2217 26.7684 17.1147 26.4467 16.8669 26.2403L11.3059 21.6258C10.7161 21.1381 11.0314 20.2053 11.8062 20.1447H11.8004Z"
        fill="#1A1A1A"
      />
    </Svg>
  );
}
