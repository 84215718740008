import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import AspectRatio from "@/components/AspectRatio";
import BackgroundImage from "@/components/BackgroundImage";
import Box from "@/components/Box";
import { getVideoGuideUrl } from "@/core/urls";
import PaperStackIcon from "@/images/PaperStackIcon";
import Center from "@/tpo/Center";
import Stack from "@/tpo/Stack";

const VideoGuideCard = forwardRef(
  (
    {
      bg,
      title,
      slug,
      shortDescription,
      video: { thumbnailUrl, thumbnailOverrideUrl },
      topic,
      minWidth,
      className
    },
    ref
  ) => {
    const history = useHistory();

    return (
      <Stack
        className={className}
        bg={bg}
        ref={ref}
        gap={0}
        borderRadius={10}
        style={{
          cursor: "pointer",
          overflow: "hidden"
        }}
        onClick={() => {
          history.push(getVideoGuideUrl(slug));
        }}
        minWidth={minWidth}
      >
        {thumbnailOverrideUrl || thumbnailUrl ? (
          <AspectRatio value={9 / 16} flex={0}>
            <BackgroundImage
              backgroundImage={thumbnailOverrideUrl || thumbnailUrl}
              width="100%"
              height="100%"
            />
          </AspectRatio>
        ) : (
          <Center
            stacked
            minHeight={300}
            style={{
              backgroundColor: "white"
            }}
          ></Center>
        )}
        <Stack bg="white" p={28} pt={20} flexGrow={1}>
          {!!topic && (
            <Box
              fontFamily="gilroyMedium"
              fontSize={[16, 16, 18]}
              lineHeight={["24px", "24px", "28px"]}
              color="purple"
            >
              {topic.name}
            </Box>
          )}
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
            {title}
          </Box>
          <Box
            fontFamily="gilroyMedium"
            fontSize={[14, 14, 16]}
            style={{
              lineClamp: 3,
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              display: "-webkit-box"
            }}
          >
            {shortDescription}
          </Box>
        </Stack>
      </Stack>
    );
  }
);

VideoGuideCard.defaultProps = {
  bg: "white"
};

export default VideoGuideCard;
