import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useAppState } from "@/components/AppStateProvider";
import Box from "@/components/Box";
import CustomerPage from "@/components/CustomerPage";
import CustomerTabs from "@/components/CustomerTabs";
import DataLoader from "@/components/DataLoader";
import { InternalTextLink } from "@/components/Links";
import Loading from "@/components/Loading";
import { useChangeOverTimeContext } from "@/contexts/ChangeOverTime";
import { theme } from "@/core/theme";
import { getTestResultsUrl, getThemeUrl, TESTS_URL } from "@/core/urls";
import { CONSULT_NEEDED, RESULTS_AVAILABLE } from "@/core/utils";
import { CORE_USER_THEME_WITH_SECTORS_FIELDS } from "@/graphql/tpo/common/types";
import usePollingRefresh from "@/hooks/tpo/usePollingRefresh";
import useDocTitle from "@/hooks/use-doc-title";
import useWindowSize from "@/hooks/use-window-size";
import { useDataLoader } from "@/hooks/useDataLoader";
import ButtonV2 from "@/v2/Buttons";
import { gql } from "@apollo/client";
import { isUndefined } from "lodash";

import Section from "../deprecated/Section";
import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import { CollapseableText } from "./CollapseableText";
import DashboardSwitcher from "./DashboardSwitcher";
import Flag from "./Flag";
import { TabsContext } from "./LazyTabs";
import { TopPriorityUserSubsector, TABS, RecommendationTabGrid } from "./Recommendations";
import Spacer from "./Spacer";
import Stack from "./Stack";
import { BestMatchedTest } from "./TestRecommendations";
import { USER_TESTS_AND_ORDERS_QUERY, useTestStatuses } from "./TestStatuses";
import UserQuestionnaireSubmissionSummaries from "./UserQuestionnaireSubmissionSummaries";
import UserTestsLoader from "./UserTestsLoader";
import WellnessScore from "./WellnessScore";
import DiscoverOtherTestsPanel from "./shop/DiscoverOtherTestsPanel";

const USER_SNAPSHOT_STATUS_QUERY = gql`
  query UserSnapshotStatusQuery {
    user {
      id
      snapshotStatus
      snapshotQueued
    }
    userSubmissions {
      id
      complete
    }
  }
`;

const DASHBOARD_QUERY = gql`
  query DashboardQuery($themeSlug: String!) {
    user {
      id
      snapshotStatus
      snapshotQueued
    }
    userTheme(themeSlug: $themeSlug) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubmissions {
      id
      complete
      status
      hasExpiredAnswers
    }
    userTestRecommendations(limit: 1) {
      id
      product {
        id
        paymentIsoCode
        organisation
        name
        slug
        productFamily {
          id
          slug
        }
        content {
          id
          image1
        }
      }
      symptoms {
        id
        name
        points
        maxPoints
        score
      }
      score
      points
      maxPoints
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
`;

function BestMatchedTestPanel({ userTestRecommendation }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 945;

  return (
    <Center
      stacked
      px={[20]}
      pt={theme.spacing.section.pt}
      pb={theme.spacing.section.pb}
      bg="blue"
      gap={0}
    >
      <Section.Flag display="flex" justifyContent="center">
        <BestMatchedTest.Icon />
      </Section.Flag>
      <BestMatchedTest.Header.Primary color="white" textAlign={["left", "center"]} />
      <BestMatchedTest.BodyCopy
        color="white"
        ml={[null, null, "auto"]}
        mr={[null, null, "auto"]}
        maxWidth={[null, null, 760]}
      />
      <Spacer py={[0, 0, 20]} />
      <Box display={"flex"} flexDirection={["column", "column", "row"]} gap={20}>
        <Box
          position="relative"
          display="flex"
          justifyContent="flex-end"
          height={isMobile ? "250px" : "auto"}
          style={{
            backgroundImage: `url(${userTestRecommendation.product.content.image1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
          minWidth={[null, null, 500]}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minWidth={[null, null, 500]}
          pl={0}
          mt={[20, "auto"]}
          mb={[null, "auto"]}
        >
          <BestMatchedTest.Symptoms color="white" userTestRecommendation={userTestRecommendation} />
        </Box>
      </Box>
    </Center>
  );
}

function Initial({ snapshotQueued, snapshotStatus, userSubmissions }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={[464, 464, 464, 500]}
        outer={{
          bg: "haze",
          px: 20,
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb
        }}
        stacked
        gap={[20, 20, 40]}
      >
        <UserTestsLoader />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="150%">
          Omnos is a platform designed to be as unique as you. Begin your journey to improving your
          wellness and wellbeing. Start with our self-assessment questionnaires or specialist test
          below to unlock your Wellness potential.
        </Box>
      </PanelBoxV2>
      <UserQuestionnaireSubmissionSummaries
        bg="white"
        newUserView
        snapshotStatus={snapshotStatus}
        snapshotQueued={snapshotQueued}
        userSubmissions={userSubmissions}
      />
      <Stack gap={40} pb={theme.spacing.section.pb}>
        <DiscoverOtherTestsPanel
          title={
            <Stack gap={20}>
              <Flag text={2} />
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Omnos Tests
              </Box>
            </Stack>
          }
          subtitle={`The following tests all help identify specific areas for you to address in order to improve your overall health and wellbeing.`}
        />
      </Stack>
    </>
  );
}

function NoRecommendations() {
  return (
    <RecommendationTabGrid
      overlay
      tabs={[
        TABS.thingsToDo,
        {
          id: "things_to_avoid",
          name: "Things To Avoid"
        },
        {
          id: "things_to_take",
          name: "Things To Take"
        },
        {
          id: "people_to_see",
          name: "People To See"
        },
        {
          id: "food_list",
          name: "Food List"
        }
      ]}
      isSelected={tab => tab.id === "things_to_do"}
      setTab={() => {}}
      loading={false}
      data={{
        recommendations: [
          {
            id: 1,
            name: "Increase Copper"
          },
          {
            id: 2,
            name: "Increase Vitamin A"
          },
          {
            id: 3,
            name: "Increase Vitamin B"
          },
          {
            id: 4,
            name: "Increase Vitamin C"
          },
          {
            id: 5,
            name: "Increase Vitamin D"
          },
          {
            id: 6,
            name: "Increase Vitamin E"
          },
          {
            id: 7,
            name: "Increase Zinc"
          },
          {
            id: 8,
            name: "Increase Iron"
          },
          {
            id: 9,
            name: "Increase Calcium"
          }
        ]
      }}
      tab={TABS.thingsToDo}
    />
  );
}

const RECOMMENDATIONS_BUTTON_TEXT = {
  things_to_do: "Suggestions",
  things_to_avoid: "Suggestions",
  things_to_take: "Supplements",
  people_to_see: "Suggestions",
  food_list: "Food List"
};

function TestsAndOrdersList({ userTests, ordersInProgress }) {
  const items = useTestStatuses({
    ordersInProgress,
    testResults: userTests
  });

  if (!items.length) {
    return (
      <Box p={20}>
        <Box fontFamily="gilroyBold" fontSize={18}>
          You have no test orders
        </Box>
      </Box>
    );
  }

  return items.map((item, index) => (
    <Box
      p={20}
      key={item.id}
      borderBottomStyle={index === items.length - 1 ? null : "solid"}
      borderWidth={1}
      borderColor="haze"
    >
      <Box display="flex" justifyContent="space-between">
        <Box color="dark" fontFamily="gilroyMedium" fontSize={14} lineHeight="170%">
          {item.name}
        </Box>
        {item.status === RESULTS_AVAILABLE || item.status === CONSULT_NEEDED ? (
          <InternalTextLink href={getTestResultsUrl(item.id)} fontSize={16} lineHeight="170%">
            {item.status}
          </InternalTextLink>
        ) : (
          <Box
            color="dark"
            fontFamily="gilroyMedium"
            fontSize={16}
            lineHeight="170%"
            textDecoration="underline"
          >
            {item.status}
          </Box>
        )}
      </Box>
      <Box>
        <Box
          color="gray"
          fontFamily="gilroyMedium"
          fontSize={14}
          lineHeight="170%"
          textDecoration="underline"
        >
          {item.created}
        </Box>
      </Box>
    </Box>
  ));
}

function HighestPriorityRecommendations() {
  const history = useHistory();

  return (
    <PanelBoxV2
      stacked
      gap={[20, 20, 40]}
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        bg: "haze"
      }}
    >
      <PanelBoxV2
        maxWidth={760}
        outer={{
          px: [40]
        }}
      >
        <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Suggestions
        </Box>
        <Box py={[2, 2, 20]} />
        <CollapseableText fontSize={[14, 14, 16]}>
          We've created a prioritised set of suggestions and advice based on your test results and
          self assessment questionnaires.
        </CollapseableText>
      </PanelBoxV2>
      <TopPriorityUserSubsector id={1} fallback={<NoRecommendations />}>
        <TabsContext.Consumer>
          {({ tabs, isSelected, setTab, loading, data, tab, tabId }) => (
            <>
              <RecommendationTabGrid
                tabs={tabs}
                isSelected={isSelected}
                tabId={tabId}
                setTab={setTab}
                loading={loading}
                data={data}
                tab={tab}
              />
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonV2
                  color="dark"
                  onClick={() => {
                    return history.push({ pathname: tab.url });
                  }}
                  rightIcon={<ChevronComponent />}
                  size={["sm", "sm", "md"]}
                >
                  {`View all ${RECOMMENDATIONS_BUTTON_TEXT[tab.id]}`}
                </ButtonV2>
              </Box>
            </>
          )}
        </TabsContext.Consumer>
      </TopPriorityUserSubsector>
    </PanelBoxV2>
  );
}

function Other({
  snapshotQueued,
  snapshotStatus,
  userTheme,
  userTestRecommendations,
  userSubmissions
}) {
  const history = useHistory();

  const { includeExpiredMarkers } = useChangeOverTimeContext();
  const [wellnessScore, setWellnessScore] = useState(0);

  useEffect(() => {
    if (!userTheme) return;

    if (includeExpiredMarkers) {
      setWellnessScore(Math.round(userTheme.invertedScore * 100));
    } else {
      setWellnessScore(Math.round(userTheme.invertedFreshScore * 100));
    }
  }, [userTheme, includeExpiredMarkers]);

  return (
    <>
      <PanelBoxV2
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <PanelBoxV2 maxWidth={1280}>
          <Box display="flex" flexDirection={["column", "column", "row"]} gap={20}>
            <Box
              background="#5220DD linear-gradient(137deg, #5220DD 0%, #9747FF 100%)"
              borderRadius={5}
              width={["100%", "100%", "calc((100% - 20px) / 2)"]}
              px={20}
              pt={[20, 20, 40]}
              pb={[30, 30, 60]}
            >
              <Box px={[null, null, 20]}>
                <Box
                  as="h2"
                  color="white"
                  fontFamily="gilroyBold"
                  lineHeight={["130%"]}
                  fontSize={[28, 28, 36]}
                >
                  Wellness score
                </Box>
                <Spacer py={[2, 2, 20]} />
                <Box
                  fontFamily="gilroyMedium"
                  color="white"
                  fontSize={[14, 14, 16]}
                  lineHeight={["170%", "170%", "150%"]}
                >
                  Take a deeper look into your overall health and wellbeing. Your Wellness score
                  does not only consider your biomarkers across all tests but also values your
                  responses to the symptom questionnaire.
                </Box>
              </Box>
              <Spacer py={[2, 2, 20]} />
              <Stack gap={40}>
                <Center>
                  <WellnessScore
                    scoreInterpretations={userTheme.scoreInterpretations}
                    wellnessScore={wellnessScore}
                  />
                </Center>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <ButtonV2
                    color="white"
                    variant="outline"
                    onClick={() => {
                      history.push({
                        pathname: getThemeUrl("health")
                      });
                    }}
                    rightIcon={<ChevronComponent />}
                    size={["sm", "sm", "md"]}
                  >
                    discover your results
                  </ButtonV2>
                </Box>
              </Stack>
            </Box>
            <Box
              pt={[20, 20, 40]}
              px={20}
              pb={[0, 0, 60]}
              width={["100%", "100%", "calc((100% - 20px) / 2)"]}
            >
              <Box px={[null, null, 20]}>
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
                  Your orders
                </Box>
                <Spacer py={[2, 2, 20]} />
                <Box
                  fontFamily="gilroyMedium"
                  fontSize={[14, 14, 16]}
                  lineHeight={["170%", "170%", "150%"]}
                >
                  Access your ordered tests. All biomarkers below are considered as part of your
                  Wellness score.
                </Box>
              </Box>
              <Spacer py={[2, 2, 20]} />
              <Box display={["block", "block", "none"]}>
                <UserTestsLoader />
              </Box>
              <Box
                display={["none", "none", "block"]}
                maxHeight={300}
                overflowY="auto"
                borderTopStyle="solid"
                borderBottomStyle="solid"
                borderWidth={1}
                borderColor="haze"
              >
                <DataLoader
                  query={USER_TESTS_AND_ORDERS_QUERY}
                  variables={{ allTests: true, excludeRetests: true }}
                  render={({ ordersInProgress = [], userTests }) => (
                    <TestsAndOrdersList ordersInProgress={ordersInProgress} userTests={userTests} />
                  )}
                />
              </Box>
              <Spacer py={[2, 2, 20]} />
              <ButtonV2
                color="green"
                onClick={() => {
                  history.push(TESTS_URL);
                }}
                rightIcon={<ChevronComponent />}
                size={["sm", "sm", "md"]}
              >
                shop all tests
              </ButtonV2>
            </Box>
          </Box>
        </PanelBoxV2>
      </PanelBoxV2>
      <UserQuestionnaireSubmissionSummaries
        bg="haze"
        snapshotStatus={snapshotStatus}
        snapshotQueued={snapshotQueued}
        userSubmissions={userSubmissions}
      />
      {!!userTestRecommendations?.length && (
        <BestMatchedTestPanel userTestRecommendation={userTestRecommendations[0]} />
      )}
      <Stack gap={40} pt={theme.spacing.section.pt} pb={theme.spacing.section.pb}>
        <DiscoverOtherTestsPanel />
      </Stack>
      <HighestPriorityRecommendations />
    </>
  );
}

export function shouldShowNoDataDashboardView(snapshotStatus, userTheme) {
  const subsectorsCount = userTheme?.userSectors
    .map(userSector => userSector?.userSubsectors?.length)
    .reduce((acc, curr) => {
      return acc + (curr || 0);
    }, 0);

  if (
    !snapshotStatus ||
    snapshotStatus.toLowerCase() !== "complete" ||
    isUndefined(subsectorsCount) ||
    subsectorsCount === 0
  ) {
    return true;
  }
  return false;
}

function Dashboard() {
  useDocTitle("Dashboard");

  const { data = {}, refetch, loading, error } = useDataLoader({
    query: DASHBOARD_QUERY,
    variables: {
      themeSlug: "health"
    },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });

  const { questionnaireProcessing } = useAppState();

  const pollingData = usePollingRefresh(
    USER_SNAPSHOT_STATUS_QUERY,
    data => {
      const snapshotQueued = data?.user?.snapshotQueued;
      return snapshotQueued === false;
    },
    refetch,
    questionnaireProcessing
  );

  const { userSubmissions, userSubsector, userTheme, userTestRecommendations, user = {} } = data;

  let { snapshotStatus, snapshotQueued } = user;

  if (pollingData) {
    if (pollingData?.user?.snapshotQueued !== undefined) {
      snapshotQueued = pollingData?.user?.snapshotQueued;
    }
    if (pollingData?.user?.snapshotStatus !== undefined) {
      snapshotStatus = pollingData?.user?.snapshotStatus;
    }
  }

  const content =
    loading || error ? (
      <Loading />
    ) : shouldShowNoDataDashboardView(snapshotStatus, userTheme) ? (
      <Initial
        testProducts={[]}
        snapshotQueued={snapshotQueued}
        snapshotStatus={snapshotStatus}
        userSubsector={userSubsector}
        userTheme={userTheme}
        userTestRecommendations={userTestRecommendations}
        userSubmissions={userSubmissions}
      />
    ) : (
      <Other
        testProducts={[]}
        snapshotQueued={snapshotQueued}
        snapshotStatus={snapshotStatus}
        userSubsector={userSubsector}
        userTheme={userTheme}
        userTestRecommendations={userTestRecommendations}
        userSubmissions={userSubmissions}
      />
    );

  return (
    <CustomerPage>
      <DashboardSwitcher />
      <CustomerTabs selectedTab="/dashboard" />
      {content}
    </CustomerPage>
  );
}

export default Dashboard;
