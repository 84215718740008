import { useCallback, useContext, useMemo, useRef } from "react";

import Flicking from "@egjs/react-flicking";
import { Redirect, useHistory } from "react-router-dom";

import Box from "@/components/Box";
import { theme } from "@/core/theme";
import {
  getQuestionnaireIntroUrl,
  getQuestionnaireOutroUrl,
  getQuestionnaireStepUrl
} from "@/core/urls";
import { QUESTIONNAIRE_QUERY } from "@/graphql/tpo/content/queries";
import { SUBMIT_USER_ANSWER_MUTATION } from "@/graphql/tpo/results/mutations";
import ButtonV2, { PageButton } from "@/v2/Buttons";
import { useMutation } from "@apollo/client";
import { isNumber, isString } from "lodash";

import ChevronComponent from "./Chevron";
import { HeadingExtraExtraSmall } from "./Headings";
import SectionProgressBar from "./QuestionSectionProgressBar";
import Answers from "./QuestionnaireAnswers";
import { SubmitButton } from "./QuestionnaireButtons";
import {
  AnswersContext,
  DownloadQuestionsContext,
  SubmissionContext,
  SectionsContext
} from "./QuestionnaireContexts";
import {
  useDownloadQuestions,
  usePercentageAnswers,
  useSection,
  useSubmission
} from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import { Meta as BaseMeta } from "./QuestionnaireQuestion";
import Questions from "./QuestionnaireQuestions";
import { padQuestionNumber } from "./QuestionnaireUtilities";
import Spacer from "./Spacer";

function hasEarlierInCompleteSections(sectionsObj, currentSection) {
  const found = Object.entries(sectionsObj)
    .map(([section, info]) => ({ section: +section, complete: info.complete }))
    .find(({ section, complete }) => section < currentSection && !complete);
  if (found) {
    return found.section;
  }
  return false;
}

function Sections({ children, getSectionUrl }) {
  const section = useSection();
  const submission = useContext(SubmissionContext);
  const sectionsObj = submission?.sectionStatus;

  if (sectionsObj) {
    if (!(section in sectionsObj)) {
      return <Redirect to={getQuestionnaireIntroUrl("symptoms")} />;
    }
    const earlierSection = hasEarlierInCompleteSections(sectionsObj, section);
    if (isNumber(earlierSection)) {
      return <Redirect to={getSectionUrl(earlierSection)} />;
    }
  }

  return <SectionsContext.Provider value={sectionsObj}>{children}</SectionsContext.Provider>;
}

function DownloadQuestionsForCompleteQuestionnaire({ children, query, variables }) {
  const { loading, questions, answers } = useDownloadQuestions({ query, variables });

  const api = useMemo(
    () => ({
      loading,
      questions: questions || [],
      answers: answers || []
    }),
    [loading, questions, answers]
  );

  return (
    <DownloadQuestionsContext.Provider value={api}>{children}</DownloadQuestionsContext.Provider>
  );
}

function Meta({ questionIndex }) {
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const section = useSection();

  return (
    <BaseMeta
      leftText={
        questionIndex === 0
          ? `Section ${padQuestionNumber(section, Object.keys(sections).length)}`
          : undefined
      }
      rightText={`${padQuestionNumber(questionIndex + 1, questions.length)}/${questions.length}`}
    />
  );
}

function Navigation() {
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const { answered, setShowErrors } = useContext(AnswersContext);
  const section = useSection();
  const history = useHistory();

  return (
    <Box display="flex" alignItems="center" mt={4}>
      {section > 1 && (
        <ButtonV2
          color="white"
          mr={2}
          onClick={() => {
            history.push(getQuestionnaireStepUrl("symptoms", +section - 1));
          }}
          leftIcon={
            <ChevronComponent
              direction="left"
              fill={theme.colors.dark}
              style={{
                marginRight: "10px"
              }}
            />
          }
          sx={{
            color: "dark"
          }}
        >
          Back
        </ButtonV2>
      )}
      <SubmitButton
        onClick={() => {
          if (questions.map(question => question.id).every(id => id in answered)) {
            +section + 1 <= Object.keys(sections).length
              ? history.push({
                  pathname: getQuestionnaireStepUrl("symptoms", +section + 1),
                  state: {
                    section: +section + 1
                  }
                })
              : history.push({
                  pathname: getQuestionnaireOutroUrl("symptoms"),
                  state: {
                    fromSection: true
                  }
                });
          } else {
            setShowErrors(true);
          }
        }}
        text={Object.keys(sections).length === +section ? "Submit" : "Next"}
      />
    </Box>
  );
}

export function allQuestionsHaveBeenAnswered(questions, answers) {
  return questions?.length && !questions.map(question => question.id).every(id => id in answers);
}

const PAGE_BUTTON_WIDTHS = [40, 40, 50];

// const PageButton = forwardRef(({ label, url, bg, color, ...rest }, ref) => (
//   <ButtonV2
//     ref={ref}
//     sx={{
//       minWidth: PAGE_BUTTON_WIDTHS,
//       maxWidth: PAGE_BUTTON_WIDTHS,
//       width: PAGE_BUTTON_WIDTHS,
//       maxHeight: PAGE_BUTTON_WIDTHS,
//       minHeight: PAGE_BUTTON_WIDTHS,
//       height: PAGE_BUTTON_WIDTHS,
//       bg: bg,
//       borderColor: bg,
//       color: color,
//       fontSize: 18,
//       px: 0,
//       py: 0,
//       ...rest
//     }}
//   >
//     {label}
//   </ButtonV2>
// ));

const SECTION_BUTTON_GAP = 10; // pixels

function SectionPagination({ getSectionUrl }) {
  const flickingApiRef = useRef();

  const urlSection = useSection();
  const sections = useContext(SectionsContext);
  const { questions } = useContext(DownloadQuestionsContext);
  const { answered, setShowErrors } = useContext(AnswersContext);
  const history = useHistory();

  if (!sections || !Object.entries(sections).length) {
    return null;
  }

  return (
    <Flicking
      ref={flickingApiRef}
      moveType="freeScroll"
      align="center"
      bound
      key={urlSection}
      style={{
        paddingLeft: 20,
        paddingRight: 20
      }}
    >
      {Object.entries(sections).map(([section, info]) => (
        <PageButton
          mx={1}
          width={[40, 40, 48]}
          bg={(function () {
            if (urlSection === +section) {
              return "black";
            } else if (info.complete) {
              return "green";
            }
            return "haze";
          })()}
          color={(function () {
            if (urlSection === +section) {
              return "white";
            } else if (info.complete) {
              return "white";
            }
            return "black";
          })()}
          label={+section}
          url={getSectionUrl(+section)}
          onClick={() => {
            if (+section > urlSection) {
              // going forwards
              if (questions?.length && allQuestionsHaveBeenAnswered(questions, answered)) {
                setShowErrors(true);
                return;
              }
            }
            setShowErrors(false);
            history.push({
              pathname: getQuestionnaireStepUrl("symptoms", +section),
              state: {
                section
              }
            });
          }}
          key={section}
        />
      ))}
    </Flicking>
  );
}

const calculateProgressBarWidth = (sectionsTotal, buttonWidth) =>
  sectionsTotal * buttonWidth + SECTION_BUTTON_GAP * (sectionsTotal - 1);

function SectionQuestionnaireProgressBar(props) {
  const percentage = usePercentageAnswers();
  const sections = useContext(SectionsContext);

  if (!sections) return null;

  if (!isString(percentage)) {
    return (
      <Box
        maxWidth="100%"
        mt={2}
        width={PAGE_BUTTON_WIDTHS.map(
          buttonWidth => `${calculateProgressBarWidth(Object.keys(sections).length, buttonWidth)}px`
        )}
        {...props}
      >
        <HeadingExtraExtraSmall
          fontSize={10}
          mb={2}
          textTransform="uppercase"
        >{`section complete: ...loading`}</HeadingExtraExtraSmall>
        <SectionProgressBar />
      </Box>
    );
  }

  return (
    <Box
      maxWidth="100%"
      mt={2}
      width={PAGE_BUTTON_WIDTHS.map(
        buttonWidth => `${calculateProgressBarWidth(Object.keys(sections).length, buttonWidth)}px`
      )}
      {...props}
    >
      <HeadingExtraExtraSmall
        fontSize={10}
        mb={2}
        textTransform="uppercase"
      >{`section complete: ${+percentage}%`}</HeadingExtraExtraSmall>
      <SectionProgressBar />
    </Box>
  );
}

function CompleteQuestionnaireQuestions() {
  const submission = useSubmission();
  const section = useSection();

  const getSectionUrl = useCallback(section => {
    return getQuestionnaireStepUrl("symptoms", section);
  }, []);

  const redirect = useCallback(sec => getQuestionnaireStepUrl("symptoms", sec), []);

  const [submitAnswerMutation] = useMutation(SUBMIT_USER_ANSWER_MUTATION);

  const onSelectMutation = useCallback(
    ({ submission, answered, questionId, answer, setAnswered }) =>
      submitAnswerMutation({
        variables: {
          input: {
            submission: submission.id,
            question: questionId.toString(),
            answer: answer.toString()
          }
        }
      }).then(res => {
        if (res.data?.submitUserAnswerMutation?.errors?.length !== 0) {
          setAnswered({
            ...answered
          });
        }
      }),
    [submitAnswerMutation]
  );

  return (
    <SubmissionContext.Provider value={submission}>
      <Sections getSectionUrl={getSectionUrl}>
        <DownloadQuestionsForCompleteQuestionnaire
          query={QUESTIONNAIRE_QUERY}
          getRedirectUrl={redirect}
          variables={{
            presentationSection: +section,
            userSubmissionId: submission?.id
          }}
        >
          <Answers>
            <Page
              Main={
                <Questions
                  navigation={<Navigation />}
                  QuestionMetaComponent={Meta}
                  onSelectMutation={onSelectMutation}
                />
              }
              Footer={
                <>
                  <Spacer pt={122} />
                  <Box bg="white" position="fixed" py={4} left={0} bottom={0} width="100vw">
                    <SectionPagination getSectionUrl={getSectionUrl} />
                    <SectionQuestionnaireProgressBar mx="auto" px={2} pt={40} />
                  </Box>
                </>
              }
            />
          </Answers>
        </DownloadQuestionsForCompleteQuestionnaire>
      </Sections>
    </SubmissionContext.Provider>
  );
}

function CompleteQuestionnaireQuestionsWrapper() {
  const section = useSection();
  return <CompleteQuestionnaireQuestions key={section} />;
}

export default CompleteQuestionnaireQuestionsWrapper;
