export default class ValidatedQueryParameters {
  constructor({ search, defaultFilters, allFilters }) {
    this.search = search;
    this.defaultFilters = defaultFilters;
    this.allFilters = allFilters;
  }

  applyFilter(key, value) {
    if (Array.isArray(this.defaultFilters[key])) {
      const params = new URLSearchParams(this.search);
      params.append(key, value);
      return params.toString();
    } else {
      const params = new URLSearchParams(this.search);
      params.set(key, value);
      return params.toString();
    }
  }

  removeFilter(key, value) {
    if (Array.isArray(this.defaultFilters[key])) {
      const params = new URLSearchParams(this.search);
      params.delete(key, value);
      return params.toString();
    } else {
      const params = new URLSearchParams(this.search);
      params.delete(key);
      return params.toString();
    }
  }

  toggleFilter(group, value) {
    const params = new URLSearchParams(this.search);
    if (params.has(group, value)) {
      return this.removeFilter(group, value);
    }
    return this.applyFilter(group, value);
  }

  get validatedFilters() {
    return [...new URLSearchParams(this.search).entries()]
      .filter(([k]) => k in this.allFilters)
      .map(([k, values]) => [k, values.split(",")])
      .filter(([k, values]) => values.every(v => this.allFilters[k].includes(v)))
      .reduce(
        (mergedObj, [k, v]) => {
          if (Array.isArray(this.defaultFilters[k])) {
            if (k in mergedObj) {
              return {
                ...mergedObj,
                [k]: [...mergedObj[k], ...v]
              };
            } else {
              return {
                ...mergedObj,
                [k]: v
              };
            }
          } else {
            return {
              ...mergedObj,
              [k]: v
            };
          }
        },
        {
          ...Object.entries(this.defaultFilters).reduce((obj, [k, v]) => {
            if (v !== undefined) {
              return { ...obj, [k]: Array.isArray(v) ? v : [v] };
            }
            return obj;
          }, {})
        }
      );
  }
}
