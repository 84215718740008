import AlbaniaIcon from "@/images/country/albania.svg?react";
import AustraliaIcon from "@/images/country/australia.svg?react";
import AustriaIcon from "@/images/country/austria.svg?react";
import BahrainIcon from "@/images/country/bahrain.svg?react";
import BelarusIcon from "@/images/country/belarus.svg?react";
import BelgiumIcon from "@/images/country/belgium.svg?react";
import BermudaIcon from "@/images/country/bermuda.svg?react";
import BulgariaIcon from "@/images/country/bulgaria.svg?react";
import CanadaIcon from "@/images/country/canada.svg?react";
import CanaryIslandsIcon from "@/images/country/canary-islands.svg?react";
import CroatiaIcon from "@/images/country/croatia.svg?react";
import CyprusIcon from "@/images/country/cyprus.svg?react";
import CzechRepublicIcon from "@/images/country/czech-republic.svg?react";
import DenmarkIcon from "@/images/country/denmark.svg?react";
import EstoniaIcon from "@/images/country/estonia.svg?react";
import FinlandIcon from "@/images/country/finland.svg?react";
import FranceIcon from "@/images/country/france.svg?react";
import GermanyIcon from "@/images/country/germany.svg?react";
import GibraltarIcon from "@/images/country/gibraltar.svg?react";
import GreeceIcon from "@/images/country/greece.svg?react";
import GuernseyIcon from "@/images/country/guernsey.svg?react";
import HungaryIcon from "@/images/country/hungary.svg?react";
import IcelandIcon from "@/images/country/iceland.svg?react";
import IndiaIcon from "@/images/country/india.svg?react";
import IrelandIcon from "@/images/country/ireland.svg?react";
import IsleOfManIcon from "@/images/country/isle-of-man.svg?react";
import ItalyIcon from "@/images/country/italy.svg?react";
import JerseyIcon from "@/images/country/jersey.svg?react";
import LatviaIcon from "@/images/country/latvia.svg?react";
import LiechtensteinIcon from "@/images/country/liechtenstein.svg?react";
import LithuaniaIcon from "@/images/country/lithuania.svg?react";
import LuxembourgIcon from "@/images/country/luxembourg.svg?react";
import MaltaIcon from "@/images/country/malta.svg?react";
import NetherlandsIcon from "@/images/country/netherlands.svg?react";
import NewZealandIcon from "@/images/country/new-zealand.svg?react";
import NorwayIcon from "@/images/country/norway.svg?react";
import PolandIcon from "@/images/country/poland.svg?react";
import PortugalIcon from "@/images/country/portugal.svg?react";
import QatarIcon from "@/images/country/qatar.svg?react";
import RomaniaIcon from "@/images/country/romania.svg?react";
import SaudiArabiaIcon from "@/images/country/saudi_arabia.svg?react";
import SerbiaIcon from "@/images/country/serbia.svg?react";
import SingaporeIcon from "@/images/country/singapore.svg?react";
import SlovakiaIcon from "@/images/country/slovakia.svg?react";
import SloveniaIcon from "@/images/country/slovenia.svg?react";
import SouthAfricaIcon from "@/images/country/south_africa.svg?react";
import SpainIcon from "@/images/country/spain.svg?react";
import SwedenIcon from "@/images/country/sweden.svg?react";
import SwitzerlandIcon from "@/images/country/switzerland.svg?react";
import TurkeyIcon from "@/images/country/turkey.svg?react";
import UnitedKingdomIcon from "@/images/country/united-kingdom.svg?react";
import UAEIcon from "@/images/country/united_arab_emirates.svg?react";
import USIcon from "@/images/country/us.svg?react";

const ICONS = {
  AL: AlbaniaIcon,
  AU: AustraliaIcon,
  AT: AustriaIcon,
  BY: BelarusIcon,
  BE: BelgiumIcon,
  BH: BahrainIcon,
  BM: BermudaIcon,
  BG: BulgariaIcon,
  CA: CanadaIcon,
  IC: CanaryIslandsIcon,
  CY: CyprusIcon,
  CZ: CzechRepublicIcon,
  DK: DenmarkIcon,
  EE: EstoniaIcon,
  FI: FinlandIcon,
  FR: FranceIcon,
  DE: GermanyIcon,
  GI: GibraltarIcon,
  GR: GreeceIcon,
  GG: GuernseyIcon,
  HU: HungaryIcon,
  IS: IcelandIcon,
  IN: IndiaIcon,
  IE: IrelandIcon,
  IM: IsleOfManIcon,
  IT: ItalyIcon,
  JE: JerseyIcon,
  LV: LatviaIcon,
  LI: LiechtensteinIcon,
  LT: LithuaniaIcon,
  LU: LuxembourgIcon,
  MT: MaltaIcon,
  NL: NetherlandsIcon,
  NZ: NewZealandIcon,
  NO: NorwayIcon,
  PL: PolandIcon,
  PT: PortugalIcon,
  RO: RomaniaIcon,
  SG: SingaporeIcon,
  SK: SlovakiaIcon,
  SI: SloveniaIcon,
  ES: SpainIcon,
  SE: SwedenIcon,
  CH: SwitzerlandIcon,
  GB: UnitedKingdomIcon,
  US: USIcon,
  RS: SerbiaIcon,
  HR: CroatiaIcon,
  QA: QatarIcon,
  SA: SaudiArabiaIcon,
  ZA: SouthAfricaIcon,
  TR: TurkeyIcon,
  AE: UAEIcon
};

export default ICONS;
