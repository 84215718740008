import { FadeTransition } from "@/components/animations/FadeTransition";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { ORGANISATION_USER_TESTS_FOR_REVIEW_QUERY } from "@/graphql/organisations/queries/patients";
import { useDataLoader } from "@/hooks/useDataLoader";

import NewResultsAvailable from "./ui/NewResultsAvailable";
import PatientsTemplate from "./ui/Template";

export default function NewResults() {
  const { organisation } = useOrganisationContext();

  const { data: availableResults } = useDataLoader({
    query: ORGANISATION_USER_TESTS_FOR_REVIEW_QUERY,
    fetchPolicy: "cache-first",
    variables: {
      organisation: parseInt(organisation?.id)
    },
    skip: !organisation
  });

  return (
    <PatientsTemplate active="new_results">
      <FadeTransition in>
        <NewResultsAvailable userTests={availableResults?.organisationUserTestsForReview} />
      </FadeTransition>
    </PatientsTemplate>
  );
}
