import Box from "@/components/Box";
import Loading from "@/components/Loading";
import Text from "@/components/Text";
import { theme } from "@/core/theme";
import { useApolloClient, useQuery } from "@apollo/client";

export function useDataLoader({ query, fetchPolicy = "network-only", ...props }) {
  const client = useApolloClient();

  const { data, loading, error, refetch, startPolling, stopPolling, fetchMore } = useQuery(query, {
    client,
    fetchPolicy,
    ...props
  });

  let rendered = loading ? (
    <Loading />
  ) : error ? (
    <Box px={20} py={theme.spacing.medium}>
      <Box
        maxWidth={600}
        mx="auto"
        bg="slate"
        color="white"
        p={theme.spacing.medium}
        borderRadius="5px"
      >
        <Text fontFamily="gilroyBold">
          I'm sorry, an error has occured, if you need assistance please contact support@omnos.me
        </Text>
      </Box>
    </Box>
  ) : null;

  return {
    client,
    rendered,
    loading,
    data,
    error,
    refetch,
    stopPolling,
    startPolling,
    fetchMore
  };
}
