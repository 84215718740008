import { useCallback, useState } from "react";

import Box from "@/components/Box";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { SET_ORGANISATION_AS_DEFAULT_FOR_PRACTITIONER_MUTATION } from "@/graphql/organisations/mutations";
import { ORGANISATION_LIST_QUERY } from "@/graphql/organisations/queries/organisation";
import PlusIcon from "@/images/thin-plus";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import Group from "@/tpo/Group";
import Menu, { Button } from "@/tpo/Menu";
import ButtonV2 from "@/v2/Buttons";
import { useMutation } from "@apollo/client";

import UpdateOrganisationNameModal from "./UpdateOrganisationNameModal";

export default function OrganisationSwitcher() {
  const {
    availableOrganisations,
    organisation,
    setOrganisationId,
    setShowCreateOrganisationModal,
    setShowUpdateOrganisationNameModal
  } = useOrganisationContext();
  const [showOrgMenu, setShowOrgMenu] = useState(false);

  const [setOrganisationAsDefaultForPractitionerMutation] = useMutation(
    SET_ORGANISATION_AS_DEFAULT_FOR_PRACTITIONER_MUTATION,
    {
      update: (cache, { data }) => {
        if (data?.setOrganisationAsDefaultForPractitionerMutation?.errors.length === 0) {
          const orgAffectedByMutation =
            data?.setOrganisationAsDefaultForPractitionerMutation?.organisationPractitioner
              ?.organisation;

          const orgs = cache.readQuery({
            query: ORGANISATION_LIST_QUERY
          }).organisations;

          // set all but the affected one to false
          // server response from mutation will update cache for the affected
          orgs
            .filter(o => o.id !== orgAffectedByMutation.id)
            .forEach(o => {
              cache.modify({
                id: `CmpOrganisationType:${o.id}`,
                fields: {
                  isDefault() {
                    return false;
                  }
                }
              });
            });
        }
      }
    }
  );

  const setOrganisationAsDefaultForPractitioner = useCallback(
    isDefault => {
      setOrganisationAsDefaultForPractitionerMutation({
        variables: {
          input: {
            organisation: organisation?.id,
            isDefault
          }
        }
      });
    },
    [setOrganisationAsDefaultForPractitionerMutation, organisation]
  );

  return (
    <>
      <Center stacked gap={[20, 20, 40]}>
        <Menu opened={showOrgMenu} onChange={setShowOrgMenu} trigger="hover">
          <Menu.Target>
            <Button
              data-component-name="OrganisationMenuButton"
              open={showOrgMenu}
              color="dark"
              sx={{
                color: "white"
              }}
            >
              Organisation
            </Button>
          </Menu.Target>
          <Menu.Dropdown
            mt={2}
            py={2}
            zIndex={2}
            minWidth={200}
            maxHeight={500}
            overflow="auto"
            bg="dark"
            data-component-name="OrganisationMenuDropdown"
          >
            {availableOrganisations
              ?.map(({ id: value, name: label }) => ({
                label: label,
                value
              }))
              .map(sortOption => (
                <Menu.Item
                  onClick={() => setOrganisationId(sortOption.value)}
                  selected={sortOption.value === organisation?.id || ""}
                  key={sortOption.value}
                  fontSize={14}
                  lineHeight={1.7}
                  py={16}
                  color="white"
                  selectedColor="rgba(255, 255, 255, 0.10)"
                >
                  {sortOption.label}
                </Menu.Item>
              ))}
            <Menu.Item
              onClick={() => {
                setShowCreateOrganisationModal(true);
              }}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              alignItems="center"
            >
              <PlusIcon color="white" style={{ marginRight: 10 }} size={16} />
              Add new organisation
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Group flexWrap="wrap" gap={10} alignItems="center" justifyContent="center">
          {organisation?.name && (
            <Box
              fontFamily="gilroyBold"
              fontSize={[24, 24, 28]}
              data-component-name="OrganisationName"
              textAlign="center"
            >
              {organisation?.name}
            </Box>
          )}
          <ButtonV2
            color="green"
            size="xs"
            onClick={() => {
              setShowUpdateOrganisationNameModal(true);
            }}
            data-component-name="EditOrganisationNameButton"
          >
            edit
          </ButtonV2>
        </Group>
        {/* <Box>
          <Checkbox
            onChange={e => {
              setOrganisationAsDefaultForPractitioner(e.target.checked);
            }}
            label="Set as default organisation:"
            styles={{
              wrapperProps: {
                flexDirection: "row-reverse"
              },
              labelProps: {
                fontFamily: "gilroyBold",
                fontSize: [14, 14, 16]
              }
            }}
            size="sm"
            value={organisation.isDefault}
          />
        </Box> */}
      </Center>
      <UpdateOrganisationNameModal />
    </>
  );
}
