import { gql } from "@apollo/client";

import { TEST_DATA_FILE_FIELDS } from "./tpo/matrix/types";

export const AREA_OF_INTEREST_FIELDS = gql`
  fragment AreaOfInterestFields on AreaOfInterestType {
    id
    name
    description
  }
`;

export const TEST_PRODUCT_INFORMATION_PANEL_FIELDS = gql`
  fragment TestProductInformationPanelFields on TestProductInformationPanelType {
    id
    title
    body
    order
    displayContext
  }
`;

export const TEST_PRODUCT_CONTENT_FIELDS = gql`
  fragment TestProductContentFields on TestProductContentType {
    id
    shortDescription
    description
    categories: areasOfInterest {
      ...AreaOfInterestFields
    }
    numOfBiomarkersTested
    processingTime
    biomarkersTested
    relatedSymptoms
    testType
    howItWorksPanel {
      id
      name
      title
      body
      displayContext
    }
    collectingYourSampleImage
    collectingYourSampleVideo
    informationPanels {
      ...TestProductInformationPanelFields
    }
    centrifuge
    iconType
  }
  ${AREA_OF_INTEREST_FIELDS}
  ${TEST_PRODUCT_INFORMATION_PANEL_FIELDS}
`;

export const CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTION_FIELDS = gql`
  fragment ConsumerTestProductWithOptionsConnectionFields on TestProductType {
    id
    addons {
      id
      paymentIsoCode
      organisation
    }
    status
    paymentIsoCode
    organisation
    slug
    productAudience
    userTestRecommendationRank
    matchedSymptoms
    name
    sampleTypes
    rrpCurrentPrice
    rrpFullPrice
    isPractitionerFavourite
    isMostPopularTest
    laboratory {
      id
      name
    }
    content {
      ...TestProductContentFields
      iconType
      image1
      image2
      image3
    }
    testDataFiles {
      ...TestDataFileFields
    }
    options {
      id
      paymentIsoCode
      organisation
    }
  }
  ${TEST_DATA_FILE_FIELDS}
  ${TEST_PRODUCT_CONTENT_FIELDS}
`;
