import { useHistory, useLocation } from "react-router-dom";

import Box from "@/components/Box";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { theme } from "@/core/theme";
import {
  DASHBOARD_HOME_URL,
  PARTNERS_AFFILIATE_LINKS_URL,
  PARTNERS_COMMISSIONS_URL,
  PARTNERS_PROFILE_URL
} from "@/core/urls";
import useLogOut from "@/hooks/header/useLogOut";
import UserIcon from "@/images/user.svg?react";
import Drawer from "@/tpo/Drawer";
import Group from "@/tpo/Group";
import NavLink from "@/tpo/NavLink";

export default function AffiliateDrawer({ bg }) {
  const { opened, close, onClick } = useHeaderContext();
  const location = useLocation();
  const history = useHistory();

  const logout = useLogOut();

  return (
    <Drawer.Provider maxWidth={700} opened={opened} close={close}>
      <Drawer.Root bg={bg}>
        <Group height={60}>
          <Drawer.CloseButton color="white" position="fixed" right={[20, 20, "5.5vw"]} top={20} />
        </Group>
        <Box
          pt={theme.spacing.section.pt}
          pb={theme.spacing.section.pb}
          pl={[30, 30, 60]}
          pr={[20, 20, "5.5vw"]}
          overflow="auto"
        >
          <NavLink
            fontSize={24}
            color="white"
            label="Account"
            leftIcon={<UserIcon fill="white" width={20} />}
          >
            <NavLink
              color="white"
              fontSize={16}
              label="Personal"
              onClick={() => {
                onClick();
                history.push(DASHBOARD_HOME_URL);
              }}
            />
            <NavLink
              color="white"
              fontSize={16}
              label="Partner"
              active
              onClick={() => {
                onClick();
                history.push(PARTNERS_PROFILE_URL);
              }}
            />
          </NavLink>
          <NavLink
            color="white"
            label="Profile"
            fontSize={24}
            active={location.pathname === PARTNERS_PROFILE_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_PROFILE_URL);
            }}
          />
          <NavLink
            color="white"
            label="Commission"
            fontSize={24}
            active={location.pathname === PARTNERS_COMMISSIONS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_COMMISSIONS_URL);
            }}
          />
          <NavLink
            color="white"
            label="Affiliate links"
            fontSize={24}
            active={location.pathname === PARTNERS_AFFILIATE_LINKS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_AFFILIATE_LINKS_URL);
            }}
          />
          <NavLink color="red" label="Logout" onClick={logout} />
        </Box>
      </Drawer.Root>
    </Drawer.Provider>
  );
}
