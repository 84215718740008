import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import DataLoader from "@/components/DataLoader";
import Page from "@/components/Page";
import OrganisationCheckout from "@/components/organisations/checkout/OrganisationCheckout";
import OrganisationOrders from "@/components/organisations/orders";
import OrganisationViewOrder from "@/components/organisations/orders/OrganisationViewOrder";
import { ERROR_404_URL } from "@/core/urls";
import { PARTNER_USER_PROFILE_QUERY } from "@/graphql/accounts";
import useFirstAvailableOrganisation from "@/hooks/organisations/useFirstAvailableOrganisation";
import useDocTitle from "@/hooks/use-doc-title";
import DashboardSwitcher from "@/tpo/DashboardSwitcher";
import PartnerRoute from "@/tpo/PartnerRoute";

import OrganisationTab from "./business";
import OrganisationCommissions from "./comissions/OrganisationCommissions";
import {
  CMP_PERMISSION_ADMIN,
  CMP_PERMISSION_PLACE_ORDERS,
  CMP_PERMISSION_VIEW_RESULTS
} from "./constants";
import KnowledgeBase from "./learningHub";
import OrganisationPatients from "./patients";
import PractitionerProfile from "./profile/PractitionerProfile";
import OrganisationShop from "./shop/OrganisationShop";
import Support from "./support/Support";

export default function OrganisationDashboard() {
  const match = useRouteMatch();

  const { loadingElement } = useFirstAvailableOrganisation();

  if (loadingElement) return loadingElement;

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/profile`} />
      </Route>
      <PartnerRoute permissionsRequired={[]} exact path={`${match.path}/profile`}>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <PractitionerProfile />
        </Page>
      </PartnerRoute>
      <PartnerRoute permissionsRequired={[CMP_PERMISSION_ADMIN]} path={`${match.path}/business`}>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationTab />
        </Page>
      </PartnerRoute>
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_VIEW_RESULTS]}
        path={`${match.path}/patients`}
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationPatients />
        </Page>
      </PartnerRoute>
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        exact
        path={`${match.path}/commissions`}
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationCommissions />
        </Page>
      </PartnerRoute>
      <Redirect from={`${match.path}/affiliate_links`} to={`${match.path}/profile`} />
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        exact
        path={`${match.path}/checkout`}
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationCheckout />
        </Page>
      </PartnerRoute>
      <PartnerRoute exact permissionsRequired={[]} path={`${match.path}/support`}>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <Support />
        </Page>
      </PartnerRoute>
      <PartnerRoute permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]} path={`${match.path}/shop`}>
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationShop />
        </Page>
      </PartnerRoute>
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        path={`${match.path}/order/:orderId`}
        exact
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationViewOrder />
        </Page>
      </PartnerRoute>
      <PartnerRoute
        permissionsRequired={[CMP_PERMISSION_PLACE_ORDERS]}
        path={`${match.path}/orders`}
      >
        <Page bg="haze" includeFooter addNavBarHeight={false}>
          <DashboardSwitcher bg="partners" isPartnerPage />
          <OrganisationOrders />
        </Page>
      </PartnerRoute>
      <PartnerRoute
        permissionsRequired={[]}
        path={`${match.path}/learning_hub`}
        component={KnowledgeBase}
      />
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
