import { useEffect } from "react";

import Loading from "@/components/Loading";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";

export default function useFirstAvailableOrganisation() {
  const {
    organisationId,
    setOrganisationId,
    availableOrganisations,
    loading
  } = useOrganisationContext();

  // For now set the organisation to the first one in the list
  useEffect(() => {
    if (availableOrganisations?.length && !organisationId) {
      setOrganisationId(availableOrganisations[0].id);
    }
  }, [availableOrganisations, setOrganisationId, organisationId]);

  return {
    loadingElement: !availableOrganisations?.length && loading ? <Loading /> : null
  };
}
