import React, { createContext, useEffect, useState } from "react";

import { theme } from "@/core/theme";
import Center from "@/tpo/Center";
import ButtonV2 from "@/v2/Buttons";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import Box from "./Box";
import Errors from "./Errors";

export const FormContext = createContext();

export function compareCamelCaseToSnakeCase(camelCase, snakeCase) {
  if (!camelCase || !snakeCase) return;
  const camelCaseNormalized = camelCase.toLowerCase();
  const snakeCaseNormalized = snakeCase.replace(/_/g, "");
  const matched = camelCaseNormalized === snakeCaseNormalized;
  return matched;
}

export function flattenFormData(formData) {
  // Takes a form data object and returns a flatted list of field data objects without the parent fieldSets
  const fieldArrays = formData.map(fieldSet => {
    return fieldSet.fields;
  }, []);
  return fieldArrays.flat();
}

export function fieldNamesFromFlatData(flatFormData) {
  return flatFormData.map(fieldData => {
    return fieldData.name;
  });
}

export function sortErrors(errors, flatFieldNames) {
  // Sort the array of errors that come back from django according to a supplied flatFieldNames array,
  // so we can match the FE ordering

  // First add an index field to the errors array, based on the index of the corresponding item in the flatFieldNames array
  const errorsWithIndexes = errors.map(error => {
    // Provide a default in case a matching item is not found (ie. non-field errors)
    let fieldIndex = 0;
    const matchedFieldIndex = flatFieldNames.findIndex(fieldName => {
      return compareCamelCaseToSnakeCase(fieldName, error.field);
    });
    if (matchedFieldIndex !== -1) {
      fieldIndex = matchedFieldIndex;
    }
    return { ...error, fieldIndex };
  });

  return errorsWithIndexes.sort((a, b) => {
    return a.fieldIndex - b.fieldIndex;
  });
}

export function getInitialValuesFromData(formData) {
  const values = formData.reduce((acc, curr) => {
    curr.fields.map(field => {
      acc[field.name] = field.initialValue;
      return null;
    });
    return acc;
  }, {});
  return values;
}

export function serializeValues(values, flatFormData) {
  return Object.keys(values).reduce((accObj, currKey) => {
    const value = values[currKey];
    const fieldDataItem = flatFormData.find(fieldData => {
      return fieldData.name === currKey;
    });
    if (!fieldDataItem || fieldDataItem.excludeFromSubmission) {
      return accObj;
    } else {
      if (fieldDataItem.serializer === undefined) {
        return { ...accObj, [currKey]: value };
      } else {
        return { ...accObj, [currKey]: fieldDataItem.serializer(value, values) };
      }
    }
  }, {});
}

export function FormField({
  component: Component,
  initialValue,
  handleChange,
  name,
  value,
  errorField,
  values,
  ...rest
}) {
  const hasError = compareCamelCaseToSnakeCase(name, errorField);

  return (
    <Component
      fieldName={name}
      initialValue={initialValue}
      value={value}
      error={hasError}
      handleChange={newValue => {
        handleChange({ ...values, [name]: newValue });
      }}
      {...rest}
    />
  );
}

export function FormFields({ formData, values, handleChange, errorField, hiddenFieldSets }) {
  return formData.map((fieldSet, fieldSetIndex) => {
    const hideFieldSet = fieldSet.name !== undefined && hiddenFieldSets.includes(fieldSet.name);

    return (
      <Box key={fieldSetIndex} display={hideFieldSet ? "none" : undefined}>
        {fieldSet.header && <>{fieldSet.header}</>}
        {fieldSet.fields.map((field, fieldIndex) => {
          const Component = field.widget.component;
          const fieldName = fieldSet.fields[fieldIndex].name;
          const hasError = compareCamelCaseToSnakeCase(fieldName, errorField);
          return (
            <Box key={fieldIndex} {...field.boxProps} data-component-name={fieldName}>
              <Component
                fieldName={field.name}
                initialValue={field.initialValue}
                value={values[fieldName]}
                error={hasError}
                handleChange={newValue => {
                  handleChange({ ...values, [fieldName]: newValue });
                }}
                {...field.widget.props}
              />
            </Box>
          );
        })}
        {fieldSet.footer && <>{fieldSet.footer}</>}
      </Box>
    );
  });
}

FormFields.defaultProps = {
  hiddenFieldSets: []
};

export function useForm(
  formData,
  mutation,
  extraInput,
  refetchQueries,
  awaitRefetchQueries,
  handleSubmitted,
  stickySubmitting
) {
  const apolloClient = useApolloClient();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorField, setErrorField] = useState(null);
  const [values, setValues] = useState(getInitialValuesFromData(formData));

  useEffect(() => {
    // If the shape of the form changes, re-initialise the base values, but then mix back in any currently set values
    setValues({ ...getInitialValuesFromData(formData), ...values });
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    setErrors("");
    setErrorField(null);
  }, [values]);

  function submit() {
    setSubmitting(true);
    setErrors("");
    setErrorField(null);

    const flatFormData = flattenFormData(formData);
    const flatFieldNames = fieldNamesFromFlatData(flatFormData);
    const valuesSerialized = serializeValues(values, flatFormData);
    const input = { ...valuesSerialized, ...extraInput };

    apolloClient
      .mutate({
        mutation,
        variables: {
          input
        },
        refetchQueries,
        awaitRefetchQueries
      })
      .then(result => {
        const outputKeyName = Object.keys(result.data)[0];
        const output = result.data[outputKeyName];
        const errors = output.errors;

        if (errors.length) {
          const sortedErrors = sortErrors(errors, flatFieldNames);
          const nextError = sortedErrors[0];
          setErrors(nextError.messages.join(", "));
          setErrorField(nextError.field);
          setSubmitting(false);
        } else {
          if (!stickySubmitting) {
            setSubmitting(false);
          }
          handleSubmitted(output);
        }
      })
      .catch(error => {
        setSubmitting(false);
        console.log("submit Error :", error);
        Sentry.captureException(error);
        setErrors(error.message);
      });
  }

  return { values, setValues, errors, errorField, submit, submitting };
}

/**
 * Deprecated. Please use 'react-hook-form' instead.
 * All instances of this form will need to be eventually replaced by 'react-hook-form'.
 * 'react-hook-form' is super light and performant and popular.  It also has lots of features.
 * See the partner dashboard for examples of this new form.
 */

function Form({
  data: formData,
  handleSubmitted,
  mutation,
  extraInput,
  refetchQueries,
  novalidate,
  maxWidth,
  handleChange,
  hiddenFieldSets,
  stickySubmitting,
  awaitRefetchQueries,
  buttonText,
  Button,
  extraErrors = null,
  formWrapperProps = {}
}) {
  const formApi = useForm(
    formData,
    mutation,
    extraInput,
    refetchQueries,
    awaitRefetchQueries,
    handleSubmitted,
    stickySubmitting
  );

  return (
    <FormContext.Provider value={formApi}>
      <Box maxWidth={maxWidth || 500} mx="auto" {...formWrapperProps}>
        <form noValidate={novalidate}>
          <FormFields
            formData={formData}
            values={formApi.values}
            handleChange={values => {
              formApi.setValues(values);
              if (handleChange) {
                handleChange(values);
              }
            }}
            errorField={formApi.errorField}
            hiddenFieldSets={hiddenFieldSets}
          />
          <Center>
            {Button ? (
              <Button />
            ) : (
              <ButtonV2
                color="green"
                submitting={formApi.submitting}
                width="100%"
                maxWidth={300}
                marginTop={theme.spacing.large}
                onClick={() => {
                  if (!formApi.submitting) {
                    formApi.submit();
                  }
                }}
                data-component-name="Submit button"
                type="button"
              >
                {buttonText}
              </ButtonV2>
            )}
          </Center>
          {(formApi.errors || extraErrors) && <Errors>{formApi.errors || extraErrors}</Errors>}
        </form>
      </Box>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  handleSubmitted: () => {},
  novalidate: false,
  stickySubmitting: true,
  awaitRefetchQueries: false,
  data: [],
  buttonText: "Submit"
};

Form.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      boxProps: PropTypes.object,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          initialValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.array
          ]),
          boxProps: PropTypes.object,
          widget: PropTypes.shape({
            component: PropTypes.func.isRequired,
            props: PropTypes.object
          })
        })
      ),
      footer: PropTypes.node
    })
  ).isRequired,
  handleSubmitted: PropTypes.func,
  mutation: PropTypes.object.isRequired,
  refetchQueries: PropTypes.array,
  extraInput: PropTypes.object,
  novalidate: PropTypes.bool,
  maxWidth: PropTypes.number,
  handleChange: PropTypes.func,
  hiddenFieldSets: PropTypes.arrayOf(PropTypes.string),
  stickySubmitting: PropTypes.bool,
  awaitRefetchQueries: PropTypes.bool,
  buttonText: PropTypes.string
};

export default Form;
