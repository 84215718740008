import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import Modal from "@/tpo/Modal";

import UpdateOrganisationNameForm from "../forms/UpdateOrganisationNameForm";

export default function UpdateOrganisationNameModal() {
  const {
    showUpdateOrganisationNameModal,
    setShowUpdateOrganisationNameModal
  } = useOrganisationContext();

  return (
    <Modal
      mode={["fullScreen", "fullScreen", "centered"]}
      maxWidth={1000}
      bg="white"
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      show={showUpdateOrganisationNameModal}
      close={() => setShowUpdateOrganisationNameModal(false)}
      data-component-name="UpdateOrganisatioNameModal"
    >
      <UpdateOrganisationNameForm
        onSuccess={() => {
          setShowUpdateOrganisationNameModal(false);
        }}
      />
    </Modal>
  );
}
