import React from "react";

import { useHistory } from "react-router-dom";

import { theme } from "@/core/theme";
import { PLATFORM_PROFILE_FORM_URL } from "@/core/urls";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import ButtonV2 from "@/v2/Buttons";

import { CustomerAccountPage } from "./CustomerPage";
import { HeadingMediumLarge } from "./Headings";
import Text from "./Text";

function PlatformProfileWelcomePage() {
  useDocTitle("Welcome");
  const history = useHistory();

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Welcome"
      }}
    >
      <Text>
        At Omnos we understand that your body is as unique as you are. You therefore deserve an
        approach to health and wellbeing that is tailored specifically to you.
      </Text>
      <HeadingMediumLarge pt={theme.spacing.large} as="h2">
        Next steps
      </HeadingMediumLarge>
      <Text pt={40}>
        The following pages will take you through your account set up as well as an initial Self
        Assesment questionnaire. This will enable us to calculate your current Wellness score and
        give you suggested ways you can improve your health.
      </Text>
      <Center>
        <ButtonV2
          color="green"
          mt={80}
          width="100%"
          maxWidth={300}
          onClick={() => {
            history.push(PLATFORM_PROFILE_FORM_URL);
          }}
          data-component-name="Complete setup button"
        >
          Complete your setup
        </ButtonV2>
      </Center>
    </CustomerAccountPage>
  );
}

export default PlatformProfileWelcomePage;
