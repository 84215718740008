import { useState } from "react";

import Circle from "@/components/Circle";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useUserProfileContext } from "@/contexts/UserProfileContext";
import { PRACTITIONER } from "@/core/constants";
import { DASHBOARD_HOME_URL, PARTNERS_PROFILE_URL } from "@/core/urls";
import usePartnerPageCheck from "@/hooks/partner/usePartnerPageCheck";
import UserIcon from "@/images/user.svg?react";
import ChevronComponent from "@/tpo/Chevron";
import LinkWrapper from "@/tpo/LinkWrapper";
import Menu from "@/tpo/Menu";
import ButtonV2 from "@/v2/Buttons";

import AccountMenuItem from "./AccountsMenuItem";
import AccountsMenuOrganisations from "./AccountsMenuOrganisations";
import AccountsMenuWards from "./AccountsMenuWards";

export default function AccountsMenu({ bg, user }) {
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const { isPartnerUser } = useUserProfileContext();

  return (
    <Menu
      opened={accountDropdownOpen}
      onChange={setAccountDropdownOpen}
      trigger="hover"
      xOffset={60}
      yOffset={30}
    >
      <Menu.Target>
        <ButtonV2
          display={["none", "none", "inline-flex"]}
          leftIcon={<UserIcon fill="white" width={16} />}
          px={0}
          py={0}
          letterSpacing="unset"
          textTransform="unset"
          fontSize={16}
          gap={10}
          size={null}
          rightIcon={<ChevronComponent direction={accountDropdownOpen ? "top" : "bottom"} />}
        >
          Account
        </ButtonV2>
      </Menu.Target>
      <Menu.Dropdown
        bg={bg}
        top={45}
        minWidth={240}
        left={-30}
        borderRadius={5}
        py={40}
        boxShadow="5px 5px 0px 0px rgba(0, 0, 0, 0.20)"
      >
        <AccountsMenuWards user={user} showHeader={isPartnerUser} />
        {isPartnerUser && <AccountsMenuOrganisations />}
      </Menu.Dropdown>
    </Menu>
  );
}
