import { useParams } from "react-router-dom";

import Box from "@/components/Box";
import { theme } from "@/core/theme";
import useDocTitle from "@/hooks/use-doc-title";
import Stack from "@/tpo/Stack";

import OrganisationTabs from "../OrganisationTabs";
import OrganisationOrderDetails from "./shared/OrganisationOrderDetails";

export default function OrganisationViewOrder() {
  // get the orderId url param and fetch the order
  useDocTitle("Order", false, "Regenerus");
  const { orderId } = useParams();

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1280}
          px={[20, 20, 40]}
          pt={theme.spacing.section.pt}
          pb={theme.spacing.section.pb}
        >
          {orderId && <OrganisationOrderDetails id={orderId} />}
        </Box>
      </Stack>
    </>
  );
}
