import Box from "@/components/Box";
import PractitionerSupportRequired from "@/components/PractitionerSupportRequired";
import RichText from "@/components/RichText";
import Badge from "@/tpo/Badge";
import Group from "@/tpo/Group";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";

import AddToBasketButton from "./AddToBasketButton";

export default function MainDetails({
  status,
  name,
  description,
  categories,
  numOfBiomarkersTested,
  processingTime,
  priceElement,
  testType,
  practitionerSupportRequired,
  onAddToBasket,
  options,
  testProduct
}) {
  return (
    <>
      {practitionerSupportRequired && (
        <>
          <Badge bg="partners" color="dark" mr="auto">
            practitioner support required
          </Badge>
          <Spacer py={[20, 20, 2]} />
        </>
      )}
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
        {name}
      </Box>
      <Spacer py={[7.5, 7.5, 15]} />
      <RichText fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        {description}
      </RichText>
      <Spacer py={[2, 2, 20]} />
      <Group flexWrap="wrap" gap={2}>
        {categories.map(c => (
          <Badge bg="lifestyle" color="dark" size="xs" key={c}>
            {c}
          </Badge>
        ))}
      </Group>
      <Spacer py={[2, 2, 20]} />
      <Stack>
        <Group alignItems="center" gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
            Sample type:
          </Box>
          <Group alignItems="center" flexWrap="wrap" gap={10}>
            <Badge bg="purple" color="white" size="xs">
              {testType}
            </Badge>
          </Group>
        </Group>
        <Group alignItems="center" gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
            Biomarkers tested:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {numOfBiomarkersTested}
          </Box>
        </Group>
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px" whiteSpace="nowrap">
            Lab Processing time:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {processingTime || "N/A"}
          </Box>
        </Group>
      </Stack>
      <Spacer py={[2, 2, 30]} />
      {options ? (
        options
      ) : practitionerSupportRequired ? (
        <PractitionerSupportRequired
          price={testProduct.rrpCurrentPrice}
          testProduct={testProduct}
        />
      ) : (
        <>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            flexWrap="wrap"
            gap={10}
          >
            {priceElement}
            <AddToBasketButton
              status={status}
              practitionerSupportRequired={practitionerSupportRequired}
              onAddToBasket={onAddToBasket}
            />
          </Box>
        </>
      )}
    </>
  );
}
