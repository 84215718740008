import { useLocation, useHistory } from "react-router-dom";

import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import { useHeaderContext } from "@/contexts/HeaderContext";
import {
  CONTACT_US_URL,
  EXPERTS_OVERVIEW_URL,
  HOW_IT_WORKS_URL,
  LOGIN_URL,
  MEET_THE_TEAM_URL,
  OUR_MISSION_URL,
  PARTNERS_PRACTITIONER_URL,
  SIGNUP_URL,
  TESTS_URL,
  THE_SCIENCE_URL
} from "@/core/urls";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Modal from "@/tpo/Modal";
import NavLink from "@/tpo/NavLink";
import Spacer from "@/tpo/Spacer";
import ButtonV2, { CloseButton } from "@/v2/Buttons";

export default function LoggedOutMenuDrawer({ bg }) {
  const { opened, close, onClick } = useHeaderContext();
  const location = useLocation();
  const history = useHistory();

  return (
    <Modal
      maxWidth={700}
      bg="dark"
      show={opened}
      close={close}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "max(calc((100% - 1538px) / 2), 20px)"],
            y: 104
          }
        }
      ]}
      maxHeight="calc(100vh - 104px - 20px)"
      borderRadius={[0, 0, 10]}
      data-component-name="drawer"
      withOverlay={false}
    >
      <Box pr={[24, 24, 40]} pl={[40, 40, 60]} pt={[40, 40, 60]} pb={[60, 60, 80]}>
        <Group
          position="sticky"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          justifyContent="flex-end"
          height={60}
        >
          <CloseButton color="white" onClick={close} size={null} />
        </Group>
        <Box mt={[60]}>
          <ButtonV2
            as={LinkWrapper}
            color="green"
            rightIcon={<ChevronComponent />}
            to={LOGIN_URL}
            style={{
              display: "inline-flex"
            }}
            onClick={onClick}
            size={["sm", "sm", "md"]}
          >
            Sign in
          </ButtonV2>
          <Spacer py={2} />
          <NavLink
            color="green"
            label="Sign up"
            fontSize={[18, 18, 24]}
            onClick={() => {
              onClick();
              history.push(SIGNUP_URL);
            }}
          />
          <NavLink
            color="white"
            label="Find a practitioner"
            fontSize={24}
            active={location.pathname === EXPERTS_OVERVIEW_URL}
            onClick={() => {
              onClick();
              history.push(EXPERTS_OVERVIEW_URL);
            }}
          />
          <NavLink
            color="white"
            label="Shop"
            fontSize={[18, 18, 24]}
            active={location.pathname === TESTS_URL}
            onClick={() => {
              onClick();
              history.push(TESTS_URL);
            }}
          />
          <NavLink color="white" label="About" fontSize={[18, 18, 24]}>
            <NavLink
              color="white"
              label="How it works"
              as={ExternalTextLink}
              href={HOW_IT_WORKS_URL}
              underline={false}
              fontSize={[14, 14, 16]}
            />
            <NavLink
              color="white"
              label="Our Mission"
              as={ExternalTextLink}
              href={OUR_MISSION_URL}
              underline={false}
              fontSize={[14, 14, 16]}
            />
            <NavLink
              color="white"
              label="The science"
              as={ExternalTextLink}
              href={THE_SCIENCE_URL}
              underline={false}
              fontSize={[14, 14, 16]}
            />
            <NavLink
              color="white"
              label="Meet the team"
              as={ExternalTextLink}
              href={MEET_THE_TEAM_URL}
              underline={false}
              fontSize={[14, 14, 16]}
            />
            <NavLink
              color="white"
              label="Contact us"
              as={ExternalTextLink}
              href={CONTACT_US_URL}
              underline={false}
              fontSize={[14, 14, 16]}
            />
          </NavLink>
          <NavLink color="white" label="Partners" fontSize={[18, 18, 24]}>
            <NavLink
              color="white"
              label="Omnos Partner programme"
              as={ExternalTextLink}
              href="https://www.regeneruslabs.me/practitioner-portal"
              underline={false}
              fontFamily="gilroyBold"
              fontSize={[14, 14, 16]}
            />
            <NavLink
              color="white"
              label="Practitioner application"
              onClick={() => {
                onClick();
                history.push(PARTNERS_PRACTITIONER_URL);
              }}
              fontSize={[14, 14, 16]}
            />
          </NavLink>
        </Box>
      </Box>
    </Modal>
  );
}
