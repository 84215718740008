import { createContext, useState, useEffect, useContext } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { SUPPLEMENT_CONNECTIONS_QUERY } from "@/graphql/shop";
import { useDataLoader } from "@/hooks/useDataLoader";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import useProductFilters from "@/tpo/shop/hooks/useProductFilters";

export const SupplementsContext = createContext();

export function useSupplementListContent({
  dietryRestrictions,
  types,
  brands,
  productFiltersDrawerBg = "blue"
}) {
  const [selectedSupplement, setSelectedSupplement] = useState();

  const history = useHistory();
  const location = useLocation();

  const slug = new URLSearchParams(location.search).get("slug");

  useEffect(() => {
    if (slug) {
      setSelectedSupplement(slug);
    }
  }, [slug]);

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "price"
      },
      {
        label: "Price Desc",
        value: "-price"
      }
    ]
  });

  const { menu, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      brands: [],
      dietryRestrictions: [],
      types: []
    },
    allFilters: {
      brands: [...brands].sort(),
      dietryRestrictions: dietryRestrictions.map(dr => dr.name).sort(),
      types: [...types].sort()
    },
    filtersTitle: "Product Filters",
    drawerBg: productFiltersDrawerBg
  });

  const { data, loading, fetchMore } = useDataLoader({
    query: SUPPLEMENT_CONNECTIONS_QUERY,
    variables: {
      orderBy: controls.sort,
      search: debouncedSearch,
      first: 10,
      after: "",
      brandNames: validatedFilters.brands.join(","),
      dietryRestrictions: validatedFilters.dietryRestrictions.join(","),
      doseTypes: validatedFilters.types.join(",")
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  const supplements = data?.supplementConnections?.edges.map(edge => edge.node);
  const hasMore = data?.supplementConnections?.pageInfo?.hasNextPage;
  const endCursor = data?.supplementConnections?.pageInfo?.endCursor;

  return {
    selectedSupplement,
    setSelectedSupplement,
    controls,
    supplements,
    hasMore,
    endCursor,
    loading,
    fetchMore,
    menu,
    selectedFilters,
    history,
    location
  };
}

export function SupplementsProvider({
  children,
  dietryRestrictions,
  types,
  brands,
  productFiltersDrawerBg = "blue"
}) {
  const api = useSupplementListContent({
    dietryRestrictions,
    types,
    brands,
    productFiltersDrawerBg
  });

  return <SupplementsContext.Provider value={api}>{children}</SupplementsContext.Provider>;
}

export function useSupplementsContext() {
  return useContext(SupplementsContext);
}
