import { useLocation } from "react-router-dom";

import { useAppState } from "@/components/AppStateProvider";
import PoweredByOmnosBanner from "@/components/PoweredByOmnosBanner";
import PoweredByRegenerusBanner from "@/components/PoweredByRegenerusBanner";
import { HeaderProvider } from "@/contexts/HeaderContext";
import { EXTERNAL_KIT_REGISTRATION_COMPLETE_URL, EXTERNAL_KIT_REGISTRATION_URL } from "@/core/urls";
import usePartnerPageCheck from "@/hooks/partner/usePartnerPageCheck";

import Container from "./Container";
import PartnerHeader from "./PartnerHeader";
import UserHeader from "./UserHeader";

const EXCEPTIONS = [EXTERNAL_KIT_REGISTRATION_URL, EXTERNAL_KIT_REGISTRATION_COMPLETE_URL];

function useHeader() {
  const location = useLocation();
  return !EXCEPTIONS.includes(location.pathname);
}

export default function Header() {
  const isPartnerPage = usePartnerPageCheck({
    excludeApplicationPages: false
  });

  const { userInfo } = useAppState();
  const showHeader = useHeader();
  const location = useLocation();

  const user = userInfo?.user;
  const userSubmissions = userInfo?.userSubmissions;

  let header = <Container bg="blue" height={60} />;

  let usePartnerHeader = isPartnerPage;

  // If we are on a patient page and the user is not a practitioner, don't show the partner header
  if (location.pathname.includes("/patients") && !user?.partnerUserProfile) {
    usePartnerHeader = false;
  }

  if (!showHeader) {
    return null;
  }

  if (userInfo) {
    header = usePartnerHeader ? (
      <>
        <PoweredByOmnosBanner />
        <PartnerHeader user={user} userSubmissions={userSubmissions} />
      </>
    ) : (
      <>
        <PoweredByRegenerusBanner />
        <UserHeader user={user} userSubmissions={userSubmissions} />
      </>
    );
  }

  return <HeaderProvider>{header}</HeaderProvider>;
}
