import { theme } from "@/core/theme";
import { PARTNERS_PRACTITIONER_URL, PARTNERS_REQUEST_PASSWORD_RESET_URL } from "@/core/urls";
import useDocTitle from "@/hooks/use-doc-title";
import ChevronComponent from "@/tpo/Chevron";
import LinkWrapper from "@/tpo/LinkWrapper";
import { LoginForm } from "@/tpo/Login";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import Box from "./Box";
import CustomerPage from "./CustomerPage";
import { InternalTextLink } from "./Links";

export default function PartnerSignIn() {
  useDocTitle("Log in");
  return (
    <CustomerPage
      jumbotronProps={{
        title: "Log In",
        bg: "partners",
        color: "dark"
      }}
    >
      <Stack pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20} gap={[40]}>
        <LoginForm
          bottomLinks={
            <>
              <InternalTextLink href={PARTNERS_PRACTITIONER_URL}>
                Click here to apply
              </InternalTextLink>
              <InternalTextLink href={PARTNERS_REQUEST_PASSWORD_RESET_URL}>
                Forgotten your password?
              </InternalTextLink>
            </>
          }
        />
        <Box bg="dark" maxWidth={600} mx="auto" p={[20, 20, 40]} borderRadius={5}>
          <Box color="white" fontSize={[16, 16, 18]} fontFamily="gilroyBold">
            Existing Regenerus customer?
          </Box>
          <Box color="white" fontSize={[14, 14, 16]} fontFamily="gilroyMedium" mt={20}>
            If you already have a Regenerus account you can now access your data here by linking
            your accounts and creating a new password.
          </Box>
          <ButtonV2
            as={LinkWrapper}
            color="green"
            rightIcon={<ChevronComponent />}
            to={PARTNERS_REQUEST_PASSWORD_RESET_URL}
            mt={30}
            size={["sm", "sm", "md"]}
          >
            link accounts
          </ButtonV2>
        </Box>
      </Stack>
    </CustomerPage>
  );
}
