import Box from "@/components/Box";
import { useHeaderContext } from "@/contexts/HeaderContext";
import LinkWrapper from "@/tpo/LinkWrapper";
import Menu from "@/tpo/Menu";

export default function AccountMenuItem({ children, ...props }) {
  const { onClick } = useHeaderContext();
  return (
    <Box
      color="#818181"
      fontFamily="Gilroy W05 Medium"
      fontSize={14}
      py={16}
      px={16}
      display="block"
      position="relative"
      {...props}
    >
      {children}
    </Box>
  );
}
