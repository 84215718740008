import Box from "@/components/Box";
import { theme } from "@/core/theme";
import useUserProfile from "@/hooks/useUserProfile";
import Center from "@/tpo/Center";
import { FoodList } from "@/tpo/FoodsPage";
import Stack from "@/tpo/Stack";

import { BlurredOverlayProvider } from "./BlurredOverlay";
import Template from "./shared/Template";

export default function FoodListRoute({ templateProps }) {
  const { partnerUserProfile } = useUserProfile();

  return (
    <BlurredOverlayProvider show={!partnerUserProfile?.wellnessScoresUnlocked}>
      <Template selectedTab="food_list" docTitle="Patient's Food list" {...templateProps}>
        <FoodList>
          <Center pt={theme.spacing.section.pt} px={20}>
            <Stack gap={28} maxWidth={758}>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Food list
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                We’ve analysed your patient's self assessment and test results to create a
                personalised food list. Explore the items within each food group to reveal their
                recommendations.
              </Box>
            </Stack>
          </Center>
        </FoodList>
      </Template>
    </BlurredOverlayProvider>
  );
}
