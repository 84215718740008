import { useRef } from "react";

import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import ChevronComponent from "@/tpo/Chevron";
import { IndicativeButton } from "@/v2/Buttons";
import { gql, useMutation } from "@apollo/client";

const RESEND_ORDER_EMAIL_MUTATION = gql`
  mutation ResendOrderEmailMutation($input: ResendOrderEmailMutationInput!) {
    resendOrderEmailMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export default function ResendOrderEmailButton({ order }) {
  const resendButtonRef = useRef();

  const { organisation } = useOrganisationContext();
  const [resendOrderEmailMutation] = useMutation(RESEND_ORDER_EMAIL_MUTATION, {
    onCompleted: data => {
      if (data?.resendOrderEmailMutation?.errors?.length) {
        resendButtonRef.current.setSuccessful(false);
        resendButtonRef.current.setPending(false);
      } else {
        resendButtonRef.current.setSuccessful(true);
        resendButtonRef.current.setPending(false);
      }
    }
  });

  return (
    <IndicativeButton
      ref={resendButtonRef}
      defaultColor="green"
      pendingColor="darkgrey"
      successColor="#2ecc71"
      failureColor="error"
      rightIcon={<ChevronComponent />}
      type="button"
      onClick={e => {
        e.resendOrderEmailButton = true;
        resendOrderEmailMutation({
          variables: {
            input: {
              organisation: organisation?.id,
              order: order?.id
            }
          }
        });
      }}
      size="xs"
    >
      resend
    </IndicativeButton>
  );
}
