import OrganisationTabs from "@/components/organisations/OrganisationTabs";
import { theme } from "@/core/theme";
import Center from "@/tpo/Center";
import Stack from "@/tpo/Stack";

import OrganisationSwitcher from "./OrganisationSwitcher";
import Subnav from "./Subnav";

export default function BusinessTemplate({ children, active, includeSubnav }) {
  return (
    <>
      <OrganisationTabs selectedTab="business" />
      <Stack
        pt={theme.spacing.section.pt}
        gap={[20, 20, 40]}
        pb={theme.spacing.section.pb}
        px={[20]}
        bg={"haze"}
      >
        <Center stacked gap={[20, 20, 40]}>
          <OrganisationSwitcher />

          {includeSubnav && <Subnav active={active} />}
        </Center>
        {children}
      </Stack>
    </>
  );
}

BusinessTemplate.defaultProps = {
  includeSubnav: true
};
