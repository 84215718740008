import {
  FITNESS,
  FOOD,
  LIFESTYLE,
  NUTRITION,
  PEOPLE_TO_SEE,
  THINGS_TO_TAKE
} from "@/core/constants";
import { theme } from "@/core/theme";
import FitnessIcon from "@/images/fitness.svg?react";
import KnifeAndForkIcon from "@/images/knife-and-fork.svg?react";
import LifestyleIcon from "@/images/lifestyle.svg?react";
import NutritionIcon from "@/images/nutrition.svg?react";
import SpecialistsIcon from "@/images/specialist.svg?react";
import SupplementsIcon from "@/images/supplements.svg?react";
import PropTypes from "prop-types";

const categoryIcons = {
  [FITNESS]: FitnessIcon,
  [LIFESTYLE]: LifestyleIcon,
  [NUTRITION]: NutritionIcon,
  [THINGS_TO_TAKE]: SupplementsIcon,
  [PEOPLE_TO_SEE]: SpecialistsIcon,
  [FOOD]: KnifeAndForkIcon
};

export default function RecommendationIcon({ category, width, height, fill }) {
  const Icon = categoryIcons[category];
  if (Icon) {
    return <Icon width={width} height={height} fill={fill} />;
  } else {
    return false;
  }
}

RecommendationIcon.propTypes = {
  category: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string
};

RecommendationIcon.defaultProps = {
  width: 30,
  height: 30,
  fill: theme.colors.dark
};
