import styled from "styled-components";

import Box from "./Box";

const Grid = props => <Box {...props} />;

Grid.defaultProps = {
  display: "grid",
  gridRowGap: 20,
  gridColumnGap: 20
};

export default Grid;
