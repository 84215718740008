import Danger from "@/images/Danger";
import Center from "@/tpo/Center";
import Stack from "@/tpo/Stack";

import Box from "./Box";
import { ExternalTextLink } from "./Links";
import PartnerDashboardBox from "./boxes/PartnerDashboardBox";

export function AccountOnHold({ children }) {
  return (
    <PartnerDashboardBox bg="midGrey" borderRadius={20} data-component-name="AccountOnHold">
      <Stack bg="white" borderRadius={20} px={40} pt={60} pb={80}>
        <Center gap={[10, 10, 20]}>
          <Danger size={[30, 40, 40]} />
          <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            Account on hold
          </Box>
        </Center>
        {children}
      </Stack>
    </PartnerDashboardBox>
  );
}

export function AccountOnHoldCheckoutMessage() {
  return (
    <AccountOnHold>
      <Box textAlign="center" mx="auto" fontSize={[14, 14, 16]}>
        Your account is currently paused, please contact{" "}
        <ExternalTextLink href="mailto:info@regeneruslabs.com">
          info@regeneruslabs.com
        </ExternalTextLink>{" "}
        to proceed with checkout
      </Box>
    </AccountOnHold>
  );
}
