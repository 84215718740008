import { useContext } from "react";

import { FormProvider } from "react-hook-form";

import { AppStateContext } from "@/components/AppStateProvider";
import CustomerPage from "@/components/CustomerPage";
import DataLoader from "@/components/DataLoader";
import { theme } from "@/core/theme";
import { DASHBOARD_HOME_URL } from "@/core/urls";
import { DIET_PREFERENCES_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import ButtonV2 from "@/v2/Buttons";
import { gql } from "@apollo/client";

import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import ControlledCheckboxGroup from "./ControlledCheckboxGroup";
import Stack from "./Stack";

const DIET_PREFERENCES_QUERY = gql`
  query GoalsPageQuery {
    dietPreferences {
      id
      name
    }
    platformUserProfile {
      id
      pk
      dietPreferences {
        id
      }
    }
  }
`;

function DietPreferencesForm({ dietPreferences, platformUserProfile }) {
  const { refetchPlatformAndRedirect } = useContext(AppStateContext);

  const { methods, onSubmit } = useDjangoGraphqlForm({
    mutation: DIET_PREFERENCES_MUTATION,
    mutationName: "dietPreferencesMutation",
    defaultValues: {
      // NB it will use the logged in user to get the right platform user profile
      // so no need for the id here
      dietPreferences: platformUserProfile.dietPreferences.map(dietPreference => dietPreference.id)
    },
    handleSuccess: ({ data }) => {
      refetchPlatformAndRedirect((res, history) => {
        if (res?.data?.user?.platformUserProfile?.isComplete) {
          history.push(DASHBOARD_HOME_URL);
        }
      });
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: 20
          }}
          stacked
          gap={40}
        >
          <ControlledCheckboxGroup
            Container={Stack}
            containerProps={{
              gap: 20
            }}
            name="dietPreferences"
            values={
              dietPreferences?.map(dietPreference => ({
                label: dietPreference.name,
                value: dietPreference.id
              })) || []
            }
          />
          <Center>
            <ButtonV2 color="green" rightIcon={<ChevronComponent />} type="submit">
              submit
            </ButtonV2>
          </Center>
        </PanelBoxV2>
      </form>
    </FormProvider>
  );
}

export default function DietPreferences() {
  useDocTitle("Diet Preferences");
  return (
    <CustomerPage
      jumbotronProps={{
        title: "Diet Preferences"
      }}
    >
      <DataLoader
        query={DIET_PREFERENCES_QUERY}
        fetchPolicy="network-only"
        nextFetchPolicy="network-only"
        render={({ dietPreferences, platformUserProfile }) => (
          <DietPreferencesForm
            dietPreferences={dietPreferences}
            platformUserProfile={platformUserProfile}
          />
        )}
      />
    </CustomerPage>
  );
}
