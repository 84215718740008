import useBreakPointBasedProp from "@/hooks/useBreakPointBasedProp";

import Box from "./Box";
import Circle from "./Circle";

export default function Avatar({ bg, src, size, placeholder, ...rest }) {
  const actualSize = useBreakPointBasedProp(size);

  return (
    <Circle bg={bg} size={size} data-component-name="Avatar" {...rest}>
      {src ? (
        <img
          src={src}
          width={actualSize}
          height={actualSize}
          alt="avatar"
          style={{
            borderRadius: "50%",
            objectFit: "cover"
          }}
        />
      ) : (
        <Box
          fontFamily="dinBold"
          fontSize={actualSize / 2}
          lineHeight={`${size}px`}
          position="relative"
          top="8px"
        >
          {placeholder}
        </Box>
      )}
    </Circle>
  );
}
