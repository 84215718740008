import { useHistory, useLocation } from "react-router-dom";

import Box from "@/components/Box";
import { InternalTextLink } from "@/components/Links";
import { theme } from "@/core/theme";
import { DASHBOARD_HOME_URL } from "@/core/urls";
import useDocTitle from "@/hooks/use-doc-title";

import { LoadingResults, Results, useOutro } from "./QuestionnaireOutro";
import Page from "./QuestionnairePages";
import { usedQuestionnaireNavigation, usedAppBackButton } from "./QuestionnaireUtilities";
import { BestMatchedTest } from "./TestRecommendations";

function CompleteQuestionnaireResults({ userTestRecommendation }) {
  useDocTitle("Questionnaire Results");
  return (
    <Page
      header="Results"
      Main={
        <Box px={20} pb={theme.spacing.section.pb}>
          <Box
            bg="white"
            pt={[30, 30, 70]}
            pb={[40, 40, 70]}
            maxWidth={1020}
            mx="auto"
            style={{
              borderRadius: 5
            }}
            px={20}
          >
            <Box maxWidth={760} mx="auto">
              <BestMatchedTest.Header.Secondary />
              <BestMatchedTest.BodyCopy />
              <Box display="flex" flexDirection={["column", "column", "row"]}>
                <Box
                  display="flex"
                  position="relative"
                  width={["100%", "100%", "50%"]}
                  justifyContent="flex-end"
                  pr={[0, 0, 20]}
                  minHeight="389px"
                  backgroundColor="#F2F2F2"
                  style={{
                    backgroundImage: `url(${userTestRecommendation.product.content.image1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain"
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  pl={[0, 0, 20]}
                  pt={[40, 40, 0]}
                  width={["100%", "100%", "50%"]}
                >
                  <BestMatchedTest.Symptoms userTestRecommendation={userTestRecommendation}>
                    <InternalTextLink color="green" href={DASHBOARD_HOME_URL} mt={[20, 20, 30]}>
                      Continue to dashboard
                    </InternalTextLink>
                  </BestMatchedTest.Symptoms>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
}

function CompleteQuestionnaireOutro() {
  const history = useHistory();
  const location = useLocation();
  const { bestMatchedTest } = useOutro();
  if (!usedQuestionnaireNavigation(location) || usedAppBackButton(history)) {
    return null;
  }

  if (!bestMatchedTest?.show) {
    return <LoadingResults />;
  }
  return (
    <Results
      userTestRecommendation={bestMatchedTest.test}
      Component={CompleteQuestionnaireResults}
    />
  );
}

export default CompleteQuestionnaireOutro;
