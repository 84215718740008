import Chevron from "@/images/ChevronRight";
import PropTypes from "prop-types";

export default function ChevronComponent({ direction, style, rotation, ...rest }) {
  let chevronDirRotation = rotation;
  if (direction === "top") {
    chevronDirRotation = 270;
  } else if (direction === "right") {
    chevronDirRotation = 0;
  } else if (direction === "bottom") {
    chevronDirRotation = 90;
  } else if (direction === "left") {
    chevronDirRotation = 180;
  }

  return (
    <Chevron
      style={{
        marginLeft: "5px",
        transform: `rotate(${chevronDirRotation}deg)`,
        ...style
      }}
      {...rest}
    />
  );
}

ChevronComponent.propTypes = {
  direction: PropTypes.string
};

ChevronComponent.defaultProps = {
  direction: "right",
  width: 5,
  fill: "white"
};
