import { useFormContext, useWatch } from "react-hook-form";

import Box from "@/components/Box";
import ControlledFormField from "@/tpo/ControlledFormField";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import { Error } from "@/tpo/InputWrapper";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export default function ProductSpecificFields({
  nonFieldError,
  testProducts,
  registrableProducts
}) {
  const { control } = useFormContext();

  const testProductId = useWatch({
    name: "testProductId",
    control
  });

  if (!testProductId) return null;

  const testProduct = testProducts.find(p => p.value === testProductId);
  const registrableProduct = registrableProducts.find(p => p.testProduct.id === testProductId);

  return (
    <Stack gap={40}>
      {nonFieldError && <Error data-testid="nonFieldError" error={nonFieldError} />}
      <Box>
        <Box fontSize={28} fontFamily="gilroyBold">
          {testProduct.content}
        </Box>
        <Spacer py={2} />
        {registrableProduct?.instructions && (
          <Box fontFamily="gilroyMedium" fontSize={16} data-testid="instructions">
            {registrableProduct?.instructions}
          </Box>
        )}
      </Box>
      <Stack gap={20}>
        <ControlledFormField
          name="testItemId"
          Component={FloatingLabelInput}
          label={registrableProduct.label}
        />
        <ControlledFormField
          name="confirmTestItemId"
          Component={FloatingLabelInput}
          label={`Confirm ${registrableProduct.label}`}
        />
      </Stack>
      <ButtonV2 color="green" mx="auto" size={["sm", "sm", "md"]}>
        next
      </ButtonV2>
    </Stack>
  );
}
