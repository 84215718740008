import { forwardRef } from "react";

import Avatar from "@/components/Avatar";
import Box from "@/components/Box";
import { EXPERT_AVAILABILITY_IN_PERSON, EXPERT_FREE_CONSULTATION_NOT_FREE } from "@/core/constants";
import { getExpertsDetailUrl } from "@/core/urls";
import Badge from "@/tpo/Badge";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { startCase } from "lodash";
import { useTheme } from "styled-components";

import FeeLevel from "./FeeLevel";

const ExpertListCard = forwardRef(
  (
    {
      id,
      partnerUserProfile,
      consultationType: availability,
      resizedAvatarUrl: avatarUrl,
      freeConsultation,
      feeLevel,
      minWidth,
      className
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Stack className={className} gap={0} ref={ref} minWidth={minWidth}>
        <Center
          bg="partners"
          py={[20, 20, 40]}
          borderTopRightRadius={theme.radius.card}
          borderTopLeftRadius={theme.radius.card}
        >
          <Avatar
            placeholder={`${partnerUserProfile.user.firstName?.[0] || ""}${
              partnerUserProfile.user.lastName?.[0] || ""
            }`}
            bg="white"
            size={160}
            src={avatarUrl}
          />
        </Center>
        <Stack
          pt={[20, 20, 28]}
          pb={[40, 40, 60]}
          px={[20, 20, 28]}
          bg="white"
          gap={0}
          borderBottomRightRadius={theme.radius.card}
          borderBottomLeftRadius={theme.radius.card}
          flexGrow={1}
        >
          <Box fontFamily="gilroyBold" fontSize={24}>
            {partnerUserProfile.user.fullName}
          </Box>
          <Stack gap={10} mt={28} mb={40}>
            {!!partnerUserProfile.practitionerType && (
              <Group gap={10}>
                <Box fontFamily="gilroyBold" fontSize={[15]}>
                  Practitioner Type:
                </Box>
                <Box fontFamily="gilroyRegular" fontSize={[15]}>
                  {partnerUserProfile.practitionerType.role}
                </Box>
              </Group>
            )}
            {!!availability?.length && (
              <Group gap={8} alignItems="center" flexWrap="wrap">
                <Box fontFamily="gilroyBold" fontSize={[15]}>
                  Availability:
                </Box>
                <Group
                  alignItems="center"
                  gap={4}
                  fontFamily="gilroyMedium"
                  fontSize={[15]}
                  flexWrap="wrap"
                >
                  {availability.map(a => (
                    <Badge bg="green" color="white" size="xs" key={a}>
                      {a}
                    </Badge>
                  ))}
                </Group>
              </Group>
            )}
            {availability.includes(EXPERT_AVAILABILITY_IN_PERSON) &&
              partnerUserProfile.businessLocationCity && (
                <Group gap={10}>
                  <Box fontFamily="gilroyBold" fontSize={[15]}>
                    Location:
                  </Box>
                  <Box fontFamily="gilroyRegular" fontSize={[15]}>
                    {startCase(partnerUserProfile.businessLocationCity.toLowerCase())}
                  </Box>
                </Group>
              )}
            {!!freeConsultation && freeConsultation !== EXPERT_FREE_CONSULTATION_NOT_FREE && (
              <Group gap={10}>
                <Box fontFamily="gilroyBold" fontSize={[15]}>
                  Free consultation:
                </Box>
                <Badge bg="haze" size="xs">
                  {freeConsultation}
                </Badge>
              </Group>
            )}
            {!!feeLevel && <FeeLevel value={feeLevel} />}
          </Stack>
          <ButtonV2
            color="dark"
            withChevron
            as={LinkWrapper}
            to={getExpertsDetailUrl(id)}
            sx={{
              mt: "auto",
              mr: "auto"
            }}
          >
            see profile
          </ButtonV2>
        </Stack>
      </Stack>
    );
  }
);

export default ExpertListCard;
