import { useAppState } from "@/components/AppStateProvider";
import Box from "@/components/Box";
import { OrganisationBasketListing } from "@/components/organisations/checkout/OrganisationBasketListing";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import Modal from "@/tpo/Modal";

export default function Basket() {
  const {
    practitionerBasketOpen: basketOpen,
    setPractitionerBasketOpen: setBasketOpen
  } = useAppState();

  const {
    hasMultipleProductsError,
    hasAddonError,
    hasStockPatientError
  } = useOrganisationBasketContext();

  return (
    <Modal
      maxWidth={"min(calc(100% - 20px - 5.5vw), 1020px)"}
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      bg="white"
      show={basketOpen}
      close={() => {
        if (hasMultipleProductsError || hasAddonError || hasStockPatientError) {
          // they must dismiss the modal containg this error first
          return;
        }
        setBasketOpen(false);
      }}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "max(calc((100% - 1538px) / 2), 20px)"],
            y: 104
          }
        }
      ]}
      maxHeight="calc(100vh - 80px - 20px)"
      // 80px is height from top, 20px gap to bottom
      borderRadius={[0, 0, 5]}
      data-component-name={"PractitionerBasket"}
    >
      <Box maxWidth={760} mx="auto" pb={[60, 60, 120]} px={[20, 20, 40]}>
        <OrganisationBasketListing showCheckoutButton={true} showShipping={false} />
      </Box>
    </Modal>
  );
}
