import { createContext, useContext, useMemo } from "react";

import ICONS from "@/components/IsoCodeFlagIcons";
import { gql, useQuery } from "@apollo/client";

import { usePersistentState } from "./LocalStorage";

const LOCALE_FIELDS = gql`
  fragment LocaleFields on LocaleType {
    id
    name
    isoCode
    flagIconUrl
  }
`;

const GET_LOCALES = gql`
  query GetLocales {
    locales {
      ...LocaleFields
    }
  }
  ${LOCALE_FIELDS}
`;

const LocaleContext = createContext();

function useLocales() {
  const [locale, setLocale] = usePersistentState("selectedLocale", [undefined, undefined]);
  const { data = { locales: [] }, loading } = useQuery(GET_LOCALES);

  const api = useMemo(
    () => ({
      locales: data.locales,
      locale,
      setLocale,
      loading,
      icons: ICONS
    }),
    [data.locales, locale, setLocale, loading]
  );

  return api;
}

export function useLocalesContext() {
  return useContext(LocaleContext);
}

export function LocalesProvider({ children }) {
  const api = useLocales();

  return <LocaleContext.Provider value={api}>{children}</LocaleContext.Provider>;
}
