import { useLocation } from "react-router-dom";

import Circle from "@/components/Circle";
import { ExternalTextLink } from "@/components/Links";
import { useHeaderContext } from "@/contexts/HeaderContext";
import LinkWrapper from "@/tpo/LinkWrapper";
import Menu from "@/tpo/Menu";

export default function AccountMenuItem({ children, to, ...props }) {
  const { onClick } = useHeaderContext();
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);

  const Component = props.newTab ? ExternalTextLink : LinkWrapper;
  if (props.newTab) {
    props.href = to;
  }

  return (
    <Menu.Item
      selectedColor="rgba(255, 255, 255, 0.10)"
      color="white"
      fontFamily="gilroyBold"
      fontSize={14}
      py={16}
      px={32}
      display="block"
      underline={false}
      position="relative"
      onClick={onClick}
      as={Component}
      to={to}
      {...props}
    >
      {isActive && (
        <Circle
          backgroundColor="green"
          position="absolute"
          top="50%"
          size={5}
          transform="translate(-50%, -50%)"
          left="15px"
        />
      )}
      {children}
    </Menu.Item>
  );
}
