export default function ChartIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9935_41047)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.65306 9.56948L10.0374 11.7328C10.0631 11.7035 10.0889 11.6753 10.117 11.6484V11.6495C10.8061 10.9593 11.8831 10.8433 12.7045 11.3706L16.8026 6.80374C16.3385 6.22834 16.2166 5.44906 16.4826 4.75882C16.7475 4.06858 17.3604 3.57289 18.0916 3.45568C18.8217 3.33966 19.5576 3.62091 20.0252 4.19395C20.4916 4.76701 20.6182 5.54512 20.3569 6.2377L23.6428 8.13028C24.3236 7.29592 25.5318 7.1248 26.4178 7.73653C27.3037 8.34826 27.5721 9.5377 27.0342 10.4705C26.4951 11.4033 25.3314 11.7666 24.3576 11.3049C23.3849 10.8432 22.9291 9.7123 23.3111 8.70448L20.0264 6.80956C19.9831 6.86464 19.935 6.91737 19.8846 6.96776C19.1955 7.65683 18.1186 7.77284 17.2983 7.24667L13.1979 11.8136C13.6666 12.396 13.7862 13.1858 13.5096 13.8808C13.2331 14.5757 12.6038 15.0679 11.862 15.1687C11.1213 15.2694 10.383 14.9636 9.9307 14.3671C9.47836 13.7718 9.38227 12.9784 9.67992 12.2917L6.29562 10.1284C6.26984 10.1577 6.24289 10.1858 6.21593 10.2128V10.2116C5.46125 10.9651 4.25657 11.0214 3.43508 10.3405C2.61359 9.65967 2.44484 8.46552 3.04484 7.58427C3.64484 6.70185 4.8179 6.42177 5.75186 6.93504C6.68702 7.44948 7.07729 8.59097 6.65306 9.56948ZM6.35775 13.2175H3.14085V25.6312H6.35775V13.2175ZM13.192 17.0296L9.97515 17.0284V25.63L13.1909 25.6312V17.0284L13.192 17.0296ZM20.0263 10.2655L16.8095 10.2643V25.6312H20.0251V10.2643L20.0263 10.2655ZM26.8607 13.6474L23.6425 13.6462V25.6309H26.8594V13.6462L26.8607 13.6474ZM28.8786 26.8159L28.8775 26.8147C28.9677 26.8124 29.0544 26.8464 29.12 26.9085C29.1845 26.9717 29.2208 27.0585 29.2208 27.1475C29.2208 27.2378 29.1845 27.3245 29.12 27.3878C29.0544 27.4499 28.9677 27.4839 28.8775 27.4815H1.12266C1.03242 27.4839 0.945704 27.4499 0.880079 27.3878C0.815627 27.3245 0.779297 27.2378 0.779297 27.1475C0.779297 27.0585 0.815624 26.9717 0.880079 26.9085C0.945704 26.8464 1.03242 26.8124 1.12266 26.8147L28.8786 26.8159Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_9935_41047">
          <rect width="30" height="30" fill="white" transform="translate(0 0.45459)" />
        </clipPath>
      </defs>
    </svg>
  );
}
