import React from "react";

import { theme } from "@/core/theme";
import { useApolloClient, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import Box from "./Box";
import Loading from "./Loading";
import Text from "./Text";

function DataLoader(props) {
  const apolloClient = useApolloClient();

  const { data, loading, error } = useQuery(props.query, {
    variables: props.variables,
    client: apolloClient,
    fetchPolicy: props.fetchPolicy,
    nextFetchPolicy: props.nextFetchPolicy
  });

  if (error)
    return (
      <Box px={20} py={theme.spacing.medium}>
        <Box
          maxWidth={600}
          mx="auto"
          bg="slate"
          color="white"
          p={theme.spacing.medium}
          borderRadius="5px"
        >
          <Text fontFamily="gilroyBold">
            I'm sorry, an error has occured, if you need assistance please contact support@omnos.me
          </Text>
        </Box>
      </Box>
    );

  if (loading) {
    if (props.loading === undefined) {
      return <Loading />;
    } else {
      return props.loading;
    }
  }

  return props.render(data);
}

DataLoader.defaultProps = {
  fetchPolicy: "cache-first"
};

DataLoader.propTypes = {
  query: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  variables: PropTypes.object,
  loading: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  fetchPolicy: PropTypes.oneOf(["cache-first", "network-only", "no-cache"])
};

export default DataLoader;
