import { useEffect, useRef } from "react";

import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { SAVE_ORGANISATION_SHIPPING_ADDRESS_MUTATION } from "@/graphql/organisations/mutations";

import useDjangoGraphqlForm from "./form/useDjangoGraphqlForm";

export default function useShippingAddressForm({
  extraValues,
  shippingAddress,
  mutation = SAVE_ORGANISATION_SHIPPING_ADDRESS_MUTATION,
  mutationName = "saveOrganisationShippingAddressMutation"
}) {
  const { organisation } = useOrganisationContext();
  const submitButtonRef = useRef();

  const { methods, nonFieldError, onSubmit } = useDjangoGraphqlForm({
    mutation,
    mutationName,
    defaultValues: {
      id: shippingAddress?.id || "",
      name: shippingAddress?.name || "",
      line1: shippingAddress?.line1 || "",
      line2: shippingAddress?.line2 || "",
      city: shippingAddress?.city || "",
      postcode: shippingAddress?.postcode || "",
      country: shippingAddress?.country?.isoCode || "",
      phoneNumber: shippingAddress?.phoneNumber || "",
      organisation: organisation?.id
    },
    handleSuccess: api => {
      // TODO - look into batching these state updates
      // i.e. calling setSuccessful sets setPending internally
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
      }
      if (submitButtonRef.current) {
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: api => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setPending(false);
      }
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
      }
    },
    transformer: data => ({
      ...data,
      ...extraValues
    })
  });

  const { isDirty } = methods.formState;

  useEffect(() => {
    methods.reset({
      id: shippingAddress?.id || "",
      name: shippingAddress?.name || "",
      line1: shippingAddress?.line1 || "",
      line2: shippingAddress?.line2 || "",
      city: shippingAddress?.city || "",
      postcode: shippingAddress?.postcode || "",
      country: shippingAddress?.country?.isoCode || "",
      phoneNumber: shippingAddress?.phoneNumber || "",
      organisation: organisation?.id
    });
  }, [shippingAddress, methods, organisation]);

  return {
    isDirty,
    methods,
    nonFieldError,
    onSubmit,
    submitButtonRef
  };
}
