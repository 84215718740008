import { Route, Switch } from "react-router-dom";

import AuthRoute from "@/components/AuthRoute";
import {
  QUESTIONNAIRES_URL_BASE,
  QUESTIONNAIRE_INTRO_PATTERN_URL,
  QUESTIONNAIRE_OUTRO_PATTERN_URL,
  QUESTIONNAIRE_STEP_PATTERN_URL,
  TEST_PRODUCT_QUESTIONNAIRE_INTRO_URL,
  TEST_PRODUCT_QUESTIONNAIRE_OUTRO_URL,
  TEST_PRODUCT_QUESTIONNAIRE_QUESTIONS_PATTERN_URL
} from "@/core/urls";

import CompleteQuestionnaireIntro from "./CompleteQuestionnaireIntro";
import CompleteQuestionnaireOutro from "./CompleteQuestionnaireOutro";
import CompleteQuestionnaireQuestions from "./CompleteQuestionnaireQuestions";
import TestProductQuestionnaireIntro from "./TestProductQuestionnaireIntro";
import TestProductQuestionnaireOutro from "./TestProductQuestionnaireOutro";
import TestProductQuestionnairePage from "./TestProductQuestionnaireQuestions";

function Questionnaires() {
  return (
    <Switch>
      <AuthRoute path={`${QUESTIONNAIRES_URL_BASE}/symptoms`}>
        <Switch>
          <AuthRoute path={QUESTIONNAIRE_INTRO_PATTERN_URL} exact>
            <CompleteQuestionnaireIntro />
          </AuthRoute>
          <AuthRoute path={QUESTIONNAIRE_OUTRO_PATTERN_URL} exact>
            <CompleteQuestionnaireOutro />
          </AuthRoute>
          <AuthRoute path={QUESTIONNAIRE_STEP_PATTERN_URL} exact>
            <CompleteQuestionnaireQuestions />
          </AuthRoute>
        </Switch>
      </AuthRoute>
      <Route path={`${QUESTIONNAIRES_URL_BASE}/:testProductSlug`}>
        <Switch>
          <Route path={TEST_PRODUCT_QUESTIONNAIRE_INTRO_URL} exact>
            <TestProductQuestionnaireIntro />
          </Route>
          <Route path={TEST_PRODUCT_QUESTIONNAIRE_OUTRO_URL} exact>
            <TestProductQuestionnaireOutro />
          </Route>
          <Route path={TEST_PRODUCT_QUESTIONNAIRE_QUESTIONS_PATTERN_URL}>
            <TestProductQuestionnairePage />
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
}

export default Questionnaires;
