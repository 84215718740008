import OrganisationTabs from "@/components/organisations/OrganisationTabs";
import { theme } from "@/core/theme";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Center from "@/tpo/Center";

import Switcher from "./Switcher";

export default function PatientsTemplate({ children, active }) {
  return (
    <>
      <OrganisationTabs selectedTab="patients" />
      <PanelBoxV2
        outer={{
          pb: theme.spacing.section.pb,
          px: [20],
          bg: "haze"
        }}
        stacked
        gap={[20, 20, 40]}
      >
        <Center pt={theme.spacing.section.pt}>
          <Switcher active={active} />
        </Center>
        {children}
      </PanelBoxV2>
    </>
  );
}
