import ArrowCircle from "@/components/ArrowCircle";
import Box, { ToBox } from "@/components/Box";
import Text from "@/components/Text";
import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

export default function PercentageBar({
  foregroundColor,
  backgroundColor,
  textColor,
  showChevron,
  to,
  height,
  zeroMessage,
  arrowCircleProps = {},
  percentageTextProps = {},
  showText,
  outerProps,
  enclosed,
  fromValue,
  toValue,
  value,
  ensureBorderRadius
}) {
  const Component = to === undefined ? Box : ToBox;

  fromValue = value !== undefined ? 0 : fromValue;
  toValue = value !== undefined ? value : toValue;

  return (
    <Component
      position="relative"
      height={height}
      backgroundColor={backgroundColor}
      borderRadius={height / 2}
      width="100%"
      overflow="hidden"
      href={to}
      {...outerProps}
    >
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={`${fromValue}%`}
        width={`${toValue - fromValue}%`}
        backgroundColor={foregroundColor}
        borderColor={enclosed ? backgroundColor : null}
        borderRadius={height / 2}
        borderStyle={enclosed ? "solid" : null}
        borderWidth={enclosed ? 2 : null}
        minWidth={ensureBorderRadius ? height : 0}
      />
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        borderColor={enclosed ? backgroundColor : null}
        borderRadius={height / 2}
        borderStyle={enclosed ? "solid" : null}
        borderWidth={enclosed ? 2 : null}
      />
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {showText && (
          <Text fontFamily="gilroyBold" color={textColor} {...percentageTextProps}>
            {value ? `${Math.round(value)}%` : zeroMessage ? zeroMessage : "0%"}
          </Text>
        )}
      </Box>
      {showChevron && (
        <Box position="absolute" top="2px" right="2px">
          <ArrowCircle width={height - 4} height={height - 4} {...arrowCircleProps} />
        </Box>
      )}
    </Component>
  );
}

PercentageBar.defaultProps = {
  className: "",
  foregroundColor: "purple",
  backgroundColor: "haze",
  textColor: "dark",
  height: 50,
  showText: true,
  enclosed: true,
  ensureBorderRadius: false
};

PercentageBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // string is for decimal place values
  fromValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // string is for decimal place values,
  toValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // string is for decimal place values,
  foregroundColor: propTypes.color.bg,
  backgroundColor: propTypes.color.bg,
  textColor: propTypes.color.color,
  showChevron: PropTypes.bool,
  arrowCircleProps: PropTypes.object,
  height: propTypes.layout.height,
  zeroMessage: PropTypes.string,
  percentageTextProps: PropTypes.object,
  enclosed: PropTypes.bool,
  outerProps: PropTypes.object,
  showText: PropTypes.bool,
  to: PropTypes.string // i.e. the href
};
