import { createContext, useContext } from "react";

import useUserProfile from "@/hooks/useUserProfile";

const UserProfileContext = createContext();

export function UserProfileProvider({ children }) {
  const api = useUserProfile();
  return <UserProfileContext.Provider value={api}>{children}</UserProfileContext.Provider>;
}

export function useUserProfileContext() {
  return useContext(UserProfileContext);
}
