import Svg from "@/components/Svg";

export default function BloodIcon({ size }) {
  return (
    <Svg
      as="svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-name="test"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19599)">
        <g clipPath="url(#clip1_3634_19599)">
          <path
            d="M36.1508 34.3799L45.6569 24.8738L55.1121 34.3289L50.5908 38.8501L36.1461 34.3845L36.1508 34.3799ZM59.6194 35.1358L44.864 20.3804C44.3539 19.8703 43.5285 19.8703 43.0184 20.3804C42.5083 20.8905 42.5083 21.7159 43.0184 22.226L43.8206 23.0282L22.717 44.1272C19.0907 47.7534 19.0954 53.6333 22.717 57.2596C26.3432 60.8858 32.2231 60.8858 35.8494 57.2596L56.9484 36.1606L57.7691 36.9813C58.2792 37.4914 59.1046 37.4914 59.6147 36.9813C60.1248 36.4713 60.1248 35.6458 59.6147 35.1358H59.6194Z"
            fill="#1A1A1A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3634_19599">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
        <clipPath id="clip1_3634_19599">
          <rect width="40" height="39.9814" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
