import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import ChevronComponent from "@/tpo/Chevron";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export default function ClinicBookingOptions({ order, orderTestItem }) {
  const clinicBooking = orderTestItem.clinicBooking;
  const clinicLocation = orderTestItem.clinicLocation;

  const status = clinicBooking.status;
  const bookingUrl =
    clinicBooking.url || clinicLocation.bookingLink || clinicLocation.clinic.bookingLink;

  return (
    <Stack gap={20} my={20}>
      {status !== "incomplete" && (
        <Box fontSize={16} fontFamily="gilroyMedium">
          Booking confirmed: {clinicBooking.details.datetime}
        </Box>
      )}
      {status === "incomplete" && bookingUrl && (
        <ExternalTextLink
          key="book-your-appointment"
          href={bookingUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonV2 color="green" rightIcon={<ChevronComponent />}>
            Book your appointment
          </ButtonV2>
        </ExternalTextLink>
      )}
      {status === "pending" && (
        <>
          <ExternalTextLink
            key="reschedule-appointment"
            href={`mailto:support@omnos.me?subject=Appointment%20reschedule%20request%20-%20${order.id} (${orderTestItem.id})`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonV2 color="green" rightIcon={<ChevronComponent />}>
              Reschedule
            </ButtonV2>
          </ExternalTextLink>
          <ExternalTextLink
            key="cancel-appointment"
            href={`mailto:support@omnos.me?subject=Appointment%20cancel%20request%20-%20${order.id}  (${orderTestItem.id})`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonV2 color="red" rightIcon={<ChevronComponent />}>
              Cancel
            </ButtonV2>
          </ExternalTextLink>
        </>
      )}
    </Stack>
  );
}
