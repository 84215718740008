import { useState } from "react";

import Tick from "@/images/tick.svg?react";
import Group from "@/tpo/Group";
import Menu, { Button } from "@/tpo/Menu";
import { useTheme } from "styled-components";

export default function MultiSelectDropdown({ label, options = [], onChange, value = [] }) {
  const [showMenu, setShowMenu] = useState(false);
  const theme = useTheme();

  return (
    <Menu
      opened={showMenu}
      onChange={setShowMenu}
      trigger="hover"
      allowedPlacements={["bottom", "bottom-start"]}
    >
      <Menu.Target>
        <Button
          open={showMenu}
          color="dark"
          sx={{
            color: "white",
            px: [20, 20, 40],
            fontSize: [14, 14, 16],
            fontFamily: "gilroyRegular",
            textTransform: "unset",
            letterSpacing: "unset"
          }}
        >
          {label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown
        mt={2}
        py={2}
        zIndex={2}
        minWidth={200}
        minHeight={300}
        overflow="auto"
        bg="dark"
      >
        {options.map(o => {
          const isSelected = value.includes(o.value);

          return (
            <Menu.Item
              onClick={() => {
                if (isSelected) {
                  onChange(
                    value.filter(v => {
                      return v !== o.value;
                    })
                  );
                } else {
                  onChange([...value, o.value]);
                }
              }}
              selected={isSelected}
              key={o.value}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              gap={10}
              alignItems="center"
              keepOpen
            >
              <Group gap={10} color="inherit">
                <Tick
                  fill={theme.colors.green}
                  width={20}
                  style={{
                    transform: "scale3D(0.5,0.5,0.5",
                    visibility: isSelected ? "visible" : "hidden"
                  }}
                />
                {o.label}
              </Group>
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
