import Badge from "@/tpo/Badge";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";

import Box from "./Box";
import GridTestProductCard from "./GridTestProductCard";

function Header({ children, id, name, addons, laboratory, iconType, iconSize, centrifuge }) {
  return (
    <Group justifyContent="space-between">
      <Group gap={20}>
        <GridTestProductCard.Icon iconType={iconType} iconSize={iconSize} />
        <Stack gap={0}>
          <Box>
            <Box fontFamily="gilroyMedium" lineHeight={"24px"} fontSize={14} color="midGrey">
              {id}
            </Box>
            <Box fontFamily="gilroyBold" lineHeight={"36px"} fontSize={[24, 24, 28]}>
              {name}
            </Box>
          </Box>
          <Group alignItems="center" gap={10}>
            {!!laboratory?.name && (
              <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                {laboratory.name}
              </Box>
            )}
            {addons?.length > 0 && (
              <Badge bg="dark" color="white" size="xs">
                add-ons available
              </Badge>
            )}
            {centrifuge && (
              <Badge bg="red" color="white" size="xs">
                centrifuge required
              </Badge>
            )}
          </Group>
        </Stack>
      </Group>
      <Group gap={8}>{children}</Group>
    </Group>
  );
}

function Content({ shortDescription, testType, categories, pb }) {
  return (
    <Stack gap={16} pb={pb}>
      {shortDescription && (
        <Box fontSize={14} fontFamily="gilroyMedium" maxWidth={1024}>
          {shortDescription}
        </Box>
      )}
      <Group flexWrap="wrap" gap={2} mt="auto">
        {testType && (
          <Badge bg="purple" color="white" size="xs">
            {testType}
          </Badge>
        )}
        {categories
          .map(cat => cat.name)
          .map(c => (
            <Badge bg="haze" color="dark" size="xs" key={c}>
              {c}
            </Badge>
          ))}
      </Group>
    </Stack>
  );
}

export default Object.assign(
  {},
  {
    Card: GridTestProductCard.Card,
    Header,
    Content
  }
);
