import { forwardRef } from "react";

import Box from "@/components/Box";

const Stack = forwardRef(({ children, ...props }, ref) => (
  <Box {...props} ref={ref}>
    {children}
  </Box>
));

Stack.defaultProps = {
  display: "flex",
  flexDirection: "column",
  gap: 10
};

export default Stack;
