import { useContext } from "react";

import { Redirect } from "react-router-dom";

import DataLoader from "@/components/DataLoader";
import { getClientManagementUserTestUrl } from "@/core/urls";
import { ORDER_TEST_ITEM_FIELDS } from "@/graphql/shop";
import { ViewerContext } from "@/tpo/Viewer";
import { gql } from "@apollo/client";

import Template from "../shared/Template";
import NoTestsAndOrders from "./NoTestsAndOrders";
import OrdersOnly from "./OrdersOnly";

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest($userId: ID!, $allTests: Boolean!, $includeUnapproved: Boolean!) {
    userTests(userId: $userId, allTests: $allTests, includeUnapproved: $includeUnapproved) {
      id
    }
    ordersInProgress(userId: $userId) {
      ...OrderTestItemFields
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
`;

export default function TestsRoute({ templateProps }) {
  const { userId } = useContext(ViewerContext);

  return (
    <Template selectedTab="tests" docTitle="Patient's tests" {...templateProps}>
      <DataLoader
        query={GET_FIRST_USER_TEST}
        variables={{
          userId,
          allTests: true,
          includeUnapproved: true
        }}
        render={({ ordersInProgress, userTests }) =>
          userTests.length ? (
            <Redirect to={getClientManagementUserTestUrl(userId, userTests[0].id)} />
          ) : ordersInProgress?.length ? (
            <OrdersOnly ordersInProgress={ordersInProgress} />
          ) : (
            <NoTestsAndOrders />
          )
        }
      />
    </Template>
  );
}
