import { useState } from "react";

import Box from "@/components/Box";
import BaseProductQtyInput from "@/components/ProductQtyInput";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import AddToBasketButton from "@/tpo/shop/AddToBasketButton";
import ButtonV2 from "@/v2/Buttons";

function ProductQtyInput({ count: initialCount, disabled, setQty }) {
  const [count, setCount] = useState(initialCount);

  return (
    <BaseProductQtyInput
      value={count}
      disabled={disabled}
      onChange={n => {
        setCount(n);
      }}
      data-testid="add-to-basket-section:thermostat-button"
      onBlur={() => {
        if (count !== "") {
          if (count !== initialCount) {
            setQty(count);
          }
        } else {
          setCount(initialCount);
        }
      }}
    />
  );
}

function Controls({ practitionerSupportRequired, onAddToBasket, status, setQty }) {
  const { selectedTestProductId } = useTestProductModalContext();
  const {
    basket,
    hasBasketGotProduct,
    removeProductFromBasket,
    loading,
    getQtyForProduct
  } = useOrganisationBasketContext();

  if (basket?.purchaseAsStock) {
    const qty = getQtyForProduct(selectedTestProductId);

    return qty ? (
      <ProductQtyInput key={qty} count={qty} disabled={loading} setQty={setQty} />
    ) : (
      <AddToBasketButton
        status={status}
        practitionerSupportRequired={practitionerSupportRequired}
        onAddToBasket={onAddToBasket}
        disabled={loading}
      />
    );
  }

  return hasBasketGotProduct(selectedTestProductId) ? (
    <ButtonV2
      color="red"
      onClick={() => removeProductFromBasket(selectedTestProductId)}
      size={["sm", "sm", "md"]}
    >
      remove
    </ButtonV2>
  ) : (
    <AddToBasketButton
      status={status}
      practitionerSupportRequired={practitionerSupportRequired}
      onAddToBasket={onAddToBasket}
      disabled={loading}
    />
  );
}

function ProductWithAddons({
  priceElement,
  status,
  practitionerSupportRequired,
  onAddToBasket,
  setQty
}) {
  return (
    <Box mt={[40]} borderTopStyle="solid" borderTopWidth={1} borderTopColor="haze" pt={20}>
      <Box fontFamily="gilroyBold" fontSize={28}>
        Base Test
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={16} mt={20}>
        You will need to add the base test to your basket to unlock the panels
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        flexWrap="wrap"
        gap={10}
        mt={[30]}
      >
        {priceElement}
        <Controls
          onAddToBasket={onAddToBasket}
          practitionerSupportRequired={practitionerSupportRequired}
          priceElement={priceElement}
          status={status}
          setQty={setQty}
        />
      </Box>
    </Box>
  );
}

function ProductWithoutAddons({
  priceElement,
  status,
  practitionerSupportRequired,
  onAddToBasket,
  setQty
}) {
  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent="space-between"
      flexWrap="wrap"
      gap={10}
      mt={[40]}
      borderTopStyle="solid"
      borderTopWidth={1}
      borderTopColor="haze"
      pt={20}
    >
      {priceElement}
      <Controls
        onAddToBasket={onAddToBasket}
        practitionerSupportRequired={practitionerSupportRequired}
        priceElement={priceElement}
        status={status}
        setQty={setQty}
      />
    </Box>
  );
}

export default function AddToBasketSection({
  setQty,
  onAddToBasket,
  practitionerSupportRequired,
  priceElement,
  status
}) {
  const { testProduct } = useTestProductModalContext();

  return testProduct?.addons?.length ? (
    <ProductWithAddons
      setQty={setQty}
      onAddToBasket={onAddToBasket}
      practitionerSupportRequired={practitionerSupportRequired}
      priceElement={priceElement}
      status={status}
    />
  ) : (
    <ProductWithoutAddons
      setQty={setQty}
      onAddToBasket={onAddToBasket}
      practitionerSupportRequired={practitionerSupportRequired}
      priceElement={priceElement}
      status={status}
    />
  );
}
