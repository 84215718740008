import { useEffect, useRef } from "react";
import { forwardRef } from "react";

import Flicking, { FlickingError, ERROR_CODE } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import "@/core/constants";
import useWindowSize from "@/hooks/use-window-size";
import ButtonV2 from "@/v2/Buttons";
import styled from "styled-components";

export const SliderTab = forwardRef(
  (
    {
      height,
      lineHeight,
      letterSpacing,
      fontSize,
      tab,
      selectedTab,
      onClick,
      px,
      py,
      disabled,
      "data-component-name": dataComponentName
    },
    ref
  ) => {
    return (
      <ButtonV2
        className="SliderTab"
        data-selected={tab.slug === selectedTab}
        bg={tab.slug === selectedTab ? "dark" : "white"}
        fontSize={fontSize}
        py={py}
        px={px}
        borderWidth={1}
        display="inline"
        whiteSpace="nowrap"
        onClick={onClick}
        disabled={disabled}
        data-component-name={dataComponentName}
        ref={ref}
        height={height}
        sx={{
          lineHeight,
          letterSpacing
        }}
      >
        {tab.label}
      </ButtonV2>
    );
  }
);

SliderTab.defaultProps = {
  borderRadiusBottom: true,
  px: [20, 20, 32],
  height: [40, 40, 48],
  lineHeight: "70%",
  fontSize: 12,
  letterSpacing: [1.5, 1.5, 2.8]
};

const SliderTabsContainer = styled(Box)`
  ${({ withBorder, theme }) => (withBorder ? `border-bottom: 1px solid ${theme.colors.haze}` : "")};
  .SliderTab:first-child {
    border-left-style: solid;
  }

  .SliderTab {
    border-right-style: solid;
    border-color: ${({ theme }) => theme.colors.haze};
    color: ${({ theme }) => theme.colors.dark};
    border-radius: 0;
    border-width: 1px;
  }

  .SliderTab[data-selected="true"] {
    color: white;
    border-color: ${({ theme }) => theme.colors.dark};
  }
`;

export default function SliderTabs({
  bg,
  tabs,
  selectedTab,
  renderTab,
  withBorder,
  "data-component-name": dataComponentName = "SliderTabs"
}) {
  const history = useHistory();
  const flickingApiRef = useRef();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (flickingApiRef.current) {
      flickingApiRef.current
        .moveTo(
          tabs.findIndex(tab => tab.slug === selectedTab),
          0
        )
        .catch(err => {
          if (err instanceof FlickingError && err.code === ERROR_CODE.ANIMATION_ALREADY_PLAYING)
            return;
          throw err;
        });
    }
  }, [selectedTab, windowSize?.width, tabs]);

  return (
    <SliderTabsContainer bg={bg} withBorder={withBorder} data-component-name={dataComponentName}>
      <Flicking
        moveType="freeScroll"
        ref={flickingApiRef}
        align="center"
        bound
        key={`${tabs.map(t => t.disabled).join(",")}.${selectedTab}`}
        // This is quite expensive, remounting the component every time they change time, and especially on resize,
        // however it's the only way I can see.  Without selected tab being included in the key, it doesn't always re-render
        // when the selected tab changes.  Must be the result of some strange bug in the library we are using.
        // disableOnInit={windowSize?.width >= 1020}
        style={{
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        {tabs.map(tab => {
          return renderTab ? (
            renderTab({
              "data-component-name": `SliderTab:${tab.label}`,
              key: tab.slug,
              tab,
              selectedTab,
              tabs,
              disabled: tab.disabled,
              onClick: () => {
                history.push(tab.slug);
              }
            })
          ) : (
            <SliderTab
              data-component-name={`SliderTab:${tab.label}`}
              // disabled if none of the permissions are in the user's permissions
              key={tab.slug}
              tab={tab}
              selectedTab={selectedTab}
              tabs={tabs}
              onClick={() => {
                history.push(tab.slug);
              }}
              disabled={tab.disabled}
            />
          );
        })}
      </Flicking>
    </SliderTabsContainer>
  );
}

SliderTabs.defaultProps = {
  bg: "white",
  withBorder: false
};
