import Circle from "@/components/Circle";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { DASHBOARD_HOME_URL, PARTNERS_ORGANISATIONS_ROOT_URL } from "@/core/urls";
import usePartnerPageCheck from "@/hooks/partner/usePartnerPageCheck";
import PlusIcon from "@/images/thin-plus";

import AccountMenuHeader from "./AccountsMenuHeader";
import AccountMenuItem from "./AccountsMenuItem";

export default function AccountsMenuOrganisations() {
  const {
    availableOrganisations,
    organisationId,
    setOrganisationId,
    setShowCreateOrganisationModal
  } = useOrganisationContext();

  const isPartnerPage = usePartnerPageCheck();
  const { onClick } = useHeaderContext();

  return (
    <>
      <AccountMenuHeader>Regenerus</AccountMenuHeader>
      {availableOrganisations?.map(organisation => (
        <AccountMenuItem
          key={`organisation-${organisation.id}`}
          to={isPartnerPage ? "#" : PARTNERS_ORGANISATIONS_ROOT_URL}
          onClick={evt => {
            setOrganisationId(organisation.id);
            onClick(evt);
          }}
        >
          {organisationId === organisation.id && isPartnerPage && (
            <Circle
              backgroundColor="green"
              position="absolute"
              top="50%"
              size={5}
              transform="translate(-50%, -50%)"
              left="15px"
            />
          )}
          {organisation.name}
        </AccountMenuItem>
      ))}
      {isPartnerPage && (
        <AccountMenuItem
          display="flex"
          alignItems="center"
          onClick={evt => {
            setShowCreateOrganisationModal(true);
            onClick(evt);
          }}
          to="#"
        >
          <PlusIcon color="white" style={{ marginRight: 10 }} size={16} />
          Add new organisation
        </AccountMenuItem>
      )}
    </>
  );
}
