import { forwardRef } from "react";

import { theme } from "@/core/theme";
import CrossIcon from "@/images/cross.svg?react";
import SearchIcon from "@/images/tpo/search.svg?react";
import getResponsiveProps from "@/utils/getResponsiveProps";

import Input from "./Input";
import InputWrapper from "./InputWrapper";

const TextInput = forwardRef(
  (
    {
      label,
      name,
      onChange,
      value,
      placeholder,
      rightIcon,
      height,
      px,
      py,
      error,
      borderRadius,
      bg,
      width,
      maxWidth,
      styles,
      onFocus,
      onBlur,
      minWidth
    },
    ref
  ) => {
    return (
      <InputWrapper
        label={label}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        input={
          <Input
            ref={ref}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            rightIcon={rightIcon}
            height={height}
            px={px}
            py={py}
            borderRadius={borderRadius}
            bg={bg}
            onFocus={onFocus}
            onBlur={onBlur}
            {...styles?.input}
          />
        }
        {...styles?.wrapper}
        error={error}
      />
    );
  }
);

TextInput.defaultProps = {
  bg: "white"
};

export default TextInput;

export function Search({ value, onChange, size, ...props }) {
  const sizes = {
    xs: {
      px: 30,
      height: 40
    },
    sm: {
      px: 30,
      height: 48
    }
  };

  const sizeProps = getResponsiveProps(sizes, size);

  return (
    <TextInput
      placeholder="Search"
      onChange={e => onChange(e.target.value)}
      value={value}
      rightIcon={
        value ? (
          <CrossIcon
            fill="black"
            width={17}
            height={17}
            onClick={() => onChange("")}
            style={{
              cursor: "pointer"
            }}
          />
        ) : (
          <SearchIcon fill={theme.colors.dark} width={17} height={17} />
        )
      }
      bg="white"
      borderRadius={100}
      {...props}
      {...sizeProps}
    />
  );
}

Search.defaultProps = {
  size: ["xs", "xs", "sm"]
};
