import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useAppState } from "@/components/AppStateProvider";
import { useAuthContext } from "@/components/AuthProvider";
import Box from "@/components/Box";
import { useBasketContext } from "@/contexts/BasketContext";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { PRACTITIONER } from "@/core/constants";
import { REGISTER_KIT_URL, TESTS_URL } from "@/core/urls";
import useWindowSize from "@/hooks/use-window-size";
import Logo from "@/images/logo.svg?react";
import MobileLogo from "@/images/tpo/omnos-navbar-logo-mobile";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import BurgerButton from "@/tpo/header/BurgerButton";

import AccountsMenu from "./AccountsMenu/AccountsMenu";
import BackButton from "./BackButton";
import BasketButtonUi from "./BasketButtonUi";
import Container from "./Container";
import LoggedOutMenuDrawer from "./LoggedOutMenuDrawer";
import LoggedOutMenuItems from "./LoggedOutMenuItems";
import ReleaseButton from "./ReleaseButton";
import UserMenuDrawer from "./UserMenuDrawer";

export default function UserHeader({ user, userSubmissions }) {
  // Always use this header if the user isn't a partner
  // If a partner this will only render is the user isn't at a partner url

  const { open, onClick } = useHeaderContext();
  const { openOrder } = useBasketContext();

  const { basketOpen, setBasketOpen } = useAppState();
  const [basketCount, setBasketCount] = useState(0);

  useEffect(() => {
    if (openOrder) {
      setBasketCount(openOrder.testItems.length + openOrder.supplementItems.length);
    }
  }, [openOrder]);

  const drawerMenu = user ? (
    userSubmissions ? (
      <UserMenuDrawer bg="dark" user={user} userSubmissions={userSubmissions} />
    ) : null
  ) : (
    <LoggedOutMenuDrawer bg="dark" />
  );

  const center = (
    <>
      <Box
        as={LinkWrapper}
        color="white"
        fontFamily="gilroyBold"
        fontSize={16}
        to={TESTS_URL}
        onClick={onClick}
        display={["none", "none", "inline"]}
      >
        Shop
      </Box>
      {user ? (
        <>
          <AccountsMenu bg="dark" user={user} />
          <Box
            as={LinkWrapper}
            color="green"
            fontFamily="gilroyBold"
            fontSize={16}
            to={REGISTER_KIT_URL}
            textDecoration="underline"
            display={["none", "none", "inline"]}
            onClick={onClick}
          >
            Register kit
          </Box>
        </>
      ) : (
        <LoggedOutMenuItems bg="dark" />
      )}
      <BurgerButton onClick={open} />
      {drawerMenu}
    </>
  );

  const right =
    !user || user?.partnerUserProfile?.tier !== PRACTITIONER ? (
      <BasketButtonUi
        basketCount={basketCount}
        setBasketOpen={setBasketOpen}
        basketOpen={basketOpen}
      />
    ) : null;

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 960;
  const history = useHistory();
  const authContext = useAuthContext();
  const impersonated = authContext?.user?.impersonated;

  return (
    <Container bg="transparent" data-component-name="NavHeader" height={[45, 45, 60]}>
      <Group alignItems="center" justifyContent="space-between" gap={10} height="100%">
        <Center bg="dark" p={20} borderRadius="10px" height="100%" width={[45, 45, 60]}>
          <BackButton onClick={() => history.goBack()} />
        </Center>
        <Group
          alignItems="center"
          bg="dark"
          justifyContent="space-between"
          flexGrow={1}
          py={[0, 0, 2]}
          borderRadius="10px"
          px={20}
          height="100%"
        >
          <Group alignItems="center" justifyContent="space-between" mr="auto">
            <Logo
              height={40}
              style={{
                display: isMobile ? "none" : "block"
              }}
            />
            <MobileLogo
              size={30}
              style={{
                display: isMobile ? "block" : "none"
              }}
            />
            {impersonated && <ReleaseButton user={user} />}
          </Group>
          <Group alignItems="center" justifyContent="space-between" gap={20}>
            {center}
          </Group>
        </Group>
        {right && (
          <Group
            height="100%"
            alignItems="center"
            justifyContent="center"
            bg="dark"
            p={20}
            borderRadius="10px"
            width={[45, 45, 60]}
          >
            {right}
          </Group>
        )}
      </Group>
    </Container>
  );
}
