export default function FilterIcon({ width = 20, height = 10, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M19 2H1V0H19V2Z" fill="inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 6H4V4H16V6Z" fill="inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 10H7V8H13V10Z" fill="inherit" />
    </svg>
  );
}
