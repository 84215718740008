import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import { theme } from "@/core/theme";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Center from "@/tpo/Center";
import { CollapseableText } from "@/tpo/CollapseableText";
import SegmentedControl from "@/tpo/SegmentedControl";
import Spacer from "@/tpo/Spacer";

export default function ListPageTemplate({
  bg,
  header,
  tab,
  title,
  subtitle,
  children,
  urlMap,
  pb,
  switcherOptions = []
}) {
  const history = useHistory();

  return (
    <>
      {header}
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: theme.spacing.section.pt,
          pb: pb,
          px: [20, 20, "5.5vw"],
          bg
        }}
      >
        <Center>
          <SegmentedControl
            data={switcherOptions}
            onChange={v => history.push(urlMap[v])}
            value={tab}
            size={["xs", "xs", "sm"]}
            bg={bg === "white" ? "haze" : "white"}
          />
        </Center>
        <Spacer py={[20]} />
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          {title}
        </Box>
        <Spacer py={[2, 2, 15]} />
        <CollapseableText fontSize={[14, 14, 16]}>{subtitle}</CollapseableText>
      </PanelBoxV2>
      {children}
    </>
  );
}

ListPageTemplate.defaultProps = {
  pb: theme.spacing.section.pb
};
