import { EXPERT_FIELDS } from "@/graphql/accounts";
import { gql, useQuery } from "@apollo/client";

const EXPERT_CONNECTIONS_QUERY = gql`
  query ExpertConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $consultationTypes: String
    $practitionerTypes: String
    $specialisms: String
    $seed: String
    $supportedTests: String
    $country: String
    $language: String
  ) {
    expertConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      consultationTypes: $consultationTypes
      practitionerTypes: $practitionerTypes
      specialisms: $specialisms
      seed: $seed
      supportedTests: $supportedTests
      country: $country
      language: $language
    ) {
      edges {
        node {
          ...ExpertFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EXPERT_FIELDS}
`;

export default function useExperts({
  orderBy,
  consultationTypes = "",
  practitionerTypes = "",
  specialisms = "",
  search = "",
  seed = "",
  supportedTests = "",
  countryName,
  language
}) {
  const { data, loading, fetchMore } = useQuery(EXPERT_CONNECTIONS_QUERY, {
    variables: {
      orderBy,
      search,
      consultationTypes,
      practitionerTypes,
      specialisms,
      first: 10,
      after: "",
      seed,
      supportedTests,
      country: countryName,
      language
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  const connectionsFieldName = "expertConnections";

  const experts = data?.[connectionsFieldName]?.edges.map(edge => edge.node);
  const hasMore = data?.[connectionsFieldName]?.pageInfo?.hasNextPage;
  const endCursor = data?.[connectionsFieldName]?.pageInfo?.endCursor;

  return {
    experts,
    loading,
    hasMore,
    endCursor,
    fetchMore
  };
}
