import { useEffect } from "react";

import { useUserProfileContext } from "@/contexts/UserProfileContext";
import { PRACTITIONER } from "@/core/constants";
import { CREATE_PARTNER_USER_PROFILE_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";

import Common from "./CommonForm";

export default function LoggedInForm({ setFormSubmitted }) {
  const { platformUserProfile } = useUserProfileContext();

  const api = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      professionalBodies: input.professionalBodies.join(","),
      documents: JSON.stringify(
        input.documents.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.type]: {
              key: doc.key,
              name: doc.name
            }
          }),
          {}
        )
      )
    }),
    mutation: CREATE_PARTNER_USER_PROFILE_MUTATION,
    mutationName: "createPartnerUserProfileMutation",
    defaultValues: {
      tier: PRACTITIONER,
      firstName: platformUserProfile?.user?.firstName || "",
      lastName: platformUserProfile?.user?.lastName || "",
      email: platformUserProfile?.user?.email || "",
      dateOfBirth: platformUserProfile?.dateOfBirth || "",
      gender: platformUserProfile?.gender || "",
      practitionerType: "",
      professionalBodies: [],
      documents: [],
      phoneNumber: "",
      jobRole: "",
      businessLocationCity: "",
      businessCountry: "",
      sources: "",
      sourcesOther: "",
      instagram: "",
      linkedin: "",
      youtube: "",
      websiteBlog: "",
      partnerUpdatesConsent: false,
      acceptTerms: false
    },
    handleSuccess: () => {
      setFormSubmitted(true);
    }
  });

  useEffect(() => {
    if (platformUserProfile) {
      api.methods.reset({
        tier: PRACTITIONER,
        firstName: platformUserProfile?.user?.firstName || "",
        lastName: platformUserProfile?.user?.lastName || "",
        email: platformUserProfile?.user?.email || "",
        dateOfBirth: platformUserProfile?.dateOfBirth || "",
        gender: platformUserProfile?.gender || "",
        practitionerType: "",
        professionalBodies: [],
        documents: [],
        phoneNumber: "",
        jobRole: "",
        businessLocationCity: "",
        businessCountry: "",
        sources: "",
        sourcesOther: "",
        instagram: "",
        linkedin: "",
        youtube: "",
        websiteBlog: "",
        partnerUpdatesConsent: false,
        acceptTerms: false
      });
    }
  }, [platformUserProfile]);

  return (
    <Common
      api={api}
      readOnlyFields={["firstName", "lastName", "email", "gender", "dateOfBirth"]}
    />
  );
}
