import {
  bindValueToSegment,
  getChartRanges,
  getInterpretationColor,
  roundTimestampToDay
} from "@/utils/shared";

export function buildSegmentedGraphConfiguration({
  userDatapoints,
  userResults,
  chartConfiguration: {
    normalisedRange,
    chartRange: { range },
    terminology
  }
}) {
  if (userDatapoints && userResults) {
    throw new Error("Cannot use both userDatapoints and userResults");
  }

  const { fullRange } = getChartRanges({
    range,
    normalisedRange,
    precision: null,
    withLabels: false
  });

  const isPlus3Chart = terminology.includes("+3");
  const isNGChart = terminology.includes("NG");

  if (isPlus3Chart) {
    fullRange.push({ value: 1 });
  }
  let points = [];
  let tooltips = [];
  let colors = [];

  if (userDatapoints) {
    [points, tooltips, colors] = userDatapoints.reduce(
      ([points, tooltips, colors], userDatapoint, index) => {
        const {
          chartConfiguration: {
            normalisedRange: { value }
          },
          dateExtracted
        } = userDatapoint;
        let y = parseFloat(value);

        if (isNGChart) {
          y = bindValueToSegment(value, fullRange.length - 1);
        } else if (isPlus3Chart) {
          y = bindValueToSegment(value, fullRange.length - 1) + 1 / 7;
        }

        let xValue = new Date(dateExtracted).getTime();

        points.push([xValue, y]);

        const stack = [
          `<div class='interpretation' style='color: ${getInterpretationColor(
            userDatapoint.chartConfiguration.interpretation
          )};'>${userDatapoint.chartConfiguration.interpretation}</div>`,
          `<div class='date'>${new Date(xValue).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
            year: "numeric"
          })}</div>`
        ];

        if (
          userDatapoints.length - 1 === index &&
          userDatapoint.dateExpired &&
          new Date(userDatapoint.dateExpired) <= new Date()
        ) {
          stack.push("<div class='retest'>(Due for retest)</div>");
        }

        tooltips.push(stack);

        colors.push(
          new Date(userDatapoint.dateExpired) <= new Date()
            ? "#818181"
            : getInterpretationColor(userDatapoint.chartConfiguration.interpretation)
        );

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  } else if (userResults) {
    [points, tooltips, colors] = userResults.reduce(
      ([points, tooltips, colors], userResult, index) => {
        const {
          userSnapshot: { snapshotDate },
          chartConfiguration: {
            normalisedRange: { value }
          }
        } = userResult;

        const y = value;
        let xValue = roundTimestampToDay(snapshotDate);
        points.push([xValue, y]);
        const stack = [
          `<div class='interpretation' style='color: ${getInterpretationColor(
            userResult.chartConfiguration.interpretation
          )};'>${userResult.chartConfiguration.interpretation}</div>`,
          `<div class='date'>${new Date(xValue).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
            year: "numeric"
          })}</div>`
        ];

        tooltips.push(stack);

        colors.push(
          new Date(userResult.dateExpired) <= new Date()
            ? "#818181"
            : getInterpretationColor(userResult.userSnapshot.interpretation)
        );

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  }

  return {
    fullRange,
    points,
    tooltips,
    colors
  };
}
