import { forwardRef } from "react";

import Box from "@/components/Box";
import classNames from "classnames";

export function Error({ className, error, ...props }) {
  return (
    <Box
      className={classNames(className, "error")}
      fontFamily="gilroyMedium"
      fontSize={10}
      color="red"
      {...props}
    >
      {error}
    </Box>
  );
}

const InputWrapper = forwardRef(
  ({ className, label, labelProps, input, error, helpText, helpTextProps, ...props }, ref) => (
    <Box className={classNames("inputWrapper", className)} {...props} ref={ref}>
      {label && (
        <Box mb={2} {...labelProps}>
          {label}
        </Box>
      )}
      {input && <Box>{input}</Box>}
      {helpText && (
        <Box
          fontFamily="gilroyMedium"
          className="helpText"
          fontSize={[10]}
          mt={2}
          {...helpTextProps}
        >
          {helpText}
        </Box>
      )}
      {error && <Error error={error} mt={2} />}
    </Box>
  )
);

export default InputWrapper;

InputWrapper.defaultProps = {
  errorPosition: "bottom"
};
