import { useState, useCallback, useMemo, useEffect } from "react";

import { useTestProductOptionContext } from "@/contexts/TestProductOptionContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { useLazyQuery } from "@apollo/client";

export default function useTestProductModal({ testProductQuery, testProductFieldName }) {
  const [selectedTestProductId, setSelectedTestProductId] = useState();
  const {
    selectTestProductOptionId,
    selectedTestProductOptionId,
    testProductOption
  } = useTestProductOptionContext();

  const selectTestProductId = useCallback(
    id => {
      setSelectedTestProductId(id);
      selectTestProductOptionId(undefined);
    },
    [setSelectedTestProductId, selectTestProductOptionId]
  );

  /**
   * Query because the detail view will have more details than on the list view.
   */

  const { basket } = useOrganisationBasketContext();
  const { organisation } = useOrganisationContext();

  const [fetchProduct, { data, loading }] = useLazyQuery(testProductQuery, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: {
      id: selectedTestProductId,
      paymentIsoCode: basket?.paymentLocale?.isoCode,
      organisation: organisation?.id
    },
    skip: !selectedTestProductId || !basket
  });

  useEffect(() => {
    if (selectedTestProductId) {
      fetchProduct({
        variables: {
          id: selectedTestProductId,
          paymentIsoCode: basket?.paymentLocale?.isoCode,
          organisation: organisation?.id
        }
      });
    }
  }, [selectedTestProductId, fetchProduct, basket, organisation?.id]);

  const testProduct = data?.[testProductFieldName];
  const userTestRecommendation = data?.userTestRecommendation;

  const api = useMemo(
    () => ({
      loading,
      selectedTestProductId,
      selectTestProductId,
      testProduct,
      userTestRecommendation,
      selectTestProductOptionId,
      testProductOption,
      selectedTestProductOptionId
    }),
    [
      selectedTestProductId,
      selectTestProductId,
      loading,
      testProduct,
      userTestRecommendation,
      selectTestProductOptionId,
      testProductOption,
      selectedTestProductOptionId
    ]
  );

  return api;
}
