import { useState } from "react";

import { useFormContext } from "react-hook-form";

import Badge from "@/tpo/Badge";
import InputWrapper from "@/tpo/InputWrapper";
import Menu, { Button } from "@/tpo/Menu";
import ButtonV2 from "@/v2/Buttons";

export default function PractitionerTypeField({ practitionerTypes }) {
  const { setValue, watch, getFieldState, clearErrors } = useFormContext();
  const practitionerTypeValue = watch("practitionerType");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const { error } = getFieldState("practitionerType");

  return (
    <>
      <InputWrapper
        error={error?.message}
        input={
          <Menu opened={showFilterMenu} onChange={setShowFilterMenu} trigger="hover">
            <Menu.Target>
              <Button
                open={showFilterMenu}
                color="dark"
                data-component-name="practitionerType"
                sx={{
                  color: "white"
                }}
              >
                Select Option
              </Button>
            </Menu.Target>
            <Menu.Dropdown
              mt={2}
              py={2}
              zIndex={2}
              minWidth={200}
              height={400}
              maxHeight={400}
              overflowY="auto"
            >
              {practitionerTypes.map(sortOption => (
                <Menu.Item
                  onClick={() => {
                    setValue("practitionerType", sortOption.id);
                    setValue("documents", []);
                    setValue("professionalBodies", []);
                    clearErrors(["practitionerType", "documents", "professionalBodies"]);
                  }}
                  selected={sortOption.id === practitionerTypeValue}
                  key={sortOption.id}
                  fontSize={14}
                  lineHeight={1.7}
                  py={16}
                  data-testid={`practitionerTypeOption:${sortOption.role}`}
                >
                  {sortOption.role}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        }
      />
      {practitionerTypeValue && (
        <Badge
          bg="green"
          color="white"
          size={["lg", "lg", "xl"]}
          data-testid="practitionerTypeValue"
        >
          {practitionerTypes.find(({ id }) => id === practitionerTypeValue)?.role}
        </Badge>
      )}
    </>
  );
}
