import React, { useEffect } from "react";

import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { theme } from "@/core/theme";
import { DASHBOARD_HOME_URL, LOGIN_URL, TERMS_OF_SERVICE_URL, TERMS_OF_USE_URL } from "@/core/urls";
import { ACCEPT_PATIENT_INVITE_MUTATION } from "@/graphql/accounts";
import { ACCEPT_PATIENT_INVITE_PAGE_PROPS_QUERY } from "@/graphql/organisations/queries/practitioners";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import ControlledFormField from "@/tpo/ControlledFormField";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import { Error } from "@/tpo/InputWrapper";
import FloatingLabelPasswordInput from "@/tpo/PasswordInput";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import CannotFindInvite from "./CannotFindInvite";
import CustomerPage from "./CustomerPage";
import DataLoader from "./DataLoader";
import { ExternalTextLink } from "./Links";

function AcceptPatientInviteForm({ token, patientId, props }) {
  useDocTitle("Signup confirmation");

  const firstName = props.patientFirstName || "";
  const lastName = props.patientLastName || "";

  const { user } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    // If the user had already authenticated, skip this page and head to the dashboard
    if (user !== null) {
      history.push(DASHBOARD_HOME_URL);
    }
    // eslint-disable-next-line
  }, [user]);

  const api = useDjangoGraphqlForm({
    defaultValues: {
      firstName,
      lastName,
      password: "",
      confirmPassword: "",
      acceptTerms: false
    },
    mutation: ACCEPT_PATIENT_INVITE_MUTATION,
    mutationName: "acceptPatientInviteMutation",
    handleSuccess: () => {
      history.push(LOGIN_URL);
    },
    transformer: data => ({
      ...data,
      patientId,
      token
    })
  });

  const organisationName = props.organisationName;

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Invitation"
      }}
    >
      <Center
        stacked
        gap={[30, 30, 40]}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={20}
      >
        <Box
          fontFamily="gilroyMedium"
          fontSize={[14, 14, 16]}
          maxWidth={760}
          mx="auto"
          width="100%"
        >
          You have been invited to join the Omnos platform by {organisationName}.
          <br />
        </Box>

        <Stack
          as="form"
          maxWidth={600}
          mx="auto"
          onSubmit={api.methods.handleSubmit(api.onSubmit)}
          gap={20}
        >
          {!!api.nonFieldError && <Error data-testid="nonFieldError" error={api.nonFieldError} />}
          <FormProvider {...api.methods}>
            <ControlledFormField
              name="firstName"
              label="First name"
              Component={FloatingLabelInput}
            />
            <ControlledFormField name="lastName" label="Last name" Component={FloatingLabelInput} />
            <ControlledFormField
              label="Password"
              name="password"
              Component={FloatingLabelPasswordInput}
            />
            <ControlledFormField
              label="Confirm Password"
              name="confirmPassword"
              Component={FloatingLabelPasswordInput}
            />
            <ControlledFormField
              Component={Checkbox}
              name="acceptTerms"
              label={
                <span>
                  I have read and agree to the{" "}
                  <ExternalTextLink href={TERMS_OF_SERVICE_URL}>terms of service</ExternalTextLink>
                  {" and the "}
                  <ExternalTextLink href={TERMS_OF_USE_URL}>terms of use</ExternalTextLink>
                </span>
              }
            />
            <ButtonV2
              color="green"
              rightIcon={<ChevronComponent />}
              mx="auto"
              size={["sm", "sm", "md"]}
            >
              submit
            </ButtonV2>
          </FormProvider>
        </Stack>
      </Center>
    </CustomerPage>
  );
}

function AcceptPatientInvitePage(props) {
  useDocTitle("Accept Patient Invite");

  return (
    <DataLoader
      query={ACCEPT_PATIENT_INVITE_PAGE_PROPS_QUERY}
      variables={{ patientId: props.match.params.patientId, token: props.match.params.token }}
      render={data => {
        if (data.acceptPatientInvitePageProps === null) {
          return (
            <CannotFindInvite
              title="Invitation not found"
              headerBg="purple"
              color="white"
              message="I'm sorry, we can't seem to find that patient invite. Please contact support@omnos.me."
            />
          );
        }

        return (
          <AcceptPatientInviteForm
            token={props.match.params.token}
            patientId={props.match.params.patientId}
            props={JSON.parse(data.acceptPatientInvitePageProps)}
          />
        );
      }}
    />
  );
}

export default AcceptPatientInvitePage;
