import React, { useCallback, useState } from "react";

import { theme } from "@/core/theme";
import EyeWithStrikeThrough from "@/images/eye-with-strikethrough.svg?react";
import Eye from "@/images/eye.svg?react";
import Pencil from "@/images/pencil.svg?react";
import PropTypes from "prop-types";
// eslint-disable-next-line
import styled from "styled-components/macro";
import { color, position } from "styled-system";

import Box from "./Box";
import { SpanText } from "./Text";

const Label = styled.label`
  ${color}
  ${position}
  pointer-events: none;
  transform-origin: top left;
  transition: transform 300ms;
  ${props => {
    return props.minimised && "transform: scale(0.65) translate3d(0, -120%, 0);";
  }}
`;

function InputIcons({ internalType, showEye, showPencil, error, toggleEye }) {
  const EyeIcon = internalType === "password" ? EyeWithStrikeThrough : Eye;

  return (
    <>
      {showEye && (
        <EyeIcon
          height={16}
          width={16}
          onClick={toggleEye}
          style={{
            cursor: "pointer",
            marginRight: showPencil ? 10 : 0
          }}
        />
      )}
      {showPencil && <Pencil fill={error ? "red" : theme.colors.slate} width={16} />}
    </>
  );
}

function Input({
  label,
  max,
  min,
  onBlur,
  handleChange,
  onFocus,
  showPencil,
  showEye,
  type,
  value,
  error,
  required,
  disabled,
  autoCapitalize,
  autoComplete,
  children,
  fieldName,
  placeholder,
  "data-component-name": dataComponentName
}) {
  const [focussed, setFocussed] = useState(false);
  const [internalType, setInternalType] = useState(type);

  const toggleEye = useCallback(() => {
    if (internalType === "password") setInternalType("text");
    if (internalType === "text") setInternalType("password");
  }, [internalType, setInternalType]);

  // TODO see if we can lock down non numerical keypresses in firefox for type=number
  // https://stackoverflow.com/questions/49923588/input-type-number-with-pattern-0-9-allows-letters-in-firefox

  let borderColor = theme.colors.carbon;
  if (focussed) borderColor = theme.colors.dark;
  if (error) borderColor = theme.colors.red;

  const topGap = "13px";

  return (
    <Box
      borderBottomColor={borderColor}
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      display="flex"
      alignItems="center"
      position="relative"
      pt={topGap}
      pb="5px"
      css={`
        & > input:-webkit-autofill ~ label {
          transform: scale(0.65) translate3d(0, -110%, 0);
        }
      `}
      data-component-name={dataComponentName}
    >
      <input
        id={fieldName}
        type={showEye ? internalType : type}
        value={value}
        onChange={e => {
          handleChange(e.target.value);
        }}
        onFocus={e => {
          setFocussed(true);
          onFocus();
        }}
        onBlur={e => {
          setFocussed(false);
          onBlur();
        }}
        max={max}
        min={min}
        required={required}
        disabled={disabled}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        style={{ marginRight: (children || showPencil) && "10px", opacity: disabled && "0.5" }}
        placeholder={placeholder}
      />
      <Label
        position="absolute"
        top={topGap}
        left={0}
        htmlFor={fieldName}
        color={error ? "red" : "slate"}
        minimised={focussed || value !== ""}
      >
        {label}
        {required && <SpanText color="red">*</SpanText>}
      </Label>
      {children ? (
        children
      ) : (
        <InputIcons
          internalType={internalType}
          showPencil={showPencil}
          showEye={showEye}
          error={error}
          toggleEye={toggleEye}
        />
      )}
    </Box>
  );
}

Input.defaultProps = {
  className: "",
  onBlur: () => {},
  onFocus: () => {},
  showPencil: true,
  error: false,
  type: "text",
  autoComplete: "off"
};

Input.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  handleChange: PropTypes.func,
  showPencil: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["text", "email", "number", "password"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.oneOf([
    "name",
    "given-name",
    "family-name",
    "email",
    "new-password",
    "current-password",
    "organization",
    "street-address",
    "address-line1",
    "address-line2",
    "address-line3",
    "address-level1",
    "country-name",
    "postal-code",
    "tel",
    "off"
  ])
};

export default Input;
