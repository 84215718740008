import { forwardRef } from "react";

import Box from "@/components/Box";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import {
  BLOOD_ICON_TYPE,
  BREATH_ICON_TYPE,
  HAIR_ICON_TYPE,
  SALIVA_ICON_TYPE,
  STOOL_ICON_TYPE,
  SWAB_ICON_TYPE,
  URINE_ICON_TYPE
} from "@/core/constants";
import BloodIcon from "@/images/BloodIcon";
import BreathIcon from "@/images/BreathIcon";
import HairIcon from "@/images/HairIcon";
import PractitionerFavourite from "@/images/PractitionerFavourite";
import SalivaIcon from "@/images/SalivaIcon";
import StoolIcon from "@/images/StoolIcon";
import SwabIcon from "@/images/SwabIcon";
import UrineIcon from "@/images/UrineIcon";
import Badge from "@/tpo/Badge";
import ChevronComponent from "@/tpo/Chevron";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { useTheme } from "styled-components";

const Card = forwardRef(({ children, onClick, "data-component-name": dataComponentName }, ref) => {
  const theme = useTheme();

  return (
    <Stack
      ref={ref}
      py={40}
      px={28}
      bg="white"
      gap={0}
      borderRadius={theme.radius.card}
      onClick={onClick}
      cursor="pointer"
      data-component-name={dataComponentName}
    >
      {children}
    </Stack>
  );
});

const Icon = forwardRef(({ children, iconType, iconSize = [40, 40, 80] }, ref) => {
  let icon = null;

  if (iconType === URINE_ICON_TYPE) {
    icon = <UrineIcon size={iconSize} />;
  } else if (iconType === BLOOD_ICON_TYPE) {
    icon = <BloodIcon size={iconSize} />;
  } else if (iconType === SALIVA_ICON_TYPE) {
    icon = <SalivaIcon size={iconSize} />;
  } else if (iconType === STOOL_ICON_TYPE) {
    icon = <StoolIcon size={iconSize} />;
  } else if (iconType === SWAB_ICON_TYPE) {
    icon = <SwabIcon size={iconSize} />;
  } else if (iconType === HAIR_ICON_TYPE) {
    icon = <HairIcon size={iconSize} />;
  } else if (iconType === BREATH_ICON_TYPE) {
    icon = <BreathIcon size={iconSize} />;
  }

  return icon;
});

const Header = forwardRef(({ children, iconType, iconSize = [40, 40, 80], mb }, ref) => {
  let icon = <Icon iconType={iconType} iconSize={iconSize} />;

  return (
    <Group justifyContent="space-between" mb={mb}>
      {icon}
      <Group gap={8}>{children}</Group>
    </Group>
  );
});

const Content = forwardRef(
  (
    {
      showProductCode = true,
      truncateDescription = false,
      id,
      name,
      laboratory,
      shortDescription,
      testType,
      categories,
      pb,
      children,
      topSeller
    },
    ref
  ) => {
    return (
      <>
        <Box>
          {!!id && showProductCode && (
            <Box fontFamily="gilroyMedium" lineHeight={"24px"} fontSize={14} color="midGrey">
              {id}
            </Box>
          )}
          {!!name && (
            <Box
              fontFamily="gilroyBold"
              lineHeight={"36px"}
              fontSize={[24, 24, 28]}
              data-testid="testProductListCard:title"
            >
              {name}
            </Box>
          )}
          {!!laboratory?.name && (
            <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
              {laboratory.name}
            </Box>
          )}
        </Box>
        <Stack gap={16} pb={pb}>
          {topSeller && (
            <Group gap={0}>
              <Badge bg="green" color="white" size="xs">
                top seller
              </Badge>
            </Group>
          )}
          {shortDescription && (
            <Box
              fontSize={14}
              fontFamily="gilroyMedium"
              style={
                truncateDescription
                  ? {
                      lineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      display: "-webkit-box"
                    }
                  : {}
              }
            >
              {shortDescription}
            </Box>
          )}
          <Group flexWrap="wrap" gap={2} mt="auto">
            {testType && (
              <Badge bg="purple" color="white" size="xs">
                {testType}
              </Badge>
            )}
            {categories
              .map(cat => cat.name)
              .map(c => (
                <Badge bg="haze" color="dark" size="xs" key={c}>
                  {c}
                </Badge>
              ))}
          </Group>
          {children}
        </Stack>
      </>
    );
  }
);

const PriceSection = forwardRef(({ rrpCurrentPrice, productAudience }, ref) => {
  let btn = (
    <ButtonV2 rightIcon={<ChevronComponent />} color="black" size={["sm", "sm", "md"]}>
      Learn more
    </ButtonV2>
  );

  return (
    <Group
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      mt="auto"
      pt={80}
      gap={28}
    >
      <GilroyFontCurrency value={rrpCurrentPrice} />
      {productAudience === "B2B" ? (
        <ButtonV2 rightIcon={<ChevronComponent />} color="black" size={["sm", "sm", "md"]}>
          learn more
        </ButtonV2>
      ) : (
        btn
      )}
    </Group>
  );
});

const GridTestProductCard = forwardRef(
  (
    {
      productAudience,
      laboratory,
      addons,
      content,
      id,
      name,
      rrpCurrentPrice,
      withCircleButton = false,
      isPractitionerFavourite = false,
      isMostPopularTest = false
    },
    ref
  ) => {
    const { selectTestProductId } = useTestProductModalContext();

    return (
      <Card
        onClick={() => {
          selectTestProductId(id);
        }}
        ref={ref}
      >
        <Header iconType={content.iconType} withCircleButton={withCircleButton} mb={16}>
          {isPractitionerFavourite ? <PractitionerFavourite /> : null}
        </Header>
        <Stack gap={16}>
          <Content
            id={id}
            name={name}
            shortDescription={content?.shortDescription}
            testType={content?.testType}
            categories={content?.categories}
            laboratory={laboratory}
            topSeller={isMostPopularTest}
          />
        </Stack>
        <PriceSection rrpCurrentPrice={rrpCurrentPrice} productAudience={productAudience} />
      </Card>
    );
  }
);

GridTestProductCard.Card = Card;
GridTestProductCard.Icon = Icon;
GridTestProductCard.Header = Header;
GridTestProductCard.Content = Content;
GridTestProductCard.PriceSection = PriceSection;

export default GridTestProductCard;
