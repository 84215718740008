import Box from "@/components/Box";
import { PanelBoxV2 } from "@/tpo/Boxes";
import NothingToShow from "@/tpo/NothingToShow";
import Stack from "@/tpo/Stack";
import TestStatuses from "@/tpo/TestStatuses";
import { useTheme } from "styled-components";

export default function OrdersOnly({ ordersInProgress }) {
  const theme = useTheme();

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: theme.spacing.section.pt,
          px: 20,
          pb: theme.spacing.section.pb
        }}
        stacked
        gap={40}
      >
        <Stack gap={40}>
          <PanelBoxV2 maxWidth={[464, 464, 464, 500]}>
            <TestStatuses
              heading="Available tests"
              ordersInProgress={ordersInProgress}
              testResults={[]}
              handleSelect={() => {}}
              defaultOpen={false}
            />
          </PanelBoxV2>
        </Stack>
        <NothingToShow
          header="No Results available"
          bodyCopy="Your patient's wellness results will become available once they have taken a functional test or completed a symptom questionnaire"
          mx="auto"
        />
      </PanelBoxV2>
    </>
  );
}
