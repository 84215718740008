import Box from "@/components/Box";
import { theme } from "@/core/theme";

import Stack from "./Stack";

function Jumbotron({ children, minHeight, bg, color, title, top, mt, pt }) {
  return (
    <Stack bg={bg} mt={mt} minHeight={minHeight} px={20} pb={theme.spacing.pageHeader.pb} pt={pt}>
      {top}
      <Box maxWidth={1020} mt="auto" mx="auto" width="100%">
        {title && (
          <Box
            color={color}
            fontSize={[32, 32, 44]}
            lineHeight={["130%", "115%", "115%"]}
            fontFamily="gilroyBold"
            as="h1"
          >
            {title}
          </Box>
        )}
        {children}
      </Box>
    </Stack>
  );
}

Jumbotron.defaultProps = {
  bg: "purple",
  color: "white",
  pt: theme.spacing.pageHeader.pt,
  minHeight: theme.spacing.pageHeader.minHeight
};

export default Jumbotron;
