import { useLocation } from "react-router-dom";

import { PARTNERS_PRACTITIONER_URL, PARTNERS_AFFILIATE_URL } from "@/core/urls";

export default function usePartnerPageCheck(
  { excludeApplicationPages = true } = {
    excludeApplicationPages: true
  }
) {
  const location = useLocation();
  return (
    (location.pathname.startsWith("/patients") || location.pathname.startsWith("/partners")) &&
    (excludeApplicationPages
      ? ![PARTNERS_PRACTITIONER_URL, PARTNERS_AFFILIATE_URL].includes(location.pathname)
      : true)
  );
}
