import Box from "@/components/Box";
import Divider from "@/components/Divider";
import MultiSelectDropdown from "@/components/MultiSelectDropdown";
import Textarea from "@/components/Textarea";
import { UploadFileToS3 } from "@/components/UploadFileToS3";
import {
  EXPERT_FEE_LEVEL_200,
  EXPERT_FEE_LEVEL_201,
  EXPERT_FEE_LEVEL_99,
  EXPERT_FREE_CONSULTATION_15_MINS_FREE,
  EXPERT_FREE_CONSULTATION_30_MINS_FREE,
  EXPERT_FREE_CONSULTATION_45_MINS_FREE,
  EXPERT_FREE_CONSULTATION_NOT_FREE
} from "@/core/constants";
import { theme } from "@/core/theme";
import { getExpertsDetailUrl } from "@/core/urls";
import Accordion from "@/tpo/Accordion";
import { PanelBoxV2 } from "@/tpo/Boxes";
import ChevronComponent from "@/tpo/Chevron";
import ControlledCheckboxGroup from "@/tpo/ControlledCheckboxGroup";
import ControlledFormField from "@/tpo/ControlledFormField";
import ControlledRadioGroup from "@/tpo/ControlledRadioGroup";
import FormControl from "@/tpo/FormControl";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Stack from "@/tpo/Stack";
import ToggleSwitch from "@/tpo/ToggleSwitch";
import ButtonV2 from "@/v2/Buttons";
import { gql, useQuery } from "@apollo/client";

import ClearAvatar from "./ClearAvatar";
import PreviewAvatar from "./PreviewAvatar";
import Testimonial from "./Testimonial";

const EXPERT_PROFILE_FORM_FIELD_OPTION_QUERY = gql`
  query ExpertProfileFormFieldOptionQuery($includeFilters: [IncludeFilters]) {
    specialisms: areasOfInterest(includeFilters: $includeFilters) {
      id
      name
    }
    languages {
      id
      name
    }
  }
`;

export default function ExpertForm({ partnerUserProfile }) {
  const { data: { specialisms = [], languages: _languages = [] } = {} } = useQuery(
    EXPERT_PROFILE_FORM_FIELD_OPTION_QUERY,
    {
      variables: {
        includeFilters: ["B2C", "Both"]
      }
    }
  );

  const languages = _languages.map(l => ({
    label: l.name,
    value: l.id
  }));

  return (
    <PanelBoxV2
      maxWidth={1532}
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        px: [20],
        bg: "supplements",
        "data-component-name": "ExpertForm"
      }}
    >
      <Stack maxWidth={760} gap={40} mx="auto">
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Promoted profile
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              As a recommended practitioner we’re happy to have you representing the Omnos platform.
              You can control the information that is shown to the public by editing the fields
              below
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <Group gap={20} alignItems="center" flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={18}>
              Visible to public
            </Box>
            <ControlledFormField
              Component={ToggleSwitch}
              data={[
                {
                  label: "No",
                  value: false,
                  bg: "dark",
                  color: "white"
                },
                {
                  label: "Yes",
                  value: true,
                  bg: "white",
                  color: "dark"
                }
              ]}
              height={40}
              disabledBackground="haze"
              name="expertPublic"
              data-testid="expertPublic"
            />
            <ButtonV2
              color="dark"
              as={partnerUserProfile.expert?.id ? LinkWrapper : undefined}
              to={
                partnerUserProfile.expert?.id
                  ? getExpertsDetailUrl(partnerUserProfile.expert?.id)
                  : undefined
              }
              rightIcon={<ChevronComponent />}
              disabled={!partnerUserProfile.expert?.id}
              type={partnerUserProfile.expert?.id ? null : "button"}
              size={["sm", "sm", "md"]}
            >
              view profile
            </ButtonV2>
          </Group>
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Profile image
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Add an image to your profile to stand out. Images should be no larger than 800x800
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <Group gap={30} alignItems="center" flexWrap="wrap">
            <PreviewAvatar
              placeholder={`${partnerUserProfile.user?.firstName?.[0] || ""}${
                partnerUserProfile.user.lastName?.[0] || ""
              }`}
            />
            <ControlledFormField
              Component={UploadFileToS3}
              name="expertAvatar"
              label="Upload image"
              publicBucket
            />
            <ClearAvatar />
          </Group>
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Personal Bio
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Introduce yourself to a potential patient, give an overview of your background, areas
              of interest and experience.
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledFormField
            Component={Textarea}
            name="expertBio"
            rows={8}
            placeholder="Add your bio"
            resize="none"
            padding="10px"
            width="100%"
            fontFamily="Gilroy W05 Medium"
            fontSize={[14, 14, 16]}
          />
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Spoken languages
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Select the languages that you are comfortable performing consultations in. This will
              allow you to match better with potential patients from around the world
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledFormField
            Component={MultiSelectDropdown}
            name="expertLanguages"
            options={languages}
            label="Languages"
          />
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Consultation type
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Let users what type of consultation you offer, select the appropriate options below.
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledCheckboxGroup
            Container={Group}
            containerProps={{
              gap: 20
            }}
            name="expertConsultationType"
            values={[
              {
                label: "Online",
                value: "Online"
              },
              {
                label: "In person",
                value: "In Person"
              }
            ]}
            uncheckedColor="black"
          />
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Fee levels
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Indicate a fee level for your ongoing consultation services. We won’t show specific
              prices to the end user, but instead indicate a range.
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledRadioGroup
            Container={Stack}
            containerProps={{
              gap: 20
            }}
            name="expertFeeLevel"
            values={[
              {
                label: "<£100/hr",
                value: EXPERT_FEE_LEVEL_99
              },
              {
                label: "£100-£200/hr",
                value: EXPERT_FEE_LEVEL_200
              },
              {
                label: ">£200/hr",
                value: EXPERT_FEE_LEVEL_201
              }
            ]}
            color="black"
          />
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Free consultation?
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              If you offer a free consultation please define the length of time
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledRadioGroup
            Container={Stack}
            containerProps={{
              gap: 20
            }}
            name="expertFreeConsultation"
            values={[
              {
                label: "No Free consultation",
                value: EXPERT_FREE_CONSULTATION_NOT_FREE
              },
              {
                label: "15 mins",
                value: EXPERT_FREE_CONSULTATION_15_MINS_FREE
              },
              {
                label: "30 mins",
                value: EXPERT_FREE_CONSULTATION_30_MINS_FREE
              },
              {
                label: "45 mins",
                value: EXPERT_FREE_CONSULTATION_45_MINS_FREE
              }
            ]}
            color="black"
          />
        </FormControl>
        {/* <FormControl
            as="fieldset"
            spaceAboveHelpText={15}
            label={
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
                Supported tests
              </Box>
            }
            helpText={
              <Box fontFamily="gilroyMedium" fontSize={16}>
                Add the tests that you support, this help your visibility to a patient when they are
                searching for practitioner assistance.
              </Box>
            }
            spaceAboveChildren={40}
            pb={40}
            borderBottom
          >
            <Stack gap={30} alignItems="flex-start">
              <ButtonV2
                color="dark"
                rightIcon={<PlusIcon color="white" size={10} />}
                onClick={() => setShowTestProductsModal(true)}
                type="button"
              >
                add tests
              </ButtonV2>
              <Group gap={10}>
                <SelectedTestProducts />
              </Group>
              <Modal
                maxWidth={1020}
                closeButton
                headerProps={{
                  p: 20,
                  bg: ["white", "white", "transparent"]
                }}
                show={showTestProductsModal}
                close={() => {
                  setShowTestProductsModal(false);
                }}
                bg="white"
                containerProps={{}}
                mode={["fullScreen", "fullScreen", "centered"]}
                height="80vh"
                minHeight={500}
              >
                <Stack px={[20, 20, 100]} pb={80} gap={10}>
                  <TestProductMultiSelectField />
                </Stack>
              </Modal>
            </Stack>
          </FormControl> */}
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Specialisms
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              These selections will be used to help match patients to you in a public profile.
            </Box>
          }
          spaceAboveChildren={40}
          pb={40}
          borderBottom
        >
          <ControlledCheckboxGroup
            name="expertSpecialisms"
            values={specialisms.map(s => ({
              label: s.name,
              value: s.id
            }))}
            Container={Stack}
            containerProps={{
              gap: 20
            }}
            uncheckedColor="black"
          />
        </FormControl>
        <FormControl
          as="fieldset"
          spaceAboveHelpText={15}
          label={
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="26px">
              Testimonials
            </Box>
          }
          helpText={
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              lineHeight={["170%", "170%", "150%"]}
            >
              Add some positive feedback from your existing clients
            </Box>
          }
          spaceAboveChildren={0}
        >
          <Accordion color="black">
            <Testimonial idx={0} />
            <Divider color="black" />
            <Testimonial idx={1} />
            <Divider color="black" />
            <Testimonial idx={2} />
            <Divider color="black" />
            <Testimonial idx={3} />
            <Divider color="black" />
            <Testimonial idx={4} />
            <Divider color="black" />
          </Accordion>
        </FormControl>
      </Stack>
    </PanelBoxV2>
  );
}
