import { PAGE_INFO_FIELDS } from "@/graphql/pagination";
import { ORDER_TEST_ITEM_FIELDS } from "@/graphql/shop";
import gql from "graphql-tag";

export const ORGANISATION_ORDER_SUMMARY_SOURCE_BASKET_FIELDS = gql`
  fragment OrganisationOrderSummarySourceBasketFields on OrganisationBasketType {
    id
    payee
    paymentStatus
    orderStatus
    basketTotalPrice
    currencyName
    currencySymbol
    paymentInvoice {
      id
      stripeId
      paymentStatus
      url
      invoiceUrl
      dateDue
    }
    paymentCheckoutSession {
      id
      stripeId
      paymentStatus
      url
      invoiceUrl
    }
  }
`;

export const ORGANISATION_ORDER_BALANCE_QUERY = gql`
  query OrganisationOrderBalanceQuery($organisation: Int!) {
    orderBalance(organisation: $organisation)
  }
`;

export const ORGANISATION_ORDERS_LIST_QUERY = gql`
  query OrganisationOrdersListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
    $filter: String
  ) {
    organisationOrdersPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
      filter: $filter
    ) {
      items {
        id
        checkoutDate
        estimatedResultsAvailableDate
        user {
          id
          firstName
          lastName
          email
        }
        testItemSet {
          id
          derivedProgressStatus
          registered
          stock {
            id
            paymentInvoice {
              id
              stripeId
              paymentStatus
              url
              invoiceUrl
              dateDue
            }
            paymentCheckoutSession {
              id
              stripeId
              paymentStatus
              url
              invoiceUrl
            }
          }
        }
        derivedPaymentStatus
        derivedProgressStatus
        sourceBasket {
          ...OrganisationOrderSummarySourceBasketFields
        }
        status
        discountedTotal
        postageCosts
        suppliedFromPracticeStock
        isStockOrder
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${ORGANISATION_ORDER_SUMMARY_SOURCE_BASKET_FIELDS}
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_ORDER_DETAIL_FIELDS = gql`
  fragment OrganisationOrderDetailFields on OrderType {
    id
    orderCopiedFrom {
      id
    }
    checkoutDate
    shippingFirstName
    shippingAddress1
    shippingAddress2
    shippingTownCity
    shippingPostalCode
    shippingCountry
    shippingPhone
    user {
      id
      firstName
      lastName
      email
    }
    status
    discountedTotal
    postageCosts
    suppliedFromPracticeStock
    isStockOrder
    derivedProgressStatus
    derivedPaymentStatus
    sourceBasket {
      ...OrganisationOrderSummarySourceBasketFields
      refunded
      organisation {
        id
        name
      }
      practitioner {
        id
        user {
          firstName
          lastName
          email
          fullName
        }
      }
      basketTotalDiscount
      basketRrpTotalPrice
      basketTotalCommission
      basketShippingCost
      paymentStatus
      orderStatus
      productLineItems {
        id
        product {
          id
          paymentIsoCode
          organisation
          name
        }
        productOption {
          id
          paymentIsoCode
          organisation
          testProductOptionFamily {
            id
            name
          }
        }
        clinicLocation {
          id
          paymentIsoCode
          organisation
          address
          clinic {
            name
          }
        }
        quantity
        pricePerUnit
        fullPricePerUnit
        rrpPerUnit
        clinicFeesPerUnit
      }
    }
    testItems {
      ...OrderTestItemFields
    }
    supplementItems {
      id
      nameInBasket
      groupBy
      price
      product {
        id
        compositeId
        name
        productType
        price
        slug
      }
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
  ${ORGANISATION_ORDER_SUMMARY_SOURCE_BASKET_FIELDS}
`;

export const ORGANISATION_ORDER_DETAIL_QUERY = gql`
  query OrganisationOrderDetailQuery($organisation: Int!, $id: String!) {
    organisationOrder(organisation: $organisation, id: $id) {
      ...OrganisationOrderDetailFields
    }
  }
  ${ORGANISATION_ORDER_DETAIL_FIELDS}
`;
