import { useDataLoader } from "@/hooks/useDataLoader";

export default function useTestProducts({
  validatedFilters,
  queryVariables,
  search,
  sort,
  query,
  connectionsFieldName,
  isoCode = "GB",
  paymentIsoCode = "GB",
  ...rest
}) {
  const { data, loading, fetchMore } = useDataLoader({
    query,
    variables: {
      orderBy: sort,
      search,
      first: 10,
      after: "",
      gender: validatedFilters.gender?.[0] !== "all" ? validatedFilters.gender?.[0] : undefined,
      testingServices: validatedFilters.testingServices.join(","),
      areasOfInterest: validatedFilters.categories.join(","),
      sampleTypes: validatedFilters.sampleTypes.join(","),
      isoCode,
      paymentIsoCode,
      ...queryVariables
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    ...rest
  });

  const products = data?.[connectionsFieldName]?.edges.map(edge => edge.node);
  const hasMore = data?.[connectionsFieldName]?.pageInfo?.hasNextPage;
  const endCursor = data?.[connectionsFieldName]?.pageInfo?.endCursor;

  return {
    search,
    sort,
    products: {
      items: products,
      hasMore,
      endCursor
    },
    loading,
    fetchMore
  };
}
