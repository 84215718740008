import { useCallback } from "react";

import Box from "@/components/Box";
import DownloadRelatedTestProductFiles from "@/components/DownloadRelatedTestProductFiles";
import RichText from "@/components/RichText";
import AddOnDetail from "@/components/organisations/shared/ui/AddOnDetail";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { theme } from "@/core/theme";
import { TOGGLE_TEST_PRODUCT_FAVOURITE_MUTATION } from "@/graphql/accounts";
import useUserProfile from "@/hooks/useUserProfile";
import { PanelBoxV2 } from "@/tpo/Boxes";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import MainDetails from "@/tpo/partnerDashboard/practitioner/MainDetails";
import TestProductOptions from "@/tpo/partnerDashboard/practitioner/TestProductOptions";
import BiomarkersAndSymptomsList from "@/tpo/shop/BiomarkersAndSymptomsList";
import Carousel from "@/tpo/shop/Carousel";
import RelatedPdfs from "@/tpo/shop/RelatedFiles";
import RelatedFileClasses from "@/tpo/shop/RelatedFiles.module.css";
import TestProductInformationPanels from "@/tpo/shop/TestProductInformationPanels";
import { DISPLAY_CONTEXT_ALWAYS_VISIBLE, DISPLAY_CONTEXT_B2B_ONLY } from "@/tpo/shop/constants";
import getCarouselImages from "@/tpo/shop/testProductDetail/utils/getCarouselImages";
import { useMutation } from "@apollo/client";

function ButtonDisabledExplanation({ hasClinics, optionId }) {
  const { selectedTestProductId } = useTestProductModalContext();
  const { basket, hasBasketGotProduct } = useOrganisationBasketContext();

  if (hasClinics && basket?.purchaseAsStock) {
    return (
      <Box
        color="error"
        fontFamily="gilroyMedium"
        lineHeight="150%"
        data-testid="optionUnavailable"
        fontSize={[14, 14, 16]}
      >
        Clinic options not available for stock purchases
      </Box>
    );
  }

  if (
    !basket?.purchaseAsStock &&
    hasBasketGotProduct(selectedTestProductId) &&
    !hasBasketGotProduct(selectedTestProductId, optionId)
  ) {
    return (
      <Box
        color="error"
        fontFamily="gilroyMedium"
        lineHeight="150%"
        data-testid="optionUnavailable"
        fontSize={[14, 14, 16]}
      >
        You can only have 1 option per order unless you are purchasing as stock
      </Box>
    );
  }

  return null;
}

export default function TestProductDetail() {
  const { testProduct, selectTestProductOptionId } = useTestProductModalContext();

  const {
    basket,
    addProductToBasket,
    addOnToProduct,
    removeAddOnFromProduct,
    hasBasketGotProduct,
    hasProductGotAddon,
    setProductQtyInOrganisationBasket
  } = useOrganisationBasketContext();

  const carouselImages = getCarouselImages(testProduct);

  const handleAddToBasket = (product, option) => {
    addProductToBasket(product.id, option?.id);
  };

  const practitionerSupportRequired = false;

  const options = testProduct.options.length ? (
    <TestProductOptions>
      {testProduct.options.map((option, index) => {
        const hasClinics = !!option.practitionerClinicsInfo?.clinics?.length;
        return (
          <TestProductOptions.Option
            key={option.id}
            status={testProduct.status}
            id={option.id}
            compositeId={option.compositeId}
            name={option.testProductOptionFamily.name}
            description={option.testProductOptionFamily.description}
            price={
              hasClinics ? (
                <Stack gap={0}>
                  <GilroyFontCurrency
                    symbol={basket?.currencySymbol}
                    prefix="FROM"
                    value={option.practitionerClinicsInfo.fromPrice}
                    stacked
                    prefixFontSize={14}
                  />
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Includes clinic fee
                  </Box>
                </Stack>
              ) : (
                option.tradeCurrentPrice
              )
            }
            previousPrice={option.exvatFullPrice}
            practitionerSupportRequired={false}
            lastOption={index === testProduct.options.length - 1}
            hasClinics={hasClinics}
            disabled={hasClinics && basket?.purchaseAsStock}
            onFindNearestClinic={() => {
              selectTestProductOptionId({
                id: option.id,
                paymentIsoCode: option.paymentIsoCode,
                organisation: option.organisation
              });
            }}
            onAddToBasket={() => {
              handleAddToBasket(testProduct, option);
            }}
            setQty={qty => {
              setProductQtyInOrganisationBasket({
                productId: testProduct.id,
                quantity: qty,
                productOptionId: option.id
              });
            }}
          >
            <ButtonDisabledExplanation hasClinics={hasClinics} optionId={option.id} />
          </TestProductOptions.Option>
        );
      })}
    </TestProductOptions>
  ) : null;

  const informationPanels = testProduct.content.informationPanels.filter(
    panel =>
      panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
      panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
  );

  const onAddToBasket = () => {
    handleAddToBasket(testProduct);
  };

  const { platformUserProfile } = useUserProfile();

  const setQty = useCallback(
    qty => {
      setProductQtyInOrganisationBasket({
        productId: testProduct.id,
        quantity: qty
      });
    },
    [setProductQtyInOrganisationBasket, testProduct.id]
  );

  const addons = testProduct.addons;
  const onAddingOn = ({ addon }) => {
    addOnToProduct(testProduct.id, addon.id);
  };
  const onRemovingAddon = ({ addon }) => {
    removeAddOnFromProduct(testProduct.id, addon.id);
  };

  const basketHasGotProduct = hasBasketGotProduct(testProduct.id);

  const sampleReports = [...testProduct.sampleLabReports, ...testProduct.sampleGeneratedReports];

  const [toggleFavouriteMutation] = useMutation(TOGGLE_TEST_PRODUCT_FAVOURITE_MUTATION);

  const toggleFavourite = useCallback(
    testProductId => {
      toggleFavouriteMutation({
        variables: {
          testProductId
        }
      });
    },
    [toggleFavouriteMutation]
  );

  return (
    <>
      <Box display="none" data-component-name="TruncatedProductDetail" />
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "white",
          pt: carouselImages.length ? 0 : theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40]
        }}
        stacked
        gap={[20, 20, 40]}
      >
        {!!carouselImages.length && (
          <Box bg="haze" p={20} mb={20}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        )}
        <Box>
          <MainDetails
            status={testProduct.status}
            practitionerSupportRequired={practitionerSupportRequired}
            onTogglingFavourite={e => {
              e.stopPropagation();
              toggleFavourite(testProduct.id);
            }}
            favourite={platformUserProfile?.testProductFavouriteIds?.includes(testProduct.id)}
            name={testProduct.name}
            description={testProduct.content.description}
            categories={testProduct.content.categories.map(cat => cat.name)}
            sampleTypes={testProduct.sampleTypes}
            numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
            processingTime={testProduct.content.processingTime}
            options={options}
            priceElement={
              <Stack>
                <Group gap={10}>
                  {testProduct.tradeFullPrice !== undefined &&
                    testProduct.tradeFullPrice > testProduct.tradeCurrentPrice && (
                      <GilroyFontCurrency
                        symbol={basket?.currencySymbol}
                        value={testProduct.tradeFullPrice}
                        color="dark"
                        strikethrough
                        data-testid="truncatedProductDetail:tradeFullPrice"
                      />
                    )}
                  <GilroyFontCurrency
                    symbol={basket?.currencySymbol}
                    value={testProduct.tradeCurrentPrice}
                    data-testid="truncatedProductDetail:tradeCurrentPrice"
                    color={
                      testProduct.tradeFullPrice !== undefined &&
                      testProduct.tradeFullPrice > testProduct.tradeCurrentPrice
                        ? "red"
                        : "dark"
                    }
                  />
                </Group>
                <GilroyFontCurrency
                  symbol={basket?.currencySymbol}
                  prefix="RRP"
                  color="midGrey"
                  fontSize={18}
                  value={testProduct.exvatFullPrice}
                  data-testid="truncatedProductDetail:retailFullPrice"
                />
              </Stack>
            }
            productOptionCardStackBg="white"
            testType={testProduct.content.testType}
            onAddToBasket={onAddToBasket}
            setQty={setQty}
            centrifuge={testProduct.content.centrifuge}
          />
        </Box>
      </PanelBoxV2>
      {!!addons?.length && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "haze",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40],
            "data-component-name": "Addons"
          }}
          stacked
          gap={40}
        >
          <Box>
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="130%">
              Additional marker add ons
            </Box>
            <Spacer py={[7.5, 7.5, 15]} />
            <RichText
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="additionalMarkerAddOnsDescription"
            >
              This test has additional markers that can be included in your purchase. They will
              become available once you have added the base test to your basket.
            </RichText>
          </Box>
          {basket?.purchaseAsStock ? (
            <Box color="red" fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              Add-ons unavailable when purchasing products as stock
            </Box>
          ) : (
            <Box
              color="dark"
              fontFamily="gilroyRegular"
              fontSize={[14, 14, 16]}
              fontStyle="italic"
              data-testid="addons-unavailable"
            >
              Add-ons will be unavailable when purchasing products as stock. They can be added to a
              stock purchase at the point of test registration.
            </Box>
          )}
          <Stack
            gap={1}
            maxHeight="calc(130.8px * 5 + 5 * 1px)"
            style={{
              overflow: "auto"
            }}
          >
            {addons.map(addon => (
              <AddOnDetail
                key={addon.id}
                name={addon.name}
                description={addon.content.description}
                price={addon.tradeCurrentPrice}
                state={
                  basketHasGotProduct && !basket?.purchaseAsStock
                    ? hasProductGotAddon(testProduct.id, addon.id)
                      ? "selected"
                      : "unselected"
                    : "unavailable"
                }
                onRemove={() => onRemovingAddon({ addon })}
                onAdd={() => onAddingOn({ addon })}
              />
            ))}
          </Stack>
        </PanelBoxV2>
      )}
      {!!(
        testProduct.content.relatedSymptoms?.length || testProduct.content.biomarkersTested?.length
      ) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "partners",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={testProduct.content.relatedSymptoms}
            biomarkersTested={testProduct.content.biomarkersTested}
          />
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "haze",
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40]
        }}
        stacked
        gap={40}
      >
        <TestProductInformationPanels panels={informationPanels} />
      </PanelBoxV2>
      {!!(
        sampleReports?.length ||
        testProduct.instructions?.length ||
        testProduct.supportingDocuments?.length
      ) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "white",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40],
            className: RelatedFileClasses.container
          }}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Download files
          </Box>
          {!!sampleReports?.length && (
            <RelatedPdfs sectionName="Sample Reports" pdfs={sampleReports} />
          )}
          {!!testProduct.instructions?.length && (
            <RelatedPdfs sectionName="Instructions" pdfs={testProduct.instructions} />
          )}
          {!!testProduct.supportingDocuments?.length && (
            <RelatedPdfs
              sectionName="Supporting Documents"
              pdfs={testProduct.supportingDocuments}
            />
          )}
        </PanelBoxV2>
      )}
    </>
  );
}
