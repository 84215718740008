import ChevronComponent from "@/tpo/Chevron";
import { ActionIcon } from "@/v2/Buttons";

export default function CircleButton({ onClick, direction, iconHeight, iconWidth, disabled }) {
  return (
    <ActionIcon
      size={["sm", "sm", "md"]}
      bg="haze"
      variant="circle"
      onClick={onClick}
      disabled={disabled}
    >
      <ChevronComponent
        fill="dark"
        style={{
          margin: 0
        }}
        height={iconHeight}
        width={iconWidth}
        direction={direction}
      />
    </ActionIcon>
  );
}
