import { useEffect, useState } from "react";

import Box from "@/components/Box";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { MODAL_Z_INDEX } from "@/core/constants";
import { UPDATE_PRACTITIONER_ROLE_MUTATION } from "@/graphql/organisations/queries/practitioners";
import Accordion from "@/tpo/Accordion";
import Checkbox from "@/tpo/Checkbox";
import { Error } from "@/tpo/InputWrapper";
import Stack from "@/tpo/Stack";
import Tooltip from "@/tpo/Tooltip";
import { useMutation } from "@apollo/client";

export function PractitionerPermissionsList({ alignItems, inviteId, status, roles, onChange }) {
  const { organisation, organisationRoles } = useOrganisationContext();
  const [roleValues, setRoleValues] = useState([]);
  const [error, setError] = useState(null);

  const [updatePractitionerRoles] = useMutation(UPDATE_PRACTITIONER_ROLE_MUTATION, {
    onCompleted: () => {
      onChange && onChange();
    }
  });

  const handleRoleChange = (value, { target: { checked } }) => {
    let newRoles = new Set(roles.map(role => parseInt(role.id)));
    if (newRoles.has(value) && !checked) {
      newRoles.delete(value);
    } else if (!newRoles.has(value) && checked) {
      newRoles.add(value);
    }

    updatePractitionerRoles({
      variables: {
        input: {
          id: inviteId,
          organisation: parseInt(organisation?.id),
          roles: Array.from(newRoles)
        }
      }
    })
      .then(resp => {
        if (resp?.data?.updatePractitionerRoleMutation?.errors?.length) {
          setError(resp.data.updatePractitionerRoleMutation.errors[0].messages[0]);
        } else {
          setError(null);
        }
      })
      .catch(err => {
        console.log("Error updating practitioner roles", err);
      });
    // NB this mutation now returns the mutated invite
    // so the cache will be updated automatically with the edited roles
    // causing the list query to fire again but this time not hitting the server
  };

  useEffect(() => {
    if (organisationRoles) {
      setRoleValues(
        organisationRoles.map(({ id, name, permissions }) => ({
          label: name === "Admin" ? "Manager" : name,
          value: id,
          tooltip: (
            <ul>
              {permissions.map(permission => (
                <li key={permission}>{permission}</li>
              ))}
            </ul>
          )
        }))
      );
    }
  }, [organisationRoles]);

  const controlProps = {
    [alignItems === "flex-start" ? "pr" : "pl"]: [15, 15, 20]
  };
  const panelProps = {
    [alignItems === "flex-start" ? "pr" : "pl"]: [20, 20, 40]
  };

  return (
    <Stack gap={10} width="100%" justifyContent="flex-end" flexDirection="row">
      <Accordion width="100%">
        <Accordion.Item value="practitionerPermissions">
          <Accordion.Control
            py={[2]}
            fontSize={10}
            fontFamily="gilroyBold"
            textTransform="uppercase"
            letterSpacing={2.8}
            justifyContent="flex-end"
            {...controlProps}
          >
            permissions
          </Accordion.Control>
          <Accordion.Panel
            pb={[2]}
            position="relative"
            textAlign={alignItems === "flex-start" ? "left" : "right"}
            {...panelProps}
          >
            <Stack
              flexDirection="row"
              justifyContent={["flex-start", "flex-end", "flex-end"]}
              flexWrap="wrap"
              gap={[20, 20, 40]}
              pt={2}
            >
              {roleValues.map(({ label, value, tooltip }) => (
                <Tooltip
                  key={value}
                  content={
                    <Box
                      bg="black"
                      color="white"
                      maxWidth={200}
                      p={2}
                      pb={20}
                      borderRadius={5}
                      zIndex={MODAL_Z_INDEX + 1}
                      position="relative"
                    >
                      {tooltip}
                    </Box>
                  }
                  placement={"bottom"}
                >
                  <Checkbox
                    key={value}
                    value={value}
                    label={label}
                    checked={roles.map(role => parseInt(role.id)).includes(parseInt(value))}
                    onChange={evt => handleRoleChange(value, evt)}
                  />
                </Tooltip>
              ))}
            </Stack>
            {!!error && <Error error={error} mt={20} />}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
}
