import Box from "@/components/Box";
import { DiscreteChart, SegmentedChart } from "@/tpo/Charts";
import { buildDiscreteChartConfig } from "@/utils/charts/discreteCharts";

export function GradientAxis({ background, labels }) {
  return (
    <Box display="flex" position="relative" height="100%">
      <Box position="relative" height="100%" minWidth={10} width={20}>
        {labels.map((label, index) =>
          label !== undefined ? (
            <Box
              key={index}
              position="absolute"
              transform="translateY(100%)"
              bottom={`${(index / (labels.length - 1)) * 100}%`}
              right={0}
              borderTop="1px solid #1a1a1a"
              fontSize={"8px"}
              fontFamily="gilroyBold"
              width={"100%"}
              textAlign="left"
            >
              {label}
            </Box>
          ) : null
        )}
      </Box>
      <Box
        minWidth={5}
        width={5}
        height="100%"
        background={background}
        borderRadius={100}
        transform="rotate(180deg)"
      />
    </Box>
  );
}

export function DiscreteAxis({ chartConfiguration: { colours, terminology, normalisedRange } }) {
  const { terms, values } = buildDiscreteChartConfig({
    colours,
    terminology,
    normalisedRange
  });
  return (
    <DiscreteChart
      terms={terms}
      values={values}
      wordProps={{
        transform: "scale(1, -1)"
      }}
    />
  );
}

export function SegmentedAxis({
  chartConfiguration: { colours, normalisedRange, terminology, interpretation }
}) {
  return (
    <SegmentedChart
      showTerms={false}
      terms={[]}
      discreteValues={colours.map(bg => ({ bg }))}
      showValueIcon={false}
    />
  );
}

export function ResultsAxis({ chartConfiguration }) {
  if (chartConfiguration.chartType === "DISCRETE_CHART") {
    return (
      <Box
        position="absolute"
        width={290}
        transform="scale(1, -1) rotate(-270deg)"
        top={165}
        left={-140}
      >
        <DiscreteAxis chartConfiguration={chartConfiguration} />;
      </Box>
    );
  } else if (chartConfiguration.chartType === "SEGMENTED_PROGRESS_CHART") {
    return (
      <Box position="absolute" width={290} transform="rotate(-90deg)" top={180} left={-120}>
        <SegmentedAxis chartConfiguration={chartConfiguration} />
      </Box>
    );
  }
  return null;
}
