import { roundTimestampToDay } from "@/utils/shared";
import { last } from "lodash";

export const COT_CHART_USER_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="8" fill="#transparent"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.31254 6.40002C9.31254 7.16998 8.72187 7.79998 8.00003 7.79998C7.27814 7.79998 6.68752 7.16998 6.68752 6.40002C6.68752 5.63 7.27814 5 8.00003 5C8.72187 5 9.31254 5.63 9.31254 6.40002ZM8.00005 8.6C9.01249 8.6 11 9.38 11 11H5C5 9.38 6.98751 8.6 8.00005 8.6Z" fill="white"/>
</svg>`;

export async function getIcon() {
  return new Promise(resolve => {
    let svgBlob = new Blob([COT_CHART_USER_ICON], {
      type: "image/svg+xml;charset=utf-8"
    });
    let svgUri = URL.createObjectURL(svgBlob);
    let image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.src = svgUri;
  });
}

export async function generateMarker(icon, color) {
  let canvas = document.createElement("canvas");
  canvas.height = canvas.width = 64;
  let context = canvas.getContext("2d");
  context.fillStyle = color;
  context.beginPath();
  context.arc(32, 32, 32, 0, Math.PI * 2);
  context.fill();
  context.drawImage(icon, 0, 0, 64, 64);
  return canvas.toDataURL("image/png");
}

export function buildLineGraphConfiguration({
  chartId,
  fullRange,
  points,
  tooltips,
  result = false,
  overrides = {},
  events = {}
}) {
  let series;
  if (result) {
    series = [
      {
        data: points[0].map(([x, y]) => [roundTimestampToDay(x), y]),
        stroke: {
          width: [2],
          colors: ["#4b2ed4"]
        }
      },
      {
        data: points[1].map(([x, y]) => [roundTimestampToDay(x), y])
      }
    ];
  } else {
    series = [
      {
        data: points.map(([x, y]) => [roundTimestampToDay(x), y])
      }
    ];
  }

  const resultOptions = {
    stroke: {
      width: [2],
      curve: ["straight", "smooth"],
      dashArray: [0, 8],
      colors: ["#4b2ed4", "#6a6a6a"]
    },
    markers: {
      size: 3,
      hover: {
        size: 4
      },
      colors: ["#fff"],
      strokeColor: ["#4b2ed4", "#6a6a6a"]
    }
  };

  const datapointOptions = {
    stroke: {
      width: [2],
      curve: "straight",
      colors: ["#1a1a1a"]
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    }
  };

  let options = {
    chart: {
      id: chartId,
      height: 350,
      type: "line",
      events,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true
        },
        autoSelected: "zoom"
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    responsive: [
      {
        breakpoint: 670,
        options: {
          chart: {
            toolbar: {
              show: false
            }
          }
        }
      }
    ],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      show: false,
      min: 0,
      max: fullRange[fullRange.length - 1].value,
      tickAmount: fullRange.length - 1
    },
    xaxis: {
      type: "datetime",
      crosshairs: {
        show: true
      },
      tooltip: {
        enabled: false
      }
    },
    grid: {
      borderColor: "#f1f1f1",
      show: true
    },
    tooltip: {
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        try {
          const stack = tooltips[seriesIndex][dataPointIndex];
          if (!stack) {
            return "";
          }
          return "<div class='tooltip'>" + stack.reduce((acc, curr) => acc + curr, "") + "</div>";
        } catch (e) {
          console.warn(`Error in custom tooltip: ${e}`);
          return "";
        }
      }
    }
  };

  // update options if result
  if (result) {
    options = {
      ...options,
      ...resultOptions,
      ...overrides
    };
  } else {
    options = {
      ...options,
      ...datapointOptions,
      ...overrides
    };
  }

  return {
    series,
    options
  };
}

export function buildUserTestBrushChartConfiguration({
  consideredUserTests,
  consideredSubmissions,
  parentChartId,
  events = {}
}) {
  let userSubmissionDates = [];
  let userTestDates = {};
  let userTestColours = {};
  let now = new Date().getTime();
  let earliestDate = now;

  const FRESH = "fresh";
  const EXPIRING = "expiring";
  const EXPIRED = "expired";

  const TYPE_COLOURS = {
    [FRESH]: "#00BF86",
    [EXPIRING]: "#FCA557",
    [EXPIRED]: "#FF5D6D"
  };

  // ensure consideredSubmissions are sorted by questionnaireDate
  let sortedSubmissions = [...consideredSubmissions].sort(
    (a, b) => new Date(a.questionnaireDate).getTime() - new Date(b.questionnaireDate).getTime()
  );
  sortedSubmissions.forEach(({ questionnaireDate, firstAnswerExpiry, lastAnswerExpiry }) => {
    if (!questionnaireDate) {
      return;
    }
    questionnaireDate = new Date(questionnaireDate).getTime();
    firstAnswerExpiry = firstAnswerExpiry ? new Date(firstAnswerExpiry).getTime() : null;
    lastAnswerExpiry = lastAnswerExpiry ? new Date(lastAnswerExpiry).getTime() : null;

    // first remove any dates in userSubmissionDates that are after questionnaireDate
    userSubmissionDates = userSubmissionDates.filter(({ date }) => date < questionnaireDate);

    userSubmissionDates.push({
      date: questionnaireDate,
      type: FRESH
    });

    if (firstAnswerExpiry && firstAnswerExpiry !== lastAnswerExpiry) {
      firstAnswerExpiry = new Date(firstAnswerExpiry).getTime();
      userSubmissionDates.push({
        date: firstAnswerExpiry,
        type: EXPIRING
      });
    }

    if (lastAnswerExpiry) {
      lastAnswerExpiry = new Date(lastAnswerExpiry).getTime();
      userSubmissionDates.push({
        date: lastAnswerExpiry,
        type: EXPIRED
      });
    }
  });

  consideredUserTests.forEach(
    ({
      testProduct: {
        productFamily: { name, familyColour }
      },
      firstDatapointExpiry,
      lastDatapointExpiry,
      freshDate
    }) => {
      if (!userTestDates[name]) {
        userTestDates[name] = [];
      }

      freshDate = new Date(freshDate).getTime();
      firstDatapointExpiry = new Date(firstDatapointExpiry).getTime();
      lastDatapointExpiry = new Date(lastDatapointExpiry).getTime();

      if (!userTestColours[name] && familyColour) {
        userTestColours[name] = familyColour;
      }

      userTestDates[name] = userTestDates[name].filter(
        ({ date, type }) => date < freshDate || type === FRESH
      );

      if (userTestDates[name].length === 0 || last(userTestDates[name]).type !== FRESH) {
        userTestDates[name].push({
          date: freshDate,
          type: FRESH
        });
      }

      if (firstDatapointExpiry && firstDatapointExpiry > freshDate) {
        userTestDates[name].push({
          date: firstDatapointExpiry,
          type: EXPIRING
        });
      }

      if (lastDatapointExpiry && lastDatapointExpiry > freshDate) {
        userTestDates[name].push({
          date: lastDatapointExpiry,
          type: EXPIRED
        });
      }

      userTestDates[name] = userTestDates[name].sort((a, b) => a.date - b.date);
    }
  );

  let brushData = [];
  let tooltips = [];

  userSubmissionDates.forEach(({ date, type }, index) => {
    brushData.push({
      x: "SYMPTOMS",
      y: [date, userSubmissionDates[index + 1] ? userSubmissionDates[index + 1].date : now],
      fill: {
        type: "solid"
      },
      fillColor: "#000000"
    });

    // if the type is expring add a fill
    if (type === EXPIRING || type === EXPIRED) {
      brushData[brushData.length - 1].fill = {
        type: "pattern",
        pattern: {
          style: "verticalLines",
          width: 8,
          height: 8,
          strokeWidth: 8
        }
      };
    }

    if (date < earliestDate) {
      earliestDate = date;
    }

    let tooltipDate = `${new Date(date).toLocaleDateString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric"
    })}`;

    if (type === EXPIRING || type === FRESH) {
      let nextDate = userSubmissionDates[index + 1] ? userSubmissionDates[index + 1].date : now;

      // if nextDate is today, change to "Today"
      if (new Date(nextDate).toLocaleDateString() === new Date().toLocaleDateString()) {
        tooltipDate += " - Today";
      } else {
        tooltipDate += ` - ${new Date(nextDate).toLocaleDateString(undefined, {
          day: "numeric",
          month: "long",
          year: "numeric"
        })}`;
      }
    }

    tooltips.push([
      `<div class='interpretation' style='color: #000000 ; text-transform: capitalize;'>${type}</div>`,
      `<div class='date'>${tooltipDate}</div>`
    ]);
  });

  Object.keys(userTestDates).forEach((name, index) => {
    let dates = userTestDates[name];
    let { date: lastDate, type: lastType } = last(dates);
    // if the last date is not expired, add an expired date
    if (lastType !== EXPIRED) {
      dates.push({
        date: lastDate > now ? lastDate : now,
        type: EXPIRED
      });
    }
    let colour = userTestColours[name];

    dates.forEach(({ date, type }, index) => {
      // if expired continue
      if (type === EXPIRED) {
        return;
      }

      if (date < earliestDate) {
        earliestDate = date;
      }

      brushData.push({
        x: name.toUpperCase(),
        y: [date, dates[index + 1] ? dates[index + 1].date : now],
        fill: {
          type: "solid"
        },
        fillColor: colour
      });

      // if the type is expring add a fill
      if (type === EXPIRING) {
        brushData[brushData.length - 1].fill = {
          type: "pattern",
          pattern: {
            style: "verticalLines",
            width: 8,
            height: 8,
            strokeWidth: 8
          }
        };
      }

      let tooltipDate = `${new Date(date).toLocaleDateString(undefined, {
        day: "numeric",
        month: "long",
        year: "numeric"
      })}`;
      if (type === EXPIRING || type === FRESH) {
        let nextDate = dates[index + 1] ? dates[index + 1].date : now;

        // if nextDate is in the year 9999, don't add anything
        if (new Date(nextDate).getFullYear() !== 9999) {
          // if nextDate is today, change to "Today"
          if (new Date(nextDate).toLocaleDateString() === new Date().toLocaleDateString()) {
            tooltipDate += " - Today";
          } else {
            tooltipDate += ` - ${new Date(nextDate).toLocaleDateString(undefined, {
              day: "numeric",
              month: "long",
              year: "numeric"
            })}`;
          }
        }
      }

      tooltips.push([
        `<div class='interpretation' style='color: ${TYPE_COLOURS[type]} ; text-transform: capitalize;'>${type}</div>`,
        `<div class='date'>${tooltipDate}</div>`
      ]);
    });
  });

  let chartHeight = Object.keys(userTestColours).length * 30 + 40;

  let brushConfig = {
    chart: {
      id: parentChartId ? parentChartId + "-brush" : "brush",
      type: "rangeBar",
      height: chartHeight,
      selection: {
        enabled: true,
        xaxis: {
          min: earliestDate,
          max: now
        }
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: false
        }
      },
      toolbar: {
        show: false,
        tools: {
          pan: false
        },
        autoSelected: "pan"
      }
    },
    grid: {
      show: false
    },
    selection: {
      enabled: false
    },
    plotOptions: {
      bar: {
        barHeight: "6px",
        horizontal: true
      }
    },
    yaxis: {
      show: false
    },
    series: [{ data: brushData }],
    tooltip: {
      // We are disabling the tooltip for now
      enabled: false
      //   custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      //     return null;
      //     try {
      //       const stack = tooltips[dataPointIndex];
      //       if (!stack) {
      //         return "";
      //       }
      //       return "<div class='tooltip'>" + stack.reduce((acc, curr) => acc + curr, "") + "</div>";
      //     } catch (e) {
      //       console.warn(`Error in custom tooltip: ${e}`);
      //       return "";
      //     }
      //   }
    },
    xaxis: {
      show: false,
      type: "datetime",
      min: earliestDate - (now - earliestDate) * 0.03,
      max: now + (now - earliestDate) * 0.03,
      labels: { show: false },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    }
  };

  let userTestAnnotations = [];
  Object.values(userTestDates).forEach(dates => {
    dates.forEach(({ date, type }) => {
      // if there are no userTestAnnotations yet, and type is "fresh", skip it
      if (Object.keys(userTestAnnotations).length === 0 && type === FRESH) {
        return;
      }
      if (date > now) {
        return;
      }

      date = roundTimestampToDay(date);

      // if date already exists, overwrite it
      userTestAnnotations = userTestAnnotations.filter(annotation => annotation.x !== date);

      let label = type === FRESH ? "updated" : type;

      // title case
      label = label.charAt(0).toUpperCase() + label.slice(1);

      userTestAnnotations.push({
        // date is a timestamp, format as YYYY-MM-DD
        x: date,
        label: {
          text: label
        }
      });
    });
  });

  return [{ options: brushConfig, series: [{ data: brushData }] }, userTestAnnotations];
}
