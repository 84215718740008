import { useHistory, useLocation } from "react-router-dom";

import { PARTNERS_ORDERS_URL, PARTNERS_STOCK_URL } from "@/core/urls";
import SegmentedControl from "@/tpo/SegmentedControl";

export default function Switcher() {
  const history = useHistory();
  const location = useLocation();

  return (
    <SegmentedControl
      data={[
        {
          label: "direct",
          value: PARTNERS_ORDERS_URL
        },
        {
          label: "stock",
          value: PARTNERS_STOCK_URL
        }
      ]}
      onChange={v => history.push(v)}
      value={location.pathname}
      height={48}
      bg="white"
      data-testid="order-stock-toggle"
    />
  );
}
