export default function ChevronRight({ size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.4 13"
      height={size}
      width={size}
      {...props}
    >
      <path d="M1.9 13L0 11.1l4.6-4.6L0 1.9 1.9 0l6.5 6.5L1.9 13z" />
    </svg>
  );
}
