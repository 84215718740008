import Svg from "@/components/Svg";

export default function BreathIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19554)">
        <path
          d="M57.0219 37.7747H27.3501C25.4021 37.8076 23.52 37.0501 22.1367 35.6761C20.7487 34.3022 19.9816 32.4248 20.0005 30.4768C19.977 28.5194 20.7346 26.6279 22.0991 25.2257C23.4683 23.8234 25.341 23.0236 27.2984 23C31.4062 23.0471 34.7281 26.369 34.7751 30.4767C34.7751 31.4554 33.9799 32.2459 33.0012 32.2459C32.0224 32.2459 31.2319 31.4554 31.2319 30.4767C31.2037 28.3169 29.458 26.5713 27.2983 26.5431C25.181 26.5995 23.5012 28.3546 23.5435 30.4767C23.5341 31.4742 23.9246 32.4341 24.6304 33.1398C25.3409 33.8503 26.3008 34.2409 27.2983 34.2314H57.0218C58.0005 34.2314 58.7958 35.0266 58.7958 36.0007C58.7958 36.9794 58.0007 37.7747 57.0219 37.7747Z"
          fill="#1A1A1A"
        />
        <path
          d="M51.1968 30.7269H42.8167C41.838 30.7269 41.0427 29.9317 41.0427 28.9529C41.0427 27.9742 41.8379 27.1837 42.8167 27.1837H51.1968C52.1755 27.1837 52.966 27.9742 52.966 28.9529C52.966 29.9317 52.1755 30.7269 51.1968 30.7269Z"
          fill="#1A1A1A"
        />
        <path
          d="M28.5589 56.7801C24.4842 56.7331 21.2186 53.397 21.261 49.3269C21.2375 47.3789 21.9951 45.5062 23.369 44.1323C24.7429 42.7537 26.6109 41.9914 28.5589 42.0102H58.2307C59.2047 42.0102 60 42.8007 60 43.7795C60 44.7582 59.2048 45.5534 58.2307 45.5534H28.5589C27.5567 45.544 26.5921 45.9393 25.8863 46.6451C25.1758 47.3555 24.7852 48.3248 24.8041 49.3271C24.7617 51.4398 26.4415 53.1901 28.5589 53.2419C30.7093 53.209 32.4502 51.4775 32.4878 49.3271C32.4878 48.3484 33.283 47.5532 34.2617 47.5532C35.2405 47.5532 36.031 48.3484 36.031 49.3271C35.9745 53.4254 32.6572 56.733 28.5589 56.7801Z"
          fill="#1A1A1A"
        />
        <path
          d="M51.9406 52.6015H41.7913C40.8126 52.6015 40.0173 51.8063 40.0173 50.8323C40.0173 49.8536 40.8125 49.0583 41.7913 49.0583H51.9406C52.9193 49.0583 53.7146 49.8535 53.7146 50.8323C53.7146 51.8063 52.9194 52.6015 51.9406 52.6015Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3634_19554">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
