import DataLoader from "@/components/DataLoader";
import GET_REGISTRABLE_PRODUCTS from "@/graphql/registerKit/queries";

import RegisterKitPageContent from "./RegisterKitPageContent";
import SignUp from "./SignUp";

function RegisterKitPage() {
  return (
    <DataLoader
      query={GET_REGISTRABLE_PRODUCTS}
      fetchPolicy="no-cache"
      render={({ registrableProducts, user }) =>
        user ? (
          <RegisterKitPageContent
            products={registrableProducts}
            gender={user.platformUserProfile.gender}
            confirmationField={{
              fullName: user.fullName,
              dateOfBirth: user.platformUserProfile.dateOfBirth
            }}
          />
        ) : (
          <SignUp products={registrableProducts} />
        )
      }
    />
  );
}

export default RegisterKitPage;
