import { Route, Switch, useRouteMatch } from "react-router-dom";

import Error404Page from "@/components/Error404Page";

import ResetAcknowledged from "./requestAcknowledged";
import RequestResetPage from "./requestForm";
import ResetCompletePage from "./resetComplete";
import ResetFormPage from "./resetForm";

export default function ResetPassword({ partners = false }) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} exact>
        <RequestResetPage partners={partners} />
      </Route>
      <Route path={`${match.path}/email-sent`} exact>
        <ResetAcknowledged partners={partners} />
      </Route>
      <Route path={`${match.path}/success`} exact>
        <ResetCompletePage partners={partners} />
      </Route>
      <Route path={`${match.path}/:userId/:token`} exact>
        <ResetFormPage partners={partners} />
      </Route>
      <Error404Page />
    </Switch>
  );
}
