export function getAPIBase() {
  return import.meta.env.VITE_API_URL || "http://backend.omnos.local:8000";
}

export function getStripePublishableKey() {
  let fallbackKey;
  if (import.meta.env.VITE_ENV === "production") {
    fallbackKey = "pk_live_ygEDoQpl4LjqpeOwkg0esViy009826XNSe";
  } else {
    fallbackKey = "pk_test_tUiZWf5n0ZaDjidgIiuBxNcr00sdCaUnN5";
  }
  return import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || fallbackKey;
}
