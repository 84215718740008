import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Error404Page from "@/components/Error404Page";
import ExpertDetailPage from "@/components/ExpertDetail";
import RedirectPractitioners from "@/components/RedirectPractitioners";
import { EXPERTS_DETAIL_URL } from "@/core/urls";
import { createGlobalStyle } from "styled-components";

import Overview from "./Overview";
import Practitioners from "./Practitioners";
import SupportedTests from "./SupportedTests";

const GlobalStyle = createGlobalStyle`
  .PoweredByRegenerusBanner svg {
    fill: black;
  }
`;

export default function ExpertRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/overview`} />
      <Route exact path={`${match.path}/overview`}>
        <GlobalStyle />
        <Overview />
      </Route>
      <Route exact path={`${match.path}/experts`}>
        <GlobalStyle />
        <Practitioners />
      </Route>
      <Route exact path={`${match.path}/tests`}>
        <RedirectPractitioners tab="tests">
          <GlobalStyle />
          <SupportedTests />
        </RedirectPractitioners>
      </Route>
      <Route path={EXPERTS_DETAIL_URL} component={ExpertDetailPage} exact />
      <Error404Page />
    </Switch>
  );
}
