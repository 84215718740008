import { forwardRef } from "react";

import Box from "@/components/Box";
import { OUT_OF_STOCK } from "@/core/constants";
import { theme } from "@/core/theme";
import LifeStyleIcon from "@/images/LifeStyleIcon";
import Badge from "@/tpo/Badge";
import ChevronComponent from "@/tpo/Chevron";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ButtonV2, { ActionIcon } from "@/v2/Buttons";
import styled from "styled-components";

const Card = styled(Stack)`
  :hover {
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.2);
  }

  cursor: pointer;
`;

const TestProductListCard = forwardRef(
  (
    {
      onTogglingFavourite,
      favourite,
      subtitle,
      title,
      currencySymbol,
      rrpFullPrice,
      tradeCurrentPrice,
      tradeFullPrice,
      pills,
      onSelect,
      addText,
      onAdd,
      onRemove,
      hasAddons,
      addDisabled,
      productCode,
      withBorder = false,
      borderRadius = 5,
      centrifuge
    },
    ref
  ) => (
    <Card
      bg="white"
      borderRadius={borderRadius}
      py={[24, 24, 40]}
      px={[20, 20, 40]}
      gap={20}
      ref={ref}
      onClick={onSelect}
      data-component-name="TestProductListCard"
      position="relative"
      border={withBorder ? `1px solid ${theme.colors.dark}` : "none"}
    >
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        justifyContent={["unset", "unset", "space-between"]}
        gap={[20]}
      >
        <Box>
          {productCode && (
            <Box
              position={["static", "static", "absolute"]}
              fontSize={14}
              top={[null, null, 0]}
              transform={[null, null, "translateY(100%)"]}
            >
              {productCode}
            </Box>
          )}
          <Box
            fontFamily="gilroyBold"
            lineHeight="130%"
            fontSize={[24, 24, 28]}
            data-testid="testProductListCard:title"
          >
            {title}
          </Box>
          {hasAddons && (
            <Badge bg="dark" color="white" size="xs">
              add-ons available
            </Badge>
          )}
          {centrifuge && (
            <Badge bg="red" color="white" size="xs">
              centrifuge
            </Badge>
          )}
          {subtitle}
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          alignItems={["flex-start"]}
          gap={[10, 10, 40]}
        >
          <Box
            display="flex"
            flexDirection={["column"]}
            gap={5}
            alignItems={["flex-start", "flex-start", "flex-end"]}
          >
            <Group gap={10}>
              {tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice && (
                <GilroyFontCurrency
                  value={tradeFullPrice}
                  color="dark"
                  symbol={currencySymbol}
                  strikethrough
                  data-testid="testProductListCard:tradeFullPrice"
                />
              )}
              <GilroyFontCurrency
                value={tradeCurrentPrice}
                data-testid="testProductListCard:tradeCurrentPrice"
                symbol={currencySymbol}
                color={
                  tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice
                    ? "red"
                    : "dark"
                }
              />
            </Group>
            <GilroyFontCurrency
              prefix="RRP"
              color="midGrey"
              fontSize={18}
              symbol={currencySymbol}
              value={rrpFullPrice}
              data-testid="testProductListCard:retailFullPrice"
            />
          </Box>
          {onAdd && (
            <ButtonV2
              onClick={onAdd}
              disabled={addDisabled}
              color={addDisabled ? "midGrey" : "green"}
              rightIcon={<ChevronComponent />}
              size={["sm", "sm", "md"]}
            >
              {addDisabled === OUT_OF_STOCK ? "Out of stock" : addText}
            </ButtonV2>
          )}
          {onRemove && (
            <ButtonV2
              onClick={onRemove}
              color="red"
              rightIcon={<ChevronComponent />}
              size={["sm", "sm", "md"]}
            >
              Remove
            </ButtonV2>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={20}
        justifyContent="space-between"
      >
        <Group flexWrap="wrap" gap={2} mt="auto">
          {pills}
        </Group>
        <Group gap={10}>
          {!!onTogglingFavourite && (
            <ActionIcon
              size={["sm", "sm", "md"]}
              bg="haze"
              variant="circle"
              onClick={onTogglingFavourite}
            >
              <LifeStyleIcon
                size={20}
                outline={!favourite}
                filled={favourite}
                color={favourite ? theme.colors.genetics : "black"}
              />
            </ActionIcon>
          )}
          <ActionIcon size={["sm", "sm", "md"]} bg="haze" variant="circle" onClick={onSelect}>
            <ChevronComponent
              fill="dark"
              style={{
                margin: 0
              }}
            />
          </ActionIcon>
        </Group>
      </Box>
    </Card>
  )
);

export default TestProductListCard;
