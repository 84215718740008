import Box from "@/components/Box";
import Circle from "@/components/Circle";
import { EXPERT_FEE_LEVEL_200, EXPERT_FEE_LEVEL_201, EXPERT_FEE_LEVEL_99 } from "@/core/constants";
import Group from "@/tpo/Group";

export default function FeeLevel({ value }) {
  const n =
    {
      [EXPERT_FEE_LEVEL_99]: 1,
      [EXPERT_FEE_LEVEL_200]: 2,
      [EXPERT_FEE_LEVEL_201]: 3
    }[value] || 0;

  return (
    <Group gap={10}>
      <Box fontFamily="gilroyBold" fontSize={[15]}>
        Fee level:
      </Box>
      <Group gap={1} flexWrap="wrap">
        {[...Array(n)].map((_, i) => (
          <Circle fontFamily="gilroyBold" fontSize={12} size={24} bg="#DCD2F8" key={i}>
            £
          </Circle>
        ))}
      </Group>
    </Group>
  );
}
