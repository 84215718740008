import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { DASHBOARD_HOME_URL, ERROR_404_URL } from "@/core/urls";

export default function AffiliateDashboard() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${DASHBOARD_HOME_URL}`} />
      </Route>
      <Redirect exact from={`${match.path}/profile`} to={`${DASHBOARD_HOME_URL}`} />
      <Redirect exact from={`${match.path}/commissions`} to={`${DASHBOARD_HOME_URL}`} />
      <Redirect exact from={`${match.path}/affiliate_links`} to={`${DASHBOARD_HOME_URL}`} />
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
