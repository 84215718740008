import Box from "./Box";

export default function Circle({
  children,
  size,
  background,
  backgroundColor,
  withBorder,
  ...rest
}) {
  let props = { ...rest };
  if (withBorder) {
    props.borderColor = "dark";
    props.borderWidth = 1;
    props.borderStyle = "solid";
  }

  props = {
    ...props,
    ...rest
  };

  return (
    <Box
      flexShrink={0}
      borderRadius="50%"
      width={size}
      height={size}
      background={background}
      backgroundColor={backgroundColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {children}
    </Box>
  );
}

Circle.defaultProps = {
  backgroundColor: "rgba(0,0,0,0.08)",
  arrowColor: "dark",
  size: 40,
  direction: "right"
};
