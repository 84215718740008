import { theme } from "@/core/theme";
import { keyframes } from "styled-components";
import styled from "styled-components";

export const spinAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${props => props.minHeight}px;
  min-width: ${props => props.minWidth}px;

  &::after {
    animation: ${spinAnimation} 1000ms linear infinite;
    border-bottom-color: transparent;
    border-left-color: ${props => props.color};
    border-radius: 50%;
    border-right-color: ${props => props.color};
    border-style: solid;
    border-top-color: transparent;
    border-width: ${props => props.width}px;
    content: "";
    height: ${props => {
      return props.size;
    }}px;
    position: absolute;
    width: ${props => {
      return props.size;
    }}px;
  }
`;

Loading.defaultProps = {
  size: 40,
  width: 3,
  color: theme.colors.purple,
  minHeight: 200,
  minWidth: 0
};

export default Loading;
