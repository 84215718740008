import Svg from "@/components/Svg";

export default function UrineIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <path
        d="M56.0873 20H23.9128C22.3074 20 21 21.3017 21 22.9V33.7485C21 35.3468 22.3057 36.6485 23.9128 36.6485H24.2526V54.7217C24.2526 57.6317 26.6297 60 29.5525 60H50.4459C53.3687 60 55.7457 57.6317 55.7457 54.7217V36.6485H56.0855C57.6909 36.6485 58.9983 35.3485 58.9983 33.7485L59 22.9C59 21.3017 57.6943 20 56.0873 20ZM50.4476 57.3334H29.5542C28.1078 57.3334 26.9327 56.1617 26.9327 54.7217V46.115C29.4219 46.2533 30.8432 46.7767 32.3315 47.3284C34.2131 48.025 36.16 48.745 40.0017 48.745C43.8452 48.745 45.7905 48.025 47.6718 47.3284C49.16 46.7767 50.5796 46.255 53.0706 46.115V54.7217C53.0706 56.1617 51.8938 57.3334 50.4491 57.3334H50.4476ZM53.0691 43.4432C50.086 43.5899 48.3868 44.2166 46.7382 44.8283C45.0022 45.4716 43.3617 46.0783 40.0001 46.0783C36.6385 46.0783 34.9981 45.4716 33.2621 44.8283C31.6115 44.2182 29.9124 43.5883 26.9311 43.4432V36.6498H53.0691V43.4432ZM56.3217 33.7498C56.3217 33.8781 56.2162 33.9831 56.0873 33.9831H23.9128C23.7839 33.9831 23.6785 33.8781 23.6785 33.7498V22.8996C23.6785 22.7713 23.7839 22.6663 23.9128 22.6663H56.0873C56.2162 22.6663 56.3217 22.7713 56.3217 22.8996V33.7498Z"
        fill="#1A1A1A"
      />
      <path
        d="M40.0001 23.9535C39.2602 23.9535 38.6609 24.5501 38.6609 25.2868V31.3634C38.6609 32.1001 39.2602 32.6968 40.0001 32.6968C40.74 32.6968 41.3393 32.1001 41.3393 31.3634V25.2868C41.3393 24.5501 40.74 23.9535 40.0001 23.9535Z"
        fill="#1A1A1A"
      />
      <path
        d="M46.1437 23.9535C45.4037 23.9535 44.8044 24.5501 44.8044 25.2868V31.3634C44.8044 32.1001 45.4037 32.6968 46.1437 32.6968C46.8836 32.6968 47.4829 32.1001 47.4829 31.3634V25.2868C47.4829 24.5501 46.8836 23.9535 46.1437 23.9535Z"
        fill="#1A1A1A"
      />
      <path
        d="M52.2872 23.9535C51.5473 23.9535 50.948 24.5501 50.948 25.2868V31.3634C50.948 32.1001 51.5473 32.6968 52.2872 32.6968C53.0271 32.6968 53.6264 32.1001 53.6264 31.3634V25.2868C53.6264 24.5501 53.0271 23.9535 52.2872 23.9535Z"
        fill="#1A1A1A"
      />
      <path
        d="M33.8563 23.9535C33.1164 23.9535 32.5171 24.5501 32.5171 25.2868V31.3634C32.5171 32.1001 33.1164 32.6968 33.8563 32.6968C34.5962 32.6968 35.1955 32.1001 35.1955 31.3634V25.2868C35.1955 24.5501 34.5962 23.9535 33.8563 23.9535Z"
        fill="#1A1A1A"
      />
      <path
        d="M27.7127 23.9535C26.9728 23.9535 26.3735 24.5501 26.3735 25.2868V31.3634C26.3735 32.1001 26.9728 32.6968 27.7127 32.6968C28.4527 32.6968 29.052 32.1001 29.052 31.3634V25.2868C29.052 24.5501 28.4527 23.9535 27.7127 23.9535Z"
        fill="#1A1A1A"
      />
    </Svg>
  );
}
