import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { BASKET_PAYEE_PATIENT } from "@/core/constants";
import { Error } from "@/tpo/InputWrapper";

import PatientDetails, { getSavePatientEditableFields } from "../../shared/PatientDetails";

export default function CheckoutPatientDetails({ patient, permissions, onEdit }) {
  const { basket } = useOrganisationBasketContext();

  const editableFields = basket?.organisationPatient
    ? getSavePatientEditableFields(basket.organisationPatient)
    : [];

  let error = null; // or warning

  const genericError = (
    <Error
      data-component-name="MissingInformation"
      color="red"
      error="Please complete the missing patient information"
    />
  );

  if (basket?.payee === BASKET_PAYEE_PATIENT) {
    if (editableFields.length !== 0) {
      if (editableFields.length > 1) {
        error = genericError;
      } else if (
        editableFields.length === 1 &&
        ["email", "guardianEmail"].includes(editableFields[0])
      ) {
        error = (
          <Error
            data-component-name="MissingInformation"
            color="red"
            error="Your patient needs a valid email address to place this order, please update before continuing."
          />
        );
      } else {
        error = genericError;
      }
    }
  } else {
    if (editableFields.length) {
      if (editableFields.length > 1 || !["email", "guardianEmail"].includes(editableFields[0])) {
        error = genericError;
      } else if (
        editableFields.length === 1 &&
        ["email", "guardianEmail"].includes(editableFields[0])
      ) {
        error = (
          <Error
            data-component-name="MissingInformation"
            color="nutrition"
            error={
              <>
                This patient is using an invalid email address.
                <br />
                Please consider updating this to ensure notifications can be sent.
              </>
            }
          />
        );
      }
    }
  }

  return (
    <PatientDetails error={error} patient={patient} permissions={permissions} onEdit={onEdit} />
  );
}
