import { useRef } from "react";

import { ERROR_CODE, FlickingError } from "@egjs/react-flicking";

export default function useCarousel() {
  const ref = useRef(null);

  const prev = () => {
    ref.current.prev().catch(err => {
      if (
        err instanceof FlickingError &&
        (err.code === ERROR_CODE.ANIMATION_ALREADY_PLAYING ||
          err.code === ERROR_CODE.INDEX_OUT_OF_RANGE)
      )
        return;
      throw err;
    });
  };

  const next = () => {
    ref.current.next().catch(err => {
      if (
        err instanceof FlickingError &&
        (err.code === ERROR_CODE.ANIMATION_ALREADY_PLAYING ||
          err.code === ERROR_CODE.INDEX_OUT_OF_RANGE)
      )
        return;
      throw err;
    });
  };

  return {
    ref,
    prev,
    next
  };
}
