import Box from "@/components/Box";
import RegenerusLogo from "@/images/RegenerusLogo";
import Logo from "@/images/logo.svg?react";

export default function ExternalKitRegistrationHeader() {
  return (
    <Box
      bg="blue"
      height="100%"
      display="flex"
      flexDirection={["column", "row"]}
      alignItems={[null, "center"]}
      py={50}
      px={[20, 20, "5.5vw"]}
      gap={[20, null]}
    >
      <Box mr={[null, "auto"]} width={[170, 180, 190, 200]}>
        <Logo />
      </Box>
      <Box ml={[null, "auto"]}>
        <Box color="white" fontFamily="gilroyRegular" fontSize={18} mb={20}>
          Supplied by
        </Box>
        <RegenerusLogo color="white" />
      </Box>
    </Box>
  );
}
