import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ERROR_404_URL } from "@/core/urls";
import useDocTitle from "@/hooks/use-doc-title";

import DiscountListPage from "./DiscountListPage";
import SupplementListPage from "./SupplementListPage";
import TestProductListPage from "./TestProductListPage";

export default function OrganisationShop() {
  const match = useRouteMatch();
  useDocTitle("Shop", false, "Regenerus");

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={`${match.path}/tests`} />
      </Route>
      <Route path={`${match.path}/supplements`} exact>
        <SupplementListPage />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <TestProductListPage />
      </Route>
      <Route path={`${match.path}/discounts`} exact>
        <DiscountListPage />
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
