import { createContext, useContext, useEffect, useMemo, useRef } from "react";

import { createPortal } from "react-dom";

import Box from "@/components/Box";
import CloseButton from "@/components/CloseButton";
import { MODAL_OVERLAY_Z_INDEX, MODAL_Z_INDEX } from "@/core/constants";
import { theme } from "@/core/theme";
import useBreakPointBasedProp from "@/hooks/useBreakPointBasedProp";

import { body, ModalContext } from "./contexts/modal";

export const ModalInstanceContext = createContext();

function Backdrop() {
  const { overlayZIndex } = useContext(ModalInstanceContext);

  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      height="100vh"
      opacity={0.5}
      zIndex={overlayZIndex}
      bg="blue"
    />
  );
}

function Container({ children, px }) {
  const { zIndex } = useContext(ModalInstanceContext);

  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      minHeight={"100svh"}
      height={"100svh"}
      zIndex={zIndex}
      px={px}
    >
      {children}
    </Box>
  );
}

function Header() {
  const { closeButton, close, title, headerProps } = useContext(ModalInstanceContext);
  const { zIndex } = useContext(ModalInstanceContext);

  if (!title && !closeButton) return null;

  return (
    <Box
      display="flex"
      position="sticky"
      top={0}
      left={0}
      width="100%"
      zIndex={1}
      {...headerProps}
      style={{
        pointerEvents: "none"
      }}
    >
      {title}
      {closeButton && (
        <Box ml="auto">
          <CloseButton
            height={[40, 40, 48]}
            width={[40, 40, 48]}
            color={theme.colors.dark}
            onClick={close}
            bg="white"
            zIndex={zIndex + 1}
            position="relative"
            data-component-name="ModalCloseButton"
            style={{
              pointerEvents: "auto"
            }}
          />
        </Box>
      )}
    </Box>
  );
}

function ModalContent({ children }) {
  const {
    close,
    bg,
    maxWidth,
    mode: modes,
    borderRadius,
    maxHeight,
    header,
    dataComponentName,
    minHeight,
    height,
    innerProps: _innerProps,
    outerProps: _outerProps
  } = useContext(ModalInstanceContext);

  const mode = useBreakPointBasedProp(modes);

  let outerProps = {};
  let innerProps = {};

  if (mode?.topRightPosition) {
    outerProps = {
      position: "relative",
      minHeight: "100%",
      maxHeight: "100%"
    };
    innerProps = {
      position: "absolute",
      right: mode.topRightPosition.x,
      top: mode.topRightPosition.y,
      maxWidth,
      width: "100%",
      maxHeight,
      overflow: "auto"
    };
  } else if (mode === "fullScreen") {
    outerProps = {
      height: "100%"
    };
    innerProps = {
      width: "100%",
      height: "100%",
      maxHeight: "100%"
    };
  } else if (mode === "centered") {
    outerProps = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100%",
      maxHeight: "100%",
      padding: 20
    };
    innerProps = {
      maxWidth,
      width: "100%",
      minHeight,
      height: height || "100%",
      maxHeight: maxHeight || "100%"
    };
  }

  return (
    <Box
      zIndex={MODAL_Z_INDEX}
      {...outerProps}
      {..._outerProps}
      onClick={e => {
        if (!e.modalContentClicked && close) {
          close();
        }
      }}
    >
      <Box
        bg={bg}
        overflowY="auto"
        {...innerProps}
        {..._innerProps}
        borderRadius={borderRadius}
        data-component-name={dataComponentName}
        onClick={e => {
          e.modalContentClicked = true;
        }}
      >
        {header ? header : <Header />}
        {children}
      </Box>
    </Box>
  );
}

function Modal({
  bg,
  children,
  show,
  close,
  maxWidth,
  closeButton,
  my,
  containerProps,
  headerProps,
  title,
  mode,
  borderRadius,
  maxHeight,
  header,
  "data-component-name": dataComponentName,
  zIndex,
  overlayZIndex,
  minHeight,
  height,
  withOverlay,
  innerProps,
  outerProps
}) {
  const { getId, close: closedThisModal, show: showThisModal } = useContext(ModalContext);

  const modalId = useRef(getId());

  const modalApi = useMemo(
    () => ({
      bg,
      closeButton,
      close,
      maxWidth,
      show,
      my,
      headerProps,
      title,
      mode,
      borderRadius,
      maxHeight,
      header,
      dataComponentName,
      overlayZIndex: overlayZIndex || MODAL_OVERLAY_Z_INDEX,
      zIndex: zIndex || MODAL_Z_INDEX,
      minHeight,
      height,
      innerProps,
      outerProps
    }),
    [
      bg,
      closeButton,
      maxWidth,
      show,
      my,
      close,
      headerProps,
      title,
      mode,
      borderRadius,
      maxHeight,
      header,
      dataComponentName,
      zIndex,
      overlayZIndex,
      minHeight,
      height,
      innerProps,
      outerProps
    ]
  );

  useEffect(() => {
    const id = modalId.current;

    if (show) {
      showThisModal(id);
    } else {
      closedThisModal(id);
    }

    return () => {
      closedThisModal(id);
    };
  }, [show, showThisModal, closedThisModal]);

  return (
    <ModalInstanceContext.Provider value={modalApi}>
      {show &&
        createPortal(
          <>
            {withOverlay && <Backdrop />}
            <Container {...containerProps}>
              <ModalContent>{children}</ModalContent>
            </Container>
          </>,
          body
        )}
    </ModalInstanceContext.Provider>
  );
}

Modal.defaultProps = {
  headerProps: {
    pr: 20,
    py: 20
  },
  withOverlay: true
};

export default Modal;
