import { useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import Box from "@/components/Box";
import Circle from "@/components/Circle";
import { theme } from "@/core/theme";
import { EVENT_CONNECTIONS_QUERY } from "@/graphql/knowledgeCenter/webinars";
import useWindowSize from "@/hooks/use-window-size";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import CalendarIcon from "@/images/CalendarIcon";
import Checkbox from "@/tpo/Checkbox";
import Group from "@/tpo/Group";
import SortMenu from "@/tpo/SortMenu";
import Stack from "@/tpo/Stack";
import { Search } from "@/tpo/TextInput";
import { useQuery } from "@apollo/client";

import EventCard from "./EventCard";
import ListTemplate from "./ListTemplate";
import NavigationInstance from "./Navigation";

export function EventsTable({ children }) {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  if (isMobile) {
    return (
      <Stack gap={20} mt={40}>
        {children}
      </Stack>
    );
  }

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px 20px"
      }}
    >
      <Box as="thead">
        <Box
          as="tr"
          fontFamily="gilroyBold"
          fontSize={12}
          letterSpacing={2.8}
          textTransform="uppercase"
        >
          <Box
            pt={2}
            pb={20}
            pl={40}
            fontFamily="inherit"
            fontSize="inherit"
            letterSpacing="inherit"
            textTransform="inherit"
            as="th"
            style={{
              width: "50%"
            }}
            textAlign="left"
          >
            event
          </Box>
          <Box
            pt={2}
            pb={20}
            as="th"
            px={20}
            textAlign="left"
            fontFamily="inherit"
            fontSize="inherit"
            letterSpacing="inherit"
            textTransform="inherit"
            minWidth={200}
          >
            time & date
          </Box>
          <Box
            pt={2}
            pb={20}
            as="th"
            fontFamily="inherit"
            fontSize="inherit"
            letterSpacing="inherit"
            textTransform="inherit"
          >
            details
          </Box>
        </Box>
      </Box>
      <tbody>{children}</tbody>
    </table>
  );
}

export default function Events() {
  const [inPersonValue, setInPersonValue] = useState(false);
  const [onlineValue, setOnlineValue] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search).get("search") || "";
  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Title asc",
        value: "title"
      },
      {
        label: "Title desc",
        value: "-title"
      },
      {
        label: "Start time asc",
        value: "start_time"
      },
      {
        label: "Start time desc",
        value: "-start_time"
      }
    ]
  });

  let types = [];
  if (inPersonValue) {
    types.push("IN_PERSON");
  }
  if (onlineValue) {
    types.push("ONLINE");
  }

  const { data, loading, fetchMore } = useQuery(EVENT_CONNECTIONS_QUERY, {
    variables: {
      first: 10,
      orderBy: controls.sort,
      search: debouncedSearch,
      types
    }
  });

  const hasMore = data?.eventConnections.pageInfo.hasNextPage;
  const endCursor = data?.eventConnections.pageInfo.endCursor;
  const events = data?.eventConnections.edges.map(edge => edge.node);

  return (
    <ListTemplate.ThemeProvider>
      <ListTemplate.Tabs selectedTab="support" />
      <Group
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, 40]}
        maxWidth={1538}
        mx="auto"
        gap={80}
      >
        <NavigationInstance />
        <Stack gap={[40]} maxWidth={1138} flexGrow={1}>
          <Box>
            <Stack gap={20}>
              <Group gap={20} alignItems="center">
                <Circle bg="transparent" size={80} border="2px solid black">
                  <CalendarIcon fill={theme.colors.dark} size={40} />
                </Circle>
                <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                  Upcoming events
                </Box>
              </Group>
            </Stack>
            <Stack gap={40} pt={[40, 40, 80]}>
              <Box
                display="flex"
                flexDirection={["column", "column", "row"]}
                gap={10}
                justifyContent="space-between"
              >
                <Search
                  value={search}
                  onChange={s => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("search", s);
                    history.push({
                      path: location.path,
                      search: searchParams.toString()
                    });
                  }}
                  maxWidth={[null, null, 350]}
                  minWidth={310}
                  width="100%"
                />
                <Box display="flex" justifyContent="flex-end">
                  <SortMenu
                    value={controls.sort}
                    setValue={controls.setSort}
                    open={controls.sortMenuOpen}
                    setOpen={controls.setSortMenuOpen}
                    options={controls.sortOptions}
                    placeholder="Sort By"
                  />
                </Box>
              </Box>
              <Group gap={20}>
                <Checkbox
                  label="In Person"
                  value={inPersonValue}
                  onChange={e => setInPersonValue(e.target.checked)}
                />
                <Checkbox
                  label="Online"
                  value={onlineValue}
                  onChange={e => setOnlineValue(e.target.checked)}
                />
              </Group>
            </Stack>
            <ListTemplate.InfiniteScrollObjectList
              Container={EventsTable}
              containerProps={{
                pt: 40,
                pb: theme.spacing.section.pb
              }}
              loading={loading}
              objects={events}
              objectsPlural="events"
              hasMore={hasMore}
              fetchMore={fetchMore}
              endCursor={endCursor}
              Card={EventCard}
            />
          </Box>
        </Stack>
      </Group>
    </ListTemplate.ThemeProvider>
  );
}
