import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import Page from "@/components/Page";
import { theme } from "@/core/theme";
import useDocTitle from "@/hooks/use-doc-title";
import ButtonV2 from "@/v2/Buttons";

import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ExternalKitRegistrationHeader from "./ExternalKitRegistrationHeader";

function ExternalKitRegistrationComplete() {
  useDocTitle("Register test kit");
  const history = useHistory();

  return (
    <Page addNavBarHeight={false} header={<ExternalKitRegistrationHeader />}>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          "data-component-name": "Register your kit",
          mt: 0,
          px: 20
        }}
      >
        <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]} mb={[20, 20, 30]}>
          Kit registration complete
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="150%">
          Congratulations! Your test is now registered and will be linked to your account. Please
          follow the instructions on how to take the test, post and return to us. You will be
          notified when your results are ready to be viewed.
        </Box>
        <Center>
          <ButtonV2
            color="green"
            my={40}
            maxWidth={300}
            width="100%"
            onClick={() => {
              history.push("/external-kit-registration");
            }}
            data-component-name="Register another button"
            size={["sm", "sm", "md"]}
          >
            Register another test
          </ButtonV2>
        </Center>
      </PanelBoxV2>
    </Page>
  );
}

export default ExternalKitRegistrationComplete;
