import Box from "@/components/Box";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { MODAL_Z_INDEX } from "@/core/constants";
import Danger from "@/images/Danger";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import Modal from "@/tpo/Modal";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export default function BasketErrorModal({ explanation, show, onClose }) {
  const { clearBasket, setFormErrors } = useOrganisationBasketContext();

  return (
    <Modal
      close={
        onClose ??
        (() => {
          setFormErrors(null);
        })
      }
      show={show}
      zIndex={MODAL_Z_INDEX + 2}
      overlayZIndex={MODAL_Z_INDEX + 1}
      maxWidth={768}
      headerProps={{
        p: [20, 20, 40]
      }}
      bg="white"
      mode={["centered"]}
      closeButton
      borderRadius={20}
      data-component-name="BasketErrorModal"
    >
      <Stack
        alignItems={["center"]}
        mx="auto"
        gap={[20, 20, 40]}
        px={[48, 48, 92]}
        pb={[48, 48, 80]}
      >
        <Group alignItems="center" gap={[10, 10, 20]}>
          <Danger size={[22, 22, 40]} />
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
            Basket issue
          </Box>
        </Group>
        <Box
          fontFamily="gilroyMedium"
          fontSize={[14, 14, 16]}
          textAlign={["center"]}
          data-testid="explanation"
        >
          {explanation}
        </Box>
        <ButtonV2 color="green" onClick={clearBasket} rightIcon={<ChevronComponent />}>
          clear basket
        </ButtonV2>
      </Stack>
    </Modal>
  );
}
