import { useState } from "react";

import Group from "@/tpo/Group";
import Menu, { Button } from "@/tpo/Menu";

export default function LanguageSelect({ value, onChange, languages = [] }) {
  const [showOrgMenu, setShowOrgMenu] = useState(false);
  const placeholder = "Language";

  let buttonContent = placeholder;
  if (value && languages.find(l => l.name === value)) {
    const lang = languages.find(l => l.name === value);

    buttonContent = (
      <Group alignItems="center" gap={10} color="white">
        {lang.name}
      </Group>
    );
  }

  const options = [...languages].sort((a, b) => {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <Menu opened={showOrgMenu} onChange={setShowOrgMenu} trigger="hover">
      <Menu.Target>
        <Button
          open={showOrgMenu}
          color="dark"
          sx={{
            color: "white",
            px: [20, 20, 40]
          }}
        >
          {buttonContent}
        </Button>
      </Menu.Target>
      <Menu.Dropdown
        mt={2}
        py={2}
        zIndex={2}
        minWidth={200}
        minHeight={300}
        overflow="auto"
        bg="dark"
        data-component-name="LanguageMenuDropdown"
      >
        {options.map(l => {
          return (
            <Menu.Item
              onClick={() => {
                onChange(l.name);
              }}
              selected={l.name === value}
              key={l.id}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              gap={10}
              alignItems="center"
            >
              {l.name}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
