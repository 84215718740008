import React, { isValidElement } from "react";

import { theme } from "@/core/theme";
import PropTypes from "prop-types";

import Box from "./Box";
import { IntroBlockHeading } from "./Headings";
import RichText from "./RichText";

export function IntroBlockContent({ heading, text }) {
  return (
    <>
      <IntroBlockHeading pb as="h2">
        {heading}
      </IntroBlockHeading>
      {isValidElement(text) ? text : <RichText>{text}</RichText>}
    </>
  );
}

export default function IntroBlock({ backgroundColor, heading, text }) {
  return (
    <Box px={20} py={theme.spacing.large} maxWidth={1200} bg={backgroundColor} mx="auto">
      <Box maxWidth={800} mx="auto">
        <IntroBlockContent heading={heading} text={text} />
      </Box>
    </Box>
  );
}

IntroBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
};
