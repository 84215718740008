import { useAppState } from "@/components/AppStateProvider";
import { useBasketContext } from "@/contexts/BasketContext";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { GET_CONSUMER_CLINICS } from "@/graphql/shop";

import BaseTestProductModal, { ChooseClinic } from "./BaseTestProductModal";
import Full from "./testProductDetail/modal/Full";
import Truncated from "./testProductDetail/modal/Truncated";
import showFullDetails from "./testProductDetail/utils/showFullDetails";

function ProductDetailView() {
  const { testProduct } = useTestProductModalContext();

  if (!testProduct) return null;

  const fullView = showFullDetails(testProduct);

  if (fullView) {
    return <Full />;
  } else {
    return <Truncated />;
  }
}

function ChooseClinicView() {
  const {
    testProductOption,
    selectTestProductId,
    selectTestProductOptionId
  } = useTestProductModalContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  return (
    <ChooseClinic
      onAddToBasket={({ clinic }) => {
        addItemToBasket({
          compositeId: testProductOption.compositeId,
          clinicLocationId: clinic.id
        });
        setBasketOpen(true);
        selectTestProductId(undefined);
        selectTestProductOptionId(undefined);
      }}
      query={GET_CONSUMER_CLINICS}
      testProductOptionPriceField="rrpCurrentPrice"
      clinicLocationsField="consumerClinicLocations"
    />
  );
}

export default function TestProductModal({ bg }) {
  return (
    <BaseTestProductModal
      bg={bg}
      clinicsInfoField="consumerClinicsInfo"
      chooseClinicView={<ChooseClinicView />}
      productDetailView={<ProductDetailView />}
    />
  );
}
