import Svg from "@/components/Svg";

export default function SalivaIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19569)">
        <path
          d="M37.736 27.8064V26.4158C37.736 23.2939 35.1969 20.755 32.0752 20.755C28.9535 20.755 26.4144 23.294 26.4144 26.4158V27.8064C25.5222 28.2345 24.905 29.1455 24.905 30.1986C24.905 31.2517 25.5222 32.1642 26.4144 32.5908V53.5844C26.4144 54.0203 26.466 54.4438 26.5597 54.8516C26.5644 54.875 26.5691 54.8969 26.5753 54.9188C27.1769 57.3985 29.4144 59.2452 32.0753 59.2452C35.1972 59.2452 37.7361 56.7061 37.7361 53.5844V32.5924C38.6283 32.1643 39.2455 31.2533 39.2455 30.2002C39.2455 29.1455 38.6266 28.2345 37.736 27.8064ZM32.0752 22.2644C34.3643 22.2644 36.2268 24.1269 36.2268 26.416V27.5488H27.9252L27.9236 26.416C27.9236 24.1269 29.7861 22.2644 32.0752 22.2644ZM32.0752 57.7364C30.4658 57.7364 29.0674 56.8145 28.3799 55.4723H29.4345C29.8517 55.4723 30.1892 55.1348 30.1892 54.7176C30.1892 54.3005 29.8517 53.963 29.4345 53.963H27.9423C27.9111 53.6177 27.9298 53.5927 27.9252 51.6989H30.5674C30.9846 51.6989 31.322 51.3614 31.322 50.9442C31.322 50.527 30.9845 50.1895 30.5674 50.1895H27.9252V47.9254H29.4345C29.8517 47.9254 30.1892 47.5879 30.1892 47.1708C30.1892 46.7536 29.8517 46.4161 29.4345 46.4161H27.9252V44.152H30.5674C30.9846 44.152 31.322 43.8145 31.322 43.3973C31.322 42.9801 30.9845 42.6426 30.5674 42.6426H27.9252V40.3786H29.4345C29.8517 40.3786 30.1892 40.0411 30.1892 39.6239C30.1892 39.2067 29.8517 38.8692 29.4345 38.8692H27.9252V36.6051H30.5674C30.9846 36.6051 31.322 36.2676 31.322 35.8504C31.322 35.4333 30.9845 35.0958 30.5674 35.0958H27.9252V32.852H36.2268V53.5848C36.2268 55.8739 34.3643 57.7364 32.0752 57.7364ZM36.594 31.3412H27.558C26.9283 31.3412 26.4158 30.8287 26.4158 30.199C26.4158 29.5693 26.9283 29.0568 27.558 29.0568H36.594C37.2237 29.0568 37.7362 29.5693 37.7362 30.199C37.7362 30.8287 37.2237 31.3412 36.594 31.3412Z"
          fill="#1A1A1A"
        />
        <path
          d="M44.6419 49.8328V30.1672L46.8576 25.4188C47.8717 23.2485 46.2873 20.7548 43.8873 20.7548C41.4919 20.7548 39.9013 23.2439 40.9169 25.4188L43.1326 30.1672V49.8328L40.9169 54.5812C39.9045 56.7515 41.4873 59.2452 43.8873 59.2452C46.2826 59.2452 47.8732 56.7561 46.8576 54.5812L44.6419 49.8328Z"
          fill="#1A1A1A"
        />
        <path
          d="M54.7829 54.5812L52.5672 49.8328V30.1672L54.7829 25.4188C55.797 23.2485 54.2125 20.7548 51.8125 20.7548C49.4172 20.7548 47.8266 23.2439 48.8422 25.4188L51.0579 30.1672V49.8328L48.8422 54.5812C47.8297 56.7515 49.4125 59.2452 51.8125 59.2452C54.2094 59.2452 55.7985 56.7561 54.7829 54.5812Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3634_19569">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
