import { useAuthContext } from "@/components/AuthProvider";

import AffiliateApplyingToBePractitionerForm from "./AffiliateApplyingToBePractitionerForm";
import LoggedInForm from "./ui/LoggedInForm";
import LoggedOutForm from "./ui/LoggedOutForm";

export default function PractitionerApplication({
  setFormSubmitted,
  isAffiliateCurrently,
  affiliateProfile
}) {
  const { user } = useAuthContext();

  if (user && isAffiliateCurrently && affiliateProfile) {
    return (
      <AffiliateApplyingToBePractitionerForm
        affiliateProfile={affiliateProfile}
        setFormSubmitted={setFormSubmitted}
      />
    );
  }

  return user ? (
    <LoggedInForm setFormSubmitted={setFormSubmitted} />
  ) : (
    <LoggedOutForm setFormSubmitted={setFormSubmitted} />
  );
}
