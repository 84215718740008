import { useCallback, useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "@/components/Box";
import Errors from "@/components/Errors";
import { FadeTransition } from "@/components/animations/FadeTransition";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { BASKET_PAYEE_PATIENT } from "@/core/constants";
import { theme } from "@/core/theme";
import { SAVE_PATIENT_MUTATION } from "@/graphql/organisations/mutations";
import { ORGANISATION_LIST_QUERY } from "@/graphql/organisations/queries/organisation";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import ControlledFormField from "@/tpo/ControlledFormField";
import ControlledRadioGroup from "@/tpo/ControlledRadioGroup";
import DateInput from "@/tpo/DateInput";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import FormControl from "@/tpo/FormControl";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import isUnderEighteen from "@/utils/isUnderEighteen";
import ButtonV2 from "@/v2/Buttons";

export default function SavePatientForm({
  handleSuccess,
  handleFailure,
  mutationOptions,
  defaultValues,
  title,
  editableFields,
  "data-component-name": dataComponentName,
  ...props
}) {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  mutationOptions = {
    refetchQueries: [{ query: ORGANISATION_LIST_QUERY }],
    // TODO - check if we need to do this?
    // Not sure why we need to refetch all the orgs ??
    ...mutationOptions
  };

  const isEditForm = !!defaultValues;

  const { methods, onSubmit, nonFieldError, loading } = useDjangoGraphqlForm({
    mutation: SAVE_PATIENT_MUTATION,
    mutationName: "savePatientMutation",
    mutationOptions,
    handleSuccess,
    handleFailure,
    defaultValues: {
      ...(defaultValues || {
        firstName: "",
        lastName: "",
        email: "",
        sex: "",
        dateOfBirth: "",
        requiresGuardianConsent: false,
        guardianFirstName: "",
        guardianLastName: "",
        guardianEmail: "",
        sendInvite: false
      })
    },
    ...props
  });

  const watchDateOfBirth = methods.watch("dateOfBirth");
  const acknowledgedAgeRanges = methods.watch("requiresGuardianConsent");

  const underEighteen = watchDateOfBirth ? isUnderEighteen(watchDateOfBirth) : undefined;

  const handleCreatePatientSubmit = useCallback(
    input => {
      let variables = {
        ...input,
        organisation: parseInt(organisation.id)
      };

      if (!underEighteen) {
        variables = {
          ...variables,
          guardianFirstName: undefined,
          guardianLastName: undefined,
          guardianEmail: undefined
        };
      } else {
        variables = {
          ...variables,
          email: ""
        };
      }

      return onSubmit(variables);
    },
    [organisation, onSubmit, underEighteen]
  );

  const emailHelpTextProps = {};
  let emailHelpText = null;

  const guardianHelpTextProps = {};
  let guardianHelpText = null;

  if (editableFields && editableFields.includes("email")) {
    if (basket?.payee === BASKET_PAYEE_PATIENT) {
      emailHelpText =
        "Your patient needs a valid email address to place this order, please update before continuing.";
      emailHelpTextProps.color = "red";
    } else {
      emailHelpText = "Your patient is using a proxy email address, please consider updating.";
      emailHelpTextProps.color = "nutrition";
    }
  }

  if (editableFields && editableFields.includes("guardianEmail")) {
    if (basket?.payee === BASKET_PAYEE_PATIENT) {
      guardianHelpText =
        "Your patient needs a valid guardian email address to place this order, please update before continuing.";
      guardianHelpTextProps.color = "red";
    } else {
      guardianHelpText =
        "The guardian for this patient is using a proxy email address.  Please consider updating.";
      guardianHelpTextProps.color = "nutrition";
    }

    if (!basket?.organisationPatient?.platformUserProfile?.guardian) {
      guardianHelpText =
        "Your patient needs a valid guardian email address to place this order, please update before continuing.";
      guardianHelpTextProps.color = "red";
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleCreatePatientSubmit)}
        data-component-name={dataComponentName}
      >
        <Box pb={theme.spacing.section.pb}>
          <Box fontFamily="gilroyBold" fontSize={28}>
            {title}
          </Box>
          <Spacer py={15} />
          <Stack gap={20}>
            <ControlledFormField
              name="firstName"
              label="First name"
              Component={FloatingLabelInput}
              editable={editableFields ? editableFields.includes("firstName") : true}
            />
            <ControlledFormField
              name="lastName"
              label="Last name"
              Component={FloatingLabelInput}
              editable={editableFields ? editableFields.includes("lastName") : true}
            />
            <FadeTransition in={!underEighteen}>
              <ControlledFormField
                name="email"
                label="Email"
                Component={FloatingLabelInput}
                editable={editableFields ? editableFields.includes("email") : true}
                helpText={emailHelpText}
                helpTextProps={emailHelpTextProps}
              />
            </FadeTransition>
          </Stack>
          <Spacer py={30} />
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Sex
              </Box>
            }
          >
            <Stack gap={20}>
              <ControlledRadioGroup
                disabled={editableFields ? !editableFields.includes("sex") : false}
                Container={Stack}
                containerProps={{
                  gap: 20
                }}
                name="sex"
                values={[
                  {
                    label: "Male",
                    value: "Male"
                  },
                  {
                    label: "Female",
                    value: "Female"
                  }
                ]}
              />
            </Stack>
          </FormControl>
          <Spacer py={2} />
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Date of birth
              </Box>
            }
          >
            <ControlledFormField
              disabled={editableFields ? !editableFields.includes("dateOfBirth") : false}
              name="dateOfBirth"
              Component={DateInput}
              getDay={dateStr => (dateStr ? dateStr.split("-")[2] : "")}
              getMonth={dateStr => (dateStr ? dateStr.split("-")[1] : "")}
              getYear={dateStr => (dateStr ? dateStr.split("-")[0] : "")}
              constructDate={({ day, month, year }) => {
                return `${year}-${month}-${day}`;
              }}
            />
          </FormControl>
          <Spacer py={15} />
          <FadeTransition in={underEighteen}>
            <Box fontFamily="gilroyBold" fontSize={18} lineHeight="36px" color="red">
              User is under 18 years old
            </Box>
            <Spacer py={15} />
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Because this user is a minor please enter the details of the legal guardian that will
              be responsible for them.
            </Box>
            <Spacer py={15} />
            <Stack gap={20}>
              <ControlledFormField
                name="guardianFirstName"
                label="Guardian first name"
                Component={FloatingLabelInput}
                editable={editableFields ? editableFields.includes("guardianFirstName") : true}
              />
              <ControlledFormField
                name="guardianLastName"
                label="Guardian last name"
                Component={FloatingLabelInput}
                editable={editableFields ? editableFields.includes("guardianLastName") : true}
              />
              <ControlledFormField
                name="guardianEmail"
                label="Guardian email"
                Component={FloatingLabelInput}
                editable={editableFields ? editableFields.includes("guardianEmail") : true}
                helpText={guardianHelpText}
                helpTextProps={guardianHelpTextProps}
              />
            </Stack>
            <Spacer py={15} />
            <ControlledFormField
              name="requiresGuardianConsent"
              Component={Checkbox}
              label="I understand that not all tests will come with appropriate ranges for users under the age of 18"
              editable={editableFields ? editableFields.includes("requiresGuardianConsent") : true}
            />
            <Spacer py={15} />
          </FadeTransition>
          {!isEditForm && (
            <ControlledFormField
              name="sendInvite"
              Component={Checkbox}
              label="Send patient invite to sign up to the Omnos platform"
            />
          )}
          <Spacer py={15} />
          <Center>
            <ButtonV2
              color="green"
              rightIcon={<ChevronComponent />}
              disabled={loading || (acknowledgedAgeRanges === false && underEighteen)}
            >
              submit
            </ButtonV2>
          </Center>
          {nonFieldError && <Errors>{nonFieldError}</Errors>}
        </Box>
      </form>
    </FormProvider>
  );
}

SavePatientForm.defaultProps = {
  title: "Enter Patient Details"
};
