import Box from "@/components/Box";
import RichText from "@/components/RichText";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";

export default function TestProductInformationPanels({ panels }) {
  return (
    <Stack gap={40}>
      {panels.map(panel => (
        <Box bg="white" borderRadius="5px" py={40} px={[20, 20, 40]} key={panel.id}>
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
            {panel.title}
          </Box>
          <Spacer py={[1, 1, 2]} />
          <RichText fontSize={[14, 14, 16]}>{panel.body}</RichText>
        </Box>
      ))}
    </Stack>
  );
}
