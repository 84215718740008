import { forwardRef } from "react";

import Box from "@/components/Box";
import { theme } from "@/core/theme";
import styled from "styled-components";

const COLORS = {
  DARK: theme.colors.dark,
  GREEN: theme.colors.green,
  WHITE: "#fff"
};

const StyledLabel = styled.label.withConfig({
  shouldForwardProp: prop => !["offBg", "onBg"].includes(prop)
})`
  cursor: pointer;
  width: 80px;
  height: 40px;
  background: ${({ checked, offBg, onBg }) =>
    checked
      ? onBg
        ? theme.colors[onBg] || onBg
        : COLORS.GREEN
      : offBg
      ? theme.colors[offBg] || offBg
      : COLORS.WHITE};
  display: block;
  border-radius: 100px;
  position: relative;

  input {
    display: none;
  }
`;

const Toggle = styled(Box).withConfig({
  shouldForwardProp: prop =>
    !["onSwitchBg", "offSwitchBg", "onSwitchColor", "offSwitchColor"].includes(prop)
})`
  position: absolute;
  left: ${({ checked }) => (checked ? "41px" : "1px")};
  top: 1px;
  width: 38px;
  height: 38px;
  background: ${({ checked, onSwitchBg, offSwitchBg }) =>
    checked
      ? onSwitchBg
        ? theme.colors[onSwitchBg]
        : COLORS.WHITE
      : offSwitchBg
      ? theme.colors[offSwitchBg]
      : COLORS.DARK};
  color: ${({ checked, onSwitchColor, offSwitchColor }) =>
    checked
      ? onSwitchColor
        ? theme.colors[onSwitchColor] || onSwitchColor
        : COLORS.DARK
      : offSwitchColor
      ? theme.colors[offSwitchColor] || offSwitchColor
      : COLORS.WHITE};
  border-radius: 90px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
`;

const ToggleSwitch = forwardRef(
  (
    {
      id,
      value,
      onChange,
      offBg,
      onBg,
      offSwitchBg,
      onSwitchBg,
      onSwitchColor,
      offSwitchColor,
      "data-component-name": dataComponentName,
      "data-testid": dataTestId
    },
    ref
  ) => {
    function handleOnChange(e) {
      onChange(e.target.checked);
    }
    return (
      <StyledLabel
        htmlFor={id}
        checked={!!value}
        offBg={offBg}
        onBg={onBg}
        data-testid={dataTestId}
        data-component-name={dataComponentName}
      >
        <input id={id} type="checkbox" checked={!!value} onChange={handleOnChange} />
        <Toggle
          as="span"
          checked={!!value}
          fontFamily="gilroyBold"
          offSwitchColor={offSwitchColor}
          onSwitchColor={onSwitchColor}
          offSwitchBg={offSwitchBg}
          onSwitchBg={onSwitchBg}
        >
          {!!value ? "yes" : "no"}
        </Toggle>
      </StyledLabel>
    );
  }
);

ToggleSwitch.defaultProps = {
  "data-component-name": "ToggleSwitch"
};

export default ToggleSwitch;
