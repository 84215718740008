import { forwardRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import ArrowCircle from "@/components/ArrowCircle";
import Box from "@/components/Box";
import Circle from "@/components/Circle";
import Grid from "@/components/Grid";
import { FAQ_CONNECTIONS_QUERY, TOPIC_QUERY } from "@/graphql/knowledgeCenter/webinars";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import OpenBookIcon from "@/images/OpenBookIcon";
import Checkbox from "@/tpo/Checkbox";
import Group from "@/tpo/Group";
import SortMenu from "@/tpo/SortMenu";
import Stack from "@/tpo/Stack";
import { Search } from "@/tpo/TextInput";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import ListTemplate from "./ListTemplate";
import NavigationInstance from "./Navigation";
import { TopicFiltersDesktop as TopicFilters } from "./TopicFilters";

const FAQ = forwardRef(({ question, answer }, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack ref={ref}>
      <Group
        justifyContent="space-between"
        onClick={() => {
          setOpen(!open);
        }}
        py={20}
        borderBottom={open ? null : "1px solid black"}
        cursor="pointer"
      >
        <Box fontFamily="gilroyRegular" fontSize={[24]}>
          {question}
        </Box>
        <ArrowCircle
          backgroundColor="dark"
          arrowColor="white"
          direction={open ? "bottom" : "top"}
          cursor="pointer"
          as="button"
        />
      </Group>
      {open && (
        <Box
          p={40}
          fontFamily="gilroyMedium"
          fontSize={16}
          borderBottom={!open ? null : "1px solid black"}
        >
          {answer}
        </Box>
      )}
    </Stack>
  );
});

export default function FAQS() {
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search).get("search") || "";
  const debouncedSearch = useDebounce(search, 200);

  const [filters, setFilters] = useState(new Set([]));

  const controls = useListControls({
    sortOptions: [
      {
        label: "Question asc",
        value: "question"
      },
      {
        label: "Question desc",
        value: "-question"
      }
    ]
  });

  const { data, loading, fetchMore } = useQuery(FAQ_CONNECTIONS_QUERY, {
    variables: {
      first: 10,
      orderBy: controls.sort,
      search: debouncedSearch,
      topics: Array.from(filters)
    }
  });

  const { data: topicsData } = useQuery(TOPIC_QUERY);

  const hasMore = data?.faqConnections.pageInfo.hasNextPage;
  const endCursor = data?.faqConnections.pageInfo.endCursor;
  const events = data?.faqConnections.edges.map(edge => edge.node);
  const topics = topicsData?.topics;

  const theme = useTheme();

  return (
    <ListTemplate.ThemeProvider>
      <ListTemplate.Tabs selectedTab="support" />
      <Group
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, 40]}
        maxWidth={1538}
        mx="auto"
        gap={80}
      >
        <NavigationInstance />
        <Grid
          flexGrow={1}
          gridTemplateColumns="minmax(auto, 788px) 1fr"
          maxWidth={1138}
          gridColumnGap={40}
        >
          <Stack gap={20} gridColumn="1 / 3">
            <Group gap={20} alignItems="center">
              <Circle bg="transparent" size={80} border="2px solid black">
                <OpenBookIcon fill={theme.colors.dark} width={40} height={30} />
              </Circle>
              <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                FAQ's
              </Box>
            </Group>
          </Stack>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={10}
            justifyContent="space-between"
            gridColumn="1 / 2"
          >
            <Search
              value={search}
              onChange={s => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("search", s);
                history.push({
                  path: location.path,
                  search: searchParams.toString()
                });
              }}
              maxWidth={[null, null, 350]}
              minWidth={310}
              width="100%"
            />
            <Box display="flex" justifyContent="flex-end">
              <SortMenu
                value={controls.sort}
                setValue={controls.setSort}
                open={controls.sortMenuOpen}
                setOpen={controls.setSortMenuOpen}
                options={controls.sortOptions}
                placeholder="Sort By"
              />
            </Box>
          </Box>
          <ListTemplate.InfiniteScrollObjectList
            Container={Stack}
            containerProps={{
              gridColumn: "1 / 2",
              maxWidth: 1138,
              width: "100%"
            }}
            loading={loading}
            objects={events}
            objectsPlural="faqs"
            hasMore={hasMore}
            fetchMore={fetchMore}
            endCursor={endCursor}
            Card={FAQ}
          />
          <TopicFilters topics={topics} filters={filters} setFilters={setFilters} />
        </Grid>
      </Group>
    </ListTemplate.ThemeProvider>
  );
}
