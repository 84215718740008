import Center from "@/tpo/Center";
import NothingToShow from "@/tpo/NothingToShow";
import { useTheme } from "styled-components";

export default function NoTestsAndOrders() {
  const theme = useTheme();

  return (
    <Center pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20}>
      <NothingToShow
        header="No results available"
        bodyCopy="Your patient currently has no available test results. Please check back once they have been received from the labs."
      />
    </Center>
  );
}
