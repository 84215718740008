import { forwardRef } from "react";

import Autocomplete from "./Autocomplete";
import InputWrapper from "./InputWrapper";

const formatPatient = patient => {
  if (!patient) {
    return null;
  }
  let user = patient.platformUserProfile.user;
  return `${user.firstName} ${user.lastName} (${user.correspondenceEmail})`;
};

export default function SelectPatient({ patients, value, onChange }) {
  const patientsById = (patients || []).reduce(
    (acc, patient) => ({
      ...acc,
      [patient.id]: patient
    }),
    {}
  );

  return (
    Object.keys(patients).length > 0 && (
      <Autocomplete
        options={Object.keys(patientsById).map(id => ({
          label: formatPatient(patientsById[id]),
          value: id
        }))}
        placeholder="Select patient"
        value={value}
        onChange={onChange}
        maxWidth={310}
        noOptionsFoundMessage="No patients found"
      />
    )
  );
}

export const SelectPatientField = forwardRef(
  ({ label, labelProps, error, patients, value, onChange }, ref) => (
    <InputWrapper
      labelProps={labelProps}
      label={label}
      error={error}
      input={<SelectPatient patients={patients} value={value} onChange={onChange} />}
    />
  )
);
