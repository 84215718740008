import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import Danger from "@/images/Danger";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import StripeCalloutBanner from "./ui/StripeCalloutBanner";

export default function UnsupportedLocale({ country }) {
  return (
    <>
      <Stack gap={30}>
        <Group gap={16} alignItems="center">
          <Danger size={40} />
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Unsupported location
          </Box>
        </Group>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          Your organisation is based in {country?.name} which is not supported by our payment
          provider. If you wish to use invoices or patient pay facilities please contact support to
          change your organisation locale.
        </Box>
      </Stack>
      <Stack gap={40}>
        <Stack gap={30}>
          <Box fontFamily="gilroyBold" fontSize={18}>
            Payment provider
          </Box>
          <StripeCalloutBanner fontSize={[14, 14, 16]} />
        </Stack>
        <ButtonV2
          color="green"
          rightIcon={<ChevronComponent opacity={1} />}
          type="button"
          size={["sm", "sm", "md"]}
          underline={false}
          mx="auto"
          as={ExternalTextLink}
          href={`mailto:support@omnos.me`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact support
        </ButtonV2>
      </Stack>
    </>
  );
}
