import { useContext, useState } from "react";

import Box from "@/components/Box";
import CustomerPage from "@/components/CustomerPage";
import CustomerTabs from "@/components/CustomerTabs";
import DataLoader from "@/components/DataLoader";
import { BlurredOverlay } from "@/components/patientView/BlurredOverlay";
import { THINGS_TO_AVOID, THINGS_TO_DO } from "@/core/constants";
import { theme } from "@/core/theme";
import { EXAMPLE_FOODS_QUERY, EXAMPLE_QUERY } from "@/graphql/tpo/content/queries";
import useDocTitle from "@/hooks/use-doc-title";
import { groupBy, sortBy } from "lodash";

import Accordion, { AccordionContext } from "./Accordion";
import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChartModal from "./ChartModal";
import ChevronComponent from "./Chevron";
import { CollapseableText } from "./CollapseableText";
import Group from "./Group";
import NothingToShow from "./NothingToShow";
import SegmentedControl from "./SegmentedControl";
import Stack from "./Stack";
import {
  RelatedResults,
  RelatedResultContext,
  USER_RESULT_QUERY,
  UserResultModelContent
} from "./UserResult";
import { useCardList } from "./UserSubsector";
import { ViewerContext } from "./Viewer";

function Foods({ foodsToDo, foodsToAvoid }) {
  const viewerContext = useContext(ViewerContext);
  const [list, setList] = useState("increase");
  const foodsToDoNoConflict = foodsToDo.filter(food => !foodsToAvoid.find(f => f.id === food.id));
  const { viewCard, cardOpen, setCardOpen } = useCardList();

  const increase = groupBy(foodsToDoNoConflict, food => food.parentCategory?.name || "Misc");
  const avoid = groupBy(foodsToAvoid, food => food.parentCategory?.name || "Misc");

  const items =
    list === "increase"
      ? sortBy(Object.entries(increase), "0")
      : sortBy(Object.entries(avoid), "0");

  const verb_group_map = {
    increase: THINGS_TO_DO,
    reduce: THINGS_TO_AVOID
  };

  return (
    <>
      <Box display="flex" justifyContent={["center"]}>
        <SegmentedControl
          data={[
            {
              label: "Increase",
              value: "increase"
            },
            {
              label: "Reduce",
              value: "reduce"
            }
          ]}
          onChange={setList}
          value={list}
          size={["xs", "xs", "sm"]}
        />
      </Box>
      <Box position="relative">
        <BlurredOverlay />
        <Stack gap={40}>
          {items?.length ? (
            items.map(([groupName, foods]) => (
              <Stack key={groupName} gap={20}>
                <Box fontFamily="gilroyBold" fontSize={28}>
                  {groupName || "Misc"}
                </Box>
                <RelatedResultContext.Provider
                  value={{
                    openModal: result => {
                      viewCard({
                        type: "results",
                        item: result
                      });
                    }
                  }}
                >
                  <Accordion
                    variant="separated"
                    chevronVariant="circle"
                    multiple
                    size={20}
                    gap={[10, 10, 20]}
                  >
                    {foods.map(food => (
                      <Accordion.Item key={food.id} value={food.id}>
                        <Accordion.Control
                          px={[15, 15, 20]}
                          py={[2, 2, 15]}
                          chevronIcon={
                            <Group alignItems="center" gap={10}>
                              <Box
                                fontFamily="gilroyBold"
                                fontSize={10}
                                textTransform="uppercase"
                                letterSpacing={2.8}
                              >
                                Why?
                              </Box>
                              <AccordionContext.Consumer>
                                {({ openState }) => (
                                  <ChevronComponent
                                    fill="dark"
                                    height={10}
                                    width={10}
                                    direction={openState.has(food.id) ? "bottom" : "right"}
                                    style={{
                                      margin: 0
                                    }}
                                  />
                                )}
                              </AccordionContext.Consumer>
                            </Group>
                          }
                        >
                          <Box fontFamily="gilroyBold" fontSize={18} lineHeight="130%">
                            {food.name}
                          </Box>
                        </Accordion.Control>
                        <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]}>
                          <DataLoader
                            query={EXAMPLE_QUERY}
                            variables={{
                              exampleId: food.id,
                              userId: viewerContext?.userId,
                              verbGroup: verb_group_map[list].replace(/\s/g, "_")
                            }}
                            render={({ example }) =>
                              example.relatedUserResults?.length ? (
                                <RelatedResults results={example.relatedUserResults} />
                              ) : (
                                <Box fontSize={16}>
                                  Linked to symptom questionnaire or lower level biomarkers
                                </Box>
                              )
                            }
                          ></DataLoader>
                        </Accordion.Panel>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </RelatedResultContext.Provider>
              </Stack>
            ))
          ) : (
            <Stack gap={20} py={60} px={[20, 20, 40]} bg="white" borderRadius={20}>
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
                No food suggestions available
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                {viewerContext?.userId
                  ? `Your patient has no suggested foods to ${list} currently`
                  : `You have no suggested foods to ${list} currently`}
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
      <ChartModal close={() => setCardOpen(undefined)} show={!!cardOpen?.type}>
        {viewCard ? (
          <ViewerContext.Consumer>
            {({ userId }) => (
              <DataLoader
                query={USER_RESULT_QUERY}
                variables={{
                  userId,
                  userResultId: cardOpen.item.id
                }}
                render={({ userResult, relatedGenes }) => (
                  <UserResultModelContent userResult={userResult} relatedGenes={relatedGenes} />
                )}
              />
            )}
          </ViewerContext.Consumer>
        ) : null}
      </ChartModal>
    </>
  );
}

export function FoodList({ children }) {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={EXAMPLE_FOODS_QUERY}
      variables={{
        userId: viewerContext?.userId
      }}
      render={({ foodsToDo, foodsToAvoid }) =>
        foodsToDo.length || foodsToAvoid.length ? (
          <>
            {children}
            <PanelBoxV2
              maxWidth={760}
              outer={{
                pt: theme.spacing.section.pt,
                pb: theme.spacing.section.pb,
                px: [20, 20, 0]
              }}
              stacked
              gap={[20, 20, 40]}
            >
              <Foods foodsToDo={foodsToDo} foodsToAvoid={foodsToAvoid} />
            </PanelBoxV2>
          </>
        ) : (
          <Center pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20}>
            <NothingToShow
              header="No foods available"
              bodyCopy={
                viewerContext?.userId
                  ? "Looks like your patient has no test results or questionnaire answers. The food list will be populated as soon as they have completed either."
                  : "This list will be populated when you have completed the symptoms questionnaire or have test results."
              }
            />
          </Center>
        )
      }
    />
  );
}

export default function FoodPage() {
  useDocTitle("Food list");
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  const isPartnerView = !!clientId;

  return (
    <ViewerContext.Provider
      value={{
        userId: clientId
      }}
    >
      {isPartnerView ? (
        <CustomerPage bg="haze">
          <FoodList />
        </CustomerPage>
      ) : (
        <CustomerPage
          bg="haze"
          jumbotronProps={{
            title: "Food list"
          }}
        >
          <CustomerTabs selectedTab="/food-list" />
          <FoodList>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                bg: "white",
                pt: theme.spacing.section.pt,
                pb: theme.spacing.section.pb,
                px: 40
              }}
            >
              <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]} mb={[22, 22, 32]}>
                Summary
              </Box>
              <CollapseableText fontSize={[14, 14, 16]}>
                {`Explore the following recommended foods to support your wellness journey.

          We have created a personalised food list by combining and analysing results from your self assessment as well as tests you have taken.

          The list contains foods to include, reduce, avoid, and eliminate. Explore the items within each food group to reveal your suggestions.`}
              </CollapseableText>
            </PanelBoxV2>
          </FoodList>
        </CustomerPage>
      )}
    </ViewerContext.Provider>
  );
}
