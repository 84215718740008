import { useCallback } from "react";

import { AccountOnHoldCheckoutMessage } from "@/components/AccountOnHold";
import Box from "@/components/Box";
import Loading from "@/components/Loading";
import { FadeTransition } from "@/components/animations/FadeTransition";
import PartnerDashboardBox from "@/components/boxes/PartnerDashboardBox";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION } from "@/graphql/organisations/mutations";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { useMutation } from "@apollo/client";

export default function CreateOrderInvoiceFields({ canSubmit, loading }) {
  const { basket } = useOrganisationBasketContext();
  const { organisation } = useOrganisationContext();

  const [updateAutomaticInvoicingSettingMutation] = useMutation(
    UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION
  );

  const updateAutomaticInvoicingSetting = useCallback(
    async variables => {
      if (!organisation?.id) {
        return;
      }
      try {
        await updateAutomaticInvoicingSettingMutation({
          variables: {
            input: {
              id: parseInt(organisation.id),
              ...variables
            }
          }
        });
      } catch (e) {}
    },
    [updateAutomaticInvoicingSettingMutation, organisation]
  );

  const handleToggleAutomaticInvoicing = useCallback(
    checked => {
      updateAutomaticInvoicingSetting({
        automaticInvoicingEnabled: checked
      });
    },
    [updateAutomaticInvoicingSetting]
  );

  if (organisation?.accountOnHold) {
    return <AccountOnHoldCheckoutMessage />;
  }

  return (
    <PartnerDashboardBox>
      <Stack gap={[24, 24, 28]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Payment Details
        </Box>
        <FadeTransition in={!organisation?.automaticInvoicingEnabled}>
          <Stack gap={[20, 20, 40]}>
            {basket?.purchaseAsStock ? (
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                By ordering these kits as stock you will need to have automatic invoice payment
                enabled before you can register a kit to a patient. Automatic invoicing means that
                payment will be withdrawn from your account at the invoice due date.
              </Box>
            ) : (
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                Since you've connected your business bank account, we'll create an invoice that you
                need to pay within 30 days of placing this order.
                <br />
                For a more seamless payment integration you can opt in to have invoices
                automatically taken from your account when they are due. If you opt in we will give
                you an extra 5% discount on your first order.
              </Box>
            )}
            {!!organisation?.stripeCustomerAccount?.paymentMethods?.length && (
              <Checkbox
                label="Allow payment to be automatically taken after 30 days"
                checked={organisation?.automaticInvoicingEnabled}
                onChange={({ target }) => handleToggleAutomaticInvoicing(target.checked)}
              />
            )}
          </Stack>
        </FadeTransition>
        <FadeTransition in={organisation.automaticInvoicingEnabled}>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Because you linked your business bank account and set up automatic payments, we'll make
            an invoice that gets automatically withdrawn 30 days after you place the order.
          </Box>
        </FadeTransition>
      </Stack>
      <Center>
        <ButtonV2
          color="green"
          rightIcon={
            loading ? (
              <Loading
                minHeight={20}
                size={10}
                color="white"
                style={{
                  marginLeft: 5
                }}
              />
            ) : (
              <ChevronComponent />
            )
          }
          type="submit"
          disabled={!canSubmit || loading}
        >
          place order
        </ButtonV2>
      </Center>
    </PartnerDashboardBox>
  );
}
