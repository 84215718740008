import { DASHBOARD_HOME_URL, getClientManagementTabUrl } from "@/core/urls";

import AccountMenuHeader from "./AccountsMenuHeader";
import AccountMenuItem from "./AccountsMenuItem";

export default function AccountsMenuWards({ user, showHeader }) {
  const userDetails = user.platformUserProfile?.user;

  return (
    <>
      {showHeader && <AccountMenuHeader>Omnos</AccountMenuHeader>}
      <AccountMenuItem to={DASHBOARD_HOME_URL}>
        {userDetails.firstName} {userDetails.lastName}
      </AccountMenuItem>
      {user.platformUserProfile?.wards.map(ward => (
        <AccountMenuItem
          key={ward.id}
          to={getClientManagementTabUrl(ward.user.id, "tests")}
          newTab={true}
        >
          {ward.user.firstName} {ward.user.lastName}
        </AccountMenuItem>
      ))}
    </>
  );
}
