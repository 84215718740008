import { useEffect } from "react";

import { useAuthContext } from "@/components/AuthProvider";
import { useNotificationsContext } from "@/contexts/Notification";
import { USER_NOTIFICATION_FIELDS } from "@/graphql/notifications/types";
import { gql, useQuery } from "@apollo/client";

const ON_LOGIN_QUERY = gql`
  query OnLoginQuery {
    onLogin {
      ...UserNotificationFields
    }
  }
  ${USER_NOTIFICATION_FIELDS}
`;

export default function OnLogin() {
  /**
   * Event: when the partner logs in to for the first time, or the app loads with the user
   * logged in already for the first time
   */

  const { user } = useAuthContext();

  const api = useNotificationsContext();
  const { data } = useQuery(ON_LOGIN_QUERY, {
    skip: !user
  });

  useEffect(() => {
    if (data && data.onLogin?.length) {
      // internally it checks if the notifications are already in the list
      api.addNotifications(data.onLogin);
    }
  }, [api, data]);

  return null;
}
