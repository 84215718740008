import { useFormContext } from "react-hook-form";

import ButtonV2, { IndicativeButton } from "@/v2/Buttons";

import ChevronComponent from "./Chevron";

export default function EditFormButtons({ cancelButtonProps, saveButtonProps }) {
  const { reset, submitButtonRef } = useFormContext();

  return (
    <>
      <ButtonV2
        type="button"
        color="red"
        rightIcon={<ChevronComponent />}
        onClick={() => reset()}
        size={["sm", "sm", "md"]}
        {...cancelButtonProps}
      >
        Cancel
      </ButtonV2>
      <IndicativeButton
        rightIcon={<ChevronComponent />}
        ref={submitButtonRef}
        defaultColor="green"
        pendingColor="#0cf970"
        successColor="#2ecc71"
        failureColor="error"
        size={["sm", "sm", "md"]}
        {...saveButtonProps}
      >
        save
      </IndicativeButton>
    </>
  );
}
