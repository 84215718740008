import { PAGE_INFO_FIELDS } from "@/graphql/pagination";
import gql from "graphql-tag";

export const ORGANISATION_COMMISSIONS_LIST_QUERY = gql`
  query OrganisationCommissionsListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
  ) {
    organisationCommissionsPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      items {
        id
        dateOnInvoice
        checkoutDate
        user {
          id
          firstName
          lastName
          email
        }
        sourceBasket {
          id
          payee
          paymentStatus
          currencyName
          currencySymbol
          basketTotalCommission
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_PAYOUTS_QUERY = gql`
  query OrganisationPayoutsQuery(
    $organisation: Int!
    $startingAfter: String
    $endingBefore: String
  ) {
    organisationPayoutsPagination(
      organisation: $organisation
      startingAfter: $startingAfter
      endingBefore: $endingBefore
    ) {
      items {
        id
        initiated
        estPayout
        description
        status
        amount
        currencySymbol
      }
      hasMore
    }
  }
`;

export const ORGANISATION_ACCOUNT_BALANCE_QUERY = gql`
  query OrganisationAccountBalanceQuery($organisation: Int!) {
    connectedAccountBalances(organisation: $organisation) {
      id
      currency
      currencySymbol
      pending
      available
    }
    organisationNextPayoutDate(organisation: $organisation)
  }
`;
