import Box from "@/components/Box";
import { EXPERTS_URL, PARTNERS_PRACTITIONER_URL, PARTNERS_SIGNIN_URL } from "@/core/urls";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

const boxProps = {
  borderRadius: 20,
  py: 60,
  px: 40,
  gap: 40,
  alignItems: "center"
};

const titleProps = {
  fontFamily: "gilroyBold",
  fontSize: [28],
  textAlign: "center"
};

const bodyCopyProps = {
  fontFamily: "gilroyMedium",
  fontSize: [14, 14, 16],
  textAlign: "center",
  maxWidth: 266
};

export default function PractitionerSupportRequired({ price, testProduct }) {
  let params = new URLSearchParams();
  params.append("supported_tests", testProduct.id);

  return (
    <>
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        This test is only available through an Omnos verified healthcare provider.
      </Box>
      <Group justifyContent="space-between" alignItems="center" pt={20}>
        <GilroyFontCurrency value={price} />
        <ButtonV2
          as={LinkWrapper}
          color="dark"
          size={["sm", "sm", "md"]}
          withChevron
          to={`${EXPERTS_URL}?${params.toString()}`}
        >
          Find a practitioner
        </ButtonV2>
      </Group>
      <Group flexWrap="wrap" gap={28} justifyContent="space-between" pt={40}>
        <Stack bg="partners" flexGrow={1} {...boxProps}>
          <Stack gap={20}>
            <Box {...titleProps}>Unlock this test</Box>
            <Box
              {...bodyCopyProps}
              style={{
                textWrap: "wrap"
              }}
            >
              To purchase this test, sign in or register to become a practitioner
            </Box>
          </Stack>
          <Stack gap={16}>
            <ButtonV2
              withChevron
              color="black"
              sx={{
                color: "white"
              }}
              size={["sm", "sm", "md"]}
              as={LinkWrapper}
              to={PARTNERS_SIGNIN_URL}
            >
              sign in
            </ButtonV2>
            <ButtonV2
              withChevron
              color="white"
              size={["sm", "sm", "md"]}
              sx={{
                color: "black"
              }}
              as={LinkWrapper}
              to={PARTNERS_PRACTITIONER_URL}
            >
              register now
            </ButtonV2>
          </Stack>
        </Stack>
        <Stack bg="purple" flexGrow={1} {...boxProps}>
          <Stack gap={20}>
            <Box color="white" {...titleProps}>
              Find a practitioner
            </Box>
            <Box
              color="white"
              {...bodyCopyProps}
              style={{
                textWrap: "wrap"
              }}
            >
              Get support from one of our verified healthcare experts who specialise in this test
            </Box>
          </Stack>
          <ButtonV2
            withChevron
            color="white"
            size={["sm", "sm", "md"]}
            sx={{
              color: "black",
              mt: "auto"
            }}
            as={LinkWrapper}
            to={`${EXPERTS_URL}?${params.toString()}`}
          >
            search now
          </ButtonV2>
        </Stack>
      </Group>
    </>
  );
}
