import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import AuthRoute from "@/components/AuthRoute";
import { ERROR_404_URL } from "@/core/urls";

import OrderDetailPage from "./OrderDetailPage";
import OrderHistoryPage from "./OrderHistoryPage";
import OrderProcessingPage from "./OrderProcessingPage";

export default function Routes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <AuthRoute path={match.path} exact>
        <OrderHistoryPage />
      </AuthRoute>
      <Route path={`${match.path}/:id/processing`} component={OrderProcessingPage} exact />
      <Route path={`${match.path}/:id`} component={OrderDetailPage} exact />
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
