import { useCallback, useEffect, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { useAppState } from "@/components/AppStateProvider";
import Box from "@/components/Box";
import CircleButton from "@/components/CircleButton";
import DataLoader from "@/components/DataLoader";
import Grid from "@/components/Grid";
import GridTestProductCard from "@/components/GridTestProductCard";
import Loading from "@/components/Loading";
import ProductFilters from "@/components/ProductFilters";
import StackedTestProductCard from "@/components/StackedTestProductCard";
import { useLocalesContext } from "@/contexts/Locale";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { OUT_OF_STOCK } from "@/core/constants";
import { TOGGLE_TEST_PRODUCT_FAVOURITE_MUTATION } from "@/graphql/accounts";
import {
  PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
  PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY,
  SHOP_LISTING_FILTERS_QUERY
} from "@/graphql/shop";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import useUserProfile from "@/hooks/useUserProfile";
import LifeStyleIcon from "@/images/LifeStyleIcon";
import { PanelBoxV2 } from "@/tpo/Boxes";
import { GilroyFontCurrency } from "@/tpo/Currency";
import GridIcon from "@/tpo/GridIcon";
import Group from "@/tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "@/tpo/InfiniteScroll";
import ListIcon from "@/tpo/ListIcon";
import SortMenu from "@/tpo/SortMenu";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import { Search } from "@/tpo/TextInput";
import ListPageTemplate from "@/tpo/shop/ListPageTemplate";
import useProductFilters from "@/tpo/shop/hooks/useProductFilters";
import useTestProducts from "@/tpo/shop/hooks/useTestProducts";
import ButtonV2, { ActionIcon } from "@/v2/Buttons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTheme } from "styled-components";

import OrganisationTabs from "../OrganisationTabs";
import LocaleSwitcher from "./ui/LocaleSwitcher";

function TestProductListContent({ sampleTypes, categories, testingServices }) {
  const theme = useTheme();

  const { platformUserProfile } = useUserProfile();

  const history = useHistory();
  const location = useLocation();

  const { locale } = useLocalesContext();
  const { organisation } = useOrganisationContext();
  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "sort_order"
      },
      {
        label: "A - Z",
        value: "name"
      },
      {
        label: "Z - A",
        value: "-name"
      },
      {
        label: "Price Asc",
        value: "trade_current_price"
      },
      {
        label: "Price Desc",
        value: "-trade_current_price"
      }
    ]
  });

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      gender: "all",
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      gender: ["all", "female", "male"],
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    },
    filterSelected: {
      gender: ["male", "female"]
    }
  });

  const { addProductToBasket, basket, hasBasketGotProduct, removeProductFromBasket } =
    useOrganisationBasketContext();

  const [filterByFavourites, setFilterByFavourites] = useState(false);

  const {
    products: { items, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY,
    queryVariables: {
      organisation: organisation?.id,
      filterByFavourites
    },
    connectionsFieldName: "practitionerTestProductConnections",
    validatedFilters,
    search: debouncedSearch,
    sort: controls.sort,
    isoCode: locale?.isoCode,
    paymentIsoCode: basket?.paymentLocale?.isoCode,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const { selectTestProductId } = useTestProductModalContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const handleAddToBasket = useCallback(
    testProduct => {
      if (testProduct.options?.length || (!basket.purchaseAsStock && testProduct.addons?.length)) {
        selectTestProductId(testProduct.id);
        return;
      }
      addProductToBasket(testProduct.id);
      selectTestProductId(null);
      setBasketOpen(true);
    },
    [addProductToBasket, selectTestProductId, setBasketOpen, basket]
  );

  const handleRemoveFromBasket = useCallback(
    testProduct => {
      const line = basket.productLineItems.find(line => line.product.id === testProduct.id);
      // clinic location should not be present within the context this function is called
      // but add anyway to be explicit
      removeProductFromBasket(line.product.id, line.productOption?.id, line.clinicLocation?.id);
    },
    [removeProductFromBasket, basket]
  );

  const [toggleFavouriteMutation] = useMutation(TOGGLE_TEST_PRODUCT_FAVOURITE_MUTATION);

  const toggleFavourite = useCallback(
    testProductId => {
      toggleFavouriteMutation({
        variables: {
          testProductId
        }
      });
    },
    [toggleFavouriteMutation]
  );

  const [view, setView] = useState("grid");
  const [card, setCard] = useState("grid");

  useEffect(() => {
    function handleMobile() {
      if (window.innerWidth <= 960 && card != "grid") {
        setCard("grid");
      } else if (window.innerWidth > 960 && view === "list" && card !== "list") {
        setCard("list");
      }
    }
    window.addEventListener("resize", handleMobile);
    return () => {
      window.removeEventListener("resize", handleMobile);
    };
  }, [card, setCard, view]);

  const infiniteScrollContent = (
    <InfiniteScrollContext.Consumer>
      {({ itemsList, setBottomElement }) => (
        <>
          {itemsList.map((testProduct, idx) => {
            let addText = "Add";
            if (
              testProduct.options?.length ||
              (!basket?.purchaseAsStock && testProduct.addons?.length)
            ) {
              addText = "Options";
            } else if (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)) {
              addText = "Added";
            } else if (testProduct.status === OUT_OF_STOCK) {
              addText = "Out of stock";
            }

            const onAdd =
              basket?.purchaseAsStock ||
              (!basket?.purchaseAsStock && !hasBasketGotProduct(testProduct.id))
                ? e => {
                    e.onAdd = true;
                    if (basket?.purchaseAsStock || !hasBasketGotProduct(testProduct.id)) {
                      handleAddToBasket(testProduct);
                    }
                  }
                : undefined;

            const addDisabled =
              testProduct.status === OUT_OF_STOCK ||
              (!basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id));

            const onRemove =
              !basket?.purchaseAsStock && hasBasketGotProduct(testProduct.id)
                ? e => {
                    e.onRemove = true;
                    handleRemoveFromBasket(testProduct);
                  }
                : undefined;

            const priceSection = (
              <Group
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
                mt="auto"
                gap={28}
              >
                <Stack>
                  <Group>
                    {testProduct.tradeFullPrice !== undefined &&
                      testProduct.tradeFullPrice > testProduct.tradeCurrentPrice && (
                        <GilroyFontCurrency
                          value={testProduct.tradeFullPrice}
                          symbol={basket?.currencySymbol}
                          data-testid="testProductListCard:tradeFullPrice"
                          strikethrough
                          color="dark"
                        />
                      )}
                    <GilroyFontCurrency
                      value={testProduct?.tradeCurrentPrice}
                      symbol={basket?.currencySymbol}
                      data-testid="testProductListCard:tradeCurrentPrice"
                      color={
                        testProduct.tradeFullPrice !== undefined &&
                        testProduct.tradeFullPrice > testProduct.tradeCurrentPrice
                          ? "red"
                          : "dark"
                      }
                    />
                  </Group>
                  <GilroyFontCurrency
                    prefix="RRP"
                    color="midGrey"
                    fontSize={[16, 16, 18]}
                    symbol={basket?.currencySymbol}
                    value={testProduct.exvatFullPrice}
                    data-testid="testProductListCard:retailFullPrice"
                  />
                </Stack>
                {onAdd && (
                  <ButtonV2
                    withChevron
                    onClick={onAdd}
                    disabled={addDisabled}
                    color={addDisabled ? "midGrey" : "green"}
                    size={["sm", "sm", "md"]}
                  >
                    {addDisabled === OUT_OF_STOCK ? "Out of stock" : addText}
                  </ButtonV2>
                )}
                {onRemove && (
                  <ButtonV2 onClick={onRemove} color="red" withChevron size={["sm", "sm", "md"]}>
                    Remove
                  </ButtonV2>
                )}
              </Group>
            );

            return card === "grid" ? (
              <GridTestProductCard.Card
                onClick={e => {
                  if (e.onAdd || e.onRemove) return;
                  selectTestProductId(testProduct.id);
                }}
                ref={idx === itemsList.length - 1 ? setBottomElement : null}
                data-component-name="TestProductListCard"
                key={testProduct.id}
              >
                <GridTestProductCard.Header
                  iconSize={[40, 40, 80]}
                  iconType={testProduct.content.iconType}
                >
                  <ActionIcon
                    size={["sm", "sm", "md"]}
                    bg="haze"
                    variant="circle"
                    onClick={e => {
                      e.stopPropagation();
                      toggleFavourite(testProduct.id);
                    }}
                  >
                    <LifeStyleIcon
                      size={20}
                      outline={
                        !platformUserProfile?.testProductFavouriteIds?.includes(testProduct.id)
                      }
                      filled={platformUserProfile?.testProductFavouriteIds?.includes(
                        testProduct.id
                      )}
                      color={
                        platformUserProfile?.testProductFavouriteIds?.includes(testProduct.id)
                          ? theme.colors.genetics
                          : "black"
                      }
                    />
                  </ActionIcon>
                  <CircleButton />
                </GridTestProductCard.Header>
                <Stack gap={16} pb={60} mt={16}>
                  <GridTestProductCard.Content
                    id={testProduct.id}
                    name={testProduct.name}
                    addons={testProduct.addons}
                    laboratory={testProduct.laboratory}
                    shortDescription={testProduct.content.shortDescription}
                    testType={testProduct.content.testType}
                    categories={testProduct.content.categories}
                    centrifuge={testProduct.content.centrifuge}
                  />
                </Stack>

                {priceSection}
              </GridTestProductCard.Card>
            ) : (
              <StackedTestProductCard.Card
                onClick={e => {
                  if (e.onAdd || e.onRemove) return;
                  selectTestProductId(testProduct.id);
                }}
                ref={idx === itemsList.length - 1 ? setBottomElement : null}
                data-component-name="TestProductListCard"
                key={testProduct.id}
              >
                <Stack gap={16} pb={60}>
                  <StackedTestProductCard.Header
                    iconSize={[40, 40, 80]}
                    iconType={testProduct.content.iconType}
                    id={testProduct.id}
                    name={testProduct.name}
                    addons={testProduct.addons}
                    laboratory={testProduct.laboratory}
                    centrifuge={testProduct.content.centrifuge}
                  >
                    <ActionIcon
                      size={["sm", "sm", "md"]}
                      bg="haze"
                      variant="circle"
                      onClick={e => {
                        e.stopPropagation();
                        toggleFavourite(testProduct.id);
                      }}
                    >
                      <LifeStyleIcon
                        size={20}
                        outline={
                          !platformUserProfile?.testProductFavouriteIds?.includes(testProduct.id)
                        }
                        filled={platformUserProfile?.testProductFavouriteIds?.includes(
                          testProduct.id
                        )}
                        color={
                          platformUserProfile?.testProductFavouriteIds?.includes(testProduct.id)
                            ? theme.colors.genetics
                            : "black"
                        }
                      />
                    </ActionIcon>
                    <CircleButton />
                  </StackedTestProductCard.Header>
                  <StackedTestProductCard.Content
                    shortDescription={testProduct.content.shortDescription}
                    testType={testProduct.content.testType}
                    categories={testProduct.content.categories}
                  />
                </Stack>
                {priceSection}
              </StackedTestProductCard.Card>
            );
          })}
        </>
      )}
    </InfiniteScrollContext.Consumer>
  );

  const content =
    view === "grid" ? (
      <Grid
        gridTemplateColumns={[
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)"
        ]}
        gap={16}
      >
        {infiniteScrollContent}
      </Grid>
    ) : (
      <Stack gap={16}>{infiniteScrollContent}</Stack>
    );

  return (
    <ListPageTemplate
      // TODO - use the standard url approach involving core.urls
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests",
        discounts: "/partners/dashboard/shop/discounts"
      }}
      switcherOptions={[
        {
          label: "tests",
          value: "tests"
        },
        {
          label: "supplements",
          value: "supplements"
        },
        {
          label: "discounts",
          value: "discounts"
        }
      ]}
      bg="haze"
      tab="tests"
      title="Find a matching test"
      subtitle="To ensure you see the correct tests available for your patient, please select the country where your patient is located. This will help you view and order the tests that are available and compliant with the regulations in their region."
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <Stack gap={20}>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            justifyContent={[null, null, "space-between"]}
            flexWrap="wrap"
          >
            <Group gap={10}>
              <ProductFilters
                allFilters={allFilters}
                filtersTitle="Product Filters"
                drawerBg="dark"
                validatedFilters={validatedFilters}
                toggleFilter={toggleFilter}
              />
              <LocaleSwitcher />
              <ActionIcon
                bg="dark"
                size={["sm", "sm", "md"]}
                variant="circle"
                onClick={() => setFilterByFavourites(!filterByFavourites)}
              >
                <LifeStyleIcon
                  size={20}
                  outline={!filterByFavourites}
                  filled={filterByFavourites}
                  color={filterByFavourites ? theme.colors.genetics : "white"}
                />
              </ActionIcon>
            </Group>
            <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
              <Search
                value={search}
                onChange={s => {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("search", s);
                  history.push({
                    path: location.path,
                    search: searchParams.toString()
                  });
                }}
                maxWidth={[null, null, 350]}
                minWidth={310}
                width="100%"
              />
              <Box display="flex" justifyContent="flex-end" gap={8}>
                <SortMenu
                  value={controls.sort}
                  setValue={controls.setSort}
                  open={controls.sortMenuOpen}
                  setOpen={controls.setSortMenuOpen}
                  options={controls.sortOptions}
                  placeholder="Sort By"
                />
                <ActionIcon
                  bg="white"
                  size={["sm", "sm", "md"]}
                  variant="circle"
                  onClick={() => {
                    const newView = view === "grid" ? "list" : "grid";
                    setView(newView);

                    if (newView === "grid") {
                      setCard("grid");
                    } else if (window.innerWidth <= 960) {
                      setCard("grid");
                    } else {
                      setCard("list");
                    }
                  }}
                >
                  {view === "grid" ? <ListIcon fill="black" /> : <GridIcon fill="black" />}
                </ActionIcon>
              </Box>
            </Box>
          </Box>
          {selectedFilters}
        </Stack>
        <Spacer py={[2, 2, 20]} />
        <InfiniteScroll
          loader={<Loading />}
          hasMore={hasMore}
          loading={loading}
          next={() => {
            if (endCursor) {
              fetchMore({
                variables: {
                  after: endCursor,
                  first: 10
                }
              });
            }
          }}
          items={items}
        >
          {content}
        </InfiniteScroll>
      </PanelBoxV2>
    </ListPageTemplate>
  );
}

export default function TestProductListPage() {
  const location = useLocation();
  const slug = new URLSearchParams(location.search).get("slug") || "";

  const { selectTestProductId } = useTestProductModalContext();
  const { basket } = useOrganisationBasketContext();
  const { organisation } = useOrganisationContext();
  const { basketOpen } = useAppState();

  const [fetchProduct] = useLazyQuery(PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY, {
    onCompleted: data => {
      if (data?.practitionerTestProduct?.id && !basketOpen) {
        selectTestProductId(data.practitionerTestProduct.id);
      }
    }
  });

  useEffect(() => {
    if (slug) {
      fetchProduct({
        variables: {
          slug,
          paymentIsoCode: basket?.paymentLocale?.isoCode,
          organisation: organisation?.id
        }
      });
    }
  }, [slug, fetchProduct, basket?.paymentLocale?.isoCode, organisation?.id]);

  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        variables={{
          includeFilters: ["Both", "B2B"]
        }}
        render={({ sampleTypes, categories, testingServices, userTestRecommendations }) => {
          return (
            <TestProductListContent
              sampleTypes={sampleTypes}
              categories={categories.map(cat => cat.name)}
              testingServices={testingServices}
              userTestRecommendations={userTestRecommendations}
            />
          );
        }}
      />
    </>
  );
}
