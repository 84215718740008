import { useState } from "react";

import Box from "@/components/Box";
import FilterIcon from "@/images/FilterIcon";
import Checkbox from "@/tpo/Checkbox";
import Group from "@/tpo/Group";
import Menu from "@/tpo/Menu";
import Stack from "@/tpo/Stack";
import { ActionIcon } from "@/v2/Buttons";
import ButtonV2 from "@/v2/Buttons";
import { useTheme } from "styled-components";

function TopicFiltersStack({ filters, setFilters, topics, minWidth, maxWidth }) {
  return (
    <>
      <Group justifyContent="space-between">
        <Box fontFamily="gilroyBold" fontSize={32}>
          Filter
        </Box>
        <ButtonV2
          variant="link"
          textTransform="unset"
          textDecoration="underline"
          letterSpacing={0}
          sx={{
            fontSize: 16,
            fontFamily: "gilroyRegular",
            p: 0
          }}
          onClick={() => {
            setFilters(new Set([]));
          }}
        >
          Reset
        </ButtonV2>
      </Group>
      <Stack gap={16}>
        <Box
          fontFamily="gilroyBold"
          color="midGrey"
          letterSpacing={2.8}
          fontSize={12}
          textTransform="uppercase"
          mb={2}
        >
          Topics
        </Box>
        {topics?.map(topic => (
          <Checkbox
            key={topic.id}
            label={topic.name}
            styles={{
              checkbox: {
                size: "sm"
              }
            }}
            checked={filters.has(topic.id)}
            onChange={e => {
              const checked = e.target.checked;
              if (checked) {
                setFilters(new Set([...filters, topic.id]));
              } else {
                setFilters(() => {
                  const newFilters = new Set(filters);
                  newFilters.delete(topic.id);
                  return newFilters;
                });
              }
            }}
          />
        ))}
      </Stack>
    </>
  );
}

export function TopicFiltersMobile({ filters, setFilters, topics, minWidth, maxWidth, ...props }) {
  const [listMenuOpen, setListMenuOpen] = useState(false);

  const theme = useTheme();

  return (
    <>
      <Menu opened={listMenuOpen} onChange={setListMenuOpen} placement="bottom-end">
        <Menu.Target>
          <ActionIcon
            bg={listMenuOpen ? "dark" : "white"}
            variant="circle"
            size="sm"
            sx={{
              display: ["flex", "flex", "none"],
              ...props
            }}
          >
            <FilterIcon fill={listMenuOpen ? "white" : theme.colors.dark} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown
          as={Stack}
          mt={2}
          p={20}
          zIndex={2}
          minWidth={minWidth}
          maxWidth={maxWidth}
          mx="auto"
          borderRadius={10}
          pb={60}
        >
          <TopicFiltersStack filters={filters} setFilters={setFilters} topics={topics} />
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

export function TopicFiltersDesktop({ filters, setFilters, topics, minWidth, maxWidth, ...props }) {
  return (
    <Stack
      gap={40}
      border="1px solid black"
      p={20}
      pb={60}
      borderRadius={20}
      minWidth={minWidth}
      maxWidth={maxWidth}
      mb="auto"
      display={["none", "none", "flex"]}
      {...props}
    >
      <TopicFiltersStack
        filters={filters}
        setFilters={setFilters}
        topics={topics}
        minWidth={minWidth}
        maxWidth={maxWidth}
      />
    </Stack>
  );
}
