import { ToBox } from "@/components/Box";
import { RELEASE_IMPERSONATION_URL } from "@/core/urls";
import Masks from "@/images/masks.svg?react";

export default function ReleaseButton({ user }) {
  return (
    <ToBox
      borderRadius="50%"
      as="button"
      borderWidth="2px"
      borderStyle="solid"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={() => (window.location.href = RELEASE_IMPERSONATION_URL)}
      bg="red"
      borderColor="white"
      size={30}
      ml="10px"
    >
      <Masks
        fill="white"
        width={20}
        title={`You are impersonating user ${user?.email}.\nClick here to release this user.`}
      />
    </ToBox>
  );
}
