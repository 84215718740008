import { useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import Box from "@/components/Box";
import Grid from "@/components/Grid";
import ProductFilters from "@/components/ProductFilters";
import { EXPERTS_OVERVIEW_URL, EXPERTS_SUPPORTED_TESTS_URL, EXPERTS_URL } from "@/core/urls";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "@/tpo/InfiniteScroll";
import SegmentedControl from "@/tpo/SegmentedControl";
import SortMenu from "@/tpo/SortMenu";
import Stack from "@/tpo/Stack";
import { Search } from "@/tpo/TextInput";
import { useTheme } from "styled-components";

import CountrySelect from "../CountrySelectWithIcons";

function ListTemplateContainer({ children }) {
  const theme = useTheme();

  return (
    <Center pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} bg="haze" px={20}>
      <Stack width="100%" gap={[20, 20, 40]} maxWidth={1280}>
        {children}
      </Stack>
    </Center>
  );
}

function ListTemplateHeader({ header, subheader }) {
  return (
    <Center stacked gap={28} mx="auto" pt={[40, 40, 80]} pb={60}>
      <Box fontFamily="gilroyBold" fontSize={[40, 40, 68]} maxWidth={1024} textAlign="center">
        {header}
      </Box>
      <Box fontFamily="gilroyRegular" fontSize={[24, 24, 32]} maxWidth={720} textAlign="center">
        {subheader}
      </Box>
    </Center>
  );
}

function ListTemplateSwitcher({ children }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box pt={[85, 85, 100]} px={20}>
      <Box bg="white" pt={[60, 60, 80]}>
        <Center>
          <SegmentedControl
            data={[
              {
                label: "overview",
                value: EXPERTS_OVERVIEW_URL
              },
              {
                label: "practitioners",
                value: EXPERTS_URL
              },
              {
                label: "supported tests",
                value: EXPERTS_SUPPORTED_TESTS_URL
              }
            ]}
            onChange={v => history.push(v)}
            value={location.pathname}
            size={["xs", "xs", "sm"]}
            bg="haze"
          />
        </Center>
      </Box>
      {children}
    </Box>
  );
}

function ListTemplateFilters({
  search,
  allFilters,
  validatedFilters,
  toggleFilter,
  controls,
  selectedFilters,
  filtersTitle,
  children
}) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  return (
    <Stack gap={[20]}>
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={20}
        justifyContent={[null, null, "space-between"]}
        flexWrap="wrap"
      >
        <Group gap={10}>
          <ProductFilters
            allFilters={allFilters}
            validatedFilters={validatedFilters}
            drawerBg="haze"
            borderColor="dark"
            color={theme.colors.dark}
            filtersTitle={filtersTitle}
            toggleFilter={toggleFilter}
          />
          {children}
        </Group>
        <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
          <Search
            placeholder="Search"
            value={search}
            onChange={s => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("search", s);
              history.push({
                path: location.path,
                search: searchParams.toString()
              });
            }}
            maxWidth={[null, null, 350]}
            minWidth={310}
            width="100%"
          />
          <Box display="flex" justifyContent="flex-end">
            <SortMenu
              value={controls.sort}
              setValue={controls.setSort}
              open={controls.sortMenuOpen}
              setOpen={controls.setSortMenuOpen}
              options={controls.sortOptions}
              placeholder="Sort By"
            />
          </Box>
        </Box>
      </Box>
      {selectedFilters}
    </Stack>
  );
}

ListTemplateFilters.defaultProps = {
  filtersTitle: "Practitioner"
};

function ListTemplateGrid({ fetchMore, hasMore, endCursor, loading, items, ItemCard }) {
  return (
    <Grid
      gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
      gridColumnGap={16}
      gridRowGap={16}
    >
      <InfiniteScroll
        hasMore={hasMore}
        next={() =>
          fetchMore({
            variables: {
              after: endCursor,
              first: 10
            }
          })
        }
        loading={loading}
      >
        <InfiniteScrollContext.Consumer>
          {({ setBottomElement }) => (
            <>
              {items?.slice(0, -1)?.map(expert => (
                <ItemCard key={expert.id} {...expert} />
              ))}
              {items?.slice(-1)?.map(expert => (
                <ItemCard key={expert.id} ref={setBottomElement} {...expert} />
              ))}
            </>
          )}
        </InfiniteScrollContext.Consumer>
      </InfiniteScroll>
    </Grid>
  );
}

export default Object.assign({
  Container: ListTemplateContainer,
  Switcher: ListTemplateSwitcher,
  Header: ListTemplateHeader,
  Filters: ListTemplateFilters,
  Grid: ListTemplateGrid
});
