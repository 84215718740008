import Box from "@/components/Box";
import { theme } from "@/core/theme";
import { borderRadius } from "styled-system";

import { PanelBoxV2 } from "./Boxes";

export function TextBox({ children, size }) {
  let maxWidth = 0;
  if (size === "xs") {
    maxWidth = 600;
  } else if (size === "s") {
    maxWidth = 760;
  }

  return (
    <PanelBoxV2
      maxWidth={maxWidth}
      bg="white"
      outer={{
        px: [20, 20, 0]
      }}
      inner={{
        display: "flex",
        flexDirection: "column",
        gap: [40]
      }}
    >
      {children}
    </PanelBoxV2>
  );
}

export function ChartBox({ children, size }) {
  let maxWidth = 0;
  let px = [];

  if (size === "s") {
    maxWidth = 760;
  } else if (size === "m") {
    maxWidth = 1020;
    px = [20, 20, 130];
  }

  return (
    <PanelBoxV2
      maxWidth={maxWidth}
      outer={{
        bg: "white"
      }}
      inner={{
        px
      }}
    >
      {children}
    </PanelBoxV2>
  );
}

export function ChartDetailModalLayout({ chart, text, accordion }) {
  return (
    <>
      <Box bg="white">
        <PanelBoxV2
          maxWidth={760}
          inner={{
            borderRadius: 5
          }}
          outer={{
            px: [20, 20, 40],
            pt: 0,
            pb: [60, 60, 80]
          }}
        >
          {chart}
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={600}
          bg="white"
          outer={{
            px: [20, 20, 40],
            pt: 0,
            pb: theme.spacing.section.pb
          }}
          inner={{
            display: "flex",
            flexDirection: "column",
            gap: [10, 10, 20]
          }}
        >
          {text}
        </PanelBoxV2>
      </Box>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40],
          bg: "haze"
        }}
      >
        {accordion}
      </PanelBoxV2>
    </>
  );
}

export function ChartDetailLayout({ chart, text, accordion }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: theme.spacing.section.pt,
          px: [20, 20, 40]
        }}
        inner={{
          bg: "white",
          pt: theme.spacing.section.pt,
          borderRadius: 5
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          inner={{
            borderRadius: 5,
            px: [20, 20, 40],
            pb: [60, 60, 80]
          }}
        >
          {chart}
        </PanelBoxV2>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: 0,
            px: [20, 20, 40],
            pb: theme.spacing.section.pb
          }}
          inner={{
            display: "flex",
            flexDirection: "column",
            gap: [10, 10, 20]
          }}
        >
          {text}
        </PanelBoxV2>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40],
          bg: "haze"
        }}
      >
        {accordion}
      </PanelBoxV2>
    </>
  );
}
