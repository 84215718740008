import { useCallback, useEffect, useState } from "react";

import Box from "@/components/Box";
import Text from "@/components/Text";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION } from "@/graphql/organisations/mutations";
import Checkbox from "@/tpo/Checkbox";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import Stack from "@/tpo/Stack";
import { useMutation } from "@apollo/client";

import StripeSetupPaymentMethodButton from "./StripeSetupPaymentMethodButton";

export default function StripeConnectDetailsPanel() {
  const { organisation, refetchOrganisations } = useOrganisationContext();
  const [paymentTypeValue, setPaymentTypeValue] = useState(null);
  const [accountNumberValue, setAccountNumberValue] = useState(null);

  const [updateAutomaticInvoicingSettingMutation] = useMutation(
    UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION
  );

  const updateAutomaticInvoicingSetting = useCallback(
    async variables => {
      if (!organisation?.id) {
        return;
      }
      try {
        await updateAutomaticInvoicingSettingMutation({
          variables: {
            input: {
              id: parseInt(organisation.id),
              ...variables
            }
          }
        });
      } catch (e) {}
    },
    [updateAutomaticInvoicingSettingMutation, organisation]
  );

  const handleToggleAutomaticInvoicing = useCallback(
    checked => {
      updateAutomaticInvoicingSetting({
        automaticInvoicingEnabled: checked
      });
    },
    [updateAutomaticInvoicingSetting]
  );

  useEffect(() => {
    let defaultPaymentMethod = organisation?.stripeCustomerAccount?.paymentMethods[0];

    if (defaultPaymentMethod) {
      if (defaultPaymentMethod.methodType === "BANK") {
        setPaymentTypeValue("Direct Debit");
        setAccountNumberValue(`**** **** **** ${defaultPaymentMethod.lastFour}`);
      } else if (defaultPaymentMethod.methodType === "CARD") {
        setPaymentTypeValue("Credit/Debit Card");
        setAccountNumberValue(`**** **** **** ${defaultPaymentMethod.lastFour}`);
      } else if (defaultPaymentMethod.methodType === "WALLET") {
        setAccountNumberValue(`**** **** **** ${defaultPaymentMethod.fingerprint.slice(-4)}`);
        if (defaultPaymentMethod.lastFour === "REVO") {
          setPaymentTypeValue("Revolut Pay");
        } else if (defaultPaymentMethod.lastFour === "APPL") {
          setPaymentTypeValue("Apple Pay");
        } else if (defaultPaymentMethod.lastFour === "GOOG") {
          setPaymentTypeValue("Google Pay");
        } else if (defaultPaymentMethod.lastFour === "PAYP") {
          setPaymentTypeValue("PayPal");
        } else if (defaultPaymentMethod.lastFour === "LINK") {
          setPaymentTypeValue("Link");
        }
      }
    }
  }, [organisation]);

  return (
    <>
      <Stack gap={20}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Automatic invoicing
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          For a more seamless payment integration you can opt in to have invoices automatically
          taken from your account when they are due. If you opt in we will give you an extra 5%
          discount on your next order.
        </Box>
        <Box
          bg="haze"
          width="100%"
          borderRadius="4px"
          border="1px solid #818181"
          padding="28px"
          mt={20}
        >
          <Text fontFamily="gilroyBold" fontSize={[16, 16, 18]} mb={40}>
            Default payment method
          </Text>
          <Stack gap={20} flexDirection="row" alignItems="center">
            {paymentTypeValue ? (
              <>
                <FloatingLabelInput
                  label="Payment type"
                  value={paymentTypeValue}
                  editable={false}
                />
                <FloatingLabelInput
                  label="Account number"
                  value={accountNumberValue}
                  editable={false}
                />
              </>
            ) : (
              <Box height="48px" width="100%" display="flex" alignItems="center">
                <Text fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="red">
                  No default payment method set
                </Text>
              </Box>
            )}
            <StripeSetupPaymentMethodButton
              update={!!paymentTypeValue}
              onNewPaymentMethod={() => {
                refetchOrganisations();
              }}
            />
          </Stack>
        </Box>
      </Stack>
      <Checkbox
        disabled={!paymentTypeValue}
        label={"Allow payment to be automatically taken after 30 days"}
        checked={organisation?.automaticInvoicingEnabled}
        onChange={({ target }) => handleToggleAutomaticInvoicing(target.checked)}
        styles={{
          labelProps: {
            color: !paymentTypeValue ? "grey" : "black"
          },
          checkbox: {
            opacity: !paymentTypeValue ? 0.5 : 1,
            filter: !paymentTypeValue ? "grayscale(100%)" : "none"
          }
        }}
      />
    </>
  );
}
