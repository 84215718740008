import Box from "@/components/Box";

export function GraphTemplate({ yAxisWidth, yAxis, chart, ...props }) {
  return (
    <Box
      bg="white"
      overflowX="hidden"
      paddingY={4}
      position="relative"
      minHeight="405px"
      {...props}
    >
      {yAxis}
      <Box pl={yAxisWidth}>{chart}</Box>
    </Box>
  );
}
