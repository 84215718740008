import Box from "@/components/Box";
import { CMP_PERMISSION_ADMIN } from "@/components/organisations/constants";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import isProxyEmail from "@/utils/isProxyEmail";
import isUnderEighteen from "@/utils/isUnderEighteen";
import ButtonV2 from "@/v2/Buttons";

export function getSavePatientEditableFields(organisationPatient) {
  let editableFields = [];

  if (organisationPatient.platformUserProfile.user.firstName === "") {
    editableFields.push("firstName");
  }
  if (organisationPatient.platformUserProfile.user.lastName === "") {
    editableFields.push("lastName");
  }
  if (organisationPatient.platformUserProfile.dateOfBirth === null) {
    editableFields.push("dateOfBirth");
    editableFields.push("requiresGuardianConsent");
    editableFields.push("guardianFirstName");
    editableFields.push("guardianLastName");
    editableFields.push("guardianEmail");
  }
  if (organisationPatient.platformUserProfile.guardian) {
    if (isProxyEmail(organisationPatient.platformUserProfile.guardian.user.email)) {
      editableFields.push("guardianEmail");
    }
  } else {
    if (
      organisationPatient.platformUserProfile.dateOfBirth &&
      isUnderEighteen(organisationPatient.platformUserProfile.dateOfBirth)
    ) {
      // then we are missing a guardian so must ask for one
      editableFields.push("guardianEmail");
      editableFields.push("guardianFirstName");
      editableFields.push("guardianLastName");
    } else {
      // email field is irrelevant if we have a guardian saved
      // email will be automatically generated whenever email of guardian is updated
      if (isProxyEmail(organisationPatient.platformUserProfile.user.email)) {
        editableFields.push("email");
      }
    }
  }

  if (organisationPatient.platformUserProfile.gender === "") {
    editableFields.push("sex");
  }

  return editableFields;
}

export function haveAllPatientDetails(organisationPatient) {
  return getSavePatientEditableFields(organisationPatient).length === 0;
}

export default function PatientDetails({ error, patient, permissions, onEdit }) {
  return (
    <Stack gap={20} data-component-name="PatientDetails">
      <Box fontFamily="gilroyBold" fontSize={18}>
        Patient details
      </Box>
      <Box>
        <Group gap={5}>
          <span>Name:</span>
          <span data-component-name="PatientDetails:name">
            {patient.platformUserProfile.user.firstName} {patient.platformUserProfile.user.lastName}
          </span>
        </Group>
        <Group gap={5}>
          <span>Date of birth:</span>
          <span data-component-name="PatientDetails:dateOfBirth">
            {patient.platformUserProfile.dateOfBirth}
          </span>
        </Group>
        <Group gap={5}>
          <span>Sex:</span>
          <span data-component-name="PatientDetails:sex">{patient.platformUserProfile.gender}</span>
        </Group>
        <Group>
          <span>Email:</span>
          <span data-component-name="PatientDetails:email">
            {patient.platformUserProfile.user.correspondenceEmail}
          </span>
        </Group>
      </Box>
      {permissions &&
        permissions.includes(CMP_PERMISSION_ADMIN) &&
        !haveAllPatientDetails(patient) && (
          <ButtonV2
            color="dark"
            onClick={() => {
              onEdit(true);
            }}
            rightIcon={<ChevronComponent />}
            size={["sm"]}
            mr="auto"
            type="button"
            data-component-name="PatientDetails:edit"
          >
            Edit
          </ButtonV2>
        )}
      {error}
    </Stack>
  );
}
