import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useAuthContext } from "@/components/AuthProvider";
import { CustomerAccountPage } from "@/components/CustomerPage";
import DataLoader from "@/components/DataLoader";
import { DASHBOARD_HOME_URL } from "@/core/urls";
import { ACCEPT_ORGANISATION_INVITE_MUTATION } from "@/graphql/organisations/mutations";
import { ORGANISATION_LIST_QUERY } from "@/graphql/organisations/queries/organisation";
import { ORGANISATION_PRACTITIONER_INVITE_DETAIL_QUERY } from "@/graphql/organisations/queries/practitioners";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ControlledFormField from "@/tpo/ControlledFormField";
import { Error } from "@/tpo/InputWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import Box from "./Box";
import CannotFindInvite from "./CannotFindInvite";

const textProps = {
  fontFamily: "gilroyMedium",
  fontSize: [14, 14, 16]
};

function OrganisationInviteFound({ inviteId, props, token }) {
  const history = useHistory();

  const { user } = useAuthContext();

  const api = useDjangoGraphqlForm({
    mutation: ACCEPT_ORGANISATION_INVITE_MUTATION,
    mutationName: "acceptOrganisationInviteMutation",
    mutationOptions: {
      refetchQueries: [ORGANISATION_LIST_QUERY]
    },
    handleSuccess: () => {
      history.push(DASHBOARD_HOME_URL);
    },
    defaultValues: {
      acceptInvite: false
    },
    transformer: data => ({
      ...data,
      token,
      inviteId
    })
  });

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Organisation invite",
        bg: "partners",
        color: "dark"
      }}
    >
      <Box {...textProps}>
        You have been invited to join the {props.organisationName} organisation. Once you have
        accepted the invitation you will have access to this organisation via your Regenerus
        dashboard.
      </Box>
      <Box pt={user ? 20 : 40}>
        <FormProvider {...api.methods}>
          <Stack
            mx="auto"
            maxWidth={760}
            as="form"
            onSubmit={api.methods.handleSubmit(api.onSubmit)}
            gap={20}
          >
            <ControlledFormField
              Component={Checkbox}
              name="acceptInvite"
              label={`I accept the invitation to join ${props.organisationName}`}
              styles={{
                inputWrapperProps: {
                  mt: 20
                }
              }}
            />
            <Center mt={20} stacked gap={20}>
              <ButtonV2 color="green" sizes={["sm", "sm", "md"]}>
                submit
              </ButtonV2>
              {!!api.nonFieldError && (
                <Error error={api.nonFieldError} data-testid="nonFieldError" />
              )}
            </Center>
          </Stack>
        </FormProvider>
      </Box>
    </CustomerAccountPage>
  );
}

export default function AcceptOrganisationInvite(props) {
  useDocTitle("Accept Organisation Invite");

  return (
    <DataLoader
      query={ORGANISATION_PRACTITIONER_INVITE_DETAIL_QUERY}
      variables={{ id: props.match.params.inviteId, token: props.match.params.token }}
      render={data => {
        if (data.acceptOrganisationInvitePageProps === null) {
          return (
            <CannotFindInvite message="I'm sorry, we can't seem to find that organisation invite. Please contact support@omnos.me." />
          );
        }

        return (
          <OrganisationInviteFound
            token={props.match.params.token}
            inviteId={props.match.params.inviteId}
            props={JSON.parse(data.acceptOrganisationInvitePageProps)}
          />
        );
      }}
    />
  );
}
