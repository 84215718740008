import { Redirect, useParams } from "react-router-dom";

import Box from "@/components/Box";
import Circle from "@/components/Circle";
import { DownloadCrossOriginFile, ExternalTextLink } from "@/components/Links";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { theme } from "@/core/theme";
import { WEBINAR_CONNECTIONS_QUERY, WEBINAR_QUERY } from "@/graphql/knowledgeCenter/webinars";
import Badge from "@/tpo/Badge";
import Center from "@/tpo/Center";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "@/tpo/InfiniteScroll";
import Stack from "@/tpo/Stack";
import convertOrderedLists from "@/utils/convertOrderedLists";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";

import VimeoComponent from "../../VimeoComponent";
import FeaturedTests from "./FeaturedTests";
import WebinarCard, { HostProfile } from "./WebinarCard";
import classes from "./richText.module.css";

export default function Webinar() {
  const { slug } = useParams();
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  const skipQuery = !basket?.paymentLocale?.isoCode || !organisation?.id;

  const { data: webinarData, loading: loadingWebinar } = useQuery(WEBINAR_QUERY, {
    variables: {
      slug,
      paymentIsoCode: basket?.paymentLocale?.isoCode,
      organisation: organisation?.id
    },
    skip: skipQuery
  });

  const webinar = webinarData?.webinar;

  const { data, loading, fetchMore } = useQuery(WEBINAR_CONNECTIONS_QUERY, {
    skip: webinar?.id === undefined,
    variables: {
      first: 10,
      orderBy: "title",
      upcoming: true,
      exclude: [webinar?.id]
    }
  });

  const hasMore = data?.webinarConnections.pageInfo.hasNextPage;
  const endCursor = data?.webinarConnections.pageInfo.endCursor;
  const webinars = data?.webinarConnections.edges.map(edge => edge.node);

  const showLab =
    webinar?.testProducts.filter(tp => tp?.laboratory?.logoUrl).map(tp => tp.laboratory.logoUrl)
      .length === webinar?.testProducts.length && webinar?.testProducts.length > 0;

  if (!skipQuery && !loadingWebinar && !webinar) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Box bg="white" pb={theme.spacing.section.pb} px={20} pt={theme.spacing.pageHeader.pt}>
        <Center stacked>
          <Stack gap={28} pt={[60, 60, 80]} pb={[40, 40, 60]}>
            <Box
              textAlign="center"
              fontFamily="gilroyBold"
              fontSize={[40, 40, 68]}
              maxWidth={1024}
              mx="auto"
              lineHeight="100%"
            >
              {webinar?.title}
            </Box>
            <Box
              textAlign="center"
              fontFamily="gilroyMedium"
              fontSize={[24, 24, 32]}
              maxWidth={720}
              mx="auto"
            >
              {webinar?.shortDescription}
            </Box>
          </Stack>
          <Stack
            gap={20}
            bg="partners"
            p={[20, 20, 40]}
            borderRadius={20}
            maxWidth={720}
            width="100%"
          >
            {webinar?.startTime && new Date() > new Date(webinar.startTime) ? null : (
              <Center stacked>
                <Box fontFamily="gilroyMedium" fontSize={[18]}>
                  {webinar?.startTime
                    ? new Date(webinar?.startTime).toLocaleDateString("en-US", {
                        month: "short"
                      })
                    : null}
                </Box>
                <Box fontFamily="gilroyMedium" fontSize={[96]}>
                  {webinar?.startTime
                    ? new Date(webinar?.startTime).toLocaleDateString("en-US", {
                        day: "2-digit"
                      })
                    : null}
                </Box>
                <Box fontFamily="gilroyBold" fontSize={[18]}>
                  {webinar?.startTime
                    ? new Date(webinar?.startTime).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short"
                      })
                    : null}
                </Box>
              </Center>
            )}
            <Group justifyContent="space-between">
              <HostProfile
                name={webinar?.host?.fullName}
                title={webinar?.host?.title}
                occupation={webinar?.host?.occupation}
                avatarUrl={webinar?.host?.avatarUrl}
              />
              {showLab && webinar?.testProducts[0].laboratory.logoUrl && (
                <Circle
                  size={106}
                  style={{
                    backgroundImage: `url(${webinar?.testProducts[0].laboratory.logoUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                />
              )}
            </Group>
          </Stack>
          {!!webinar?.testProducts?.length && (
            <Center stacked gap={20} mt={40}>
              <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                Featured Tests
              </Box>
              <Group gap={10} flexWrap="wrap" maxWidth="calc(100vw - 40px)">
                {webinar?.testProducts?.map(tp => (
                  <Badge
                    bg="lifestyle"
                    color="dark"
                    size="lg"
                    sx={{
                      px: 20,
                      py: 16
                    }}
                    key={tp.id}
                    truncate
                  >
                    {tp.name}
                  </Badge>
                ))}
              </Group>
            </Center>
          )}
          {!!webinar?.registerNowUrl && new Date() < new Date(webinar?.startTime) && (
            <ButtonV2
              color="dark"
              mx="auto"
              mt={40}
              size={["sm", "sm", "md"]}
              sx={{
                pt: 20,
                pb: 20
              }}
              rightIcon={<ChevronComponent />}
              as={ExternalTextLink}
              underline={false}
              href={webinar?.registerNowUrl}
            >
              register now
            </ButtonV2>
          )}
          {!!webinar?.video?.url && (
            <VimeoComponent height={720} url={webinar?.video?.url} width={1274} pt={[80, 80, 96]} />
          )}
          {!!webinar?.description && (
            <Box
              pt={theme.spacing.section.pt}
              dangerouslySetInnerHTML={{
                __html: webinar?.description
                  ? convertOrderedLists(webinar.description)
                  : webinar?.description
              }}
              className={classes.richText}
              maxWidth={720}
              width="100%"
            />
          )}
          {!!webinar?.documents?.length && (
            <Stack pt={60} width="100%" maxWidth={720} gap={0}>
              <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]}>
                Download files
              </Box>
              <Stack bg="white" borderRadius={20} py={40} gap={20}>
                <Box fontFamily="gilroyBold" fontSize={[28]}>
                  Related documents
                </Box>
                {webinar?.documents.map(document => (
                  <Group
                    flexDirection={["column", "row"]}
                    key={document.id}
                    gap={20}
                    alignItems={["unset", "center"]}
                  >
                    <Box fontFamily="gilroyMedium" fontSize={16}>
                      {document.displayName}
                    </Box>
                    <DownloadCrossOriginFile
                      fileUrl={document.url}
                      fileName={document.filename}
                      contentType={document.contentType}
                      trigger={
                        <ButtonV2
                          color="green"
                          rightIcon={<ChevronComponent direction="bottom" />}
                          size="xs"
                        >
                          Download
                        </ButtonV2>
                      }
                      containerProps={{
                        ml: [0, "auto"],
                        mr: ["auto", 0]
                      }}
                    />
                  </Group>
                ))}
              </Stack>
            </Stack>
          )}
          {!!webinar?.testProducts?.length && <FeaturedTests testProducts={webinar.testProducts} />}
        </Center>
      </Box>
      {!!webinars?.length && (
        <Box bg="haze" px={20}>
          <Box
            pt={theme.spacing.section.pt}
            pb={theme.spacing.section.pb}
            maxWidth={1138}
            mx="auto"
            px={20}
          >
            <Center stacked gap={16}>
              <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]} textAlign="center">
                Upcoming webinars
              </Box>
              <Box
                fontFamily="gilroyMedium"
                fontSize={[18, 18, 24]}
                maxWidth={720}
                textAlign="center"
              >
                Elevate your expertise and dive into educational insights, best practices, case
                studies, and more in our webinars.
              </Box>
            </Center>
            <Stack gap={16} pt={80} pb={theme.spacing.section.pb}>
              <InfiniteScroll
                hasMore={hasMore}
                next={() =>
                  fetchMore({
                    variables: {
                      after: endCursor,
                      first: 10
                    }
                  })
                }
                loading={loading}
              >
                <InfiniteScrollContext.Consumer>
                  {({ setBottomElement }) => (
                    <>
                      {webinars?.slice(0, -1)?.map(webinar => (
                        <WebinarCard
                          key={webinar.id}
                          title={webinar.title}
                          description={webinar.shortDescription}
                          host={webinar.host}
                          startTime={webinar.startTime}
                          endTime={webinar.endTime}
                          id={webinar.id}
                          slug={webinar.slug}
                          cpdPoints={webinar.cpdPoints}
                        />
                      ))}
                      {webinars?.slice(-1)?.map(webinar => (
                        <WebinarCard
                          key={webinar.id}
                          title={webinar.title}
                          description={webinar.shortDescription}
                          host={webinar.host}
                          startTime={webinar.startTime}
                          endTime={webinar.endTime}
                          ref={setBottomElement}
                          id={webinar.id}
                          // avatarRendition={webinar.host.avatarRendition}
                          slug={webinar.slug}
                          cpdPoints={webinar.cpdPoints}
                        />
                      ))}
                    </>
                  )}
                </InfiniteScrollContext.Consumer>
              </InfiniteScroll>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
}
