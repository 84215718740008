import Svg from "@/components/Svg";

export default function StoolIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19592)">
        <path
          d="M21.1758 29.4127H22.3537V56.4705C22.3559 58.4205 23.9331 59.9978 25.8832 60H54.119C56.0668 59.9978 57.6463 58.4205 57.6485 56.4705V29.4127H58.8242C59.1352 29.4127 59.4352 29.2892 59.6559 29.0686C59.8765 28.848 60 28.548 60 28.2347V23.5295C59.9978 21.5817 58.4205 20.0022 56.4705 20H23.5295C21.5817 20.0022 20.0022 21.5817 20 23.5295V28.2347C20 28.5479 20.1235 28.8479 20.3441 29.0686C20.5647 29.2892 20.8647 29.4127 21.1758 29.4127ZM55.2948 56.4705C55.2926 57.1212 54.7676 57.6463 54.119 57.6485H25.8832C25.2325 57.6463 24.7075 57.1212 24.7053 56.4705V50.5896H28.2348C28.8855 50.5896 29.4127 50.0623 29.4127 49.4116C29.4127 48.7631 28.8855 48.2359 28.2348 48.2359H24.7053V43.5307H28.2348C28.8855 43.5307 29.4127 43.0034 29.4127 42.3527C29.4127 41.7042 28.8855 41.177 28.2348 41.177H24.7053V36.4718H28.2348C28.8855 36.4718 29.4127 35.9445 29.4127 35.2938C29.4127 34.6453 28.8855 34.1181 28.2348 34.1181H24.7053V29.4129H55.2948L55.2948 56.4705ZM22.3537 23.5295C22.3537 22.8809 22.881 22.3537 23.5295 22.3537H56.4705C57.1213 22.3537 57.6463 22.8809 57.6485 23.5295V27.0589H22.3537L22.3537 23.5295Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3634_19592">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
