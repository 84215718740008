import { forwardRef } from "react";

import Box from "@/components/Box";
import SupplementIcon from "@/images/SupplementIcon";
import Badge from "@/tpo/Badge";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import { useTheme } from "styled-components";

const Card = forwardRef(({ children, onClick, "data-component-name": dataComponentName }, ref) => {
  const theme = useTheme();

  return (
    <Stack
      ref={ref}
      py={40}
      px={28}
      bg="white"
      gap={0}
      borderRadius={theme.radius.card}
      onClick={onClick}
      cursor="pointer"
      data-component-name={dataComponentName}
    >
      {children}
    </Stack>
  );
});

const Header = forwardRef(({ children, iconSize = 60 }, ref) => {
  return (
    <Group justifyContent="space-between">
      <SupplementIcon size={iconSize} />
      <Group gap={8}>{children}</Group>
    </Group>
  );
});

const Content = forwardRef(
  ({ id, name, shortDescription, pb, children, brandName, doseType, dietryRestrictions }, ref) => {
    return (
      <>
        <Box>
          {!!id && (
            <Box fontFamily="gilroyMedium" lineHeight={"24px"} fontSize={14} color="midGrey">
              {id}
            </Box>
          )}
          {!!name && (
            <Box
              fontFamily="gilroyBold"
              lineHeight={"36px"}
              fontSize={[24, 24, 28]}
              data-testid="supplementCard:title"
            >
              {name}
            </Box>
          )}
          {!!brandName && (
            <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
              {brandName}
            </Box>
          )}
        </Box>
        <Stack gap={16} pb={pb}>
          {shortDescription && (
            <Box fontSize={14} fontFamily="gilroyMedium">
              {shortDescription}
            </Box>
          )}
          <Group flexWrap="wrap" gap={2} mt="auto">
            {doseType && (
              <Badge bg="purple" color="white" size="xs">
                {doseType}
              </Badge>
            )}
            {dietryRestrictions
              .map(dr => dr.name)
              .map(n => (
                <Badge bg="haze" color="dark" size="xs" key={n}>
                  {n}
                </Badge>
              ))}
          </Group>
          {children}
        </Stack>
      </>
    );
  }
);

const PriceSection = forwardRef(({ price, children }, ref) => {
  return (
    <Group
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      mt="auto"
      // pt={80}
      gap={28}
    >
      <Group gap="8px">
        <GilroyFontCurrency value={price} />
      </Group>
      {children}
    </Group>
  );
});

export default Object.assign(
  {},
  {
    Card,
    Header,
    Content,
    PriceSection
  }
);
