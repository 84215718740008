import React from "react";

import { useHistory } from "react-router-dom";

import { LOGIN_URL } from "@/core/urls";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import ButtonV2 from "@/v2/Buttons";

import { CustomerAccountPage } from "./CustomerPage";
import Text from "./Text";

function SignupCompletePage() {
  useDocTitle("Signup complete");
  const history = useHistory();

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Success"
      }}
    >
      <Text>
        Your account has been successfully activated. Please click below to log in to your new
        account.
      </Text>
      <Center>
        <ButtonV2
          color="green"
          mt={40}
          maxWidth={300}
          width="100%"
          onClick={() => {
            history.push(LOGIN_URL);
          }}
          data-component-name="Log in button"
          type="button"
        >
          Log in
        </ButtonV2>
      </Center>
    </CustomerAccountPage>
  );
}

export default SignupCompletePage;
