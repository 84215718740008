import gql from "graphql-tag";

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on AddressType {
    id
    name
    line1
    line2
    city
    postcode
    country {
      id
      isoCode
      name
    }
    phoneNumber
  }
`;

export const ORGANISATION_PATIENT_FIELDS = gql`
  fragment OrganisationPatientFields on OrganisationPatientType {
    id
    requiresGuardianConsent
    platformUserProfile {
      id
      pk
      gender
      dateOfBirth
      user {
        id
        firstName
        lastName
        email
        correspondenceEmail
      }
      guardian {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const ORGANISATION_FIELDS = gql`
  fragment OrganisationFields on CmpOrganisationType {
    id
    isDefault
    adminOverrideEmail
    accountOnHold
    primaryAddress {
      ...AddressFields
    }
    secondaryAddress {
      ...AddressFields
    }
    hashId
    stripeConnectedAccountName
    stripeConnectedAccountAddress
    stripeCustomerAccount {
      id
      paymentMethods {
        id
        default
        methodType
        lastFour
        fingerprint
      }
    }
    stripeConnectedAccount {
      id
      paymentMethods {
        id
        default
        methodType
        lastFour
        fingerprint
      }
    }
    name
    patientNotifications
    automaticInvoicingEnabled
    chargesEnabled
    onboardingUrl
    onboardingStatus
    disabled
    disabledReason
    contactEmail
    contactNumber
    website
    companyNumber
    vatNumber
    currencyName
    currencySymbol
    line1
    line2
    city
    postcode
    permissions
    teamMembersEnabled
    country {
      id
      name
      isoCode
    }
    patients {
      ...OrganisationPatientFields
    }
  }
  ${ADDRESS_FIELDS}
  ${ORGANISATION_PATIENT_FIELDS}
`;

export const ORGANISATION_BASKET_FIELDS = gql`
  fragment OrganisationBasketFields on OrganisationBasketType {
    id
    organisation {
      id
      name
    }
    practitioner {
      id
      user {
        firstName
        lastName
        email
      }
    }
    organisationPatient {
      ...OrganisationPatientFields
    }
    patient {
      id
      pk
      user {
        firstName
        lastName
        email
      }
    }
    patientShippingAddress {
      ...AddressFields
    }
    shippingAddress {
      ...AddressFields
    }
    locale {
      id
      name
      isoCode
    }
    paymentLocale {
      id
      name
      isoCode
    }
    invalid
    currencyName
    currencySymbol
    payee
    customsConfirmation
    purchaseAsStock
    commissionPercentageSplit
    appliedTradeDiscount {
      id
      code
    }
    basketShippingCost
    basketRrpTotalPrice
    basketTotalPrice
    basketTotalCommission
    basketTotalDiscount
    discountInvalidReason
    productLineItems {
      id
      invalid
      invalidReason
      product {
        id
        paymentIsoCode
        organisation
        name
        productCode
      }
      productOption {
        id
        paymentIsoCode
        organisation
        testProductOptionFamily {
          name
        }
      }
      clinicLocation {
        id
        paymentIsoCode
        organisation
        address
        clinic {
          name
        }
      }
      quantity
      fullPricePerUnit
      rrpPerUnit
      pricePerUnit
      discountPerUnit
      clinicFeesPerUnit
      addons {
        id
        product {
          id
          paymentIsoCode
          organisation
          name
        }
        quantity
        fullPricePerUnit
        rrpPerUnit
        pricePerUnit
        discountPerUnit
      }
    }
    supplementLineItems {
      id
      invalid
      invalidReason
      supplement {
        id
        name
      }
      quantity
      fullPricePerUnit
      rrpPerUnit
      pricePerUnit
    }
  }
  ${ADDRESS_FIELDS}
  ${ORGANISATION_PATIENT_FIELDS}
`;

export const ORGANISATION_ONBOARDING_STATUS = gql`
  query OrganisationOnboardingStatus($organisation: ID!) {
    connectedAccount(organisation: $organisation) {
      id
      missingRequirements
      onboardingStatus
    }
  }
`;

export const CONNECTED_USER_FRAGMENT = gql`
  fragment ConnectedUserFragment on OrganisationPatientType {
    id
    created
    email
    platformUserProfile {
      id
      pk
      user {
        id
        email
        firstName
        lastName
      }
      isWard
      guardian {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
      wards {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
    granted
    isAdmin
    relatedResultsAccessLinkId
    shippingAddress {
      ...AddressFields
    }
  }
  ${ADDRESS_FIELDS}
`;
