import gql from "graphql-tag";

import {
  AREA_OF_INTEREST_FIELDS,
  CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTION_FIELDS,
  TEST_PRODUCT_CONTENT_FIELDS
} from "./shared";
import { TEST_DATA_FILE_FIELDS } from "./tpo/matrix/types";
import {
  CHART_CONFIGURATION_FIELDS,
  USER_RESULT_FIELDS_TRUNCATED,
  USER_TEST_FIELDS_TRUNCATED,
  USER_TEST_RECOMMENDATION_BADGE_FIELDS
} from "./tpo/results/types";

// Queries

export const CLINIC_LOCATION_FIELDS = gql`
  fragment ClinicLocationFields on ClinicLocationType {
    id
    paymentIsoCode
    organisation
    fee
    clinic {
      id
      name
      fee
    }
    address
    postCode
    bookingLink
    phoneNumber
    coords {
      lat
      lng
    }
  }
`;

export const TEST_PRODUCT_OPTION_FAMILY_FIELDS = gql`
  fragment TestProductOptionFamilyFields on TestProductOptionFamilyType {
    id
    name
    description
  }
`;

export const TEST_PRODUCT_OPTION_TRUNCATED_FIELDS = gql`
  fragment TestProductOptionTruncatedFields on TestProductOptionType {
    id
    paymentIsoCode
    organisation
    compositeId
    price
    default
  }
`;

// NB we have two queries for getting clinics
// Because we only want fields not relating to the prices strictly speaking we don't
// need two queries.  After all clinic fee is constant.
// However there is a price field on the ClinicLocationType which does depend on consumer or practitioner shop
// so it's dangerous to use the same query for both.
// We don't use this price field because clinics are gotten within the context of a test product option so
// we already have the price relevant to the shop context already.

export const GET_CONSUMER_CLINICS = gql`
  query GetConsumerClinics($compositeId: ID!, $postCode: String) {
    consumerClinicLocations(compositeId: $compositeId, postCode: $postCode) {
      ...ClinicLocationFields
      distance
    }
  }
  ${CLINIC_LOCATION_FIELDS}
`;

export const GET_PRACTITIONER_CLINICS = gql`
  query GetPractitionerClinics(
    $compositeId: ID!
    $postCode: String
    $paymentIsoCode: String
    $organisation: ID
  ) {
    practitionerClinicLocations(
      compositeId: $compositeId
      postCode: $postCode
      paymentIsoCode: $paymentIsoCode
      organisation: $organisation
    ) {
      ...ClinicLocationFields
      distance
    }
  }
  ${CLINIC_LOCATION_FIELDS}
`;

// TODO - look to remove the clinics list below
// Do we need it?  If it's just for knowing if there are clinics return a boolean instead somewhere

export const CONSUMER_TEST_PRODUCT_OPTION_FIELDS = gql`
  fragment ConsumerTestProductOptionFields on TestProductOptionType {
    id
    paymentIsoCode
    organisation
    compositeId
    testProductOptionFamily {
      ...TestProductOptionFamilyFields
    }
    rrpCurrentPrice
    rrpFullPrice
    consumerClinicsInfo {
      fromPrice
      clinics {
        id
        paymentIsoCode
        organisation
      }
    }
  }
  ${TEST_PRODUCT_OPTION_FAMILY_FIELDS}
`;

export const PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS = gql`
  fragment PractitionerTestProductOptionFields on TestProductOptionType {
    id
    paymentIsoCode
    organisation
    compositeId
    testProductOptionFamily {
      ...TestProductOptionFamilyFields
    }
    tradeCurrentPrice
    tradeFullPrice
    rrpFullPrice
    exvatFullPrice
    practitionerClinicsInfo {
      fromPrice
      clinics {
        id
        paymentIsoCode
        organisation
      }
    }
  }
  ${TEST_PRODUCT_OPTION_FAMILY_FIELDS}
`;

export const TEST_PRODUCT_FIELDS = gql`
  fragment TestProductFields on TestProductType {
    id
    paymentIsoCode
    organisation
    compositeId
    name
    slug
    letters
    subletters
    sector
    productFamily {
      id
      name
      slug
    }
    specifiedSex
    sampleTypes
    areasOfInterest
    description
    overview
    productCode
    price
    previousPrice
    status
    bundlePromoImage
    bundlePromoImageAltText
    promoImage
    promoImageAltText
    smallPromoImage
    smallPromoImageAltText
    socialImage
    socialImageAltText
    reportPdf
    collectingYourSampleImage
    collectingYourSampleVideo
    shortDescription
    testType
    numOfBiomarkersTested
    processingTime
    productOptionsExplanation
    whyYouShouldTakeThisTest
    whatYouGetWithTest
    shippingAndProcessing
    needHelp
    testDetails
    relatedSymptoms
    biomarkersTested
    metaDescription
    metaTitle
    howItWorks
  }
`;

export const TEST_PRODUCT_SHORT_FIELDS = gql`
  fragment TestProductShortFields on TestProductType {
    id
    paymentIsoCode
    organisation
    name
    slug
    productFamily {
      id
      name
      slug
    }
    description
    shortDescription
    overview
    productCode
    price
    smallPromoImage
    smallPromoImageAltText
  }
`;

export const TEST_PRODUCT_FAMILY_FIELDS = gql`
  fragment TestProductFamilyFields on TestProductFamilyType {
    id
    name
    slug
    metaTitle
    metaDescription
    description
  }
`;

export const SHIPPING_COUNTRIES_QUERY = gql`
  query ShippingCountriesQuery {
    shippingCountries {
      id
      name
      isoCode
      postageCost
      stripeSupported
    }
  }
`;

export const CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_FIELDS = gql`
  fragment ConsumerOrderTestItemTestProductFields on TestProductType {
    id
    paymentIsoCode
    organisation
    compositeId
    name
    slug
    productType
    sector
    rrpCurrentPrice
    rrpFullPrice
  }
`;

export const CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_OPTION_FIELDS = gql`
  fragment ConsumerOrderTestItemTestProductOptionFields on TestProductOptionType {
    id
    paymentIsoCode
    organisation
    compositeId
    rrpCurrentPrice
    rrpFullPrice
  }
`;

export const ORDER_TEST_ITEM_FIELDS = gql`
  fragment OrderTestItemFields on OrderTestItemType {
    id
    testSentToUser
    sampleReceivedByProvider
    sampleReceivedByLab
    resultsReceivedByProvider
    resultsProcessed
    estimatedResultsAvailableDate
    defunct
    price
    nameInBasket
    groupBy
    providerTestId
    derivedProgressStatus
    registered
    parent {
      id
    }
    userTestSet {
      ...UserTestFieldsTruncated
    }
    product {
      ...ConsumerOrderTestItemTestProductFields
    }
    productOption {
      ...ConsumerOrderTestItemTestProductOptionFields
    }
    clinicLocation {
      ...ClinicLocationFields
    }
    clinicBooking {
      id
      url
      status
      details
    }
    registeredUser {
      email
      firstName
      lastName
    }
  }
  ${CLINIC_LOCATION_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_OPTION_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;

export const CONSUMER_ORDER_TEST_ITEM_FIELDS = gql`
  fragment ConsumerOrderTestItemFields on OrderTestItemType {
    id
    created
    testSentToUser
    sampleReceivedByProvider
    sampleReceivedByLab
    resultsReceivedByProvider
    resultsProcessed
    estimatedResultsAvailableDate
    defunct
    price
    nameInBasket
    groupBy
    derivedProgressStatus
    registered
    userTestSet {
      ...UserTestFieldsTruncated
    }
    product {
      ...ConsumerOrderTestItemTestProductFields
    }
    productOption {
      ...ConsumerOrderTestItemTestProductOptionFields
    }
    clinicLocation {
      ...ClinicLocationFields
    }
    clinicBooking {
      id
      url
      status
      details
    }
    registeredUser {
      email
      firstName
      lastName
    }
    inboundTracking
    outboundTracking
  }
  ${CLINIC_LOCATION_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_OPTION_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;

export const CONSUMER_OPEN_ORDER_TEST_ITEM_FIELDS = gql`
  fragment ConsumerOpenOrderTestItemFields on OrderTestItemType {
    id
    created
    testSentToUser
    sampleReceivedByProvider
    sampleReceivedByLab
    resultsReceivedByProvider
    resultsProcessed
    defunct
    price
    nameInBasket
    groupBy
    product {
      ...ConsumerOrderTestItemTestProductFields
    }
    productOption {
      ...ConsumerOrderTestItemTestProductOptionFields
    }
    clinicLocation {
      ...ClinicLocationFields
    }
    clinicBooking {
      id
      url
      status
      details
    }
  }
  ${CLINIC_LOCATION_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_FIELDS}
  ${CONSUMER_ORDER_TEST_ITEM_TEST_PRODUCT_OPTION_FIELDS}
`;

export const SOURCE_BASKET_FIELDS = gql`
  fragment SourceBasketFields on OrganisationBasketType {
    id
    organisation {
      id
      name
    }
    practitioner {
      id
      user {
        id
        fullName
      }
    }
    payee
    basketTotalPrice
    basketShippingCost
    currencySymbol
  }
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on OrderType {
    id
    updated
    checkoutDate
    shippingFullName
    shippingFirstName
    shippingLastName
    shippingAddress
    shippingAddress1
    shippingAddress2
    shippingTownCity
    shippingPostalCode
    shippingCountry
    shippingPhone
    billingFirstName
    billingLastName
    billingAddress
    billingPostalCode
    billingCountry
    postageCosts
    discountCode
    fullTotal
    discountedTotal
    discountMeetsRequirements
    status
    email
    derivedPaymentStatus
    derivedProgressStatus
    sourceBasket {
      ...SourceBasketFields
    }
    user {
      id
      fullName
    }
    supplementItems {
      id
      nameInBasket
      groupBy
      price
      product {
        id
        compositeId
        name
        productType
        price
        slug
      }
    }
    testItems {
      ...ConsumerOrderTestItemFields
    }
    refundSet {
      id
      updated
      amount
    }
  }
  ${CONSUMER_ORDER_TEST_ITEM_FIELDS}
  ${SOURCE_BASKET_FIELDS}
`;

export const CONSUMER_ORDER_FIELDS = gql`
  fragment ConsumerOrderFields on OrderType {
    id
    updated
    orderCopiedFrom {
      id
    }
    checkoutDate
    shippingFullName
    shippingFirstName
    shippingLastName
    shippingAddress
    shippingAddress1
    shippingAddress2
    shippingTownCity
    shippingPostalCode
    shippingCountry
    shippingPhone
    billingFirstName
    billingLastName
    billingAddress
    billingPostalCode
    billingCountry
    postageCosts
    discountCode
    fullTotal
    discountedTotal
    totalDiscount
    vat
    refunded
    discountMeetsRequirements
    status
    email
    user {
      id
      fullName
    }
    supplementItems {
      id
      nameInBasket
      groupBy
      price
      product {
        id
        compositeId
        name
        productType
        price
        slug
      }
      created
    }
    testItems {
      ...ConsumerOrderTestItemFields
    }
    refundSet {
      id
      updated
      amount
    }
    derivedPaymentStatus
    derivedProgressStatus
    sourceBasket {
      ...SourceBasketFields
    }
  }
  ${CONSUMER_ORDER_TEST_ITEM_FIELDS}
  ${SOURCE_BASKET_FIELDS}
`;

export const CONSUMER_OPEN_ORDER_FIELDS = gql`
  fragment ConsumerOpenOrderFields on OrderType {
    id
    updated
    checkoutDate
    shippingFullName
    shippingFirstName
    shippingLastName
    shippingAddress
    shippingAddress1
    shippingAddress2
    shippingTownCity
    shippingPostalCode
    shippingCountry
    shippingPhone
    billingFirstName
    billingLastName
    billingAddress
    billingPostalCode
    billingCountry
    postageCosts
    discountCode
    fullTotal
    discountedTotal
    totalDiscount
    vat
    refunded
    discountMeetsRequirements
    status
    email
    user {
      id
      fullName
    }
    supplementItems {
      id
      nameInBasket
      groupBy
      price
      product {
        id
        compositeId
        name
        productType
        price
        slug
      }
      created
    }
    testItems {
      ...ConsumerOpenOrderTestItemFields
    }
    refundSet {
      id
      updated
      amount
    }
  }
  ${CONSUMER_OPEN_ORDER_TEST_ITEM_FIELDS}
`;

export const ORDER_LIST_FIELDS = gql`
  fragment OrderListFields on OrderType {
    id
    checkoutDate
    estimatedResultsAvailableDate
    derivedPaymentStatus
    derivedProgressStatus
    sourceBasket {
      ...SourceBasketFields
    }
    user {
      id
      fullName
    }
    shippingFullName
    discountedTotal
    postageCosts
    status
  }
  ${SOURCE_BASKET_FIELDS}
`;

export const ORDER_QUERY = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      ...ConsumerOrderFields
      sourceBasket {
        ...SourceBasketFields
      }
    }
  }
  ${CONSUMER_ORDER_FIELDS}
  ${SOURCE_BASKET_FIELDS}
`;

export const OPEN_ORDER_QUERY = gql`
  query GetOpenOrder {
    openOrder {
      ...ConsumerOpenOrderFields
    }
  }
  ${CONSUMER_OPEN_ORDER_FIELDS}
`;

export const ORDERS_QUERY = gql`
  query OrderQuery($userId: ID) {
    orders(userId: $userId) {
      ...ConsumerOrderFields
    }
  }
  ${CONSUMER_ORDER_FIELDS}
`;

export const ORDER_TEST_ITEMS_QUERY = gql`
  query GetOrderTestItems($userId: ID) {
    orderTestItems(userId: $userId) {
      ...OrderTestItemFields
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
`;

// Mutations

export const UPDATE_OPEN_ORDER = gql`
  mutation UpdateOpenOrder($testItemIds: [ID]!, $supplementItemIds: [ID]!, $discountCode: String!) {
    updateOpenOrder(
      testItemIds: $testItemIds
      supplementItemIds: $supplementItemIds
      discountCode: $discountCode
    ) {
      status
    }
  }
`;

export const ADD_ITEM_TO_BASKET = gql`
  mutation AddItemToBasket($compositeId: ID!, $clinicLocationId: ID) {
    addItemToBasket(clinicLocationId: $clinicLocationId, compositeId: $compositeId) {
      status
    }
  }
`;

export const REMOVE_ITEM_FROM_BASKET = gql`
  mutation RemoveItemFromBasket($compositeId: ID!, $clinicLocationId: ID) {
    removeItemFromBasket(clinicLocationId: $clinicLocationId, compositeId: $compositeId) {
      status
    }
  }
`;

export const SET_BASKET_ITEM_QTY_MUTATION = gql`
  mutation SetOrderItemQtyMutation($input: SetOrderItemQtyMutationInput!) {
    setOrderItemQtyMutation(input: $input) {
      openOrder {
        ...ConsumerOpenOrderFields
      }
    }
  }
  ${CONSUMER_OPEN_ORDER_FIELDS}
`;

export const STORE_INCOMING_DISCOUNT_CODE = gql`
  mutation StoreIncomingDiscountCode($discountCode: String!) {
    storeIncomingDiscountCode(discountCode: $discountCode) {
      status
    }
  }
`;

export const GET_PAYMENT_INTENT = gql`
  mutation GetPaymentIntent($orderId: ID!) {
    getPaymentIntent(orderId: $orderId) {
      clientSecret
      status
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod {
    removePaymentMethod {
      status
    }
  }
`;

export const CHECKOUT_MUTATION = gql`
  mutation CheckoutMutation($input: CheckoutMutationInput!) {
    checkoutMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UpdateDiscountMutation($input: UpdateDiscountMutationInput!) {
    updateDiscountMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_SHIPPING_COUNTRY_MUTATION = gql`
  mutation UpdateShippingCountryMutation($input: UpdateShippingCountryMutationInput!) {
    updateShippingCountryMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const SUPPLEMENT_QUERY = gql`
  query SupplementQuery($slug: String!) {
    supplement(slug: $slug) {
      id
      compositeId
      name
      productCode
      price
      description
      directions
      allergens
      slug
      nutritionalInfoImage
      productImage
      status
      supplementCategories {
        id
        name
      }
    }
  }
`;

export const SUPPLEMENTS_QUERY = gql`
  query SupplementsQuery {
    supplements {
      id
      name
      slug
      compositeId
    }
  }
`;

export const RESCHEDULE_BOOKING_MUTATION = gql`
  mutation RescheduleBookingMutation($orderTestItemId: ID!) {
    rescheduleBookingMutation(orderTestItemId: $orderTestItemId) {
      status
      errors {
        field
        messages
      }
    }
  }
`;

export const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBookingMutation($orderTestItemId: ID!) {
    cancelBookingMutation(orderTestItemId: $orderTestItemId) {
      status
      errors {
        field
        messages
      }
    }
  }
`;

const CURRENCY_FIELDS = gql`
  fragment CurrencyFields on CurrencyType {
    id
    isoCode
  }
`;

export const SAMPLE_REPORT_TYPE_FIELDS = gql`
  fragment SampleReportTypeFields on SampleReportType {
    id
    name
    pdf
    filename
  }
`;

const CONSUMER_TEST_PRODUCT_DETAIL_FIELDS = gql`
  fragment ConsumerTestProductDetailFields on TestProductType {
    id
    paymentIsoCode
    organisation
    compositeId
    productAudience
    name
    slug
    status
    sampleTypes
    rrpCurrentPrice
    rrpFullPrice
    reportPdf
    sampleLabReports {
      ...SampleReportTypeFields
    }
    sampleGeneratedReports {
      ...SampleReportTypeFields
    }
    instructions {
      ...SampleReportTypeFields
    }
    supportingDocuments {
      ...SampleReportTypeFields
    }
    content {
      ...TestProductContentFields
      description
      shortDescription
      collectingYourSampleVideo
      collectingYourSampleImage
      carouselImage1: image1
      carouselImage2: image2
      carouselImage3: image3
    }
    testDataFiles {
      ...TestDataFileFields
    }
    options {
      ...ConsumerTestProductOptionFields
    }
  }
  ${TEST_DATA_FILE_FIELDS}
  ${TEST_PRODUCT_CONTENT_FIELDS}
  ${CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
  ${SAMPLE_REPORT_TYPE_FIELDS}
`;

export const CONSUMER_TEST_PRODUCT_DETAIL_PAGE_QUERY = gql`
  query ConsumerTestProductDetailPageQuery($slug: String!) {
    consumerTestProduct(slug: $slug) {
      ...ConsumerTestProductDetailFields
    }
    userTestRecommendation(productSlug: $slug) {
      ...UserTestRecommendationBadgeFields
    }
  }
  ${CONSUMER_TEST_PRODUCT_DETAIL_FIELDS}
  ${USER_TEST_RECOMMENDATION_BADGE_FIELDS}
`;

export const CONSUMER_TEST_PRODUCT_DETAIL_QUERY = gql`
  query ConsumerTestProductQuery($id: ID!) {
    consumerTestProduct(id: $id) {
      ...ConsumerTestProductDetailFields
    }
    userTestRecommendation(productId: $id) {
      ...UserTestRecommendationBadgeFields
    }
  }
  ${CONSUMER_TEST_PRODUCT_DETAIL_FIELDS}
  ${USER_TEST_RECOMMENDATION_BADGE_FIELDS}
`;

export const PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY = gql`
  query PractitionerTestProductQuery(
    $id: ID
    $slug: String
    $paymentIsoCode: String
    $organisation: ID
  ) {
    practitionerTestProduct(
      id: $id
      slug: $slug
      paymentIsoCode: $paymentIsoCode
      organisation: $organisation
    ) {
      id
      paymentIsoCode
      organisation
      productAudience
      reportPdf
      sampleGeneratedReports {
        ...SampleReportTypeFields
      }
      sampleLabReports {
        ...SampleReportTypeFields
      }
      instructions {
        ...SampleReportTypeFields
      }
      supportingDocuments {
        ...SampleReportTypeFields
      }
      name
      slug
      sampleTypes
      tradeCurrentPrice
      tradeFullPrice
      rrpFullPrice
      exvatFullPrice
      status
      addons {
        id
        paymentIsoCode
        organisation
        name
        content {
          id
          description
        }
        tradeCurrentPrice
      }
      content {
        ...TestProductContentFields
        description
        shortDescription
        collectingYourSampleVideo
        collectingYourSampleImage
        carouselImage1: image1
        carouselImage2: image2
        carouselImage3: image3
      }
      testDataFiles {
        ...TestDataFileFields
      }
      options {
        ...PractitionerTestProductOptionFields
      }
    }
    userTestRecommendation(productId: $id) {
      ...UserTestRecommendationBadgeFields
    }
  }
  ${TEST_DATA_FILE_FIELDS}
  ${PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
  ${TEST_PRODUCT_CONTENT_FIELDS}
  ${USER_TEST_RECOMMENDATION_BADGE_FIELDS}
  ${SAMPLE_REPORT_TYPE_FIELDS}
`;

const CONSUMER_TEST_PRODUCT_CONNECTION_FIELDS = gql`
  fragment ConsumerTestProductConnectionFields on TestProductType {
    id
    paymentIsoCode
    organisation
    slug
    productAudience
    userTestRecommendationRank
    matchedSymptoms
    name
    sampleTypes
    rrpCurrentPrice
    rrpFullPrice
    addons {
      id
      paymentIsoCode
      organisation
    }
    laboratory {
      id
      name
      productImageUrl
    }
    content {
      ...TestProductContentFields
      image1
      image2
      image3
    }
    testDataFiles {
      ...TestDataFileFields
    }
  }
  ${TEST_DATA_FILE_FIELDS}
  ${TEST_PRODUCT_CONTENT_FIELDS}
`;

export const PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS = gql`
  fragment PractitionerTestProductConnectionFields on TestProductType {
    id
    paymentIsoCode
    organisation
    slug
    productAudience
    userTestRecommendationRank
    matchedSymptoms
    name
    sampleTypes
    status
    laboratory {
      id
      name
    }
    tradeCurrentPrice
    tradeFullPrice
    rrpFullPrice
    exvatFullPrice
    content {
      ...TestProductContentFields
    }
    testDataFiles {
      ...TestDataFileFields
    }
    options {
      id
      paymentIsoCode
      organisation
    }
    addons {
      id
      paymentIsoCode
      organisation
    }
  }
  ${TEST_DATA_FILE_FIELDS}
  ${TEST_PRODUCT_CONTENT_FIELDS}
`;

export const CONSUMER_TEST_PRODUCT_CONNECTIONS_QUERY = gql`
  query ConsumerTestProductConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $productAudience: String
    $gender: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $isoCode: String
    $paymentIsoCode: String
  ) {
    consumerTestProductConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      productAudience: $productAudience
      gender: $gender
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      isoCode: $isoCode
      paymentIsoCode: $paymentIsoCode
    ) {
      edges {
        node {
          ...ConsumerTestProductConnectionFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONSUMER_TEST_PRODUCT_CONNECTION_FIELDS}
`;

export const CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTIONS_QUERY = gql`
  query ConsumerTestProductConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $productAudience: String
    $gender: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $isoCode: String
    $paymentIsoCode: String
  ) {
    consumerTestProductConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      productAudience: $productAudience
      gender: $gender
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      isoCode: $isoCode
      paymentIsoCode: $paymentIsoCode
    ) {
      edges {
        node {
          ...ConsumerTestProductWithOptionsConnectionFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTION_FIELDS}
`;

export const PRACTITIONER_TEST_PRODUCT_CONNECTIONS_QUERY = gql`
  query PractitionerTestProductConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $productAudience: String
    $gender: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $isoCode: String
    $paymentIsoCode: String
    $organisation: ID
    $filterByFavourites: Boolean
  ) {
    practitionerTestProductConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      productAudience: $productAudience
      gender: $gender
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      isoCode: $isoCode
      paymentIsoCode: $paymentIsoCode
      organisation: $organisation
      filterByFavourites: $filterByFavourites
    ) {
      edges {
        node {
          ...PractitionerTestProductConnectionFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS}
`;

export const SUPPLEMENT_CONNECTION_FIELDS = gql`
  fragment SupplementConnectionFields on SupplementType {
    id
    compositeId
    name
    slug
    brandName
    size
    price
    dietryRestrictions: excludedDietPreferences {
      id
      name
    }
    doseType
    productImage
    status
    productCode
    description
    supplementIngredients
  }
`;

export const SUPPLEMENT_DETAIL_QUERY = gql`
  query SupplementDetailQuery($slug: String!, $userId: ID) {
    supplement(slug: $slug) {
      ...SupplementConnectionFields
      description
      directions
      relatedUserResults(userId: $userId) {
        id
        ...UserResultFieldsTruncated
        chartConfiguration {
          ...ChartConfigurationFields
        }
        resultState {
          id
          name
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
  ${SUPPLEMENT_CONNECTION_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;

export const SUPPLEMENT_CONNECTIONS_QUERY = gql`
  query SupplementConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $brandNames: String
    $doseTypes: String
    $dietryRestrictions: String
  ) {
    supplementConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      brandNames: $brandNames
      doseTypes: $doseTypes
      dietryRestrictions: $dietryRestrictions
    ) {
      edges {
        node {
          ...SupplementConnectionFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SUPPLEMENT_CONNECTION_FIELDS}
`;

/**
 * Supplement listing page needs dietryRestrictions,
 * types and brands.  TestProductListingPage needs
 * sampleTypes, userTestRecommendations, testingServices,
 * and categories.
 *
 * Get them all in one query so when user switches between
 * supplements and tests we don't get a loading icon.
 * Smoother experience this way.
 */

export const SHOP_LISTING_FILTERS_QUERY = gql`
  query FiltersQuery($includeFilters: [IncludeFilters]) {
    dietryRestrictions: dietPreferences {
      id
      name
    }
    types: supplementDoseTypes
    brands: supplementBrands
    sampleTypes
    testingServices
    categories: areasOfInterest(includeFilters: $includeFilters) {
      ...AreaOfInterestFields
    }
  }
  ${AREA_OF_INTEREST_FIELDS}
`;

export const OMNOS_TEST_PRODUCTS_QUERY = gql`
  query OmnosTestProductsQuery(
    $productAudience: String
    $orderBy: String
    $limit: Int
    $isMostPopularTest: Boolean
  ) {
    consumerTestProducts(
      productAudience: $productAudience
      orderBy: $orderBy
      limit: $limit
      isMostPopularTest: $isMostPopularTest
    ) {
      id
      paymentIsoCode
      isPractitionerFavourite
      organisation
      name
      slug
      laboratory {
        id
        name
        productImageUrl
      }
      sampleTypes
      productAudience
      userTestRecommendationRank
      productFamily {
        id
        slug
      }
      rrpCurrentPrice
      rrpFullPrice
      content {
        id
        image1
        categories: areasOfInterest {
          id
          name
        }
        testType
        shortDescription
      }
    }
  }
`;
