import { useEffect, useRef } from "react";

import Box from "@/components/Box";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { theme } from "@/core/theme";
import { useOnScreen } from "@/hooks/useOnScreen";
import { PanelBoxV2 } from "@/tpo/Boxes";

import OrganisationTabs from "../OrganisationTabs";
import { OrganisationBasketListing } from "./OrganisationBasketListing";
import NonStockCheckoutForm from "./forms/NonStockCheckoutForm";
import StockCheckoutForm from "./forms/StockCheckoutForm";
import { BasketTotals } from "./ui/BasketTotals";

export default function Checkout() {
  const basketSummaryRef = useRef();
  const basketSummaryIsVisible = useOnScreen(basketSummaryRef);
  const { basket } = useOrganisationBasketContext();

  useEffect(() => {
    // if the basket is empty, redirect to the shop
    if (basket && basket.productLineItems.length === 0 && basket.supplementLineItems.length === 0) {
      window.location.href = "/partners/dashboard/shop";
    }
  }, [basket]);

  return (
    <>
      <OrganisationTabs selectedTab="shop" />
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [60, 60, 80],
          pb: 60,
          px: [20, 20, "5.5vw"],
          bg: "haze"
        }}
        stacked
        gap={40}
      >
        <Box fontFamily="gilroyBold" fontSize={36}>
          Checkout
        </Box>
        <PanelBoxV2
          ref={basketSummaryRef}
          maxWidth={760}
          outer={{
            bg: "white",
            px: [20, 20, 40],
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            borderRadius: 5
          }}
        >
          <OrganisationBasketListing />
        </PanelBoxV2>
        {basket?.purchaseAsStock ? <StockCheckoutForm /> : <NonStockCheckoutForm />}
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={760}
        outer={{
          bg: "white",
          position: "sticky",
          bottom: 0,
          borderTopColor: "haze",
          borderTopStyle: "solid",
          borderTopWidth: 1,
          px: 40,
          pt: 20,
          pb: 40,
          transition: "all 100ms ease-out",
          transform: basketSummaryIsVisible ? "translateY(100%)" : "translateY(0)",
          opacity: basketSummaryIsVisible ? 0 : 1
        }}
        stacked
        gap={0}
        inner={{
          alignItems: "flex-end"
        }}
      >
        <BasketTotals basket={basket} payee={basket?.payee} />
      </PanelBoxV2>
    </>
  );
}
