import { GENERIC_USER_PROFILE_QUERY } from "@/graphql/accounts";
import { useQuery } from "@apollo/client";

export default function useUserProfile() {
  const { data, loading } = useQuery(GENERIC_USER_PROFILE_QUERY, {
    fetchPolicy: "cache-first"
  });

  return {
    loading,
    isPlatformUser: !!data?.user?.platformUserProfile?.id,
    isPartnerUser: !!data?.user?.partnerUserProfile?.id && data?.user?.partnerUserProfile?.approved,
    platformUserProfile: data?.user?.platformUserProfile,
    partnerUserProfile: data?.user?.partnerUserProfile,
    user: data?.user
  };
}
