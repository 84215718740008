import Box from "@/components/Box";
import Currency from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";

import { getPrice } from "./Basket";
import getClinicFees from "./utils/getClinicFees";
import groupItemsByName from "./utils/groupItemByName";

function Item({ count, name, price, previousPrice, "data-testid": dataTestId }) {
  return (
    <Group
      alignItems="flex-start"
      justifyContent="space-between"
      gap={10}
      data-component-name="BasketItem"
      data-testid={dataTestId || "lineInBasket"}
    >
      <Group alignItems="flex-start" gap={5} mr="auto">
        <Box fontFamily="gilroyBold" fontSize={18} data-testid="count">{`${count}`}</Box>
        <Box fontFamily="gilroyBold" fontSize={18}>
          x
        </Box>
        <Box fontFamily="gilroyBold" fontSize={18} data-testid="name">
          {name}
        </Box>
      </Group>
      {previousPrice && previousPrice > price && (
        <Currency
          fontSize={28}
          value={previousPrice}
          color="red"
          strikethrough
          data-testid="previousPrice"
        />
      )}
      <Currency fontSize={28} value={price} data-testid="currentPrice" />
    </Group>
  );
}

function ItemWithClinic({ count, name, price, previousPrice, clinicName, address, fees }) {
  return (
    <Stack gap={20} data-component-name="BasketItemWithClinic" data-testid="lineInBasket">
      <Item
        count={count}
        name={name}
        price={price}
        previousPrice={previousPrice}
        data-testid="BasketItem"
      />
      <Box>
        <Box fontFamily="gilroyBold" fontSize={16} data-testid="clinicName">
          {clinicName}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14} data-testid="clinicAddress">
          {address}
        </Box>
        <Group alignItems="center" justifyContent="space-between" data-testid="clinicFee">
          <Box fontFamily="gilroyBold" fontSize={16}>
            Clinic fee
          </Box>
          <Currency fontSize={18} value={fees} />
        </Group>
      </Box>
    </Stack>
  );
}

export default function OrderSummary({ order, title, showBookingLinks = false }) {
  const allOrderItems = [...order.testItems, ...order.supplementItems];
  const orderItemsByName = groupItemsByName(allOrderItems);

  const postageCosts = parseFloat(order.postageCosts);

  return (
    <>
      <Box as="h2" fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        {title}
      </Box>
      <Stack gap={[10, 10, 20]}>
        {Object.keys(orderItemsByName).map(orderItem => {
          const lineSummary = orderItemsByName[orderItem];
          const clinicBookings = lineSummary.clinicBookings;
          let clinic = null;
          if (lineSummary.clinicBookings.length > 0) {
            clinic = lineSummary.clinicBookings[0].location;
          }
          return clinic ? (
            <ItemWithClinic
              key={orderItem}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.rrpFullPrice === undefined
                  ? undefined
                  : lineSummary.rrpFullPrice * lineSummary["count"]
              }
              clinicName={clinic.name || clinic.clinic?.name || "Clinic"}
              address={clinic.address}
              fees={getClinicFees(lineSummary.clinicBookings)}
              count={lineSummary.count}
              clinicBookings={clinicBookings}
              showBookingLinks={showBookingLinks}
              orderId={order.id}
            />
          ) : (
            <Item
              key={orderItem}
              name={lineSummary.nameInBasket}
              price={getPrice(lineSummary)}
              previousPrice={
                lineSummary.rrpFullPrice === undefined
                  ? undefined
                  : lineSummary.rrpFullPrice * lineSummary["count"]
              }
              count={lineSummary.count}
            />
          );
        })}
      </Stack>
      <Stack alignItems="flex-end" gap={20}>
        {!!order.totalDiscount && (
          <Currency
            prefix="DISCOUNT"
            value={-1 * order.totalDiscount}
            fontSize={28}
            data-testid="discountTotal"
            color="red"
          />
        )}
        <Currency
          prefix="SHIPPING"
          value={parseFloat(order.postageCosts).toFixed(2)}
          fontSize={28}
          data-testid="shippingTotal"
        />
        <Currency
          prefix="TOTAL"
          value={(parseFloat(order.discountedTotal) + parseFloat(order.postageCosts)).toFixed(2)}
          fontSize={44}
          data-testid="totalWithPostage"
        />
      </Stack>
    </>
  );
}

OrderSummary.defaultProps = {
  title: "Order summary"
};
