import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { theme } from "@/core/theme";
import { LOGIN_URL, SIGNUP_DONE_URL } from "@/core/urls";
import { SIGNUP_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import Asterisk from "@/tpo/Asterisk";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import ControlledFormField from "@/tpo/ControlledFormField";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import FormControl from "@/tpo/FormControl";
import { Error } from "@/tpo/InputWrapper";
import FloatingLabelPasswordInput from "@/tpo/PasswordInput";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import Box from "./Box";
import CustomerPage from "./CustomerPage";
import { InternalTextLink } from "./Links";

export default function SignupPage() {
  const history = useHistory();

  const api = useDjangoGraphqlForm({
    mutation: SIGNUP_MUTATION,
    mutationName: "signupMutation",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      marketingConsent: true
    },
    handleSuccess: ({ data }) => {
      history.push(SIGNUP_DONE_URL, { createdUserEmail: data.signupMutation.user.email });
    }
  });

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Sign Up"
      }}
    >
      <Center
        stacked
        gap={[30, 30, 40]}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={20}
      >
        <Box maxWidth={760} mx="auto">
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            We're looking forward to having you on the platform. Simply sign up with your email and
            a strong password below to create an account and start your free self assessment.
          </Box>
        </Box>
        <Stack
          as="form"
          maxWidth={600}
          mx="auto"
          onSubmit={api.methods.handleSubmit(api.onSubmit)}
          gap={[30, 30, 40]}
        >
          {!!api.nonFieldError && <Error error={api.nonFieldError} />}
          <FormProvider {...api.methods}>
            <Box>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Your details
              </Box>
              <Spacer py={[2, 2, 20]} />
              <Stack gap={20}>
                <ControlledFormField
                  name="firstName"
                  label="First name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="lastName"
                  label="Last name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="email"
                  label="Email address"
                  Component={FloatingLabelInput}
                />
              </Stack>
            </Box>
            <FormControl
              as="fieldset"
              label="Password"
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: 18
              }}
              fields={
                <>
                  <ControlledFormField
                    label="Password"
                    name="password"
                    Component={FloatingLabelPasswordInput}
                  />
                  <ControlledFormField
                    label="Confirm Password"
                    name="confirmPassword"
                    Component={FloatingLabelPasswordInput}
                  />
                </>
              }
            />
            <ControlledFormField
              name="marketingConsent"
              label="By submitting this form, you indicate your consent to receiving our latest health news, exclusive promotions and product updates. Uncheck this box if you would not like to receive this information"
              Component={Checkbox}
              labelProps={{
                fontFamily: "gilroyMedium",
                fontSize: 14
              }}
              styles={{
                wrapperProps: {
                  alignItems: "flex-start"
                }
              }}
            />
            <ButtonV2
              color="green"
              rightIcon={<ChevronComponent />}
              mx="auto"
              size={["sm", "sm", "md"]}
            >
              submit
            </ButtonV2>
            <InternalTextLink href={LOGIN_URL} mx="auto">
              Already have an account?
            </InternalTextLink>
          </FormProvider>
        </Stack>
      </Center>
    </CustomerPage>
  );
}
