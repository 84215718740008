import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import Page from "@/components/Page";
import { theme } from "@/core/theme";
import { ACTIVATE_EXTERNAL_KIT_MUTATION } from "@/graphql/providers";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import ButtonV2 from "@/v2/Buttons";

import Center from "./Center";
import Checkbox from "./Checkbox";
import ChevronComponent from "./Chevron";
import ControlledFormField from "./ControlledFormField";
import ControlledRadioGroup from "./ControlledRadioGroup";
import DateInput from "./DateInput";
import ExternalKitRegistrationHeader from "./ExternalKitRegistrationHeader";
import FloatingLabelInput from "./FloatingLabelInput";
import FormControl from "./FormControl";
import { Error } from "./InputWrapper";
import Stack from "./Stack";

function ExternalKitRegistration() {
  useDocTitle("Register test kit");
  const history = useHistory();

  const { loading, methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: ACTIVATE_EXTERNAL_KIT_MUTATION,
    mutationName: "activateExternalTestItemMutation",
    defaultValues: {
      testItemId: "",
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userDateOfBirth: "",
      userGender: "",
      testConsent: false
    },
    handleSuccess: () => {
      return history.push("/external-kit-registration/complete");
    }
  });

  return (
    <Page addNavBarHeight={false} header={<ExternalKitRegistrationHeader />}>
      <Center
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20]}
        stacked
        gap={40}
      >
        <Box
          fontFamily="gilroyBold"
          fontSize={[28, 28, 36]}
          maxWidth={760}
          width="100%"
          pb={[20, 20, 30]}
        >
          Register your kit
        </Box>
        <Stack maxWidth={600} width="100%" gap={40}>
          {nonFieldError && <Error error={nonFieldError} data-testid="nonFieldError" />}
          <FormProvider {...methods}>
            <Stack as="form" gap={40} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack gap={20}>
                <FormControl
                  label={
                    <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                      Test Kit Barcode
                    </Box>
                  }
                  fields={
                    <ControlledFormField
                      name="testItemId"
                      label="Barcode number"
                      Component={FloatingLabelInput}
                    />
                  }
                />
                <FormControl
                  as="fieldset"
                  label="User Details"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: [16, 16, 18]
                  }}
                  fields={
                    <>
                      <ControlledFormField
                        name="userFirstName"
                        label="First Name"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="userLastName"
                        label="Last Name"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="userEmail"
                        label="Email"
                        Component={FloatingLabelInput}
                      />
                    </>
                  }
                />
                <FormControl
                  label={
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="36px">
                      Date of birth
                    </Box>
                  }
                >
                  <ControlledFormField
                    name="userDateOfBirth"
                    Component={DateInput}
                    getDay={dateStr => dateStr.split("-")[2]}
                    getMonth={dateStr => dateStr.split("-")[1]}
                    getYear={dateStr => dateStr.split("-")[0]}
                    constructDate={({ day, month, year }) => {
                      return `${year}-${month}-${day}`;
                    }}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="36px">
                      Gender
                    </Box>
                  }
                >
                  <ControlledRadioGroup
                    Container={Stack}
                    containerProps={{
                      gap: 20
                    }}
                    name="userGender"
                    values={[
                      {
                        label: "Male",
                        value: "Male"
                      },
                      {
                        label: "Female",
                        value: "Female"
                      }
                    ]}
                  />
                </FormControl>
              </Stack>
              <FormControl
                fields={
                  <ControlledFormField
                    name="testConsent"
                    label={
                      <Box as="span" fontFamily="gilroyMedium" fontSize={14}>
                        I confirm that I am the person providing the sample. The results for this
                        test will be linked to this account.
                      </Box>
                    }
                    Component={Checkbox}
                    styles={{
                      wrapperProps: {
                        alignItems: "flex-start"
                      }
                    }}
                  />
                }
              />
              <Center>
                <ButtonV2
                  color="green"
                  rightIcon={<ChevronComponent />}
                  size={["sm", "sm", "md"]}
                  disabled={loading}
                >
                  Submit
                </ButtonV2>
              </Center>
            </Stack>
          </FormProvider>
        </Stack>
      </Center>
    </Page>
  );
}

export default ExternalKitRegistration;
