import { forwardRef } from "react";

import Box from "@/components/Box";
import { theme } from "@/core/theme";
import Tick from "@/images/tick.svg?react";

import { Error } from "./InputWrapper";

export const BaseCheckbox = forwardRef(
  (
    {
      name,
      onChange,
      value,
      disabled,
      color,
      checked: checkedProp,
      uncheckedColor,
      "data-component-name": dataComponentName = "checkbox",
      size = "md",
      ...props
    },
    ref
  ) => {
    /**
     * For checkbox group the value will be non boolean in which case you checkedProp,
     * otherwise for a single checkbox the value should be boolean.
     */

    const checked = value === false || value === true ? value : checkedProp;

    let checkboxSize = 40;
    let tickSize = 25;

    if (size === "sm") {
      checkboxSize = 28;
      tickSize = 12;
    }

    let tickColor;
    let bg;
    let borderColor;

    if (checked) {
      if (disabled) {
        bg = "midGrey";
        borderColor = "midGrey";
        tickColor = "white";
      } else {
        bg = "green";
        borderColor = "green";
        tickColor = "white";
      }
    } else {
      if (disabled) {
        bg = "transparent";
        borderColor = "dark";
        tickColor = theme.colors.dark;
      } else {
        bg = "transparent";
        borderColor = uncheckedColor;
        tickColor = uncheckedColor;
      }
    }

    return (
      <Box position="relative" data-component-name={dataComponentName}>
        <Box
          as="input"
          id={`${name}.${value}`}
          type="checkbox"
          onChange={onChange}
          value={value}
          disabled={disabled}
          opacity={0}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          height={checkboxSize}
          ref={ref}
          name={name}
          checked={checked}
        />
        <Box
          htmlFor={`${name}.${value}`}
          as="label"
          color={color}
          display="flex"
          alignItems="center"
          {...props}
        >
          <Box
            bg={bg}
            width={checkboxSize}
            height={checkboxSize}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderColor={borderColor}
            borderWidth="2px"
            borderStyle="solid"
            borderRadius="5px"
            flexShrink={0}
          >
            <Tick fill={tickColor} width={tickSize} />
          </Box>
        </Box>
      </Box>
    );
  }
);

const Checkbox = forwardRef(({ label, error, uncheckedColor, styles, ...props }, ref) => (
  <Box className="inputWrapper" {...styles?.inputWrapperProps}>
    <Box display="flex" alignItems="center" gap={10} {...styles?.wrapperProps}>
      <BaseCheckbox
        {...styles?.checkbox}
        {...props}
        uncheckedColor={error ? theme.colors.red : uncheckedColor}
        ref={ref}
      />
      <Box
        fontFamily="gilroyMedium"
        fontSize={14}
        as="label"
        data-testid="label"
        {...styles?.labelProps}
      >
        {label}
      </Box>
    </Box>
    {error && <Error error={error} mt={2} />}
  </Box>
));

Checkbox.defaultProps = {
  uncheckedColor: theme.colors.carbon
};

export default Checkbox;
