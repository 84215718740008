import { useEffect, useState } from "react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import { InternalTextLink } from "@/components/Links";
import { theme } from "@/core/theme";
import { getOrderProcessingUrl } from "@/core/urls";
import { GET_PAYMENT_INTENT } from "@/graphql/shop";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import { Error } from "@/tpo/InputWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";

const cardStyle = {
  base: {
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: theme.colors.slate
    }
  },
  invalid: {
    color: theme.colors.red,
    iconColor: theme.colors.red
  }
};

export default function StripePaymentFormContent({ order, platformUserProfile }) {
  const [succeeded, setSucceeded] = useState(false);
  const [paymentErrors, setPaymentErrors] = useState("");
  const [paymentIntentErrors, setPaymentIntentErrors] = useState("");
  const [cardErrors, setCardErrors] = useState("");
  const [processing, setProcessing] = useState("");
  const [empty, setEmpty] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [saveCard, setSaveCard] = useState(false);
  const [useSavedCard, setUseSavedCard] = useState(false);

  const [getPaymentIntentMutation] = useMutation(GET_PAYMENT_INTENT, {
    onCompleted: data => {
      if (data.getPaymentIntent.status === "Success") {
        setPaymentIntentErrors("");
        setClientSecret(data.getPaymentIntent.clientSecret);
      } else {
        setPaymentIntentErrors(data.getPaymentIntent.status);
      }
    },
    onError: error => {
      console.log("Get payment intent error");
      console.log(error);
      Sentry.captureException(error);
      setPaymentIntentErrors(error.message);
    }
  });

  useEffect(() => {
    if (order.discountedTotal !== 0) {
      getPaymentIntentMutation({
        variables: {
          orderId: order.id
        }
      });
    }
  }, [getPaymentIntentMutation, order.discountedTotal, order.id]);

  const onChange = async event => {
    setEmpty(event.empty);
    setCardErrors(event.error ? event.error.message : "");
  };

  const onSubmit = async () => {
    setProcessing(true);

    let variables;

    if (useSavedCard) {
      variables = {
        payment_method: platformUserProfile.stripeCardPaymentMethodId
      };
    } else {
      variables = {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${order.billingFirstName} ${order.billingLastName}`,
            address: {
              line1: order.billingAddress,
              line2: order.billingTownCity,
              postal_code: order.billingPostalCode
            }
          }
        },
        shipping: {
          name: `${order.shippingFirstName} ${order.shippingLastName}`,
          address: {
            line1: order.shippingAddress,
            line2: order.shippingTownCity,
            postal_code: order.shippingPostalCode,
            country: order.shippingCountry
          },
          phone: order.shippingPhone
        }
      };
      if (saveCard) {
        variables.setup_future_usage = "on_session";
      }
    }
    try {
      const payload = await stripe.confirmCardPayment(clientSecret, variables);
      if (payload.error) {
        setPaymentErrors(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setPaymentErrors("");
        setProcessing(false);
        setSucceeded(true);
      }
    } catch (err) {
      setPaymentErrors("Sorry an error has occured");
    }
  };

  useEffect(() => {
    if (succeeded) {
      history.push(getOrderProcessingUrl(order.id));
    }
    // eslint-disable-next-line
  }, [succeeded]);

  return (
    <Stack
      as="form"
      gap={40}
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {platformUserProfile?.stripeCardPaymentMethodId && (
        <Box mb={20}>
          <InternalTextLink
            underline
            as="button"
            color="purple"
            onClick={e => {
              e.preventDefault();
              setUseSavedCard(!useSavedCard);
            }}
          >
            {useSavedCard
              ? "Use new card"
              : `Use saved ${platformUserProfile.stripeCardBrand} card ending in ${platformUserProfile.stripeCardLast4}`}
          </InternalTextLink>
        </Box>
      )}
      {!useSavedCard && (
        <>
          <Box borderColor="haze" borderWidth="1px" borderStyle="solid" borderRadius="5px" p="10px">
            <CardElement
              id="card-element"
              options={{ style: cardStyle, hidePostalCode: true }}
              onChange={onChange}
            />
          </Box>
          {platformUserProfile && (
            <Box pt={20}>
              <Checkbox
                value={saveCard}
                label="Save card"
                onChange={() => {
                  setSaveCard(!saveCard);
                }}
              />
            </Box>
          )}
        </>
      )}
      <Center stacked gap={20}>
        <ButtonV2
          width="100%"
          maxWidth={300}
          color="green"
          disabled={
            (empty && !useSavedCard) || cardErrors || paymentIntentErrors || processing || succeeded
          }
        >
          pay now
        </ButtonV2>
        {(cardErrors || paymentIntentErrors || paymentErrors) && (
          <Error error={cardErrors || paymentIntentErrors || paymentErrors} />
        )}
      </Center>
    </Stack>
  );
}
