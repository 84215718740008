import gql from "graphql-tag";

export const ANSWER_CONFIG_FIELDS = gql`
  fragment AnswerConfigFields on QuestionAnswerConfigType {
    id
    name
    answer0
    answer1
    answer2
    answer3
  }
`;

export const QUESTION_FIELDS = gql`
  fragment QuestionFields on QuestionType {
    id
    presentationSection
    text
    specifiedSex
    answerConfig {
      ...AnswerConfigFields
    }
  }
  ${ANSWER_CONFIG_FIELDS}
`;

export const EXAMPLE_FIELDS = gql`
  fragment ExampleFields on ExampleType {
    id
    name
    category
    parentExample
    slug
  }
`;

export const SUPPLEMENT_FIELDS = gql`
  fragment SupplementFields on SupplementType {
    id
    name
    brandName
    productCode
    price
    slug
  }
`;

export const CHARACTERISTIC_FIELDS = gql`
  fragment CharacteristicFields on CharacteristicType {
    id
    name
  }
`;

export const RECOMMENDATION_FIELDS = gql`
  fragment RecommendationFields on RecommendationType {
    id
    name
    verb
    characteristic {
      ...CharacteristicFields
    }
  }
  ${CHARACTERISTIC_FIELDS}
`;

export const USER_ANSWER_FIELDS = gql`
  fragment UserAnswerFields on UserAnswerType {
    id
    question {
      ...QuestionFields
    }
    answer
  }
  ${QUESTION_FIELDS}
`;
