import { createContext, useContext, useEffect, useMemo, useRef } from "react";

import { createPortal } from "react-dom";

import Box from "@/components/Box";
import { CloseButton as BaseCloseButton } from "@/v2/Buttons";

import Stack from "./Stack";
import { body } from "./contexts/modal";

const DrawerContext = createContext();

function DrawerProvider({ close, opened, children, maxWidth, position }) {
  const api = useMemo(
    () => ({
      close,
      opened,
      maxWidth,
      position
    }),
    [close, opened, maxWidth, position]
  );
  return <DrawerContext.Provider value={api}>{children}</DrawerContext.Provider>;
}

DrawerProvider.defaultProps = {
  position: "right"
};

function Root({ children, bg, overflow }) {
  const { maxWidth, opened, close, position } = useContext(DrawerContext);
  const ref = useRef();

  useEffect(() => {
    if (opened) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }
    return () => {
      body.style.overflow = "unset";
    };
  }, [opened]);

  if (!opened) return null;

  let conditionalContainerProps = {};

  if (position === "right") {
    conditionalContainerProps = {
      ...conditionalContainerProps,
      flexDirection: "row"
    };
  } else if (position === "left") {
    conditionalContainerProps = {
      ...conditionalContainerProps,
      flexDirection: "row-reverse"
    };
  } else if (position === "top") {
    conditionalContainerProps = {
      ...conditionalContainerProps,
      flexDirection: "column-reverse"
    };
  } else if (position === "bottom") {
    conditionalContainerProps = {
      ...conditionalContainerProps,
      flexDirection: "column"
    };
  }

  return createPortal(
    <Box
      display="flex"
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={1000000}
      ref={ref}
      {...conditionalContainerProps}
    >
      <Box bg="black" flexGrow={1} opacity={0.25} onClick={close} />
      <Stack
        bg={bg}
        width={["100%", maxWidth]}
        position="relative"
        data-component-name="drawer"
        overflow={overflow}
      >
        {children}
      </Stack>
    </Box>,
    body
  );
}

function CloseButton(props) {
  const { close } = useContext(DrawerContext);
  return <BaseCloseButton onClick={close} {...props} />;
}

export default Object.assign(
  {},
  {
    Root,
    Context: DrawerContext,
    Provider: DrawerProvider,
    CloseButton
  }
);
