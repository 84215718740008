export default function PoweredByRegenerus({ fill }) {
  return (
    <svg
      width="184"
      height="16"
      viewBox="0 0 184 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.7898 4.55762H63.6298L61.0618 11.1096C60.6058 12.2616 59.7178 13.0416 58.6378 12.9576V12.2136C59.4178 12.2976 59.9578 11.7456 60.2818 10.9656L60.4378 10.5936L57.7498 4.55762H58.5898L60.8458 9.58562L62.7898 4.55762Z"
        fill="inherit"
      />
      <path
        d="M54.2913 4.41372C55.9833 4.41372 57.3873 5.78171 57.3873 7.55772C57.3873 9.33372 55.9833 10.7017 54.2913 10.7017C53.2233 10.7017 52.3713 10.1857 51.8673 9.38172V10.5577H51.0753V2.15771H51.8673V5.73372C52.3713 4.92972 53.2233 4.41372 54.2913 4.41372ZM54.2313 9.93372C55.5633 9.93372 56.5953 8.88972 56.5953 7.55772C56.5953 6.22572 55.5633 5.18172 54.2313 5.18172C52.8993 5.18172 51.8673 6.22572 51.8673 7.55772C51.8673 8.88972 52.8993 9.93372 54.2313 9.93372Z"
        fill="inherit"
      />
      <path
        d="M45.6064 2.15771H46.3984V10.5577H45.6064V9.38172C45.1024 10.1857 44.2504 10.7017 43.1824 10.7017C41.4904 10.7017 40.0864 9.33372 40.0864 7.55772C40.0864 5.78171 41.4904 4.41372 43.1824 4.41372C44.2504 4.41372 45.1024 4.92972 45.6064 5.73372V2.15771ZM43.2424 9.93372C44.5744 9.93372 45.6064 8.88972 45.6064 7.55772C45.6064 6.22572 44.5744 5.18172 43.2424 5.18172C41.9104 5.18172 40.8784 6.22572 40.8784 7.55772C40.8784 8.88972 41.9104 9.93372 43.2424 9.93372Z"
        fill="inherit"
      />
      <path
        d="M36.2106 4.41357C38.0466 4.41357 39.1866 5.93757 39.1866 7.58157C39.1866 7.70157 39.1746 7.83357 39.1626 7.95357H33.8946C34.0626 9.17757 35.0106 9.93357 36.2826 9.93357C37.2306 9.93357 37.8906 9.48957 38.2146 8.91357L38.9106 9.32157C38.4066 10.1376 37.4826 10.7016 36.2706 10.7016C34.3746 10.7016 33.0786 9.36957 33.0786 7.55757C33.0786 5.79357 34.3506 4.41357 36.2106 4.41357ZM36.2106 5.18157C34.9506 5.18157 34.0506 6.00957 33.8946 7.18557H38.3706C38.2026 5.86557 37.2426 5.18157 36.2106 5.18157Z"
        fill="inherit"
      />
      <path
        d="M30.8204 5.55391C31.2404 4.71391 31.9484 4.46191 32.7164 4.46191V5.22991C31.7564 5.22991 30.8204 5.73391 30.8204 7.22191V10.5579H30.0284V4.55791H30.8204V5.55391Z"
        fill="inherit"
      />
      <path
        d="M25.7926 4.41357C27.6286 4.41357 28.7686 5.93757 28.7686 7.58157C28.7686 7.70157 28.7566 7.83357 28.7446 7.95357H23.4766C23.6446 9.17757 24.5926 9.93357 25.8646 9.93357C26.8126 9.93357 27.4726 9.48957 27.7966 8.91357L28.4926 9.32157C27.9886 10.1376 27.0646 10.7016 25.8526 10.7016C23.9566 10.7016 22.6606 9.36957 22.6606 7.55757C22.6606 5.79357 23.9326 4.41357 25.7926 4.41357ZM25.7926 5.18157C24.5326 5.18157 23.6326 6.00957 23.4766 7.18557H27.9526C27.7846 5.86557 26.8246 5.18157 25.7926 5.18157Z"
        fill="inherit"
      />
      <path
        d="M21.2447 4.55762H22.0607L20.2126 10.5576H19.3486L17.8007 5.72162L16.2527 10.5576H15.3886L13.5406 4.55762H14.3566L15.8327 9.53762L17.4047 4.55762H18.1967L19.7687 9.53762L21.2447 4.55762Z"
        fill="inherit"
      />
      <path
        d="M9.77368 10.7016C8.02168 10.7016 6.61768 9.33357 6.61768 7.55757C6.61768 5.78157 8.02168 4.41357 9.77368 4.41357C11.5257 4.41357 12.9297 5.78157 12.9297 7.55757C12.9297 9.33357 11.5257 10.7016 9.77368 10.7016ZM9.77368 9.93357C11.1057 9.93357 12.1377 8.88957 12.1377 7.55757C12.1377 6.22557 11.1057 5.18157 9.77368 5.18157C8.44168 5.18157 7.40968 6.22557 7.40968 7.55757C7.40968 8.88957 8.44168 9.93357 9.77368 9.93357Z"
        fill="inherit"
      />
      <path
        d="M3.5 2.15771C5.012 2.15771 6.14 3.28572 6.14 4.79772C6.14 6.29772 5.012 7.43772 3.5 7.43772H1.34V10.5577H0.5V2.15771H3.5ZM3.5 6.64572C4.544 6.64572 5.3 5.86572 5.3 4.79772C5.3 3.71771 4.544 2.94972 3.5 2.94972H1.34V6.64572H3.5Z"
        fill="inherit"
      />
      <g clipPath="url(#clip0_3354_17155)">
        <path
          d="M75.1509 3.29905C75.6346 2.98523 76.2517 2.8291 77.0008 2.8291V3.53053H76.7871C76.028 3.53053 75.397 3.75735 74.894 4.20943C74.391 4.66229 74.1395 5.40101 74.1395 6.42635V11.0987H73.4999V2.993H74.1403V4.66463C74.33 4.06728 74.6672 3.61209 75.1509 3.29905Z"
          fill="inherit"
        />
        <path
          d="M85.9527 7.30649H79.2781C79.2973 8.03278 79.4485 8.64488 79.7332 9.14279C80.0179 9.6407 80.3928 10.0136 80.8572 10.2621C81.3216 10.5107 81.83 10.635 82.3801 10.635C83.1392 10.635 83.7749 10.4385 84.2871 10.0454C84.7994 9.65236 85.1219 9.11793 85.2545 8.44058H85.9234C85.7714 9.26707 85.3826 9.93588 84.7562 10.4486C84.1298 10.9612 83.3375 11.2176 82.3793 11.2176C81.658 11.2176 81.0131 11.0482 80.4437 10.7103C79.8744 10.3724 79.4285 9.88694 79.106 9.25465C78.7835 8.62313 78.6223 7.88364 78.6223 7.03772C78.6223 6.19182 78.7812 5.45543 79.0991 4.82857C79.4169 4.20171 79.8605 3.72167 80.4298 3.38843C80.9992 3.05519 81.6487 2.88818 82.3793 2.88818C83.1099 2.88818 83.7618 3.05519 84.308 3.38843C84.8534 3.72167 85.2684 4.16443 85.5531 4.71672C85.8378 5.269 85.9797 5.86402 85.9797 6.5002C85.9797 6.85829 85.9705 7.12705 85.9512 7.30649H85.9527ZM84.9776 4.93266C84.7068 4.43552 84.3396 4.06422 83.8744 3.82032C83.4092 3.57641 82.907 3.45445 82.3654 3.45445C81.8239 3.45445 81.324 3.57641 80.8642 3.82032C80.4036 4.06422 80.0295 4.43475 79.7402 4.93266C79.4509 5.43057 79.2966 6.03724 79.2773 6.75342H85.3402C85.3687 6.03724 85.2476 5.4298 84.9776 4.93266Z"
          fill="inherit"
        />
        <path
          d="M93.8658 3.50825C94.4351 3.9215 94.8332 4.45126 95.0616 5.09831V2.99325H95.7019V11.3529C95.7019 12.0994 95.5453 12.7534 95.232 13.3158C94.9188 13.8782 94.4968 14.3109 93.9653 14.6146C93.4338 14.9183 92.8413 15.0698 92.1863 15.0698C91.2189 15.0698 90.4166 14.8259 89.7809 14.3381C89.1452 13.8503 88.7279 13.1939 88.5288 12.3674H89.1692C89.3589 13.0144 89.7146 13.5294 90.2368 13.9124C90.7584 14.2953 91.4087 14.4872 92.1863 14.4872C92.7271 14.4872 93.2155 14.3606 93.6521 14.1066C94.0887 13.8526 94.432 13.4891 94.6843 13.0168C94.9358 12.5437 95.0616 11.9891 95.0616 11.3521V8.97831C94.834 9.62537 94.4328 10.1598 93.8588 10.5831C93.2849 11.0065 92.5944 11.2178 91.7883 11.2178C91.0669 11.2178 90.4243 11.0461 89.8596 10.7028C89.2949 10.3594 88.8536 9.87161 88.5358 9.24009C88.2179 8.60857 88.059 7.87451 88.059 7.0387C88.059 6.20289 88.2179 5.47117 88.5358 4.84431C88.8536 4.21745 89.2949 3.73507 89.8596 3.3964C90.4243 3.0585 91.0669 2.88916 91.7883 2.88916C92.6045 2.88916 93.2972 3.09578 93.8658 3.50903V3.50825ZM94.6411 5.16512C94.3611 4.62293 93.9792 4.20502 93.4955 3.9114C93.0118 3.61778 92.471 3.47097 91.8731 3.47097C91.2752 3.47097 90.6966 3.61312 90.2222 3.89664C89.7477 4.18016 89.3774 4.59108 89.1121 5.12783C88.8467 5.66536 88.7132 6.30232 88.7132 7.0387C88.7132 7.77509 88.8459 8.41204 89.1121 8.94957C89.3774 9.4871 89.7501 9.90035 90.2291 10.1885C90.7082 10.4775 91.2559 10.6212 91.8731 10.6212C92.4903 10.6212 93.0141 10.4744 93.5024 10.1808C93.9908 9.88714 94.3726 9.46924 94.6481 8.92705C94.9235 8.38486 95.0608 7.75567 95.0608 7.0387C95.0608 6.32174 94.9204 5.70808 94.6411 5.16512Z"
          fill="inherit"
        />
        <path
          d="M105.508 7.30649H98.8332C98.8525 8.03278 99.0037 8.64488 99.2883 9.14279C99.573 9.6407 99.9479 10.0136 100.412 10.2621C100.877 10.5107 101.385 10.635 101.935 10.635C102.694 10.635 103.33 10.4385 103.842 10.0454C104.354 9.65236 104.677 9.11793 104.81 8.44058H105.479C105.327 9.26707 104.938 9.93588 104.311 10.4486C103.685 10.9612 102.893 11.2176 101.934 11.2176C101.213 11.2176 100.568 11.0482 99.9988 10.7103C99.4295 10.3724 98.9836 9.88694 98.6611 9.25465C98.3387 8.62313 98.1774 7.88364 98.1774 7.03772C98.1774 6.19182 98.3363 5.45543 98.6542 4.82857C98.972 4.20171 99.4156 3.72167 99.985 3.38843C100.554 3.05519 101.204 2.88818 101.934 2.88818C102.665 2.88818 103.317 3.05519 103.863 3.38843C104.409 3.72167 104.824 4.16443 105.108 4.71672C105.393 5.269 105.535 5.86402 105.535 6.5002C105.535 6.85829 105.526 7.12705 105.506 7.30649H105.508ZM104.533 4.93266C104.262 4.43552 103.895 4.06422 103.43 3.82032C102.964 3.57641 102.462 3.45445 101.921 3.45445C101.379 3.45445 100.879 3.57641 100.419 3.82032C99.9587 4.06422 99.5846 4.43475 99.2953 4.93266C99.006 5.43057 98.8517 6.03724 98.8324 6.75342H104.895C104.924 6.03724 104.803 5.4298 104.533 4.93266Z"
          fill="inherit"
        />
        <path
          d="M113.748 3.71725C114.326 4.30915 114.616 5.18768 114.616 6.35207V11.099H113.989V6.39713C113.989 5.43237 113.756 4.69288 113.292 4.1802C112.827 3.66753 112.196 3.4112 111.399 3.4112C110.602 3.4112 109.897 3.69006 109.399 4.24701C108.901 4.80473 108.652 5.63045 108.652 6.72493V11.099H108.011V2.99329H108.652V4.70997C108.87 4.1033 109.232 3.63801 109.74 3.3141C110.248 2.99096 110.833 2.82861 111.497 2.82861C112.418 2.82861 113.168 3.12456 113.746 3.71647L113.748 3.71725Z"
          fill="inherit"
        />
        <path
          d="M124.351 7.30649H117.676C117.695 8.03278 117.847 8.64488 118.131 9.14279C118.416 9.6407 118.791 10.0136 119.255 10.2621C119.72 10.5107 120.228 10.635 120.778 10.635C121.537 10.635 122.173 10.4385 122.685 10.0454C123.197 9.65236 123.52 9.11793 123.653 8.44058H124.321C124.169 9.26707 123.781 9.93588 123.154 10.4486C122.528 10.9612 121.736 11.2176 120.777 11.2176C120.056 11.2176 119.411 11.0482 118.842 10.7103C118.272 10.3724 117.827 9.88694 117.504 9.25465C117.182 8.62313 117.02 7.88364 117.02 7.03772C117.02 6.19182 117.179 5.45543 117.497 4.82857C117.815 4.20171 118.259 3.72167 118.828 3.38843C119.397 3.05519 120.047 2.88818 120.777 2.88818C121.508 2.88818 122.16 3.05519 122.706 3.38843C123.251 3.72167 123.667 4.16443 123.951 4.71672C124.236 5.269 124.378 5.86402 124.378 6.5002C124.378 6.85829 124.369 7.12705 124.349 7.30649H124.351ZM123.376 4.93266C123.105 4.43552 122.738 4.06422 122.272 3.82032C121.807 3.57641 121.305 3.45445 120.764 3.45445C120.222 3.45445 119.722 3.57641 119.262 3.82032C118.802 4.06422 118.428 4.43475 118.138 4.93266C117.849 5.43057 117.695 6.03724 117.675 6.75342H123.738C123.767 6.03724 123.646 5.4298 123.376 4.93266Z"
          fill="inherit"
        />
        <path
          d="M128.507 3.29905C128.99 2.98523 129.608 2.8291 130.357 2.8291V3.53053H130.143C129.384 3.53053 128.753 3.75735 128.25 4.20943C127.747 4.66229 127.495 5.40101 127.495 6.42635V11.0987H126.855V2.993H127.495V4.66463C127.685 4.06728 128.022 3.61209 128.506 3.29905H128.507Z"
          fill="inherit"
        />
        <path
          d="M138.91 2.99316V11.0988H138.27V9.3371C138.052 9.95464 137.688 10.4246 137.181 10.7477C136.674 11.0716 136.093 11.2332 135.438 11.2332C134.518 11.2332 133.765 10.9373 133.182 10.3454C132.599 9.75345 132.307 8.87492 132.307 7.71053V2.99316H132.933V7.66548C132.933 8.63101 133.165 9.36972 133.63 9.8824C134.095 10.3951 134.727 10.6514 135.524 10.6514C136.32 10.6514 137.025 10.3733 137.523 9.81559C138.022 9.25865 138.271 8.43215 138.271 7.33768V2.99394H138.911L138.91 2.99316Z"
          fill="inherit"
        />
        <path
          d="M142.247 10.6212C141.701 10.2235 141.395 9.65644 141.329 8.91928H141.984C142.031 9.44671 142.261 9.86461 142.674 10.173C143.087 10.4814 143.649 10.636 144.361 10.636C144.948 10.636 145.416 10.479 145.762 10.166C146.109 9.85219 146.282 9.46691 146.282 9.00938C146.282 8.69091 146.189 8.42991 146.004 8.22562C145.819 8.0221 145.589 7.86519 145.313 7.75567C145.038 7.64614 144.664 7.52652 144.189 7.39757C143.62 7.23833 143.159 7.08142 142.808 6.92762C142.457 6.77382 142.158 6.54234 141.912 6.23318C141.665 5.9248 141.542 5.51156 141.542 4.99423C141.542 4.61593 141.65 4.26561 141.869 3.94169C142.087 3.61855 142.39 3.36222 142.78 3.17268C143.169 2.98393 143.61 2.88916 144.104 2.88916C144.91 2.88916 145.563 3.10044 146.061 3.52379C146.559 3.94713 146.827 4.53127 146.865 5.27775H146.224C146.196 4.74022 145.997 4.30289 145.627 3.96422C145.256 3.62632 144.744 3.45698 144.09 3.45698C143.54 3.45698 143.084 3.60612 142.724 3.90518C142.363 4.20347 142.183 4.567 142.183 4.995C142.183 5.37329 142.285 5.67934 142.489 5.91315C142.693 6.14696 142.944 6.3264 143.244 6.45068C143.542 6.57497 143.939 6.70702 144.432 6.84606C144.972 6.9952 145.407 7.14201 145.734 7.28649C146.061 7.43097 146.339 7.63993 146.566 7.91335C146.794 8.18755 146.908 8.55264 146.908 9.01016C146.908 9.42807 146.798 9.80636 146.581 10.1443C146.363 10.4829 146.059 10.7463 145.67 10.9358C145.281 11.1245 144.844 11.2193 144.361 11.2193C143.497 11.2193 142.793 11.0205 142.247 10.622V10.6212Z"
          fill="inherit"
        />
        <path d="M154.593 0.0522461V11.0988H153.953V0.0522461H154.593Z" fill="inherit" />
        <path
          d="M157.561 4.84431C157.879 4.21745 158.32 3.73507 158.885 3.3964C159.45 3.0585 160.092 2.88916 160.814 2.88916C161.63 2.88916 162.322 3.09811 162.891 3.51602C163.46 3.93392 163.858 4.46679 164.087 5.11307V2.99325H164.727V11.0989H164.087V8.96433C163.859 9.62149 163.458 10.1613 162.884 10.5839C162.31 11.0073 161.62 11.2185 160.814 11.2185C160.092 11.2185 159.45 11.0469 158.885 10.7035C158.32 10.3602 157.879 9.87238 157.561 9.24086C157.243 8.60934 157.084 7.87529 157.084 7.03948C157.084 6.20367 157.243 5.47194 157.561 4.84508V4.84431ZM163.666 5.16512C163.386 4.62293 163.004 4.20502 162.521 3.9114C162.037 3.61778 161.496 3.47097 160.898 3.47097C160.301 3.47097 159.722 3.61312 159.247 3.89664C158.773 4.18016 158.403 4.59108 158.137 5.12783C157.871 5.66536 157.738 6.30232 157.738 7.0387C157.738 7.77509 157.871 8.41204 158.137 8.94957C158.403 9.4871 158.775 9.90035 159.254 10.1885C159.733 10.4775 160.281 10.6212 160.898 10.6212C161.516 10.6212 162.039 10.4744 162.528 10.1808C163.016 9.88714 163.398 9.46924 163.673 8.92705C163.949 8.38486 164.086 7.75567 164.086 7.0387C164.086 6.32174 163.946 5.70808 163.666 5.16512Z"
          fill="inherit"
        />
        <path
          d="M169.452 3.51512C170.021 3.09721 170.708 2.88826 171.515 2.88826C172.237 2.88826 172.879 3.0576 173.444 3.39549C174.009 3.73417 174.45 4.21655 174.768 4.84341C175.086 5.47026 175.245 6.20199 175.245 7.0378C175.245 7.87361 175.086 8.60767 174.768 9.23919C174.45 9.87148 174.009 10.3585 173.444 10.7019C172.879 11.0452 172.237 11.2169 171.515 11.2169C170.708 11.2169 170.019 11.0079 169.445 10.59C168.871 10.1721 168.47 9.63457 168.242 8.97819V11.098H167.602V0.0522461H168.242V5.12771C168.479 4.47055 168.882 3.9338 169.452 3.51589V3.51512ZM174.191 5.12693C173.925 4.5894 173.553 4.17926 173.074 3.89574C172.595 3.61221 172.042 3.47006 171.416 3.47006C170.79 3.47006 170.277 3.61687 169.794 3.9105C169.31 4.20412 168.93 4.62202 168.655 5.16421C168.379 5.7064 168.242 6.33093 168.242 7.0378C168.242 7.74467 168.379 8.38395 168.655 8.92614C168.93 9.46833 169.31 9.88702 169.794 10.1799C170.277 10.4735 170.818 10.6203 171.416 10.6203C172.014 10.6203 172.595 10.4758 173.074 10.1876C173.553 9.89945 173.925 9.4862 174.191 8.94867C174.456 8.41114 174.59 7.77418 174.59 7.0378C174.59 6.30142 174.457 5.66446 174.191 5.12693Z"
          fill="inherit"
        />
        <path
          d="M178.183 10.6212C177.638 10.2235 177.331 9.65644 177.265 8.91928H177.92C177.967 9.44671 178.198 9.86461 178.611 10.173C179.023 10.4814 179.586 10.636 180.297 10.636C180.885 10.636 181.352 10.479 181.699 10.166C182.045 9.85219 182.218 9.46691 182.218 9.00938C182.218 8.69091 182.125 8.42991 181.94 8.22562C181.755 8.0221 181.525 7.86519 181.25 7.75567C180.974 7.64614 180.6 7.52652 180.126 7.39757C179.556 7.23833 179.096 7.08142 178.745 6.92762C178.394 6.77382 178.094 6.54234 177.848 6.23318C177.601 5.9248 177.478 5.51156 177.478 4.99423C177.478 4.61593 177.587 4.26561 177.805 3.94169C178.023 3.61855 178.327 3.36222 178.716 3.17268C179.105 2.98393 179.546 2.88916 180.04 2.88916C180.846 2.88916 181.499 3.10044 181.997 3.52379C182.496 3.94713 182.763 4.53127 182.801 5.27775H182.161C182.132 4.74022 181.933 4.30289 181.563 3.96422C181.193 3.62632 180.68 3.45698 180.026 3.45698C179.476 3.45698 179.02 3.60612 178.66 3.90518C178.299 4.20347 178.119 4.567 178.119 4.995C178.119 5.37329 178.221 5.67934 178.425 5.91315C178.629 6.14696 178.881 6.3264 179.18 6.45068C179.478 6.57497 179.875 6.70702 180.368 6.84606C180.909 6.9952 181.343 7.14201 181.67 7.28649C181.997 7.43097 182.275 7.63993 182.503 7.91335C182.73 8.18755 182.844 8.55264 182.844 9.01016C182.844 9.42807 182.735 9.80636 182.517 10.1443C182.299 10.4829 181.995 10.7463 181.606 10.9358C181.217 11.1245 180.781 11.2193 180.297 11.2193C179.434 11.2193 178.729 11.0205 178.183 10.622V10.6212Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_3354_17155">
          <rect width="110" height="16" fill="inherit" transform="translate(73.4999)" />
        </clipPath>
      </defs>
    </svg>
  );
}
