import { useRef } from "react";

import { useLocation } from "react-router-dom";

import Page from "@/components/Page";
import { LANGUAGE_FIELDS, LANGUAGES_QUERY } from "@/graphql/accounts";
import { SHIPPING_COUNTRIES_QUERY } from "@/graphql/shop";
import useExperts from "@/hooks/experts/useExperts";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import useProductFilters from "@/tpo/shop/hooks/useProductFilters";
import { gql, useQuery } from "@apollo/client";

import CountrySelect from "../CountrySelectWithIcons";
import LanguageSelect from "../LanguageSelect";
import ExpertListCard from "./ExpertListCard";
import ListTemplate from "./ListTemplate";

const FILTERS_QUERY = gql`
  query FiltersQuery($includeFilters: [IncludeFilters]) {
    consultationTypes: expertConsultationTypes
    practitionerTypes {
      id
      role
    }
    specialisms: areasOfInterest(includeFilters: $includeFilters) {
      id
      name
    }
    expertCountries {
      id
      name
    }
    languages(usedOnly: true) {
      ...LanguageFields
    }
  }
  ${LANGUAGE_FIELDS}
`;

export default function Practitioners() {
  const {
    data: {
      consultationTypes = [],
      practitionerTypes = [],
      specialisms = [],
      expertCountries = [],
      languages = []
    } = {}
  } = useQuery(FILTERS_QUERY, {
    variables: {
      includeFilters: ["Both", "B2C"]
    }
  });

  const location = useLocation();

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      consultationType: [],
      practitionerType: [],
      specialisms: [],
      country: undefined,
      language: undefined
    },
    allFilters: {
      consultationType: [...consultationTypes].sort(),
      practitionerType: [...practitionerTypes.map(pt => pt.role)].sort(),
      specialisms: [...specialisms.map(s => s.name)].sort(),
      country: expertCountries.map(sc => sc.name),
      language: languages.map(l => l.name)
    }
  });

  const controls = useListControls({
    defaultSort: "random_hash",
    sortOptions: [
      {
        label: "A - Z",
        value: "partner_user_profile__user__first_name"
      },
      {
        label: "Z - A",
        value: "-partner_user_profile__user__first_name"
      },
      {
        label: "Fee: High - Low",
        value: "fee_level"
      },
      {
        label: "Fee: Low - High",
        value: "-fee_level"
      }
    ]
  });

  const search = new URLSearchParams(location.search).get("search") || "";
  const supportedTests = new URLSearchParams(location.search).get("supported_tests") || "";

  const debouncedSearch = useDebounce(search, 200);

  const seedRef = useRef(new Date().toString());

  const { experts, fetchMore, hasMore, endCursor, loading } = useExperts({
    search: debouncedSearch,
    orderBy: controls.sort,
    consultationTypes: validatedFilters.consultationType.join(","),
    practitionerTypes: validatedFilters.practitionerType.join(","),
    specialisms: validatedFilters.specialisms.join(","),
    seed: seedRef.current,
    supportedTests,
    countryName: validatedFilters?.country?.[0] || "",
    language: validatedFilters?.language?.[0] || ""
  });

  const allFiltersForOverlay = {
    ...allFilters
  };

  delete allFiltersForOverlay["country"];
  delete allFiltersForOverlay["language"];

  return (
    <Page>
      <ListTemplate.Switcher>
        <ListTemplate.Header
          header="Find a practitioner"
          subheader="Search our exclusive list of verified experts to assist you with your healthcare journey"
        />
      </ListTemplate.Switcher>
      <ListTemplate.Container>
        <ListTemplate.Filters
          search={search}
          allFilters={allFiltersForOverlay}
          validatedFilters={validatedFilters}
          toggleFilter={toggleFilter}
          controls={controls}
          selectedFilters={selectedFilters}
        >
          {!!expertCountries?.length && (
            <CountrySelect
              onChange={name => {
                toggleFilter("country", name);
              }}
              value={validatedFilters?.country?.[0] || ""}
              shippingCountries={expertCountries}
            />
          )}
          {!!languages?.length && (
            <LanguageSelect
              onChange={name => {
                toggleFilter("language", name);
              }}
              value={validatedFilters?.language?.[0] || ""}
              languages={languages}
            />
          )}
        </ListTemplate.Filters>
        <ListTemplate.Grid
          ItemCard={ExpertListCard}
          fetchMore={fetchMore}
          hasMore={hasMore}
          endCursor={endCursor}
          loading={loading}
          items={experts}
        />
      </ListTemplate.Container>
    </Page>
  );
}
