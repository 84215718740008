import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function ShippingAndReturnsPolicyPage() {
  useDocTitle("Shipping And Returns Policy");
  return <IFramePage title="Shipping And Returns Policy" src="/shipping-policy.html" />;
}

export default ShippingAndReturnsPolicyPage;
