import { useFormContext, useWatch } from "react-hook-form";

import { AccountOnHoldCheckoutMessage } from "@/components/AccountOnHold";
import Box from "@/components/Box";
import Loading from "@/components/Loading";
import PartnerDashboardBox from "@/components/boxes/PartnerDashboardBox";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import ControlledFormField from "@/tpo/ControlledFormField";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export default function PayOrderLaterFields({ canSubmit, loading }) {
  const { control } = useFormContext();

  const acknowledge = useWatch({
    name: "acknowledge",
    control
  });

  const { organisation } = useOrganisationContext();

  if (organisation?.accountOnHold) {
    return <AccountOnHoldCheckoutMessage />;
  }

  return (
    <PartnerDashboardBox>
      <Stack gap={[24, 24, 28]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Payment Details
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          After you complete this order, we will send an email to your patient containing a payment
          link for them to action. The order will be dispatched once payment has been received.
        </Box>
      </Stack>
      <ControlledFormField
        name="acknowledge"
        label="I acknowledge that this order will be sent directly to my patient"
        Component={Checkbox}
      />
      <Center>
        <ButtonV2
          color="green"
          rightIcon={
            loading ? (
              <Loading
                minHeight={20}
                size={20}
                color="white"
                style={{
                  marginLeft: 10
                }}
              />
            ) : (
              <ChevronComponent />
            )
          }
          type="submit"
          disabled={!canSubmit || !acknowledge || loading}
        >
          place order
        </ButtonV2>
      </Center>
    </PartnerDashboardBox>
  );
}
