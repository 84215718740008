import { useEffect, useRef, useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "@/components/Box";
import Errors from "@/components/Errors";
import { FadeTransition } from "@/components/animations/FadeTransition";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { UPDATE_OR_CREATE_SHIPPING_ADDRESS_MUTATION } from "@/graphql/organisations/mutations";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ControlledFormField from "@/tpo/ControlledFormField";
import EditFormButtons from "@/tpo/EditFormButtons";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import Group from "@/tpo/Group";
import NativeSelect from "@/tpo/NativeSelect";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export function ShippingAddressFields({
  readOnly,
  shippingCountries,
  showButtons,
  error,
  prefix,
  children
}) {
  return (
    <>
      <Stack gap={20}>
        <ControlledFormField
          name={prefix ? `${prefix}.name` : "name"}
          Component={FloatingLabelInput}
          label="Name"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.line1` : "line1"}
          Component={FloatingLabelInput}
          label="Address line 1"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.line2` : "line2"}
          Component={FloatingLabelInput}
          label="Address line 2"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.city` : "city"}
          Component={FloatingLabelInput}
          label="City"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.postcode` : "postcode"}
          Component={FloatingLabelInput}
          label="Postcode"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.phoneNumber` : "phoneNumber"}
          Component={FloatingLabelInput}
          label="Phone number"
          editable={!readOnly}
        />
        <ControlledFormField
          name={prefix ? `${prefix}.country` : "country"}
          Component={NativeSelect}
          label=""
          readOnly={readOnly}
        >
          <option value="">Select country</option>
          {shippingCountries.map((country, idx) => (
            <option key={`${country.isoCode}-${idx}`} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </ControlledFormField>
      </Stack>
      {showButtons && (
        <Center gap={20}>
          <EditFormButtons />
        </Center>
      )}
      {children}
      <FadeTransition in={!!error}>
        <Errors>{error}</Errors>
      </FadeTransition>
    </>
  );
}

export default function ShippingAddressCheckoutForm({
  shippingCountries,
  handleSuccess,
  handleFailure,
  error,
  onCustomsConfirmationChange,
  customsConfirmationError,
  title,
  ...props
}) {
  /*
    This is an usual form.

    It's self contained except for the case where the we already have a shipping address but the customs confirmation field is not checked.
    In this case we want to the checkbox field to be part of the form which is submitted when the final button, for example "place order",
    is submitted.

    If the address is being edited however it's a normal form - above doesn't apply.
  */

  const submitButtonRef = useRef();

  const { basket } = useOrganisationBasketContext();
  const [editing, setEditing] = useState(false);

  // We set shipping address on the basket to the patient's
  // when a patient is selected (if a shipping address is saved for that patient)
  const shippingAddress = basket?.shippingAddress;

  const haveExistingInstance = !!shippingAddress;

  const { methods, nonFieldError, onSubmit } = useDjangoGraphqlForm({
    mutation: UPDATE_OR_CREATE_SHIPPING_ADDRESS_MUTATION,
    mutationName: "updateOrCreateShippingAddressMutation",
    defaultValues: {
      id: shippingAddress?.id || "",
      name: shippingAddress?.name || "",
      line1: shippingAddress?.line1 || "",
      line2: shippingAddress?.line2 || "",
      city: shippingAddress?.city || "",
      postcode: shippingAddress?.postcode || "",
      country: shippingAddress?.country?.isoCode || "",
      organisation: basket?.organisation?.id,
      customsConfirmation: shippingAddress?.customsConfirmation || false
    },
    handleSuccess: api => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
      handleSuccess?.(api);
    },
    handleFailure: api => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
      handleFailure?.(api);
    },
    ...props
  });

  useEffect(() => {
    if (!basket) {
      return;
    }

    methods.reset({
      id: shippingAddress?.id || "",
      name: shippingAddress?.name || "",
      line1: shippingAddress?.line1 || "",
      line2: shippingAddress?.line2 || "",
      city: shippingAddress?.city || "",
      postcode: shippingAddress?.postcode || "",
      country: shippingAddress?.country?.isoCode || "",
      organisation: basket?.organisation?.id,
      customsConfirmation: shippingAddress?.customsConfirmation || false
    });
  }, [shippingAddress, basket, methods]);

  const watchCountry = methods.watch("country");

  const errorString = typeof error === "string" ? error : error?.message;

  const readOnly = haveExistingInstance && !editing;

  const showButtons = editing || !haveExistingInstance;
  const errorToDisplay = nonFieldError || errorString;

  let showCustomsConfirmationField = false;
  let foreignCountry = watchCountry && watchCountry !== "GB";

  if (foreignCountry) {
    if (editing) {
      showCustomsConfirmationField = true;
    } else if (!shippingAddress?.customsConfirmation) {
      showCustomsConfirmationField = true;
    }
  }

  return (
    <>
      <FormProvider {...methods} submitButtonRef={submitButtonRef}>
        <Stack as="form" gap={[20, 20, 40]} onSubmit={methods.handleSubmit(onSubmit)}>
          <Group alignItems="center" justifyContent="space-between">
            <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
              {title}
            </Box>
            {haveExistingInstance && (
              <ButtonV2
                color="dark"
                size={["xs", "xs", "sm"]}
                sx={{
                  color: "white"
                }}
                onClick={() => {
                  setEditing(true);
                }}
                type="button"
              >
                edit address
              </ButtonV2>
            )}
          </Group>
          <Stack gap={20}>
            <ControlledFormField
              name="name"
              Component={FloatingLabelInput}
              label="Name"
              editable={!readOnly}
            />
            <ControlledFormField
              name="line1"
              Component={FloatingLabelInput}
              label="Address line 1"
              editable={!readOnly}
            />
            <ControlledFormField
              name="line2"
              Component={FloatingLabelInput}
              label="Address line 2"
              editable={!readOnly}
            />
            <ControlledFormField
              name="city"
              Component={FloatingLabelInput}
              label="City"
              editable={!readOnly}
            />
            <ControlledFormField
              name="postcode"
              Component={FloatingLabelInput}
              label="Postcode"
              editable={!readOnly}
            />
            <ControlledFormField
              name="country"
              Component={NativeSelect}
              label=""
              readOnly={readOnly}
            >
              <option value="">Select country</option>
              {shippingCountries.map((country, idx) => (
                <option key={`${country.isoCode}-${idx}`} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </ControlledFormField>
          </Stack>
          <FadeTransition
            in={!!showCustomsConfirmationField}
            timeout={50}
            sx={{
              as: Stack,
              gap: [20, 20, 40]
            }}
          >
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              All our products are shipped from the UK and will be sent “DAP – Delivery at place”.
              We will arrange for our courier to deliver to your specified address, but please be
              aware that you as the buyer will be responsible for any cost related to local custom
              clearance regulations.
            </Box>
            {editing ? (
              <ControlledFormField
                name="customsConfirmation"
                Component={Checkbox}
                label="I understand I am responsible for any cost related to local custom clearance regulation"
                disabled={readOnly}
              />
            ) : (
              <Checkbox
                label="I understand I am responsible for any cost related to local custom clearance regulation"
                name="customsConfirmation"
                value={true}
                onChange={onCustomsConfirmationChange}
                error={customsConfirmationError}
              />
            )}
          </FadeTransition>
          {showButtons && (
            <Center gap={20}>
              <EditFormButtons />
            </Center>
          )}
          <FadeTransition in={!!errorToDisplay}>
            <Errors>{errorToDisplay}</Errors>
          </FadeTransition>
        </Stack>
      </FormProvider>
    </>
  );
}
