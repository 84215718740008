import { isEqual, uniqWith } from "lodash";

import {
  REVIEW_STATUS_APPROVED,
  REVIEW_STATUS_CONSULT_GP,
  REVIEW_STATUS_FOR_PRACTITIONER_REVIEW,
  REVIEW_STATUS_FOR_REVIEW,
  REVIEW_STATUS_PARTIAL,
  REVIEW_STATUS_RETEST
} from "./constants";

export function averageArray(arr) {
  const total = arr.reduce((acc, curr) => acc + curr, 0);
  const avg = total / arr.length;
  return avg;
}

export function averageMatrix(arr) {
  // https://stackoverflow.com/questions/49062795/average-a-columns-in-a-2d-array-with-functional-programming
  return arr
    .reduce((acc, cur) => {
      cur.forEach((e, i) => (acc[i] = acc[i] ? acc[i] + e : e));
      return acc;
    }, [])
    .map(e => e / arr.length);
}

export function mapValues(value, low1, high1, low2, high2, clamp = false) {
  let val = low2 + ((value - low1) * (high2 - low2)) / (high1 - low1);
  if (clamp) {
    if (high2 > low2) {
      if (val > high2) {
        val = high2;
      }
      if (val < low2) {
        val = low2;
      }
    } else {
      if (val < high2) {
        val = high2;
      }
      if (val > low2) {
        val = low2;
      }
    }
  }

  return val;
}

export function groupBy(items, keyName) {
  let groupedItems = {};
  items.forEach(({ item }) => {
    const key = item[keyName];
    if (!(key in groupedItems)) {
      groupedItems[key] = [];
    }
    groupedItems[key].push(item);
  });
  return groupedItems;
}

export const RESULTS_AVAILABLE = "Results available";
export const CONSULT_NEEDED = "Consultation Needed";

const PUBLISH_STATUS_PARTIAL = "Partial Available";
const PUBLISH_STATUS_AVAILABLE = "Available";
const PUBLISH_STATUS_FAILED = "Failed";

export function statusFromResult(testInput) {
  if (testInput.publishStatus === PUBLISH_STATUS_FAILED) {
    return "Test failed";
  }
  if (
    testInput.publishStatus === PUBLISH_STATUS_PARTIAL ||
    testInput.publishStatus === PUBLISH_STATUS_AVAILABLE
  ) {
    return RESULTS_AVAILABLE;
  }
  return "Processing";
}

export function statusFromUserTest(userTest, terminology = {}) {
  let status;

  if (
    userTest.reviewStatus === REVIEW_STATUS_APPROVED ||
    userTest.reviewStatus === REVIEW_STATUS_PARTIAL ||
    userTest.reviewStatus === REVIEW_STATUS_RETEST
  ) {
    status = RESULTS_AVAILABLE;
    return status in terminology ? terminology[status] : status;
  }
  if (
    userTest.reviewStatus === REVIEW_STATUS_FOR_REVIEW ||
    userTest.reviewStatus === REVIEW_STATUS_FOR_PRACTITIONER_REVIEW
  ) {
    status = "Under Review";
    return status in terminology ? terminology[status] : status;
  }
  if (userTest.reviewStatus === REVIEW_STATUS_CONSULT_GP) {
    status = CONSULT_NEEDED;
    return status in terminology ? terminology[status] : status;
  }
  status = "Processing";
  return status in terminology ? terminology[status] : status;
}

export function statusFromOrderTestItem(orderTestItem) {
  if (orderTestItem.testInput?.publishStatus.indexOf("Available") > -1) {
    return RESULTS_AVAILABLE;
  }

  if (orderTestItem.resultsReceivedByProvider !== null) {
    return "In review";
  }

  if (
    orderTestItem.sampleReceivedByProvider !== null ||
    orderTestItem.sampleReceivedByLab !== null
  ) {
    return "At lab";
  }

  if (orderTestItem.registeredUser !== null) {
    return "Registered";
  }

  if (orderTestItem.testSentToUser !== null) {
    return "Dispatched";
  }
  return "Ordered";
}

export const flexRow = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

export function isTouch() {
  const isMobile = window.navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );
  return "ontouchstart" in document.documentElement && isMobile;
}

export function normalizeValues(arr) {
  let min = Math.min(...arr);
  let max = Math.max(...arr);
  return arr.map(v => mapValues(v, min, max, 0, 100));
}

export function findByKey(key, value, items) {
  return items.find(item => {
    return item[key] === value;
  });
}

export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    let x = a[key];
    let y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatValue(value) {
  if (typeof value === "number") {
    if (value === 0 || value === 1) {
      return value;
    }
    if (value < 1) {
      return value.toFixed(3);
    }
    if (value > 100) {
      let nf = new Intl.NumberFormat();
      return nf.format(Math.round(value));
    }
    return value.toFixed(2);
  }
  return value;
}

export function humanize(interpretation) {
  return interpretation.replace("_", " ");
}

export function normalizePoints(points) {
  const values = points.map(point => point.value);
  let min = Math.min(...values);
  let max = Math.max(...values);
  return points.map(point => ({ ...point, value: mapValues(point.value, min, max, 0, 100) }));
}

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return str;
}

export function noOrphans(text) {
  let words = text.replace(/(<([^>]+)>)/gi, "").split(" ");
  return text.replace(
    `${words[words.length - 2]} ${words[words.length - 1]}`,
    `${words[words.length - 2]}&nbsp;${words[words.length - 1]}`
  );
}
export function toTitleCase(text) {
  let smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  let alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  let wordSeparators = /([ :–—-])/;

  return text
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ":" &&
        array[index + 1] !== ":" &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== "-" || (array[index - 1] === "-" && array[index + 1] === "-"))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ":" && array[index + 2] !== "") {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join("");
}

export function floatToInteger(float, decimalPlaces) {
  return parseInt(float * 10 ** decimalPlaces);
}

export function mergeListsIntoUniqueList(items, property) {
  const withDuplicates = items?.reduce((acc, cur) => acc.concat(cur?.[property]), []);
  return uniqWith(withDuplicates, isEqual);
}

export function priceFormat(price) {
  if (Math.floor(+price) === parseFloat(+price.toFixed(2))) {
    return parseInt(+price);
  } else {
    return parseFloat(+price.toFixed(2));
  }
}

export function sorted(arr, sortCallback) {
  arr.sort(sortCallback);
  return arr;
}

export function pairwise(arr) {
  const returnArr = [];
  for (var i = 0; i < arr.length - 1; i++) {
    returnArr.push([arr[i], arr[i + 1]]);
  }
  return returnArr;
}
