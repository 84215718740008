import { useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import Box from "@/components/Box";
import Circle from "@/components/Circle";
import Grid from "@/components/Grid";
import { TOPIC_QUERY, VIDEO_GUIDE_CONNECTIONS_QUERY } from "@/graphql/knowledgeCenter/webinars";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import Group from "@/tpo/Group";
import SortMenu from "@/tpo/SortMenu";
import Stack from "@/tpo/Stack";
import { Search } from "@/tpo/TextInput";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import { justifyContent } from "styled-system";

import ListTemplate from "./ListTemplate";
import NavigationInstance from "./Navigation";
import { TopicFiltersMobile, TopicFiltersDesktop } from "./TopicFilters";
import VideoGuideCard from "./VideoGuideCard";

const breakpoints = ["640px", "1360px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export default function VideoGuides() {
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search).get("search") || "";
  const debouncedSearch = useDebounce(search, 200);

  const [filters, setFilters] = useState(new Set([]));

  const controls = useListControls({
    sortOptions: [
      {
        label: "Title asc",
        value: "title"
      },
      {
        label: "Title desc",
        value: "-title"
      }
    ]
  });

  const { data, loading, fetchMore } = useQuery(VIDEO_GUIDE_CONNECTIONS_QUERY, {
    variables: {
      first: 10,
      orderBy: controls.sort,
      search: debouncedSearch,
      topics: Array.from(filters)
    }
  });

  const { data: topicsData } = useQuery(TOPIC_QUERY);

  const hasMore = data?.videoGuideConnections.pageInfo.hasNextPage;
  const endCursor = data?.videoGuideConnections.pageInfo.endCursor;
  const videoGuides = data?.videoGuideConnections.edges.map(edge => edge.node);
  const topics = topicsData?.topics;

  const theme = useTheme();

  return (
    <ListTemplate.ThemeProvider breakpoints={breakpoints}>
      <ListTemplate.Tabs selectedTab="learning_hub" />
      <Box px={20} pt={theme.spacing.section.pt} pb={theme.spacing.section.pb}>
        <Grid
          maxWidth={1528}
          mx="auto"
          gridTemplateColumns={["1fr", "1fr", "min-content auto"]}
          gridColumnGap={80}
        >
          <NavigationInstance />
          <Grid
            gridColumnStart={["1", "1", "2"]}
            gridColumnGap={[10, 10, 40]}
            gridRowGap={0}
            gridTemplateColumns={[
              "1fr 1fr",
              "1fr min-content min-content",
              "auto auto min-content"
            ]}
          >
            <Stack gap={20} pb={80} gridColumnStart={["1"]} gridColumnEnd={["3"]} mb="auto">
              <Group gap={20} alignItems="center">
                <Circle bg="transparent" size={80} border="2px solid black">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.3068 18.7479C37.2311 19.2949 37.2311 20.7051 36.3068 21.2521L10.011 36.8161C9.10978 37.3496 8 36.6586 8 35.564L8 4.436C8 3.34142 9.10979 2.65044 10.011 3.18388L36.3068 18.7479Z"
                      fill="#1A1A1A"
                    />
                  </svg>
                </Circle>
                <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                  Video Guides
                </Box>
              </Group>
              <Box fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                Discover our detailed guides designed you help you navigate the platform
              </Box>
            </Stack>

            <Search
              value={search}
              onChange={s => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("search", s);
                history.push({
                  path: location.path,
                  search: searchParams.toString()
                });
              }}
              maxWidth={[null, 350]}
              minWidth={310}
              width="100%"
              styles={{
                wrapper: {
                  gridRowStart: ["2"],
                  gridColumnStart: ["1"],
                  gridColumnEnd: ["3", "unset"],
                  ml: ["unset", "auto", "unset"],
                  justifyContent: ["center", null]
                }
              }}
            />
            <SortMenu
              value={controls.sort}
              setValue={controls.setSort}
              open={controls.sortMenuOpen}
              setOpen={controls.setSortMenuOpen}
              options={controls.sortOptions}
              placeholder="Sort By"
              styles={{
                button: {
                  gridRowStart: ["3", "2"],
                  gridColumnStart: ["1", "2", "2"],
                  mt: [2, 0]
                }
              }}
            />
            <TopicFiltersMobile
              topics={topics}
              filters={filters}
              setFilters={setFilters}
              maxWidth={310}
              minWidth={310}
              gridColumnStart={["2", "3", "3"]}
              gridRowStart={["3", "2", null]}
              ml={["auto", "unset"]}
              mt={[2, 0]}
            />
            <ListTemplate.InfiniteScrollObjectList
              Container={Grid}
              containerProps={{
                gridTemplateColumns: ["1fr", "1fr 1fr"],
                gridColumn: "1 / 2",
                // maxWidth: 1138,
                width: "100%",
                gridColumnGap: 16,
                gridRowGap: 40,
                gridColumnStart: ["1"],
                gridColumnEnd: ["3", "4", "3"],
                mt: 40
              }}
              loading={loading}
              objects={videoGuides}
              objectsPlural="video guides"
              hasMore={hasMore}
              fetchMore={fetchMore}
              endCursor={endCursor}
              Card={VideoGuideCard}
            />
            <TopicFiltersDesktop
              topics={topics}
              filters={filters}
              setFilters={setFilters}
              maxWidth={310}
              minWidth={310}
              gridColumnStart={["1", "1", "3"]}
              mt={40}
            />
          </Grid>
        </Grid>
      </Box>
    </ListTemplate.ThemeProvider>
  );
}
