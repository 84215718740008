import { useEffect } from "react";

import { useUserProfileContext } from "@/contexts/UserProfileContext";
import { MIGRATE_AFFILIATE_TO_PRACTITIONER_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";

import Common from "./ui/CommonForm";

export default function AffiliateApplyingToBePractitionerForm({
  setFormSubmitted,
  affiliateProfile
}) {
  const { platformUserProfile } = useUserProfileContext();

  const api = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      professionalBodies: input.professionalBodies.join(","),
      documents: JSON.stringify(
        input.documents.reduce(
          (acc, doc) => ({
            ...acc,
            [doc.type]: {
              key: doc.key,
              name: doc.name
            }
          }),
          {}
        )
      )
    }),
    mutation: MIGRATE_AFFILIATE_TO_PRACTITIONER_MUTATION,
    mutationName: "migrateAffiliateToPractitionerMutation",
    defaultValues: {
      firstName: platformUserProfile?.user?.firstName || "",
      lastName: platformUserProfile?.user?.lastName || "",
      email: platformUserProfile?.user?.email || "",
      dateOfBirth: platformUserProfile?.dateOfBirth || "",
      gender: platformUserProfile?.gender || "",
      practitionerType: "",
      professionalBodies: [],
      documents: [],
      phoneNumber: affiliateProfile.phoneNumber || "",
      jobRole: affiliateProfile.jobRole || "",
      businessLocationCity: affiliateProfile.businessLocationCity || "",
      businessCountry: "",
      sources: affiliateProfile.sources || "",
      sourcesOther: affiliateProfile.sourcesOther || "",
      instagram: affiliateProfile.instagram || "",
      linkedin: affiliateProfile.linkedin || "",
      youtube: affiliateProfile.youtube || "",
      websiteBlog: affiliateProfile.websiteBlog || "",
      acceptTerms: false
    },
    handleSuccess: () => {
      setFormSubmitted(true);
    }
  });

  useEffect(() => {
    if (platformUserProfile) {
      api.methods.reset({
        firstName: platformUserProfile?.user?.firstName || "",
        lastName: platformUserProfile?.user?.lastName || "",
        email: platformUserProfile?.user?.email || "",
        dateOfBirth: platformUserProfile?.dateOfBirth || "",
        gender: platformUserProfile?.gender || "",
        practitionerType: "",
        professionalBodies: [],
        documents: [],
        phoneNumber: affiliateProfile.phoneNumber || "",
        jobRole: affiliateProfile.jobRole || "",
        businessLocationCity: affiliateProfile.businessLocationCity || "",
        businessCountry: "",
        sources: affiliateProfile.sources || "",
        sourcesOther: affiliateProfile.sourcesOther || "",
        instagram: affiliateProfile.instagram || "",
        linkedin: affiliateProfile.linkedin || "",
        youtube: affiliateProfile.youtube || "",
        websiteBlog: affiliateProfile.websiteBlog || "",
        acceptTerms: false
      });
    }
  }, [platformUserProfile]);

  return (
    <Common
      api={api}
      readOnlyFields={["firstName", "lastName", "email", "gender", "dateOfBirth"]}
      includePartnerUpdatesConsentField={false}
    />
  );
}
