import { createContext, useCallback, useContext, useMemo } from "react";

import { useAppState } from "@/components/AppStateProvider";
import useDisclosure from "@/hooks/useDisclosure";

const HeaderContext = createContext();

export function useHeaderContext() {
  return useContext(HeaderContext);
}

export function HeaderProvider({ children }) {
  const [opened, { close, open }] = useDisclosure();
  const { setBasketOpen, setPractitionerBasketOpen } = useAppState();

  const onClick = useCallback(() => {
    // various things to do upon clicking a link in the menu
    close();
    setBasketOpen(false);
    setPractitionerBasketOpen(false);
  }, [close, setBasketOpen, setPractitionerBasketOpen]);

  const api = useMemo(
    () => ({
      close,
      open,
      opened,
      onClick
    }),
    [close, open, opened, onClick]
  );

  return <HeaderContext.Provider value={api}>{children}</HeaderContext.Provider>;
}
