import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";

import BasketErrorModal from "./BasketErrorModal";

export default function AddOnErrorModal() {
  const { hasAddonError } = useOrganisationBasketContext();

  return (
    <BasketErrorModal
      explanation={`Oops! You have a product in your basket with either add-ons or clinic options attached. If
        you’d like to build a stock basket you will need to remove these items before you proceed.`}
      show={hasAddonError}
    />
  );
}
