import Svg from "@/components/Svg";

export default function SwabIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19582)">
        <path
          d="M49.1639 51.8436C48.8624 51.7202 48.5764 51.567 48.2967 51.3795C48.0233 51.1936 47.7795 50.9936 47.5592 50.7748C47.003 50.2186 46.5936 49.542 46.3561 48.8092C46.1405 48.1451 46.0686 47.4342 46.1498 46.7248L30.5482 31.1232L27.1623 34.5092L49.1387 56.4856C50.1481 57.4949 51.4809 58.0012 52.8106 58.0012C54.1418 58.0012 55.4731 57.4965 56.4825 56.4871C57.4918 55.4778 57.9966 54.1465 57.9966 52.8152C57.9966 51.484 57.4903 50.1528 56.4809 49.1434L34.5045 27.167L31.1186 30.5529L46.7202 46.1545C47.4311 46.0717 48.1421 46.1451 48.8046 46.3607C49.5374 46.5982 50.2139 47.006 50.7702 47.5638C50.9968 47.7904 51.203 48.0435 51.3843 48.3138C51.5624 48.581 51.7155 48.867 51.839 49.1685C51.9999 49.5591 52.039 49.9685 51.9624 50.3529C51.8858 50.7404 51.6936 51.1044 51.3952 51.4029C51.0952 51.7029 50.7311 51.8951 50.3374 51.9732C49.9499 52.0466 49.5452 52.0076 49.1608 51.8482L49.1639 51.8436ZM30.4375 22.5216L22.5203 30.4388C22.1734 30.7857 22 31.2435 22 31.7013C22 32.1591 22.1734 32.6185 22.5203 32.9638L23.7797 34.2216C24.1266 34.5684 24.5844 34.7419 25.0422 34.7419C25.5 34.7419 25.9578 34.5684 26.3046 34.2216L34.225 26.3012C34.5719 25.9496 34.7438 25.4949 34.7438 25.0418C34.7422 24.584 34.5688 24.1262 34.2219 23.7793L32.9641 22.52C32.6157 22.1715 32.1579 21.9981 31.7016 21.9981C31.2438 21.9996 30.7844 22.1731 30.4392 22.52L30.4375 22.5216Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3634_19582">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
