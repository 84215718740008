import { useEffect } from "react";

import { useNotificationsContext } from "@/contexts/Notification";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { USER_NOTIFICATION_FIELDS } from "@/graphql/notifications/types";
import { gql, useQuery } from "@apollo/client";

const ON_CMP_LOGIN_QUERY = gql`
  query OnCmpLoginQuery($organisation: ID!) {
    onCmpLogin(organisation: $organisation) {
      ...UserNotificationFields
    }
  }
  ${USER_NOTIFICATION_FIELDS}
`;

export default function OnCmpLogin() {
  /**
   * Event: when the partner logs in to the cmp for the first time, or the app loads with the user
   * logged in already for the first time
   */

  const api = useNotificationsContext();
  const { organisation } = useOrganisationContext();

  const { data } = useQuery(ON_CMP_LOGIN_QUERY, {
    variables: { organisation: organisation?.id },
    skip: !organisation?.id
  });

  useEffect(() => {
    if (data && data.onCmpLogin?.length) {
      // internally it checks if the notifications are already in the list
      api.addNotifications(data.onCmpLogin);
    }
  }, [api, data]);

  return null;
}
