import ArrowCircle from "@/components/ArrowCircle";
import Box from "@/components/Box";
import Text from "@/components/Text";

import RecommendationIcon from "./RecommendationIcon";

export default function RecommendationCard({ bg, name, icon, onClick }) {
  const borderRadius = 5;

  return (
    <Box display="flex" dataComponentName="CategoryCard">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={bg}
        minWidth={80}
        maxWidth={80}
        borderTopLeftRadius={borderRadius}
        borderBottomLeftRadius={borderRadius}
        flexGrow={1}
        aspectRatio="1"
      >
        <RecommendationIcon category={icon} fill="black" height={30} width={30} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        bg="white"
        borderTopRightRadius={borderRadius}
        borderBottomRightRadius={borderRadius}
        p={2}
        as="button"
        onClick={onClick}
      >
        <Text fontSize={15} textAlign="left">
          {name}
        </Text>
        <ArrowCircle />
      </Box>
    </Box>
  );
}
