import { useCallback } from "react";

import { useAppState } from "@/components/AppStateProvider";
import Loading from "@/components/Loading";
import { SupplementsProvider } from "@/contexts/SupplementContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { SHOP_LISTING_FILTERS_QUERY } from "@/graphql/shop";
import { PanelBoxV2 } from "@/tpo/Boxes";
import ListPageTemplate from "@/tpo/shop/ListPageTemplate";
import {
  SupplementControls,
  SupplementDetailModal,
  SupplementList
} from "@/tpo/shop/SupplementListPage";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import OrganisationTabs from "../OrganisationTabs";

function CmpSupplementList() {
  const { addSupplementToBasket } = useOrganisationBasketContext();
  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();

  const onAddToBasket = useCallback(
    ({ supplement }) => {
      addSupplementToBasket(supplement.id);
      setBasketOpen(true);
    },
    [addSupplementToBasket, setBasketOpen]
  );

  const theme = useTheme();

  return (
    <ListPageTemplate
      bg="haze"
      switcherOptions={[
        {
          label: "tests",
          value: "tests"
        },
        {
          label: "supplements",
          value: "supplements"
        },
        {
          label: "discounts",
          value: "discounts"
        }
      ]}
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests",
        discounts: "/partners/dashboard/shop/discounts"
      }}
      header={<OrganisationTabs selectedTab="shop" />}
      tab="supplements"
      title="Find a matching supplement"
      subtitle="We can get a huge amount of benefit from small changes to our diets. Alongside our nutritional advice we offer more specific, targeted support, in the form of carefully selected supplements. These supplements have been designed to be specific rather than general."
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1220}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <SupplementControls />
        <SupplementList onAddToBasket={onAddToBasket} />
      </PanelBoxV2>
      <SupplementDetailModal onAddToBasket={onAddToBasket} />
    </ListPageTemplate>
  );
}

export default function SupplementListPage() {
  const { data, loading } = useQuery(SHOP_LISTING_FILTERS_QUERY);

  if (loading) {
    return <Loading />;
  }

  return (
    <SupplementsProvider
      dietryRestrictions={data?.dietryRestrictions}
      types={data?.types}
      brands={data?.brands}
      productFiltersDrawerBg="dark"
      views={["grid", "stacked"]}
    >
      <CmpSupplementList />
    </SupplementsProvider>
  );
}
