import { useFormContext } from "react-hook-form";

import Avatar from "@/components/Avatar";

export default function PreviewAvatar({ placeholder }) {
  const { watch } = useFormContext();
  const avatar = watch("expertAvatar");

  return <Avatar bg="white" size={160} placeholder={placeholder} src={avatar?.fileUrl || null} />;
}
