import { ActionIcon } from "@/v2/Buttons";

import Strip from "../Strip";

export default function BurgerButton({ onClick, open }) {
  const y = open ? 0 : 8;
  const r = open ? 45 : 0;
  const o = open ? 0 : 100;

  return (
    <ActionIcon
      onClick={onClick}
      height={20}
      width={25}
      position="relative"
      data-component-name="BurgerButton"
      size={null}
    >
      <Strip y={y} r={r} />
      <Strip o={o} />
      <Strip y={-y} r={-r} />
    </ActionIcon>
  );
}
