import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Avatar from "@/components/Avatar";
import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import { getWebinarUrl } from "@/core/urls";
import CpdIcon from "@/images/CpdIcon";
import ChevronComponent from "@/tpo/Chevron";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

export function HostProfile({ occupation, title, name, avatarUrl }) {
  return (
    <Group alignItems="center" gap={10}>
      {avatarUrl && <Avatar src={avatarUrl} size={70} />}
      <Stack gap={0}>
        <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
          Keynote speaker
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {title ? `${title} ${name}` : name}
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={14}>
          {occupation}
        </Box>
      </Stack>
    </Group>
  );
}

const WebinarCard = forwardRef(
  ({ id, title, shortDescription, host, startTime, endTime, videoUrl, slug, cpdPoints }, ref) => {
    const history = useHistory();

    return (
      <Group
        flexDirection={["column", "column", "row"]}
        borderRadius={20}
        cursor="pointer"
        ref={ref}
        onClick={() => {
          history.push(getWebinarUrl(slug));
        }}
      >
        <Stack
          bg="partners"
          padding={["32px 20px", "", "32px 20px"]}
          borderTopLeftRadius={20}
          borderBottomLeftRadius={[0, 0, 20]}
          borderTopRightRadius={[20, 20, 0]}
          borderBottomRightRadius={0}
          justifyContent="center"
          gap={0}
          alignItems="center"
          width={["100%", "100%", 120]}
          minWidth={["100%", "100%", 120]}
          maxWidth={["100%", "100%", 120]}
        >
          <Box fontFamily="gilroyMedium" fontSize={[18]}>
            {new Date(startTime).toLocaleDateString("en-US", {
              month: "short"
            })}
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[60]}>
            {new Date(startTime).toLocaleDateString("en-US", {
              day: "2-digit"
            })}
          </Box>
        </Stack>
        <Stack
          px={[24, 24, 40]}
          py={24}
          gap={20}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={[20, 20, 0]}
          borderTopRightRadius={[0, 0, 20]}
          borderBottomRightRadius={20}
          flexGrow={1}
          bg="white"
        >
          <Box>
            <Group justifyContent="space-between" alignItems="center">
              <Box>
                <Box fontFamily="gilroyMedium" fontSize={[18]} color="anchorBlue">
                  {new Date(startTime).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit"
                  })}{" "}
                  &bull;{" "}
                  {new Date(startTime).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric"
                  })}{" "}
                  {endTime ? "-" : null}{" "}
                  {!!endTime &&
                    new Date(endTime).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric"
                    })}
                </Box>
                <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                  {title}
                </Box>
              </Box>
              {!!cpdPoints && (
                <Stack gap={0} alignItems="center">
                  <CpdIcon />
                  <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
                    {cpdPoints > 1 ? `${cpdPoints}pts` : `${cpdPoints}pt`}
                  </Box>
                </Stack>
              )}
            </Group>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} pt="8px">
              {shortDescription}
            </Box>
          </Box>
          <Group alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={20}>
            <HostProfile
              name={host?.fullName}
              title={host?.title}
              occupation={host?.occupation}
              avatarUrl={host?.avatarUrl}
            />
            <ButtonV2
              as={ExternalTextLink}
              color="dark"
              rightIcon={<ChevronComponent />}
              underline={false}
              href={getWebinarUrl(slug)}
              size={["sm", "sm", "md"]}
              sx={{
                pt: 20,
                pb: 20
              }}
            >
              {new Date() > new Date(startTime) ? "watch now" : "join us"}
            </ButtonV2>
          </Group>
        </Stack>
      </Group>
    );
  }
);

export default WebinarCard;
