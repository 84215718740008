import { Error } from "@/tpo/InputWrapper";

import PatientDetails, { getSavePatientEditableFields } from "../shared/PatientDetails";

export default function PatientDetailsForRegisteringStock({ patient, permissions, onEdit }) {
  const editableFields = patient ? getSavePatientEditableFields(patient) : [];

  let error = null; // or warning

  const genericError = (
    <Error
      data-component-name="MissingInformation"
      color="red"
      error="Please complete the missing patient information"
    />
  );

  if (editableFields.length) {
    if (editableFields.length > 1 || !["email", "guardianEmail"].includes(editableFields[0])) {
      error = genericError;
    } else if (
      editableFields.length === 1 &&
      ["email", "guardianEmail"].includes(editableFields[0])
    ) {
      error = (
        <Error
          data-component-name="MissingInformation"
          color="nutrition"
          error={
            <>
              This patient is using an invalid email address.
              <br />
              Please consider updating this to ensure notifications can be sent.
            </>
          }
        />
      );
    }
  }

  return (
    <PatientDetails error={error} patient={patient} permissions={permissions} onEdit={onEdit} />
  );
}
