// For some pages like LogIn, we don't want logged in users accessing them
// The same can be said for a lot of the auth related stuff
import React, { useEffect } from "react";

import { useHistory, Route } from "react-router-dom";

import { useAuthContext } from "@/components/AuthProvider";
import Loading from "@/components/Loading";
import { DASHBOARD_HOME_URL, DEFAULT_PARTNERS_URL, PARTNERS_EXTERNAL_URL } from "@/core/urls";

// TODO: review all the pages and see if this should be used like it is for SignIn page
function LoggedOutRoute({ partners, ...props }) {
  const history = useHistory();
  const { user } = useAuthContext();

  // Redirect to login page if the user is loaded but is in fact null
  useEffect(() => {
    if (user) {
      if (user.partnerUserProfile?.id && user.partnerUserProfile?.approved) {
        history.push(DEFAULT_PARTNERS_URL);
      } else {
        if (partners) {
          // if intended for partners dashboard, but they aren't approved
          // hard redirect to home, force a refresh and let them redirect
          window.location.href = DASHBOARD_HOME_URL;
        } else {
          history.push(DASHBOARD_HOME_URL);
        }
      }
    }
    // eslint-disable-next-line
  }, [user]);

  // If we are waiting for the redirect to kick in, render a loading
  // component instead of the actual route
  if (user) {
    return <Loading />;
  }

  return <Route {...props} />;
}

export default LoggedOutRoute;
