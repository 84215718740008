import { forwardRef, useCallback, useState } from "react";

import { theme } from "@/core/theme";
import EyeWithStrikeThrough from "@/images/eye-with-strikethrough.svg?react";
import Eye from "@/images/eye.svg?react";
import Pencil from "@/images/pencil.svg?react";
import { ActionIcon } from "@/v2/Buttons";

import FloatingLabelInput from "./FloatingLabelInput";
import Group from "./Group";

const FloatingLabelPasswordInput = forwardRef(({ error, ...rest }, ref) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible, setVisible]);

  const PasswordVisibilityIcon = visible ? Eye : EyeWithStrikeThrough;

  return (
    <FloatingLabelInput
      type={visible ? "text" : "password"}
      rightIcon={
        <Group gap={10}>
          <ActionIcon onClick={toggleVisibility} size={null}>
            <PasswordVisibilityIcon width={20} color={error ? "red" : theme.colors.slate} />
          </ActionIcon>
        </Group>
      }
      error={error}
      {...rest}
      ref={ref}
    />
  );
});

export default FloatingLabelPasswordInput;
