import { useAppState } from "@/components/AppStateProvider";
import TestProductDetail from "@/components/organisations/shop/ui/TestProductDetail";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { GET_PRACTITIONER_CLINICS } from "@/graphql/shop";
import BaseTestProductModal, { ChooseClinic } from "@/tpo/shop/BaseTestProductModal";

function ProductDetailView() {
  const { testProduct } = useTestProductModalContext();

  if (!testProduct) {
    return null;
  }

  return <TestProductDetail />;
}

function ChooseClinicView() {
  const {
    testProduct,
    testProductOption,
    selectTestProductId,
    selectTestProductOptionId
  } = useTestProductModalContext();

  const { setPractitionerBasketOpen: setBasketOpen } = useAppState();
  const { basket, addProductToBasket } = useOrganisationBasketContext();

  return (
    <ChooseClinic
      query={GET_PRACTITIONER_CLINICS}
      testProductOptionPriceField="tradeCurrentPrice"
      clinicLocationsField="practitionerClinicLocations"
      paymentIsoCode={basket?.paymentLocale?.isoCode}
      currencySymbol={basket?.currencySymbol}
      onAddToBasket={({ clinic }) => {
        addProductToBasket(testProduct.id, testProductOption.id, clinic.id);
        setBasketOpen(true);
        selectTestProductId(undefined);
        selectTestProductOptionId(undefined);
      }}
    />
  );
}

export default function TestProductModal({ bg }) {
  return (
    <BaseTestProductModal
      chooseClinicView={<ChooseClinicView />}
      productDetailView={<ProductDetailView />}
      clinicsInfoField="practitionerClinicsInfo"
      organisationShop={true}
      bg={bg}
    />
  );
}
