import { useState } from "react";

import Box from "@/components/Box";
import Stack from "@/components/Stack";
import { SpanText } from "@/components/Text";
import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Chevron from "./Chevron";

// TODO, add animation eg. https://codesandbox.io/s/animate-height-framer-motion-yn59l?file=/src/AnimateHeight.js

function SelectDropdownIcon({ children, color, open }) {
  return (
    <Box display="flex" alignItems="center">
      {children}
      <Chevron direction={open ? "top" : "bottom"} fill={color} width={10} />
    </Box>
  );
}

SelectDropdownIcon.propTypes = {
  children: PropTypes.node,
  color: propTypes.color.color,
  open: PropTypes.bool
};

export function SelectDropdownStatusIcon({ open, ...rest }) {
  return (
    <SelectDropdownIcon open={open} {...rest}>
      <SpanText color="white" fontSize={12} letterSpacing={2.8} uppercase>
        {open ? "closed" : "open"}
      </SpanText>
      <Box mx="5px"></Box>
    </SelectDropdownIcon>
  );
}

SelectDropdownStatusIcon.propTypes = {
  open: PropTypes.bool
};

export function FullWidthTop({ children, open, setOpen, color, SelectDropdownIcon, ...props }) {
  return (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      onClick={e => {
        e.preventDefault();
        setOpen(!open);
      }}
      {...props}
    >
      {children}
      <SelectDropdownIcon color={color} open={open} />
    </Box>
  );
}

FullWidthTop.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  color: propTypes.color.color,
  SelectDropdownIcon: PropTypes.elementType
};

export function StackedBottom({ items, callback, setOpen, color, ...props }) {
  return (
    <Box {...props}>
      <Stack itemBoxProps={{ pt: "10px", pb: "10px" }}>
        {items.map(({ value, content }, index) => {
          if (typeof content === "string") {
            content = (
              <SpanText display="block" lineHeight={1} textAlign="left">
                {content}
              </SpanText>
            );
          }
          return (
            <Box
              color={color}
              display="block"
              key={index}
              as="button"
              width="100%"
              onClick={() => {
                setOpen(false);
                callback(value);
              }}
            >
              {content}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}

StackedBottom.propTypes = {
  items: PropTypes.array,
  callback: PropTypes.func,
  setOpen: PropTypes.func,
  color: propTypes.color.color
};

export function Expand({
  renderTop,
  renderBottom,
  defaultOpen,
  bg,
  push,
  roundCorners,
  containerProps,
  ...rest
}) {
  const [open, setOpen] = useState(defaultOpen);

  const roundAmt = roundCorners ? 5 : 0;

  return (
    <Box position="relative" data-component-name="Expand" {...rest}>
      <Box
        width="100%"
        borderTopRightRadius={roundAmt}
        borderTopLeftRadius={roundAmt}
        borderBottomLeftRadius={open ? 0 : roundAmt}
        borderBottomRightRadius={open ? 0 : roundAmt}
        bg={bg}
        {...containerProps}
      >
        {renderTop(open, setOpen)}
      </Box>
      {open && (
        <Box
          borderBottomLeftRadius={roundAmt}
          borderBottomRightRadius={roundAmt}
          bg={bg}
          position={!push && "absolute"}
          width="100%"
          zIndex={1}
        >
          {renderBottom(setOpen)}
        </Box>
      )}
    </Box>
  );
}

Expand.propTypes = {
  renderTop: PropTypes.func,
  renderBottom: PropTypes.func,
  defaultOpen: PropTypes.bool,
  bg: propTypes.color.bg,
  push: PropTypes.bool,
  roundCorners: PropTypes.bool,
  containerProps: PropTypes.object
};

Expand.defaultProps = {
  roundCorners: true,
  defaultOpen: false,
  width: "100%"
};

export function SelectExpand({
  top,
  items,
  callback,
  color,
  bg,
  defaultOpen,
  push,
  roundCorners,
  padding,
  expandContainerProps = {},
  SelectDropdownIcon,
  px,
  py,
  maxHeight,
  ...rest
}) {
  return (
    <Expand
      roundCorners={roundCorners}
      push={push}
      bg={bg}
      color={color}
      defaultOpen={defaultOpen && items.length > 0}
      renderTop={(open, setOpen) => {
        return (
          <FullWidthTop
            open={open}
            setOpen={value => {
              setOpen(value && items.length > 0);
            }}
            px={px}
            py={py}
            color={color}
            SelectDropdownIcon={SelectDropdownIcon}
          >
            {top}
          </FullWidthTop>
        );
      }}
      renderBottom={setOpen => {
        return (
          <StackedBottom
            px={px}
            pb={py}
            color={color}
            setOpen={setOpen}
            items={items}
            callback={value => {
              callback(value);
            }}
            maxHeight={maxHeight}
            overflow="auto"
          />
        );
      }}
      containerProps={expandContainerProps}
      {...rest}
    />
  );
}

SelectExpand.propTypes = {
  top: propTypes.position.top,
  items: PropTypes.array,
  callback: PropTypes.func,
  color: propTypes.color.color,
  bg: propTypes.color.bg,
  defaultOpen: PropTypes.bool,
  push: PropTypes.bool,
  roundCorners: PropTypes.bool,
  padding: propTypes.space.padding,
  expandContainerProps: PropTypes.object,
  SelectDropdownIcon: PropTypes.elementType
};

SelectExpand.defaultProps = {
  color: "white",
  bg: "dark",
  padding: 20,
  push: false,
  SelectDropdownIcon
};

export default Expand;
