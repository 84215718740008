import { useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { theme } from "@/core/theme";
import {
  COURSES_URL,
  SUPPORT_DOCUMENTS_URL,
  WEBINARS_URL,
  EVENTS_URL,
  VIDEOS_URL
} from "@/core/urls";
import CalendarIcon from "@/images/CalendarIcon";
import CirclePlayIcon from "@/images/CirclePlayIcon";
import FolderIcon from "@/images/FolderIcon";
import OpenBookIcon from "@/images/OpenBookIcon";
import PageIcon from "@/images/PageIcon";
import PaperStackIcon from "@/images/PaperStackIcon";
import PenIcon from "@/images/PenIcon";
import PlayIcon from "@/images/PlayIcon";
import ThinSearchIcon from "@/images/ThinSearchIcon";
import Menu, { Button } from "@/tpo/Menu";
import Stack from "@/tpo/Stack";
import isPathEqual from "@/utils/isPathEqual";
import ButtonV2 from "@/v2/Buttons";

function Navigation({ links, ...props }) {
  const history = useHistory();

  const [listMenuOpen, setListMenuOpen] = useState(false);

  return (
    <>
      <Menu opened={listMenuOpen} onChange={setListMenuOpen} trigger="hover">
        <Menu.Target>
          <Button
            sx={{
              display: ["flex", "flex", "none"]
            }}
            open={listMenuOpen}
            fullWidth
            lineHeight="24px"
            paddingY="16px"
            paddingX="40px"
            maxWidth={[null, 353]}
          >
            {links.find(item => item.active)?.label}
          </Button>
        </Menu.Target>
        <Menu.Dropdown
          as={Stack}
          mt={2}
          py={40}
          px={40}
          zIndex={2}
          minWidth={200}
          width="calc(100% - 40px)"
          mx="auto"
        >
          {links.map(({ Icon, label, url, active }) => (
            <ButtonV2
              leftIcon={
                <Icon
                  fill={!url ? theme.colors.carbon : theme.colors.dark}
                  size={24}
                  width={24}
                  height={24}
                />
              }
              sx={{
                borderLeft: active ? `3px solid ${theme.colors.purple}` : "3px solid transparent",
                color: url ? theme.colors.dark : theme.colors.carbon,
                borderRadius: 0,
                justifyContent: "flex-start",
                gap: 10,
                padding: "5px 0px 5px 10px",
                height: "auto"
              }}
              key={label}
              disabled={!url}
              onClick={() => {
                if (url) {
                  history.push(url);
                }
              }}
            >
              {label}
            </ButtonV2>
          ))}
        </Menu.Dropdown>
      </Menu>
      <Stack
        display={["none", "none", "flex"]}
        bg="white"
        borderRadius={5}
        pt={60}
        pb={80}
        px={40}
        mb="auto"
        minWidth={310}
        maxWidth={310}
        {...props}
      >
        {links.map(({ Icon, label, url, active }) => (
          <ButtonV2
            leftIcon={
              <Icon
                fill={!url ? theme.colors.carbon : theme.colors.dark}
                size={24}
                width={24}
                height={24}
              />
            }
            sx={{
              borderLeft: active ? `3px solid ${theme.colors.purple}` : "3px solid transparent",
              color: url ? theme.colors.dark : theme.colors.carbon,
              borderRadius: 0,
              justifyContent: "flex-start",
              gap: 10,
              padding: "5px 0px 5px 10px",
              height: "auto"
            }}
            key={label}
            disabled={!url}
            onClick={() => {
              if (url) {
                history.push(url);
              }
            }}
          >
            {label}
          </ButtonV2>
        ))}
      </Stack>
    </>
  );
}

export default function NavigationInstance(props) {
  const location = useLocation();

  return (
    <Navigation
      links={[
        {
          Icon: ThinSearchIcon,
          label: "Search",
          url: null,
          active: false
        },
        {
          Icon: PlayIcon,
          label: "Webinars",
          url: WEBINARS_URL,
          active: isPathEqual(WEBINARS_URL, location.pathname)
        },
        {
          Icon: CalendarIcon,
          label: "Events",
          url: null,
          active: false
        },
        {
          Icon: PaperStackIcon,
          label: "Courses",
          url: COURSES_URL,
          active: isPathEqual(COURSES_URL, location.pathname)
        },
        {
          Icon: CirclePlayIcon,
          label: "Videos",
          url: VIDEOS_URL,
          active: isPathEqual(VIDEOS_URL, location.pathname)
        },
        {
          Icon: PenIcon,
          label: "Articles",
          url: null,
          active: false
        },
        {
          Icon: FolderIcon,
          label: "Platform guides",
          url: null,
          active: false
        },
        {
          Icon: OpenBookIcon,
          label: "FAQ'S",
          url: null,
          active: false
        },
        {
          Icon: PageIcon,
          label: "Support documents",
          url: null,
          active: false
        }
      ]}
      {...props}
    />
  );
}
