import React, { useEffect } from "react";

import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { theme } from "@/core/theme";
import {
  DASHBOARD_HOME_URL,
  SIGNUP_COMPLETE_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL
} from "@/core/urls";
import { ACTIVATE_ACCOUNT_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import Checkbox from "@/tpo/Checkbox";
import ChevronComponent from "@/tpo/Chevron";
import ControlledFormField from "@/tpo/ControlledFormField";
import { Error } from "@/tpo/InputWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";

import { useAuthContext } from "./AuthProvider";
import Box from "./Box";
import CustomerPage from "./CustomerPage";
import { ExternalTextLink } from "./Links";

function SignupConfirmPage({ match }) {
  useDocTitle("Signup confirmation");
  const { user } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    // If the user had already authenticated, skip this page and head to the dashboard
    if (user !== null) {
      history.push(DASHBOARD_HOME_URL);
    }
    // eslint-disable-next-line
  }, [user]);

  const api = useDjangoGraphqlForm({
    defaultValues: {
      acceptTerms: false
    },
    mutation: ACTIVATE_ACCOUNT_MUTATION,
    mutationName: "activateAccountMutation",
    handleSuccess: () => {
      history.push(SIGNUP_COMPLETE_URL);
    },
    transformer: data => ({
      ...data,
      userId: match.params.userId,
      token: match.params.token
    })
  });

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Welcome to Omnos"
      }}
    >
      <Center px={20} pt={theme.spacing.section.pt} pb={theme.spacing.section.pb}>
        <FormProvider {...api.methods}>
          <Stack
            as="form"
            maxWidth={760}
            gap={[20, 20, 40]}
            onSubmit={api.methods.handleSubmit(api.onSubmit)}
          >
            {!!api.nonFieldError && <Error data-testid="nonFieldError" error={api.nonFieldError} />}
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              We take your privacy and security very seriously so please have a read and agree to
              our Terms of Use & Privacy policy before validating your account
            </Box>
            <ControlledFormField
              name="acceptTerms"
              Component={Checkbox}
              label={
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  I agree to the Omnos{" "}
                  <ExternalTextLink data-testid="termsOfUseLink" href={TERMS_OF_USE_URL}>
                    Terms of use
                  </ExternalTextLink>{" "}
                  and{" "}
                  <ExternalTextLink data-testid="termsOfServiceLink" href={TERMS_OF_SERVICE_URL}>
                    Terms of service
                  </ExternalTextLink>
                </Box>
              }
              styles={{
                inputWrapperProps: {
                  maxWidth: 600,
                  mx: "auto"
                }
              }}
            />
            <ButtonV2 color="green" mx="auto" rightIcon={<ChevronComponent />}>
              Submit
            </ButtonV2>
          </Stack>
        </FormProvider>
      </Center>
    </CustomerPage>
  );
}

export default SignupConfirmPage;
