import { theme } from "@/core/theme";

import useWindowSize from "./use-window-size";

function useBreakPointBasedPropIndex() {
  const { width } = useWindowSize();
  const { breakpoints } = theme;
  const i = breakpoints.findIndex(breakpoint => width < breakpoint.match(/\d+/)?.[0]);
  return i === -1 ? breakpoints.length - 1 : i;
}

export default useBreakPointBasedPropIndex;
