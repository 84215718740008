import { Redirect, useParams } from "react-router-dom";

import { useAppState } from "@/components/AppStateProvider";
import { PRACTITIONER } from "@/core/constants";
import { getPartnerShopTabUrl } from "@/core/urls";

export default function RedirectPractitioners({ children, tab }) {
  const { userInfo } = useAppState();
  const { slug } = useParams();

  if (userInfo?.user?.partnerUserProfile?.tier === PRACTITIONER) {
    if (slug) {
      return <Redirect to={`${getPartnerShopTabUrl(tab)}?slug=${slug}`} />;
    } else {
      return <Redirect to={getPartnerShopTabUrl(tab)} />;
    }
  }

  return children;
}
