import { useState } from "react";

import { Redirect, useParams } from "react-router-dom";

import Box from "@/components/Box";
import CarouselContainer from "@/components/CarouselContainer";
import CircleButton from "@/components/CircleButton";
import { VIDEOS_URL } from "@/core/urls";
import {
  VIDEO_GUIDE_QUERY,
  VIDEO_GUIDE_CONNECTIONS_QUERY
} from "@/graphql/knowledgeCenter/webinars";
import useCarousel from "@/hooks/useCarousel";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import InfiniteScroll from "@/tpo/InfiniteScroll";
import { InfiniteScrollContext } from "@/tpo/InfiniteScroll";
import LinkWrapper from "@/tpo/LinkWrapper";
import Stack from "@/tpo/Stack";
import convertOrderedLists from "@/utils/convertOrderedLists";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import VimeoComponent from "../../VimeoComponent";
import VideoGuideCard from "./VideoGuideCard";
import classes from "./richText.module.css";

export default function VideoGuide() {
  const theme = useTheme();

  const { slug } = useParams();

  const { data: videoGuideData, loading: loadingVideoGuide } = useQuery(VIDEO_GUIDE_QUERY, {
    variables: {
      slug
    }
  });

  const videoGuide = videoGuideData?.videoGuide;

  const {
    data: relatedVideoGuidesData,
    loading: loadingRelatedVideoGuides,
    fetchMore
  } = useQuery(VIDEO_GUIDE_CONNECTIONS_QUERY, {
    variables: {
      first: 10,
      orderBy: "title",
      topics: [videoGuide?.topic?.id],
      exclude: [videoGuide?.id]
    },
    skip: !videoGuide || !videoGuide?.topic?.id
  });

  const hasMore = relatedVideoGuidesData?.videoGuideConnections.pageInfo.hasNextPage;
  const endCursor = relatedVideoGuidesData?.videoGuideConnections.pageInfo.endCursor;
  const videoGuides = relatedVideoGuidesData?.videoGuideConnections.edges.map(edge => edge.node);

  const [playVideo, setPlayVideo] = useState(false);

  const { ref, prev, next } = useCarousel();

  if (!loadingVideoGuide && !videoGuide) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Box bg="white" pt={[85 + 40, 85 + 40, 100 + 80]} px={20}>
        {videoGuide && videoGuide?.video && (
          <VimeoComponent
            height={720}
            url={videoGuide.video.url}
            width={1274}
            play={playVideo}
            key={videoGuide.video.url}
            //   should we record these events?
            //   onStart={onVideoStarted}
            //   onEnd={onVideoEnded}
          />
        )}
      </Box>
      <Box bg="white" pb={theme.spacing.section.pb} px={20} pt={theme.spacing.pageHeader.pt}>
        <Center stacked>
          {!!videoGuide?.description && (
            <Box
              dangerouslySetInnerHTML={{
                __html: videoGuide?.description
                  ? convertOrderedLists(videoGuide.description)
                  : videoGuide?.description
              }}
              className={classes.richText}
              maxWidth={720}
              width="100%"
            />
          )}
        </Center>
      </Box>
      {!!videoGuides?.length && (
        <Box bg="white" px={20} pb={60}>
          <Stack
            maxWidth={1528}
            mx="auto"
            bg="dark"
            pt={theme.spacing.section.pt}
            pb={theme.spacing.section.pb}
            borderRadius={20}
            gap={48}
          >
            <Group alignItems="center" justifyContent="space-between" px={[20, 20, 80]}>
              <Box fontFamily="gilroyBold" color="white" fontSize={[32, 32, 44]}>
                Related videos
              </Box>
              <ButtonV2
                withChevron
                color="white"
                sx={{
                  color: "dark"
                }}
                as={LinkWrapper}
                to={VIDEOS_URL}
              >
                see all videos
              </ButtonV2>
            </Group>
            <InfiniteScroll
              hasMore={hasMore}
              next={() =>
                fetchMore({
                  variables: {
                    after: endCursor,
                    first: 10
                  }
                })
              }
              loading={loadingRelatedVideoGuides}
            >
              <InfiniteScrollContext.Consumer>
                {({ setBottomElement }) => (
                  <CarouselContainer
                    align="prev"
                    spacing={16}
                    ref={ref}
                    circular
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20
                    }}
                    bound
                  >
                    {videoGuides?.map((vg, idx) => (
                      <VideoGuideCard
                        key={vg.id}
                        minWidth={310}
                        className="carouselCard"
                        {...vg}
                        ref={idx === videoGuides.length - 1 ? setBottomElement : null}
                      />
                    ))}
                  </CarouselContainer>
                )}
              </InfiniteScrollContext.Consumer>
            </InfiniteScroll>
            {videoGuides?.length > 1 && (
              <Center gap={10}>
                <CircleButton iconHeight={15} iconWidth={15} direction="left" onClick={prev} />
                <CircleButton iconHeight={15} iconWidth={15} direction="right" onClick={next} />
              </Center>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
}
