import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { useAppState } from "@/components/AppStateProvider";
import Box from "@/components/Box";
import CustomerPage from "@/components/CustomerPage";
import DataLoader from "@/components/DataLoader";
import Image from "@/components/Image";
import { useBasketContext } from "@/contexts/BasketContext";
import { OUT_OF_STOCK } from "@/core/constants";
import { ERROR_404_URL } from "@/core/urls";
import { SUPPLEMENT_DETAIL_QUERY } from "@/graphql/shop";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Center from "@/tpo/Center";
import ChevronComponent from "@/tpo/Chevron";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Spacer from "@/tpo/Spacer";
import { RelatedResults } from "@/tpo/UserResult";
import ButtonV2 from "@/v2/Buttons";
import { createGlobalStyle } from "styled-components";

import Stack from "../Stack";
import Pills from "./Pills";

export function SupplementDetailContent({
  supplementIngredients,
  description,
  name,
  productImage,
  dietryRestrictions,
  price,
  directions,
  relatedUserResults,
  dimensions,
  onAddToBasket,
  status,
  onViewInShop
}) {
  return (
    <>
      <Box mx={[20, 20, "5.5vw"]} position="relative" zIndex={2}>
        <Center>
          <Box bg="white" width="100%" maxWidth={1020}>
            <Image
              src={productImage}
              alt={name}
              unloadedHeight={560}
              style={{
                objectFit: "contain",
                width: "100%",
                maxHeight: 560,
                display: "block"
              }}
            />
          </Box>
        </Center>
      </Box>
      <Spacer py={50} position="relative" pt={100} zIndex={1} mt={-100} bg="supplements" />
      <PanelBoxV2
        maxWidth={dimensions?.columnWidth}
        outer={{
          px: [20, 20, "5.5vw"],
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "supplements"
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} data-testid="supplementDetail:title">
          {name}
        </Box>
        <Spacer py={2} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {description}
        </Box>
        <Spacer py={[2, 2, 20]} />
        <Pills.Group>
          {dietryRestrictions.map(dr => (
            <Pills.Pill
              key={dr.id}
              color="dark"
              sx={{
                color: "white"
              }}
            >
              {dr.name}
            </Pills.Pill>
          ))}
        </Pills.Group>
        <Spacer py={[2, 2, 15]} />
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Directions for use
        </Box>
        <Spacer py={2} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {directions}
        </Box>
        {!!supplementIngredients && (
          <Stack gap={20} mt={[20, 20, 40]} mb={[20, 20, 40]}>
            <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} pb={2}>
              Ingredients
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              {supplementIngredients}
            </Box>
          </Stack>
        )}
        <Group alignItems="center" justifyContent="space-between" mt={[20, 20, 40]}>
          <GilroyFontCurrency value={price} />
          {!!onAddToBasket && (
            <ButtonV2
              color={status === OUT_OF_STOCK ? "midGrey" : "dark"}
              rightIcon={<ChevronComponent />}
              onClick={onAddToBasket}
              disabled={status === OUT_OF_STOCK}
              size={["sm", "sm", "md"]}
            >
              {status === OUT_OF_STOCK ? "Out of stock" : "Add to basket"}
            </ButtonV2>
          )}
          {!!onViewInShop && (
            <ButtonV2
              color="dark"
              rightIcon={<ChevronComponent />}
              onClick={onViewInShop}
              size={["sm", "sm", "md"]}
            >
              view in shop
            </ButtonV2>
          )}
        </Group>
      </PanelBoxV2>
      {!!relatedUserResults.length && (
        <PanelBoxV2
          maxWidth={dimensions?.columnWidth}
          outer={{
            pt: [40],
            pb: [50, 50, 80],
            px: [50],
            bg: "white"
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={28}>
            Why should you do this?
          </Box>
          <Box py={[7.5, 7.5, 15]} />
          <Box>The above suggestion is based on the following results</Box>
          <Box py={20} />
          <Box fontFamily="gilroyBold" fontSize={18}>
            Linked results
          </Box>
          <Box py={15} />
          <Box display="flex" flexDirection="column" gap={20}>
            <RelatedResults results={relatedUserResults} py={0} />
          </Box>
        </PanelBoxV2>
      )}
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  .PoweredByRegenerusBanner svg {
    fill: black;
  }
`;

export default function SupplementDetailPage() {
  const { slug } = useParams();

  const { setBasketOpen } = useAppState();
  const { addItemToBasket } = useBasketContext();

  return (
    <CustomerPage bg="haze">
      <Box bg="haze" pt={[94]} />
      <GlobalStyle />
      <DataLoader
        query={SUPPLEMENT_DETAIL_QUERY}
        variables={{
          slug
        }}
        render={({ supplement }) =>
          supplement ? (
            <SupplementDetailContent
              supplementIngredients={supplement.supplementIngredients}
              onAddToBasket={() => {
                addItemToBasket({ compositeId: supplement.compositeId });
                setBasketOpen(true);
              }}
              description={supplement.description}
              name={supplement.name}
              productImage={supplement.productImage}
              dietryRestrictions={supplement.dietryRestrictions}
              price={supplement.price}
              directions={supplement.directions}
              relatedUserResults={supplement.relatedUserResults}
              dimensions={{
                columnWidth: 760
              }}
              status={supplement.status}
            />
          ) : (
            <Redirect to={ERROR_404_URL} />
          )
        }
      />
    </CustomerPage>
  );
}
