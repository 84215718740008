import { Redirect, Switch, useRouteMatch, useLocation } from "react-router-dom";

import AuthRoute from "@/components/AuthRoute";
import CustomerPage from "@/components/CustomerPage";
import CustomerTabs from "@/components/CustomerTabs";
import DataLoader from "@/components/DataLoader";
import { REVIEW_STATUS_FOR_PRACTITIONER_REVIEW } from "@/core/constants";
import { ERROR_404_URL, getTestResultsUrl } from "@/core/urls";
import { gql } from "@apollo/client";

import { ExpiredMarkersToggleSwitch } from "./ExpiredMarkersToggleSwitch";
import UserTest from "./UserTest";

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest($allTests: Boolean, $excludeRetests: Boolean, $excludeStatuses: [String]) {
    userTests(
      allTests: $allTests
      excludeRetests: $excludeRetests
      excludeStatuses: $excludeStatuses
    ) {
      id
    }
  }
`;

export default function UserTests() {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthRoute exact path={match.path}>
        {/* In the menu for example we have a 'Test Results' link.  This route takes
        care of working out the first test id */}
        <CustomerPage
          bg="haze"
          includeFooter
          jumbotronProps={{
            title: "Test Results",
            top: <ExpiredMarkersToggleSwitch />
          }}
        >
          <CustomerTabs selectedTab="/test-results" />
          <DataLoader
            query={GET_FIRST_USER_TEST}
            variables={{
              allTests: true,
              excludeRetests: true,
              excludeStatuses: [REVIEW_STATUS_FOR_PRACTITIONER_REVIEW]
            }}
            render={({ userTests }) => (
              <Redirect
                to={userTests?.length ? getTestResultsUrl(userTests?.[0]?.id) : ERROR_404_URL}
              />
            )}
          />
        </CustomerPage>
      </AuthRoute>
      <AuthRoute path={`${match.path}/:userTestId`} component={UserTest} />
    </Switch>
  );
}
