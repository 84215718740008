import { useCallback, useEffect, useState } from "react";

import { useElements, useStripe } from "@stripe/react-stripe-js";

import { Spinner } from "@/components/Spinner";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import ChevronComponent from "@/tpo/Chevron";
import ButtonV2 from "@/v2/Buttons";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";

const GET_STRIPE_SETUP_INTENT_QUERY = gql`
  query GetStripeSetupIntent($organisation: ID!) {
    stripeSetupIntent(organisation: $organisation) {
      customer
      clientSecret
      url
    }
  }
`;

const CONFIRM_SETUP_INTENT_MUTATION = gql`
  mutation ConfirmSetupIntentMutation($input: ConfirmSetupIntentMutationInput!) {
    confirmSetupIntentMutation(input: $input) {
      success
      error
    }
  }
`;

export default function StripeSetupPaymentMethodButton({ update = false, onNewPaymentMethod }) {
  const stripe = useStripe();
  const elements = useElements();
  const { organisation } = useOrganisationContext();
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [stripeModalActive, setStripeModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getStripeSetupIntent, { data }] = useLazyQuery(GET_STRIPE_SETUP_INTENT_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [GET_STRIPE_SETUP_INTENT_QUERY],
    awaitRefetchQueries: true
  });
  const [confirmSetupIntent, { data: confirmSetupIntentData }] = useMutation(
    CONFIRM_SETUP_INTENT_MUTATION
  );

  const handleClick = useCallback(() => {
    setLoading(true);
    getStripeSetupIntent({
      variables: {
        organisation: parseInt(organisation?.id)
      }
    });
  }, [getStripeSetupIntent, organisation]);

  useEffect(() => {
    if (data) {
      if (data.stripeSetupIntent.url) {
        window.location.href = data.stripeSetupIntent.url;
      } else {
        setStripeClientSecret(data.stripeSetupIntent.clientSecret);
      }
    }
  }, [data]);

  useEffect(() => {
    async function wrapper() {
      const paymentMethod = await stripe?.collectBankAccountForSetup({
        clientSecret: stripeClientSecret,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: organisation.name,
              email: organisation.email
            }
          },
          return_url: `${window.location.origin}/partners/dashboard/business/payment`
        }
      });

      if (paymentMethod.error) {
        Sentry.captureException(paymentMethod.error);
      } else if (paymentMethod.setupIntent.status === "requires_confirmation") {
        confirmSetupIntent({
          variables: {
            input: {
              organisation: parseInt(organisation?.id),
              setupIntentId: paymentMethod.setupIntent.id,
              paymentMethod: paymentMethod.setupIntent.payment_method
            }
          }
        });
      } else {
        setLoading(false);
        setStripeClientSecret(null);
        setStripeModalActive(false);
      }
    }
    if (stripeClientSecret && !stripeModalActive) {
      setStripeModalActive(true);
      wrapper();
    }
  }, [stripeClientSecret, stripe, elements, organisation, confirmSetupIntent, stripeModalActive]);

  useEffect(() => {
    if (confirmSetupIntentData) {
      setLoading(false);
      setStripeClientSecret(null);
      setStripeModalActive(false);

      if (onNewPaymentMethod) {
        onNewPaymentMethod(confirmSetupIntentData.confirmSetupIntentMutation.paymentMethod);
      }
    }
  }, [confirmSetupIntentData, onNewPaymentMethod]);

  return (
    <ButtonV2
      cursor="pointer"
      underline={false}
      target="_self"
      rel="noopener noreferrer"
      mx="auto"
      color="green"
      onClick={handleClick}
      rightIcon={loading ? "" : <ChevronComponent />}
      type="button"
      size={["sm", "sm", "md"]}
      loading={loading}
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner />
          &nbsp;&nbsp;
        </>
      ) : update ? (
        "Update"
      ) : (
        "Add"
      )}
    </ButtonV2>
  );
}
