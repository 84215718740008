import { createContext, useRef } from "react";

import { useOnScreen } from "@/hooks/useOnScreen";
import { PanelBoxV2 } from "@/tpo/Boxes";
import OrderDetailTemplate from "@/tpo/orders/OrderDetailTemplate";

export const CheckoutContext = createContext();

export default function CheckoutTemplate({
  title,
  children,
  discount,
  shipping,
  total,
  showBasketSummaryFooter,
  gap
}) {
  const basketSummaryRef = useRef();

  const basketSummaryIsVisible = useOnScreen(basketSummaryRef);

  return (
    <CheckoutContext.Provider value={{ basketSummaryRef, basketSummaryIsVisible }}>
      <OrderDetailTemplate
        gap={gap}
        title={title}
        footer={
          showBasketSummaryFooter && (
            <PanelBoxV2
              maxWidth={760}
              outer={{
                bg: "white",
                position: "sticky",
                bottom: 0,
                borderTopColor: "haze",
                borderTopStyle: "solid",
                borderTopWidth: 1,
                px: 40,
                pt: 20,
                pb: 40,
                transition: "all 100ms ease-out",
                transform: basketSummaryIsVisible ? "translateY(100%)" : "translateY(0)",
                opacity: basketSummaryIsVisible ? 0 : 1,
                zIndex: 1
              }}
              stacked
              gap={10}
              inner={{
                alignItems: "flex-end"
              }}
            >
              {discount}
              {shipping}
              {total}
            </PanelBoxV2>
          )
        }
      >
        {children}
      </OrderDetailTemplate>
    </CheckoutContext.Provider>
  );
}
