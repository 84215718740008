import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import { getCmpOrderUrl, getOrderUrl } from "@/core/urls";

export default function OrderedBy({ order, cmpContext = true }) {
  const { sourceBasket: basket } = order;

  if (order) {
    if (order.orderCopiedFrom) {
      return (
        <Box fontFamily="gilroyMedium" fontSize={18}>
          Original order:{" "}
          <ExternalTextLink
            href={
              cmpContext
                ? getCmpOrderUrl(order.orderCopiedFrom.id)
                : getOrderUrl(order.orderCopiedFrom.id)
            }
          >
            {order.orderCopiedFrom.id}
          </ExternalTextLink>
        </Box>
      );
    } else {
      return (
        <Box fontFamily="gilroyMedium" fontSize={18} data-testid="orderedBy">
          Ordered by: {basket?.practitioner?.user.fullName}
        </Box>
      );
    }
  }
}
