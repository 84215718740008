import { useState } from "react";

import { useFormContext } from "react-hook-form";

import CrossIcon from "@/images/cross.svg?react";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import Group from "@/tpo/Group";
import ButtonV2 from "@/v2/Buttons";

export default function ProfessionalBodiesField({ name = "professionalBodies" }) {
  const [professionalBodyValue, setProfessionalBodyValue] = useState("");
  const { setValue, watch } = useFormContext();
  const professionalBodies = watch(name);

  return (
    <>
      <Group gap={20}>
        <FloatingLabelInput
          label="Enter Details"
          style={{
            flexGrow: 1
          }}
          value={professionalBodyValue}
          onChange={e => setProfessionalBodyValue(e.target.value)}
          name={name}
        />
        <ButtonV2
          type="button"
          color="dark"
          size="sm"
          onClick={() => {
            if (professionalBodyValue) {
              setValue(name, [...new Set([...professionalBodies, professionalBodyValue])], {
                shouldDirty: true
              });
              setProfessionalBodyValue("");
            }
          }}
          data-testid="addProfessionalBodyButton"
        >
          add
        </ButtonV2>
      </Group>
      {!!professionalBodies?.length && (
        <Group pt={[20, 20, 40]} gap={10} flexWrap="wrap">
          {professionalBodies.map(pb => (
            <ButtonV2
              key={pb}
              leftIcon={<CrossIcon fill="white" width={10} />}
              variant="pill"
              size={["xs", "xs", "sm"]}
              type="button"
              sx={{
                color: "white",
                bg: "green",
                borderStyle: "solid",
                borderWidth: 0,
                borderColor: "white",
                gap: 10
              }}
              onClick={() => {
                setValue(
                  name,
                  professionalBodies.filter(p => p !== pb),
                  { shouldDirty: true }
                );
              }}
              data-component-name="professionalBodyValue"
            >
              {pb}
            </ButtonV2>
          ))}
        </Group>
      )}
    </>
  );
}
