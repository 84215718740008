import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function PrivacyPage() {
  useDocTitle("Privacy policy");
  return <IFramePage title="Privacy Policy" src="/privacy-policy-content.html" />;
}

export default PrivacyPage;
