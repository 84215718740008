import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import CustomerPage from "@/components/CustomerPage";
import DataLoader from "@/components/DataLoader";
import Page from "@/components/Page";
import { theme } from "@/core/theme";
import { PLATFORM_PROFILE_GOALS_URL } from "@/core/urls";
import { PLATFORM_USER_PROFILE_MUTATION, PLATFORM_USER_PROFILE_QUERY } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import ButtonV2 from "@/v2/Buttons";

import Asterisk from "./Asterisk";
import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ControlledFormField from "./ControlledFormField";
import ControlledRadioGroup from "./ControlledRadioGroup";
import DateInput from "./DateInput";
import FloatingLabelInput from "./FloatingLabelInput";
import FormControl from "./FormControl";
import Jumbotron from "./Jumbotron";
import Stack from "./Stack";

function initialize(platformUserProfile) {
  // NB it will use the logged in user to get the right platform user profile
  // instance server side so no need for the id here

  return {
    height: platformUserProfile.height || "",
    weight: platformUserProfile.weight || "",
    dateOfBirth: platformUserProfile.dateOfBirth || "",
    gender: platformUserProfile.gender
  };
}

function PlatformUserProfilePageForm({ platformUserProfile }) {
  const history = useHistory();

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: PLATFORM_USER_PROFILE_MUTATION,
    mutationName: "platformUserProfileMutation",
    defaultValues: initialize(platformUserProfile),
    handleSuccess: () => {
      history.push(PLATFORM_PROFILE_GOALS_URL);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PanelBoxV2
          maxWidth={600}
          outer={{
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: 20
          }}
          stacked
          gap={40}
        >
          {nonFieldError && (
            <Box color="red" py={20}>
              {nonFieldError}
            </Box>
          )}
          <FormControl
            as="fieldset"
            label={
              <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                Physical details
                <Asterisk />
              </Box>
            }
            fields={
              <Stack gap={20}>
                <ControlledFormField
                  name="height"
                  label="Height (cm)"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="weight"
                  label="Weight (kg)"
                  Component={FloatingLabelInput}
                />
              </Stack>
            }
          />
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Date of birth
              </Box>
            }
          >
            <ControlledFormField
              name="dateOfBirth"
              Component={DateInput}
              getDay={dateStr => {
                const digits = dateStr.split("-")[2];
                return digits === undefined ? "" : digits;
              }}
              getMonth={dateStr => {
                const digits = dateStr.split("-")[1];
                return digits === undefined ? "" : digits;
              }}
              getYear={dateStr => {
                const digits = dateStr.split("-")[0];
                return digits === undefined ? "" : digits;
              }}
              constructDate={({ day, month, year }) => {
                return `${year}-${month}-${day}`;
              }}
            />
          </FormControl>
          <FormControl
            label={
              <Box as="h2" fontFamily="gilroyBold" fontSize={18} lineHeight="36px">
                Gender
              </Box>
            }
          >
            <ControlledRadioGroup
              Container={Stack}
              containerProps={{
                gap: 20
              }}
              name="gender"
              values={[
                {
                  label: "Male",
                  value: "Male"
                },
                {
                  label: "Female",
                  value: "Female"
                }
              ]}
            />
          </FormControl>
          <Center>
            <ButtonV2 color="green" type="submit">
              Submit
            </ButtonV2>
          </Center>
        </PanelBoxV2>
      </form>
    </FormProvider>
  );
}

export default function PlatformUserProfilePage() {
  useDocTitle("Profile");
  return (
    <CustomerPage
      jumbotronProps={{
        title: "Profile"
      }}
    >
      <DataLoader
        query={PLATFORM_USER_PROFILE_QUERY}
        fetchPolicy="no-cache"
        nextFetchPolicy="no-cache"
        render={({ platformUserProfile }) =>
          platformUserProfile ? (
            <PlatformUserProfilePageForm platformUserProfile={platformUserProfile} />
          ) : null
        }
      />
    </CustomerPage>
  );
}
