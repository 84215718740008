import { PanelBoxV2 } from "@/tpo/Boxes";

export default function Container({
  children,
  bg,
  height,
  "data-component-name": dataComponentName
}) {
  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        px: [20],
        position: "fixed",
        top: 40,
        left: 0,
        right: 0,
        bg,
        zIndex: 4,
        "data-component-name": dataComponentName
      }}
      inner={{
        height
      }}
    >
      {children}
    </PanelBoxV2>
  );
}
