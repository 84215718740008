import styled from "styled-components";
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography
} from "styled-system";

const Svg = styled.svg.attrs(props => {})`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${grid}
  ${typography}
  ${system({
    width: {
      property: "width"
    },
    height: {
      property: "height"
    }
  })}
`;

Svg.defaultProps = {};

export default Svg;
