import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import Grid from "@/components/Grid";
import { theme } from "@/core/theme";
import { getQuestionnaireIntroUrl } from "@/core/urls";
import Danger from "@/images/Danger";
import Cross from "@/images/cross.svg?react";
import Pencil from "@/images/pencil.svg?react";
import Tick from "@/images/tick.svg?react";

import { PanelBoxV2 } from "./Boxes";
import Card from "./Card";
import Center from "./Center";
import { CollapseableText } from "./CollapseableText";
import Flag from "./Flag";
import Group from "./Group";
import Square from "./Square";
import Stack from "./Stack";

function Status({ icon, text, textProps }) {
  return (
    <Group gap={10}>
      <Box color="white" fontFamily="gilroyMedium" fontSize={[12, 12, 14]} {...textProps}>
        {text}
      </Box>
      {icon}
    </Group>
  );
}

export function useUserQuestionnaireSubmissionSummaries({
  snapshotStatus,
  snapshotQueued,
  userSubmissions
}) {
  const latestSubmission = userSubmissions?.[0];

  const statusProps = {
    text: "",
    icon: null,
    textProps: {}
  };

  let nextAction;

  const greenTick = (
    <Tick fill={theme.colors.green} width={10} style={{ transform: "scale3D(0.5,0.5,0.5" }} />
  );
  const redCross = <Cross fill={theme.colors.red} width={10} />;

  if (latestSubmission?.complete) {
    if (snapshotQueued) {
      if (snapshotStatus?.toLowerCase() === "complete") {
        statusProps.text = "Updating pending";
      } else {
        statusProps.text = "Processing";
      }
    } else {
      statusProps.text = latestSubmission?.hasExpiredAnswers ? "Expired answers" : "Complete";
      statusProps.icon = latestSubmission?.hasExpiredAnswers ? <Danger /> : greenTick;
      nextAction = "edit";

      if (latestSubmission?.hasExpiredAnswers) {
        statusProps.textProps = {
          ...statusProps.textProps,
          color: "error"
        };
      }
    }
  } else {
    statusProps.text = latestSubmission?.hasExpiredAnswers
      ? "Expired answers"
      : latestSubmission?.status ?? "Incomplete";
    statusProps.icon = latestSubmission?.hasExpiredAnswers ? <Danger /> : redCross;

    if (latestSubmission?.hasExpiredAnswers) {
      statusProps.textProps = {
        ...statusProps.textProps,
        color: "error"
      };
    }

    nextAction = latestSubmission ? "edit" : "begin";
  }

  return {
    statusElement: <Status {...statusProps} />,
    nextAction,
    latestSubmission
  };
}

function UserQuestionnaireSubmissionSummaries({
  bg,
  snapshotStatus,
  snapshotQueued,
  userSubmissions,
  newUserView
}) {
  // for now we only accept submissions for the symptom questionnaire
  // so we just take the first which is the latest
  // later on when we have multiple questionnaires we'll need the latest PER questionnaire type

  const history = useHistory();

  const {
    statusElement,
    nextAction,
    latestSubmission,
    redCross
  } = useUserQuestionnaireSubmissionSummaries({
    snapshotStatus,
    snapshotQueued,
    userSubmissions
  });

  return (
    <PanelBoxV2
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        bg,
        px: [20, 20, "5.5vw"]
      }}
    >
      <PanelBoxV2
        maxWidth={760}
        inner={{
          px: [20, 20, 0]
        }}
      >
        <Stack gap={[10, 10, 20]}>
          {newUserView && <Flag text="1" />}
          <Box>
            <Box
              as="h2"
              color="dark"
              fontFamily="gilroyBold"
              fontSize={[28, 28, 36]}
              lineHeight="130%"
            >
              Self assessment
            </Box>
            <Box py={[2, 2, 20]} />
            <CollapseableText fontSize={[14, 14, 16]}>
              {`A wellness score is a measure that indicates your overall well-being or health status. Your test results can significantly impact your wellness score by providing important insights that help quantify and adjust it based on objective data about your health status.

          This score aims to provide a concise assessment of your holistic wellness, allowing you to gauge your current state and track your progress over time. It is typically derived from various factors such as health score + performance score (soon) + risk score (soon). It can be used as a tool for self-assessment, goal setting, and making informed decisions regarding health and lifestyle choices. Sub-optimal test results may lower your wellness score, indicating potential health risks. On the other hand, favourable test results can contribute to a higher wellness score, reflecting better health and well-being.`}
            </CollapseableText>
          </Box>
        </Stack>
      </PanelBoxV2>
      <Box py={[2, 2, 20]} />
      <PanelBoxV2 maxWidth={844}>
        <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={[2, 2, 20]}>
          <Card
            with3DShadowEffect
            height="100%"
            withBorder
            onClick={() => {
              history.push({
                pathname: getQuestionnaireIntroUrl("symptoms"),
                state: {
                  getStarted: true
                }
              });
            }}
          >
            <Card.Header bg={latestSubmission?.hasExpiredAnswers ? "partners" : "white"}>
              <Center p={[20, 20, 40, 40]}>
                <Square withBorder borderRadius="33%" size={[60, 100]}>
                  <Center height="100%">
                    <Box width={[23, 36]}>
                      <Pencil fill={theme.colors.dark} width="100%" />
                    </Box>
                  </Center>
                </Square>
              </Center>
            </Card.Header>
            <Card.Body bg="#17224D" pt={20} pb={30} flexGrow={1}>
              <Stack alignItems="center" gap={[20, 20, 30]}>
                <Box
                  color="white"
                  fontSize={[16, 16, 18]}
                  fontFamily="gilroyBold"
                  lineHeight="130%"
                >
                  Symptoms
                </Box>
                {statusElement}
                <Box
                  color="white"
                  letterSpacing={2.8}
                  fontSize={[10, 10, 12]}
                  fontFamily="gilroyBold"
                  textTransform="uppercase"
                >
                  {nextAction}
                </Box>
              </Stack>
            </Card.Body>
          </Card>
          <Card with3DShadowEffect withBorder opacity={0.5} height="100%">
            <Card.Header bg="white">
              <Center p={[20, 20, 40, 40]}>
                <Square withBorder borderRadius="33%" size={[60, 100]}>
                  <Center height="100%">
                    <Box width={[23, 36]}>
                      <Pencil fill={theme.colors.dark} width="100%" />
                    </Box>
                  </Center>
                </Square>
              </Center>
            </Card.Header>
            <Card.Body bg="#17224D" pt={20} pb={30} flexGrow={1}>
              <Stack alignItems="center" gap={[20, 20, 30]}>
                <Box
                  color="white"
                  fontSize={[16, 16, 18]}
                  fontFamily="gilroyBold"
                  lineHeight="130%"
                >
                  Lifestyle
                </Box>
                <Status text="Incomplete" icon={redCross} />
                <Box
                  color="white"
                  letterSpacing={2.8}
                  fontSize={[10, 10, 12]}
                  fontFamily="gilroyBold"
                  textTransform="uppercase"
                >
                  coming soon
                </Box>
              </Stack>
            </Card.Body>
          </Card>
        </Grid>
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

UserQuestionnaireSubmissionSummaries.defaultProps = {
  bg: "white"
};

export default UserQuestionnaireSubmissionSummaries;
