import { useEffect, useRef, useState } from "react";

import Box from "@/components/Box";
import Text from "@/components/Text";
import { FadeSlideTransition, FadeTransition } from "@/components/animations/FadeTransition";
import { useOnScreen } from "@/hooks/useOnScreen";
import Avatar from "@/images/tpo/avatar-tooltip-upwards.svg?react";

import { HeadingExtraExtraSmall } from "./Headings";

/**
 * Ideally the gql should implement a union of possible chart types.
 * Each type in the union would then feed the props needed for the react component.
 * Alas, apolloJS doesn't seem to be working when I try and set it up for unions and interfaces.
 * So we'll need to bump apolloJS first before switching to this approach on the BE.
 *
 * For now we need to do things like check terminology to know how to interpret the colours
 * given a value.  All this should be moved to the BE once apolloJS has been bumped.
 */

export function UnknownChart() {
  return (
    <Text
      fontFamily="gilroyBold"
      fontSize={[14, 16]}
      textTransform="uppercase"
      textAlign="center"
      mt={2}
      mb={4}
    >
      results unavailable
    </Text>
  );
}

function Point({ label }) {
  return (
    <Box position="relative" fontSize="10px">
      <Box>{label}</Box>
      <Box display="flex" justifyContent="center">
        <Box height="5px" width="1px" backgroundColor="black" />
      </Box>
    </Box>
  );
}

function Points({ points }) {
  return (
    <Box position="relative" height={20}>
      {points
        .filter(point => point.position >= 0 && point.position <= 100)
        .map((point, index) => (
          <Box
            key={point.position}
            position="absolute"
            left={`${point.position}%`}
            transform="translate(-50%)"
          >
            <Point label={point.label} />
          </Box>
        ))}
    </Box>
  );
}

function ValueIcon({ valuePerCent, visible = true }) {
  return (
    <Box position="relative" height={30}>
      <FadeSlideTransition
        in={visible}
        appear={true}
        timeout={1000}
        delay={300}
        offsetX={`-${valuePerCent}%`}
        easing={"cubic-bezier(.7,.3,.3,.7)"}
      >
        <Box position="absolute" transform="translateX(-50%)" left={`${valuePerCent}%`}>
          <Avatar />
        </Box>
      </FadeSlideTransition>
    </Box>
  );
}

function BaseGradientChart({ chart, points, value, onScreen }) {
  // Gradiant Chart: Low, Below, Optimal, Above, High

  return (
    <>
      <Points points={points} />
      {chart}
      <ValueIcon valuePerCent={value} visible={onScreen} />
    </>
  );
}

/**
 * Sometimes the points overlap.  How should we handle this?
 * One option would be to may be use a different gradient chart if there is a
 * too small gap between points.
 */

function pickHex(color1, color2, weight) {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w / 1 + 1) / 2;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
  return rgb;
}

// Inspired by: https://stackoverflow.com/a/30144587
export function getColourAtPointOnGradient(gradient, valuePerCent) {
  const index = gradient.findIndex(([percent]) => valuePerCent <= percent);
  if (index === -1) return "dark";
  if (index === 0) return gradient[0][1];
  const firstColor = gradient[index - 1][1];
  const secondColor = gradient[index][1];
  const min = gradient[index - 1][0];
  const max = gradient[index][0];
  const ratio = 1 - (valuePerCent - min) / (max - min);
  return pickHex(firstColor, secondColor, ratio);
}

export function GradientChart({ background, points, value, hasExpired, onScreen }) {
  return (
    <BaseGradientChart
      chart={
        <Box
          background={background}
          borderRadius="100px"
          height={"10px"}
          filter={hasExpired ? "saturate(0%)" : null}
        />
      }
      onScreen={onScreen}
      points={points}
      value={value}
    />
  );
}

function Interval({ borderRadius, bg, defaultBg, width, height, animate, delay }) {
  const intervalRef = useRef();
  const onScreen = useOnScreen(intervalRef, { once: true });

  defaultBg = defaultBg || "haze";

  return (
    <>
      <Box
        bg={defaultBg}
        borderRadius={borderRadius}
        height={height}
        width={width}
        ref={intervalRef}
        display="flex"
        flexDirection="row"
      >
        {animate ? (
          <FadeTransition
            in={onScreen && bg !== defaultBg}
            delay={delay}
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <Box bg={bg} borderRadius={borderRadius} width="100%" height="100%" />
          </FadeTransition>
        ) : (
          <Box bg={bg} borderRadius={borderRadius} width="100%" height="100%" />
        )}
      </Box>
    </>
  );
}

function Intervals({ bg, intervals = [], borderRadius, height, filter, animate, animDelayOffset }) {
  let activeIntervalCount = 0;

  return (
    <Box display="flex" bg={bg} borderRadius={borderRadius} height={height} filter={filter}>
      {intervals.map((interval, index) => (
        <interval.Component
          borderRadius={borderRadius}
          animate={animate}
          key={index}
          {...interval.props}
          delay={
            (interval.props.bg !== bg ? activeIntervalCount++ : 0) * 60 +
            (animDelayOffset || 0) +
            50
          }
        />
      ))}
    </Box>
  );
}

Intervals.defaultProps = {
  borderRadius: 100,
  height: "10px"
};

function BaseDiscreteChart({ IntervalComponent, bg, values, height, hasExpired, animDelayOffset }) {
  return (
    <Intervals
      filter={hasExpired ? "saturate(0%)" : null}
      bg={bg}
      height={height}
      intervals={values.map(value => ({
        Component: IntervalComponent,
        props: {
          bg: value.bg,
          width: `${100 / values.length}%`,
          meta: { value }
        }
      }))}
    />
  );
}

BaseDiscreteChart.defaultProps = {
  bg: "haze",
  IntervalComponent: Interval
};
function BaseDiscreteChartWithTermName({ terms, values, hasExpired, wordProps }) {
  return (
    <>
      <BaseDiscreteChart values={values} hasExpired={hasExpired} />
      <Box display="flex" mt={2} fontSize="8px">
        {terms.map((term, index) => (
          <Box
            textTransform="uppercase"
            textAlign="center"
            key={index}
            width={`${100 / terms.length}%`}
            display="flex"
            alignItems="center"
            borderRight="1px solid black"
            {...wordProps}
          >
            <Box backgroundColor="black" height="1px" flexGrow={1} flexShrink={1} minWidth="10%" />
            <Box fontFamily="gilroyBold" mx="5%">
              {term}
            </Box>
            <Box backgroundColor="black" height="1px" flexGrow={1} flexShrink={1} minWidth="10%" />
          </Box>
        ))}
      </Box>
    </>
  );
}

export function DiscreteChart({ terms, values, hasExpired, wordProps }) {
  return (
    <BaseDiscreteChartWithTermName
      terms={terms}
      values={values}
      hasExpired={hasExpired}
      wordProps={wordProps}
    />
  );
}
export function BaseSegmentedProgressChart({
  colours,
  fill = false,
  backgroundColor,
  defaultColor,
  animate = true,
  animDelayOffset = 0,
  hasExpired
}) {
  const intervals = colours
    .map(colour => ({
      Component: Interval,
      props: {
        bg: colour,
        width: "10%"
      }
    }))
    .concat(
      fill
        ? Array.from(Array(10 - colours.length).keys()).map(() => ({
            Component: Interval,
            props: { bg: defaultColor, width: "10%" }
          }))
        : []
    );

  return (
    <Intervals
      filter={hasExpired ? "saturate(0%)" : null}
      bg={backgroundColor}
      intervals={intervals}
      animate={animate}
      animDelayOffset={animDelayOffset}
    />
  );
}

BaseSegmentedProgressChart.defaultProps = {
  borderRadius: 100,
  height: "10px"
};

export function SegmentedProgressChart({
  colours,
  fill,
  defaultColor = "haze",
  backgroundColor = "white",
  animate = true,
  animDelayOffset = 0,
  hasExpired
}) {
  // if (!colours?.length) return null;

  // default color is the default color of a segment
  // background color is the background color of the container

  return (
    <BaseSegmentedProgressChart
      colours={colours}
      fill={fill}
      defaultColor={defaultColor}
      backgroundColor={backgroundColor}
      animate={animate}
      animDelayOffset={animDelayOffset}
      hasExpired={hasExpired}
    />
  );
}

function MidPoint(props) {
  return (
    <Box position="relative" width={props.width}>
      <Box left="50%" position="absolute" transform="translate(-50%)">
        <Point label={props.meta.value.term} />
      </Box>
    </Box>
  );
}

function ValueIconUndernearth(props) {
  return (
    <Box width={props.width}>
      {props.meta.value.showValueIcon ? <ValueIcon valuePerCent={50} /> : null}
    </Box>
  );
}

function BaseSegmentedChart({
  discreteValues,
  value,
  terms,
  showTerms = true,
  hasExpired,
  showValueIcon = true
}) {
  const [markerAppear, setMarkerAppear] = useState(false);
  const ref = useRef();
  const onScreen = useOnScreen(ref, { once: true });

  useEffect(() => {
    if (showValueIcon && onScreen) {
      setMarkerAppear(true);
    }
  }, [setMarkerAppear, showValueIcon, onScreen]);

  if (discreteValues === null) {
    return null;
  }

  if (terms === null) {
    showTerms = false;
  }

  return (
    <Box ref={ref}>
      {showTerms && (
        <BaseDiscreteChart
          bg="transparent"
          borderRadius={0}
          height="20px"
          values={terms}
          IntervalComponent={MidPoint}
        />
      )}
      <BaseDiscreteChart values={discreteValues} hasExpired={hasExpired} />
      {showValueIcon && (
        <FadeSlideTransition
          in={markerAppear}
          appear={true}
          timeout={1000}
          delay={300}
          offsetX={-50}
          easing={"ease-out"}
        >
          <BaseDiscreteChart
            bg="transparent"
            borderRadius={0}
            height="20px"
            values={value}
            IntervalComponent={ValueIconUndernearth}
          />
        </FadeSlideTransition>
      )}
    </Box>
  );
}

export function SegmentedChart({ terms, discreteValues, value, hasExpired, showValueIcon }) {
  return (
    <BaseSegmentedChart
      terms={terms}
      discreteValues={discreteValues}
      value={value}
      hasExpired={hasExpired}
      showValueIcon={showValueIcon}
    />
  );
}

export function GenesAllelesRowChart({ color, risk, snp, type }) {
  return (
    <Box display="flex" justifyContent="space-between" mt={30} mb={15}>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>SNP</HeadingExtraExtraSmall>
        </Box>
        <Box>{snp}</Box>
      </Box>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>Type</HeadingExtraExtraSmall>
        </Box>
        <Box>{type}</Box>
      </Box>
      <Box>
        <Box>
          <HeadingExtraExtraSmall>Risk</HeadingExtraExtraSmall>
        </Box>
        <Box color={color}>{risk}</Box>
      </Box>
    </Box>
  );
}
