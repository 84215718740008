import { useRef } from "react";

import { useLocation } from "react-router-dom";

import Box from "@/components/Box";
import Circle from "@/components/Circle";
import Grid from "@/components/Grid";
import { useOrganisationBasketContext } from "@/contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { theme } from "@/core/theme";
import { COURSES_URL, EVENTS_URL, WEBINARS_URL } from "@/core/urls";
import { HOMEPAGE_QUERY } from "@/graphql/knowledgeCenter/webinars";
import CalendarIcon from "@/images/CalendarIcon";
import PaperStackIcon from "@/images/PaperStackIcon";
import PenIcon from "@/images/PenIcon";
import PlayIcon from "@/images/PlayIcon";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";

import OrganisationTabs from "../OrganisationTabs";
import CourseCard from "./CourseCard";
import EventCard from "./EventCard";
import { EventsTable } from "./Events";
import { ListTemplateThemeProvider } from "./ListTemplate";
import NavigationInstance from "./Navigation";
import WebinarCard from "./WebinarCard";

export default function Homepage() {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  const { data } = useQuery(HOMEPAGE_QUERY, {
    variables: {
      paymentIsoCode: basket?.paymentLocale?.isoCode,
      organisation: organisation?.id
    }
  });

  const webinars = data?.learningHubHomepage?.webinars;
  const courses = data?.learningHubHomepage?.courses;
  const events = data?.learningHubHomepage?.events;

  const location = useLocation();

  const webinarsSectionRef = useRef();
  const coursesSectionRef = useRef();
  const eventsSectionRef = useRef();

  return (
    <ListTemplateThemeProvider>
      <OrganisationTabs selectedTab="support" />
      <Group
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, 40]}
        maxWidth={1538}
        mx="auto"
        gap={80}
      >
        <NavigationInstance />
        <Stack gap={[40]} maxWidth={1138} flexGrow={1}>
          <Box>
            <Stack gap={20}>
              <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                Learning hub
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={18} maxWidth={720} lineHeight="28px">
                Here you’ll find everything you need to enhance your understanding of our range of
                tests as well as staying up to date with the latest industry news
              </Box>
            </Stack>
            <Group gap={20} flexWrap="wrap" pt={80}>
              <ButtonV2
                color="grey"
                leftIcon={<PlayIcon fill="white" size={24} />}
                variant="pill"
                gap={16}
                size="md"
                sx={{
                  px: 20
                }}
                onClick={() => {
                  webinarsSectionRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Webinars
              </ButtonV2>
              <ButtonV2
                color="grey"
                leftIcon={<CalendarIcon fill="white" size={24} />}
                variant="pill"
                gap={16}
                size="md"
                sx={{
                  px: 20
                }}
                onClick={() => {
                  eventsSectionRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Events
              </ButtonV2>
              <ButtonV2
                color="grey"
                leftIcon={<PaperStackIcon fill="white" size={24} />}
                variant="pill"
                gap={16}
                size="md"
                sx={{
                  px: 20
                }}
                onClick={() => {
                  coursesSectionRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Courses
              </ButtonV2>
            </Group>
            <Box
              pt={theme.spacing.section.pt}
              pb={theme.spacing.section.pb}
              ref={webinarsSectionRef}
            >
              <Group
                alignItems="center"
                justifyContent="space-between"
                mb={40}
                flexWrap="wrap"
                gap={20}
              >
                <Group gap={20} alignItems="center" flexWrap="wrap">
                  <Circle bg="transparent" size={60} border="2px solid black">
                    <PlayIcon fill={theme.colors.dark} size={30} />
                  </Circle>
                  <Box fontFamily="gilroyBold" fontSize={36} whiteSpace="nowrap">
                    Upcoming webinars
                  </Box>
                </Group>
                <ButtonV2 color="dark" as={LinkWrapper} to={WEBINARS_URL}>
                  see all
                </ButtonV2>
              </Group>
              <Grid gridTemplateColumns={["1fr", "1fr 1fr"]}>
                {webinars?.map(webinar => (
                  <WebinarCard key={webinar.id} {...webinar} />
                ))}
              </Grid>
            </Box>
            <Box
              bg="white"
              pt={theme.spacing.section.pt}
              pb={theme.spacing.section.pb}
              borderRadius={20}
              px={40}
              ref={coursesSectionRef}
            >
              <Stack gap={20}>
                <Group alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={20}>
                  <Group gap={20} alignItems="center" flexWrap="wrap">
                    <Circle bg="transparent" size={60} border="2px solid black">
                      <PaperStackIcon fill={theme.colors.dark} size={30} />
                    </Circle>
                    <Box fontFamily="gilroyBold" fontSize={36} whiteSpace="nowrap">
                      Latest courses
                    </Box>
                  </Group>
                  <ButtonV2 color="dark" as={LinkWrapper} to={COURSES_URL}>
                    see all
                  </ButtonV2>
                </Group>
                <Box fontFamily="gilroyMedium" fontSize={18}>
                  Elevate your expertise and dive into educational insights, best practices, case
                  studies, and more in our webinars.
                </Box>
                <Grid gridTemplateColumns="repeat(auto-fill, minmax(310px, 1fr))">
                  {courses?.map(course => (
                    <CourseCard key={course.id} bg="haze" {...course} />
                  ))}
                </Grid>
              </Stack>
            </Box>
            <Box pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} ref={eventsSectionRef}>
              <Stack gap={20}>
                <Group alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={20}>
                  <Group gap={20} alignItems="center" flexWrap="wrap">
                    <Circle bg="transparent" size={60} border="2px solid black">
                      <CalendarIcon fill={theme.colors.dark} size={30} />
                    </Circle>
                    <Box fontFamily="gilroyBold" fontSize={36} whiteSpace="nowrap">
                      Upcoming Events
                    </Box>
                  </Group>
                  <ButtonV2 color="dark" as={LinkWrapper} to={EVENTS_URL}>
                    see all
                  </ButtonV2>
                </Group>
                <Box fontFamily="gilroyMedium" fontSize={18}>
                  We’ll be hosting or attending the following events
                </Box>
              </Stack>
              <EventsTable>
                {events?.map(event => (
                  <EventCard key={event.id} {...event} />
                ))}
              </EventsTable>
            </Box>
          </Box>
        </Stack>
      </Group>
    </ListTemplateThemeProvider>
  );
}
