import { useLocation } from "react-router-dom";

import GridTestProductCard from "@/components/GridTestProductCard";
import Page from "@/components/Page";
import { TestProductModalProvider } from "@/contexts/TestProductModalContext";
import { TestProductOptionProvider } from "@/contexts/TestProductOptionContext";
import {
  CONSUMER_TEST_PRODUCT_DETAIL_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS,
  CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTIONS_QUERY,
  SHOP_LISTING_FILTERS_QUERY
} from "@/graphql/shop";
import { useDebounce } from "@/hooks/useDebounce";
import useListControls from "@/hooks/useListControls";
import PractitionerFavourite from "@/images/PractitionerFavourite";
import Group from "@/tpo/Group";
import TestProductModal from "@/tpo/shop/TestProductModal";
import useProductFilters from "@/tpo/shop/hooks/useProductFilters";
import useTestProducts from "@/tpo/shop/hooks/useTestProducts";
import { useQuery } from "@apollo/client";

import Box from "../Box";
import ListTemplate from "./ListTemplate";

export default function SupportedTests() {
  const {
    data: { sampleTypes = [], categories = [], testingServices = [], userTestRecommendations } = {}
  } = useQuery(SHOP_LISTING_FILTERS_QUERY, {
    variables: {
      includeFilters: ["Both", "B2C"]
    }
  });

  const location = useLocation();

  const { allFilters, validatedFilters, toggleFilter, selectedFilters } = useProductFilters({
    defaultFilters: {
      gender: "all",
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      gender: ["all", "female", "male"],
      testingServices: [...testingServices].sort(),
      categories: [...categories.map(cat => cat.name)].sort(),
      sampleTypes: [...sampleTypes].sort()
    },
    filterSelected: {
      gender: ["male", "female"]
    }
  });

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "-product_audience,sort_order"
      },
      {
        label: "A - Z",
        value: "-product_audience,name"
      },
      {
        label: "Z - A",
        value: "-product_audience,-name"
      },
      {
        label: "Price Asc",
        value: "-product_audience,rrp_current_price"
      },
      {
        label: "Price Desc",
        value: "-product_audience,-rrp_current_price"
      }
    ]
  });

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const includePractitionerSupportedTests = true;

  const {
    products: { items: products, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: CONSUMER_TEST_PRODUCT_WITH_OPTIONS_CONNECTIONS_QUERY,
    connectionsFieldName: "consumerTestProductConnections",
    search: debouncedSearch,
    sort: controls.sort,
    validatedFilters,
    queryVariables: {
      productAudience: includePractitionerSupportedTests ? undefined : "B2C"
    }
  });

  return (
    <Page>
      <ListTemplate.Switcher>
        <ListTemplate.Header
          header="Practitioner supported tests"
          subheader="Unlock the full list of extensive functional testing to cover your every need"
        />
      </ListTemplate.Switcher>
      <ListTemplate.Container>
        <ListTemplate.Filters
          search={search}
          allFilters={allFilters}
          validatedFilters={validatedFilters}
          toggleFilter={toggleFilter}
          controls={controls}
          selectedFilters={selectedFilters}
          filtersTitle="Tests"
        />

        <TestProductOptionProvider
          testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
          testProductOptionFragmentName="ConsumerTestProductOptionFields"
          testProductOptionFragmentType="TestProductOptionType"
        >
          <TestProductModalProvider
            testProductQuery={CONSUMER_TEST_PRODUCT_DETAIL_QUERY}
            testProductFieldName="consumerTestProduct"
          >
            <Group alignItems="center" justifyContent="flex-end">
              <Group
                gap={20}
                alignItems="center"
                justifyContent="flex-end"
                fontFamily="gilroyMedium"
                fontSize={14}
                mt={[0, 0, -20]}
              >
                Practitioner favourite: <PractitionerFavourite />
              </Group>
            </Group>
            <ListTemplate.Grid
              ItemCard={GridTestProductCard}
              fetchMore={fetchMore}
              hasMore={hasMore}
              endCursor={endCursor}
              loading={loading}
              items={products}
            />
            <TestProductModal bg="white" />
          </TestProductModalProvider>
        </TestProductOptionProvider>
      </ListTemplate.Container>
    </Page>
  );
}
