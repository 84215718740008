import Svg from "@/components/Svg";

export default function HairIcon({ size }) {
  return (
    <Svg
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3634_19529)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.1745 23.3328C51.1745 23.3328 45.1181 23.6609 40.9261 29.7296C39.2604 32.139 37.8886 35.4544 37.3073 40.014C37.1745 41.0531 37.0823 42.1593 37.0386 43.3328C37.0183 43.8734 37.0073 44.4296 37.0073 45C37.0073 46.7859 36.2886 47.8546 35.623 48.8468C35.0464 49.7046 34.5073 50.5062 34.5073 51.6672C34.5073 54.4281 36.7464 56.6672 39.5073 56.6672C42.2682 56.6672 44.5073 54.4281 44.5073 51.6672C44.5073 50.4578 44.073 49.8078 43.5651 49.05C42.912 48.075 42.1386 46.9203 42.0073 44.1672C41.9901 43.8172 41.9776 43.4718 41.9698 43.1344C41.9433 42.0312 41.9604 40.9875 42.0167 40C42.2183 36.4391 42.9198 33.6064 43.8511 31.3656C46.5495 24.8748 51.1745 23.3328 51.1745 23.3328ZM41.1745 51.6672C41.1745 52.5875 40.4276 53.3328 39.5073 53.3328C38.587 53.3328 37.8401 52.5875 37.8401 51.6672C37.8401 51.6625 37.8401 51.6594 37.8385 51.6547C37.8323 51.6141 37.8229 51.5563 38.5307 50.4953C38.8495 50.0157 39.2323 49.4219 39.5635 48.6829C39.8604 49.4047 40.2026 50.0016 40.5494 50.5329C40.6416 50.675 40.7198 50.7954 40.7869 50.9C41.1432 51.4454 41.1854 51.511 41.1823 51.5735C41.1823 51.5813 41.1807 51.5907 41.1791 51.6C41.1776 51.6172 41.1745 51.6375 41.1745 51.6672Z"
          fill="#1A1A1A"
        />
        <path
          d="M22.8401 40H33.7481C34.7481 40 35.5215 40.875 35.4496 41.8734C35.4153 42.3484 35.3887 42.8359 35.3715 43.3328H22.8403L22.8401 40Z"
          fill="#1A1A1A"
        />
        <path
          d="M56.1745 43.3328H43.6433C43.6245 42.7266 43.6198 42.1375 43.6292 41.5687C43.6417 40.6875 44.3745 40 45.2573 40H56.1745L56.1745 43.3328Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3634_19529">
          <rect width="40" height="40" fill="white" transform="translate(20 20)" />
        </clipPath>
      </defs>
    </Svg>
  );
}
