import { useHistory } from "react-router-dom";

import ArrowCircle from "@/components/ArrowCircle";
import Box from "@/components/Box";
import { buildSegmentedProgressChartConfig } from "@/utils/charts/segmentedCharts";

import { SegmentedProgressChart } from "./Charts";
import Group from "./Group";
import Stack from "./Stack";

export function ScoreChart({ item, animDelayOffset }) {
  const history = useHistory();
  const preparedChartProps = buildSegmentedProgressChartConfig({ ...item.chart, fill: true });

  return (
    <Box
      onClick={() => item.href && history.push(item.href)}
      cursor={item.href ? "pointer" : "not-allowed"}
    >
      <Stack gap={10}>
        <Group alignItems="center" justifyContent="space-between">
          <Box fontFamily="gilroyBold" fontSize={18} lineHeight="130%">
            {item.title}
          </Box>
          {item.showChevron && <ArrowCircle arrowColor="white" bg="black" size={40} />}
        </Group>
        <SegmentedProgressChart {...preparedChartProps} animDelayOffset={animDelayOffset} />
      </Stack>
    </Box>
  );
}
