import { pairwise } from "@/core/utils";

function isWithinRange(value, range, isMaximum) {
  if (value >= range[0] && (isMaximum ? value <= range[1] : value < range[1])) return true;
  return false;
}

export function buildDiscreteChartConfig({ colours, terminology, normalisedRange, value }) {
  const range = [normalisedRange.minimum, ...normalisedRange.range, normalisedRange.maximum];
  value = value === undefined ? normalisedRange.value : value;

  const values = pairwise(range).map(([pointA, pointB], index) => ({
    bg: isWithinRange(value, [pointA, pointB], range[range.length - 1] === pointB)
      ? colours[index]
      : "transparent"
  }));

  return {
    terms: terminology,
    values,
    interpretationColor: values.find(value => value.bg !== "transparent")?.bg
  };
}
