import React from "react";

import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { CustomerAccountPage } from "@/components/CustomerPage";
import { InternalTextLink } from "@/components/Links";
import ResendVerificationEmailButton from "@/components/ResendVerificationEmailButton";
import {
  LOGIN_URL,
  PARTNERS_PRACTITIONER_URL,
  PARTNERS_REQUEST_PASSWORD_RESET_DONE,
  PARTNERS_SIGNIN_URL,
  REQUEST_PASSWORD_RESET_DONE,
  SIGNUP_URL
} from "@/core/urls";
import { REQUEST_PASSWORD_RESET_MUTATION } from "@/graphql/accounts";
import useDjangoGraphqlForm from "@/hooks/form/useDjangoGraphqlForm";
import useDocTitle from "@/hooks/use-doc-title";
import Center from "@/tpo/Center";
import ControlledFormField from "@/tpo/ControlledFormField";
import FloatingLabelInput from "@/tpo/FloatingLabelInput";
import Stack from "@/tpo/Stack";

export default function RequestResetPage({ partners }) {
  useDocTitle("Request password reset");
  const history = useHistory();

  const api = useDjangoGraphqlForm({
    mutation: REQUEST_PASSWORD_RESET_MUTATION,
    mutationName: "requestPasswordResetMutation",
    defaultValues: {
      email: ""
    },
    handleSuccess: () => {
      history.push(partners ? PARTNERS_REQUEST_PASSWORD_RESET_DONE : REQUEST_PASSWORD_RESET_DONE);
    }
  });

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset",
        bg: partners ? "partners" : "purple",
        color: partners ? "dark" : "white"
      }}
    >
      <FormProvider {...api.methods}>
        <Stack
          gap={40}
          as="form"
          onSubmit={api.methods.handleSubmit(api.onSubmit)}
          maxWidth={600}
          mx="auto"
        >
          <ControlledFormField name="email" label="Email" Component={FloatingLabelInput} />
          <Center>
            <ResendVerificationEmailButton errorField="email" />
          </Center>
          <Stack gap={20}>
            <InternalTextLink
              href={partners ? PARTNERS_SIGNIN_URL : LOGIN_URL}
              textAlign="center"
              display="block"
            >
              Click here to log in
            </InternalTextLink>
            {partners ? (
              <InternalTextLink href={PARTNERS_PRACTITIONER_URL} textAlign="center" display="block">
                Click here to apply
              </InternalTextLink>
            ) : (
              <InternalTextLink href={SIGNUP_URL} textAlign="center" display="block">
                Click here to sign up
              </InternalTextLink>
            )}
          </Stack>
        </Stack>
      </FormProvider>
    </CustomerAccountPage>
  );
}
