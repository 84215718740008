import { Fragment, useEffect } from "react";

import Box from "@/components/Box";
import Divider from "@/components/Divider";
import ConnectedBadge from "@/components/organisations/shared/ui/ConnectedBadge";
import InvitedBadge from "@/components/organisations/shared/ui/InvitedBadge";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { ORGANISATION_PRACITITIONER_LIST_QUERY } from "@/graphql/organisations/queries/practitioners";
import { List, ListContext } from "@/tpo/List";
import SimpleTable from "@/tpo/SimpleTable";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import { useTableControls } from "@/tpo/Table";
import { useQuery } from "@apollo/client";

import { PractitionerInviteControls } from "./PractitionerInviteControls";
import { PractitionerPermissionsList } from "./PractitionerPermissionsList";

const SORT_OPTIONS = [
  {
    label: "Newest",
    value: "last_sent"
  },
  {
    label: "Oldest",
    value: "-last_sent"
  },
  {
    label: "A - Z",
    value: "last_name"
  },
  {
    label: "Z - A",
    value: "-last_name"
  }
];

export default function TeamMembersList({ refetch = 0 }) {
  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { organisation } = useOrganisationContext();
  const { data, loading, refetch: refetchList } = useQuery(ORGANISATION_PRACITITIONER_LIST_QUERY, {
    variables: {
      organisation: parseInt(organisation?.id),
      page,
      orderBy: sort,
      search: debouncedSearch
    },
    skip: !organisation
  });

  useEffect(() => {
    if (refetch !== 0) {
      refetchList();
    }
  }, [refetch, refetchList]);

  const items = data?.organisationInvitePagination?.items;
  const pageInfo = data?.organisationInvitePagination?.pageInfo;

  const rows = items?.map(item => ({
    id: item.id,
    client: `${item.firstName}, ${item.lastName}`,
    email: item.email,
    date: new Date(item.lastSent || item.created).toLocaleDateString(),
    status: item.accepted ? <ConnectedBadge /> : <InvitedBadge />,
    actions: (
      <PractitionerInviteControls
        status={item.accepted ? "Connected" : "Invited"}
        invite={{ id: item.id }}
        onSuccess={() => refetchList()}
      />
    ),
    permissions: (
      <PractitionerPermissionsList
        inviteId={item.id}
        roles={item.roles}
        status={item.accepted ? "Connected" : "Invited"}
        alignItems="flex-end"
      />
    )
  }));

  return (
    <>
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
        Team members
      </Box>
      <Spacer py={[2, 2, 20]} />
      <SimpleTable
        search={search}
        setSearch={setSearch}
        sort={sort}
        setSort={setSort}
        sortMenuOpen={sortMenuOpen}
        setSortMenuOpen={setSortMenuOpen}
        pageInfo={pageInfo}
        setPage={setPage}
        sortingOptions={SORT_OPTIONS}
        table={
          <>
            <List items={rows} loading={loading}>
              <ListContext.Consumer>
                {rows =>
                  rows?.length ? (
                    rows?.map(({ id, client, date, email, status, actions, permissions }) => (
                      <Fragment key={id}>
                        <Box py={2}>
                          <Box py={15}>
                            <Stack gap={20} flexDirection={["column", "row", "row"]}>
                              <Box display="flex" flexDirection="column" width="25%">
                                <Box fontFamily="gilroyBold" fontSize={16}>
                                  {client}
                                </Box>
                                <Box fontSize={16}>{email}</Box>
                              </Box>
                              <Box flex={1}>
                                <Box
                                  fontFamily="gilroyBold"
                                  fontSize={16}
                                  lineHeight="150%"
                                  display={["block", "none", "none"]}
                                >
                                  Date
                                </Box>
                                <Box>{date}</Box>
                              </Box>
                              <Box>{status}</Box>
                              <Stack
                                gap={10}
                                alignItems="flex-start"
                                width={["100%", "33%", "33%"]}
                              >
                                {actions}
                              </Stack>
                            </Stack>
                            {permissions}
                          </Box>
                        </Box>
                        <Divider />
                      </Fragment>
                    ))
                  ) : (
                    <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} textAlign="center" mt={20}>
                      No team members in this organisation, you can add them below.{" "}
                    </Box>
                  )
                }
              </ListContext.Consumer>
            </List>
          </>
        }
      />
    </>
  );
}
