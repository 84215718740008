import { useCallback } from "react";

import { useHistory } from "react-router-dom";

import Box from "@/components/Box";
import Image from "@/components/Image";
import { InternalTextLink } from "@/components/Links";
import { theme } from "@/core/theme";
import Icon from "@/images/tpo/self_assesment.svg?react";
import ButtonV2 from "@/v2/Buttons";

import ChevronComponent from "./Chevron";
import { useSubmission } from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import Stack from "./Stack";

export function useIntro(questionsUrl) {
  const submission = useSubmission();
  const history = useHistory();

  const proceedToQuestions = useCallback(() => {
    history.push({
      pathname: questionsUrl,
      state: {
        getStarted: true
      }
    });
  }, [history, questionsUrl]);

  return {
    handleClick: useCallback(() => {
      if (submission) {
        proceedToQuestions();
      }
    }, [submission, proceedToQuestions]),
    submission
  };
}

function Intro({ h1, h2, h3, bodyCopy, buttonText, handleClick, linkText, linkUrl }) {
  return (
    <Page
      header={h1}
      Main={
        <>
          <Box pb={theme.spacing.section.pb} px={20}>
            <Box
              bg="white"
              pt={[40, 40, 60]}
              pb={[60, 60, 80]}
              maxWidth={1020}
              mx="auto"
              style={{
                borderRadius: 5
              }}
              px={20}
            >
              <Stack maxWidth={760} mx="auto" gap={40}>
                <Stack alignItems="center">
                  <Image src={Icon} />
                  <Box
                    as="h2"
                    textAlign="center"
                    fontSize={[18, 18, 24]}
                    textTransform="uppercase"
                    fontFamily="gilroyBold"
                  >
                    {h2}
                  </Box>
                </Stack>
                <Box>
                  <Box as="h3" fontFamily="gilroyBold" fontSize={[18, 18, 24]} mb={20}>
                    {h3}
                  </Box>
                  <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                    {bodyCopy}
                  </Box>
                </Box>
                <ButtonV2
                  color="dark"
                  onClick={handleClick}
                  rightIcon={<ChevronComponent fill="white" />}
                  mx="auto"
                  size={["sm", "sm", "md"]}
                >
                  {buttonText}
                </ButtonV2>
                {linkText && linkUrl && (
                  <InternalTextLink href={linkUrl} mx="auto">
                    {linkText}
                  </InternalTextLink>
                )}
              </Stack>
            </Box>
          </Box>
        </>
      }
    />
  );
}

export default Intro;
