import { AREA_OF_INTEREST_FIELDS } from "@/graphql/shared";
import { gql, useQuery } from "@apollo/client";

const FILTERS_QUERY = gql`
  query FiltersQuery($includeFilters: [IncludeFilters]) {
    sampleTypes
    testingServices
    categories: areasOfInterest(includeFilters: $includeFilters) {
      ...AreaOfInterestFields
    }
  }
  ${AREA_OF_INTEREST_FIELDS}
`;

export default function useFilters() {
  const {
    data: { sampleTypes = [], testingServices = [], categories: _categories = [] } = {}
  } = useQuery(FILTERS_QUERY, {
    variables: {
      includeFilters: ["Both", "B2C"]
    }
  });

  return {
    sampleTypes,
    testingServices,
    categories: _categories.map(cat => cat.name)
  };
}
