import { useCallback, useMemo } from "react";

import {
  ADD_ITEM_TO_BASKET,
  OPEN_ORDER_QUERY,
  REMOVE_ITEM_FROM_BASKET,
  SET_BASKET_ITEM_QTY_MUTATION
} from "@/graphql/shop";
import { useDataLoader } from "@/hooks/useDataLoader";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";

export default function useBasket() {
  const [removeItemFromBasketMutation, { loading: removeItemMutationLoading }] = useMutation(
    REMOVE_ITEM_FROM_BASKET
  );
  const [addItemToBasketMutation, { loading: addItemMutationLoading }] = useMutation(
    ADD_ITEM_TO_BASKET
  );
  const [setOrderItemQtyMutation, { loading: setOrderItemQtyMutationLoading }] = useMutation(
    SET_BASKET_ITEM_QTY_MUTATION
  );

  const { data: orderData, loading: orderLoading, error: orderError } = useDataLoader({
    query: OPEN_ORDER_QUERY,
    fetchPolicy: "network-only"
  });

  const openOrder = orderData?.openOrder;

  const isSubmitting =
    removeItemMutationLoading || addItemMutationLoading || setOrderItemQtyMutationLoading;

  const addItemToBasket = useCallback(
    ({ clinicLocationId, compositeId }) => {
      if (isSubmitting) return;
      addItemToBasketMutation({
        variables: {
          clinicLocationId,
          compositeId
        },
        refetchQueries: [
          {
            query: OPEN_ORDER_QUERY
          }
        ]
      }).catch(error => {
        console.log("Error encountered adding item to basket", error);
        Sentry.captureException(error);
      });
    },
    [addItemToBasketMutation, isSubmitting]
  );

  const setBasketItemQty = useCallback(
    ({ clinicLocationId, compositeId, qty }) => {
      if (isSubmitting) return;

      if (qty === "") {
        return;
      }

      setOrderItemQtyMutation({
        variables: {
          input: {
            clinicLocationId,
            compositeId,
            qty: parseInt(qty)
          }
        }
      }).catch(error => {
        console.log("Error encountered setting item qty in the basket", error);
        Sentry.captureException(error);
      });
    },
    [isSubmitting, setOrderItemQtyMutation]
  );

  const removeItemFromBasket = useCallback(
    productInfo => {
      if (isSubmitting) return;

      removeItemFromBasketMutation({
        variables: {
          clinicLocationId: productInfo.clinicLocationId,
          compositeId: productInfo.compositeId
        },
        refetchQueries: [
          {
            query: OPEN_ORDER_QUERY
          }
        ]
      }).catch(error => {
        console.log("Error encountered removing item from basket", error);
        Sentry.captureException(error);
      });
    },
    [removeItemFromBasketMutation, isSubmitting]
  );

  const api = useMemo(
    () => ({
      removeItemFromBasket,
      isSubmitting,
      addItemToBasket,
      openOrder,
      orderError,
      orderLoading,
      setBasketItemQty
    }),
    [
      removeItemFromBasket,
      isSubmitting,
      addItemToBasket,
      openOrder,
      orderError,
      orderLoading,
      setBasketItemQty
    ]
  );

  return api;
}
