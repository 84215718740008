import { forwardRef } from "react";

import Box from "@/components/Box";
import GridTestProductCard from "@/components/GridTestProductCard";
import PractitionerFavourite from "@/images/PractitionerFavourite";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ButtonV2 from "@/v2/Buttons";
import { useTheme } from "styled-components";

import IsThisTestRightForYou from "./IsThisTestRightForYou";

function PriceSection({ rrpCurrentPrice, rrpFullPrice, px, pb, bg }) {
  return (
    <Group
      pt={60}
      mt="auto"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
      gap={8}
      px={px}
      pb={pb}
      bg={bg}
    >
      <Group gap={8}>
        {rrpFullPrice !== undefined && rrpFullPrice > rrpCurrentPrice && (
          <GilroyFontCurrency value={rrpFullPrice} color="#e44c4b" strikethrough />
        )}
        <GilroyFontCurrency value={rrpCurrentPrice} />
      </Group>
      <ButtonV2 withChevron color="dark" size={["sm", "sm", "md"]}>
        learn more
      </ButtonV2>
    </Group>
  );
}

const GridListingViewCard = forwardRef(
  (
    {
      className,
      rrpCurrentPrice,
      rrpFullPrice,
      imageBackgroundColor,
      name,
      slug,
      badge,
      productAudience,
      categories,
      onClick,
      image,
      id,
      laboratory,
      testType,
      shortDescription,
      minWidth,
      maxWidth,
      isPractitionerFavourite,
      py,
      px,
      showProductCode,
      truncateDescription = false
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Box
        borderRadius={theme.radius.card}
        data-component-name="Test product card"
        display="flex"
        flexDirection="column"
        overflow="hidden"
        onClick={onClick}
        cursor="pointer"
        ref={ref}
        minWidth={minWidth}
        maxWidth={maxWidth}
        className={className}
      >
        <Box
          bg={imageBackgroundColor}
          position="relative"
          width="100%"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
          minHeight={300}
        >
          {!!badge && (
            <Box position="absolute" zIndex={1}>
              {badge}
            </Box>
          )}
          {isPractitionerFavourite ? (
            <PractitionerFavourite position="absolute" zIndex={1} top={28} right={28} />
          ) : null}
        </Box>
        <Stack
          gap={16}
          flexGrow={1}
          backgroundColor="white"
          width="100%"
          height="100%"
          py={py}
          px={px}
        >
          <GridTestProductCard.Content
            showProductCode={showProductCode}
            id={id}
            name={name}
            laboratory={laboratory}
            shortDescription={shortDescription}
            testType={testType}
            categories={categories}
            truncateDescription={truncateDescription}
          >
            {productAudience === "B2C" && <IsThisTestRightForYou slug={slug} pt="8px" />}
          </GridTestProductCard.Content>
        </Stack>
        <PriceSection
          rrpCurrentPrice={rrpCurrentPrice}
          rrpFullPrice={rrpFullPrice}
          pb={py}
          px={px}
          bg="white"
        />
      </Box>
    );
  }
);

GridListingViewCard.PriceSection = PriceSection;

GridListingViewCard.defaultProps = {
  py: 48,
  px: 28,
  showProductCode: true
};

export default GridListingViewCard;
