import BackgroundImage from "@/components/BackgroundImage";
import Box from "@/components/Box";
import Lock from "@/images/lock.svg?react";
import Center from "@/tpo/Center";
import Stack from "@/tpo/Stack";

function DataSecurity({ px, py }) {
  return (
    <Stack bg="haze" gap={30} px={px} py={py}>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
        Data Security
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        We take security very seriously and work hard to make sure you have complete control over
        your data. You can access the raw results, request all of your data or delete your account
        at any time. Your data is your data and we don’t sell or pass on your data to any other
        third parties.
      </Box>
    </Stack>
  );
}

function DataSecurityImage(props) {
  return (
    <Center bg="supplements" {...props}>
      <Lock width="15%" />
    </Center>
  );
}

function LabsYouCanTrust({ px, py }) {
  return (
    <Stack bg="haze" gap={30} px={px} py={py}>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
        Labs you can trust
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        We carefully choose our lab partners based on the highest standards of accreditation and
        accuracy. Our team of scientists and practitioners have worked in combination with our data
        experts to ensure that the suggestions and results we present are not only accurate but also
        build a complete understanding of your current wellness.
      </Box>
    </Stack>
  );
}

function LabsYouCanTrustImage(props) {
  return (
    <BackgroundImage
      backgroundImage="/test-images/test-page/labs-image.jpg"
      alt="Testing laboratory"
      backgroundColor="haze"
      width="100%"
      height="100%"
      {...props}
    />
  );
}

export default Object.assign(
  {},
  {
    DataSecurity,
    DataSecurityImage,
    LabsYouCanTrust,
    LabsYouCanTrustImage
  }
);
