import { forwardRef, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import Avatar from "@/components/Avatar";
import Box from "@/components/Box";
import CarouselContainer from "@/components/CarouselContainer";
import CircleButton from "@/components/CircleButton";
import Image from "@/components/Image";
import Page from "@/components/Page";
import {
  TestProductModalProvider,
  useTestProductModalContext
} from "@/contexts/TestProductModalContext";
import { TestProductOptionProvider } from "@/contexts/TestProductOptionContext";
import { useUserProfileContext } from "@/contexts/UserProfileContext";
import { theme } from "@/core/theme";
import { EXPERTS_SUPPORTED_TESTS_URL, EXPERTS_URL, getPartnerShopTabUrl } from "@/core/urls";
import {
  CONSUMER_TEST_PRODUCT_CONNECTIONS_QUERY,
  CONSUMER_TEST_PRODUCT_DETAIL_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS,
  OMNOS_TEST_PRODUCTS_QUERY
} from "@/graphql/shop";
import useExperts from "@/hooks/experts/useExperts";
import useCarousel from "@/hooks/useCarousel";
import CirclePlayIcon from "@/images/CirclePlayIcon";
import ConsultationImage from "@/images/Consultation.png";
import PractitionerFavourite from "@/images/PractitionerFavourite";
import PractitionerIcon from "@/images/PractitionerIcon";
import LaptopTyping from "@/images/laptopTyping.png";
import MagnifiedFiltersOverlay from "@/images/magnifiedFiltersOverlay.png";
import PractitionerOnlyProductBox from "@/images/practitionerOnlyProductBox.png";
import RequestCallback from "@/images/requestCallback.png";
import Center from "@/tpo/Center";
import Group from "@/tpo/Group";
import LinkWrapper from "@/tpo/LinkWrapper";
import Modal from "@/tpo/Modal";
import Stack from "@/tpo/Stack";
import GridListingViewCard from "@/tpo/shop/GridListingViewCard";
import TestProductModal from "@/tpo/shop/TestProductModal";
import ButtonV2, { ActionIcon } from "@/v2/Buttons";
import { useQuery } from "@apollo/client";
import Player from "@vimeo/player";
import { ThemeProvider, useTheme } from "styled-components";

import Grid from "../Grid";
import ExpertCard from "./ExpertListCard";
import ListTemplate from "./ListTemplate";

const practitionerSupportSectionThemeBreakpoints = ["640px", "960px", "1360px"];
practitionerSupportSectionThemeBreakpoints.sm = practitionerSupportSectionThemeBreakpoints[0];
practitionerSupportSectionThemeBreakpoints.md = practitionerSupportSectionThemeBreakpoints[1];
practitionerSupportSectionThemeBreakpoints.lg = practitionerSupportSectionThemeBreakpoints[2];

const practitionerSupportSectionTheme = {
  ...theme,
  breakpoints: practitionerSupportSectionThemeBreakpoints,
  disableStyledSystemCache: true
};

const howItWorksSectionThemeBreakpoints = ["780px", "960px", "1540px"];
howItWorksSectionThemeBreakpoints.sm = howItWorksSectionThemeBreakpoints[0];
howItWorksSectionThemeBreakpoints.md = howItWorksSectionThemeBreakpoints[1];
howItWorksSectionThemeBreakpoints.lg = howItWorksSectionThemeBreakpoints[2];

const howItWorksSectionTheme = {
  ...theme,
  breakpoints: howItWorksSectionThemeBreakpoints,
  disableStyledSystemCache: true
};

function HowItWorksCard({ children, title, description }) {
  return (
    <Box
      background="linear-gradient(162deg, #CCBAFF 0.67%, #FFCCCA 99.51%)"
      borderRadius={10}
      py={[60, 60, 28]}
      px={28}
      // maxWidth={361}
    >
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
        {title}
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} mt={20} mb={40}>
        {description}
      </Box>
      {children}
    </Box>
  );
}

const ConnectWithAnExpertCarousel = forwardRef((props, ref) => {
  // TODO - presumably we'll want to limit the number that show here

  const { experts } = useExperts({});

  return (
    <CarouselContainer
      ref={ref}
      style={{
        paddingLeft: 20,
        paddingRight: 20
      }}
      bound
      align="prev"
    >
      {experts?.map(expert => (
        <ExpertCard className="carouselCard" key={expert.id} {...expert} minWidth={432} />
      ))}
    </CarouselContainer>
  );
});

function useMostPopularTests() {
  const { data } = useQuery(OMNOS_TEST_PRODUCTS_QUERY, {
    variables: {
      limit: 20,
      isMostPopularTest: true
    }
  });

  return {
    tests: data?.consumerTestProducts
  };
}

const MostPopularTestCard = forwardRef(
  ({ className, testProduct: tp, minWidth, maxWidth }, ref) => {
    const { selectTestProductId } = useTestProductModalContext();
    const { partnerUserProfile } = useUserProfileContext();
    const history = useHistory();

    return (
      <GridListingViewCard
        key={tp.id}
        name={tp.name}
        rrpCurrentPrice={tp.rrpCurrentPrice}
        rrpFullPrice={tp.rrpFullPrice}
        slug={tp.slug}
        image={tp.laboratory.productImageUrl}
        imageBackgroundColor="haze"
        categories={tp.content.categories}
        sampleTypes={tp.sampleTypes}
        productAudience={tp.productAudience}
        onClick={() => {
          if (partnerUserProfile) {
            history.push(getPartnerShopTabUrl("tests") + "?slug=" + tp.slug);
          } else {
            selectTestProductId(tp.id);
          }
        }}
        id={tp.id}
        shortDescription={tp.content.shortDescription}
        laboratory={tp.laboratory}
        testType={tp.content.testType}
        ref={ref}
        minWidth={minWidth}
        maxWidth={maxWidth}
        className={className}
        isPractitionerFavourite={tp.isPractitionerFavourite}
        py={40}
        showProductCode={false}
        truncateDescription={true}
      />
    );
  }
);

const WhatPeopleAreSayingCard = forwardRef(({ className, minWidth, maxWidth }, ref) => (
  <Stack
    className={className}
    ref={ref}
    py={60}
    px={20}
    gap={30}
    minWidth={minWidth}
    maxWidth={maxWidth}
    bg="white"
    borderRadius={10}
  >
    <Group gap={10}>
      <Avatar />
      <Box>
        <Box fontFamily="gilroyMedium" fontSize={16}>
          Name Surname
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={12} lineHeight="120%" color="midGrey">
          Feb 21, 2024
        </Box>
      </Box>
    </Group>
    <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="150%">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </Box>
  </Stack>
));

function usePractitionerTestimonials() {
  return {
    testimonials: [
      {
        id: 1
      },
      {
        id: 2
      },
      {
        id: 3
      },
      {
        id: 4
      },
      {
        id: 5
      }
    ]
  };
}

const WhatPeopleAreSayingCarousel = forwardRef(({ prev, next }, ref) => {
  const { testimonials } = usePractitionerTestimonials({}); // TODO - define most popular

  return (
    <CarouselContainer
      ref={ref}
      style={{
        paddingLeft: 20,
        paddingRight: 20
      }}
      bound
      align="prev"
    >
      {testimonials?.map(t => (
        <WhatPeopleAreSayingCard
          className="carouselCard"
          key={t.id}
          minWidth={424}
          maxWidth={424}
        />
      ))}
    </CarouselContainer>
  );
});

function CarouselBlock({
  children,
  bg,
  prev,
  next,
  title,
  description,
  buttonText,
  topPadding,
  bottomPadding,
  fontColor,
  withArrows,
  buttonHref
}) {
  return (
    <Box
      pt={topPadding ? theme.spacing.section.pt : null}
      pb={bottomPadding ? theme.spacing.section.pb : null}
      px={20}
    >
      <Box
        bg={bg}
        maxWidth={1538}
        mx="auto"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        borderRadius={20}
      >
        <Center stacked mb={48}>
          <Box fontFamily="gilroyBold" fontSize={[32, 32, 68]} color={fontColor}>
            {title}
          </Box>
          <Box
            mt={20}
            fontFamily="gilroyRegular"
            fontSize={[18, 18, 24]}
            textAlign="center"
            color={fontColor}
          >
            {description}
          </Box>
          <Center stacked gap={48} mt={48}>
            {!!buttonText && (
              <ButtonV2
                color="white"
                withChevron
                sx={{
                  color: "dark"
                }}
                size={["sm", "sm", "md"]}
                as={buttonHref ? LinkWrapper : null}
                to={buttonHref}
              >
                {buttonText}
              </ButtonV2>
            )}
            {withArrows && (
              <Group gap={10}>
                <CircleButton iconHeight={15} iconWidth={15} direction="left" onClick={prev} />
                <CircleButton iconHeight={15} iconWidth={15} direction="right" onClick={next} />
              </Group>
            )}
          </Center>
        </Center>
        {children}
      </Box>
    </Box>
  );
}

CarouselBlock.defaultProps = {
  fontColor: "white"
};

function VimeoComponent({ onStart, onEnd, play = false, height, url, width }) {
  /**
   * Vimeo component used elsewhere uses "responsive" option provided by the library.
   * This doesn't work though on this occasion where the video sits in a modal window.
   * This time round we just calculate what the height and width should be and pass
   * this to a new instance of the plugin library each time.  More expensive but only option it seems.
   * Unlikely user will be resizing their browser much anyway.
   */

  const onStartRef = useRef(onStart);
  const onEndRef = useRef(onEnd);
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      height,
      url,
      width,
      maxwidth: width,
      maxheight: height,
      loop: false,
      autoplay: play
    };

    const player = new Player(url, options);

    player.on("play", () => {
      onStartRef.current?.(url);
    });

    player.on("ended", () => {
      onEndRef.current?.(url);
    });

    player.on("error", e => {
      console.log("error with vimeo component", e);
    });

    if (containerRef.current && play) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return () => {
      player.destroy();
    };
  }, [url, width, height, play]);

  return <Box id={url} ref={containerRef} maxWidth={width} />;
}

function Video() {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const videoContainerRef = useRef();
  const [rect, setRect] = useState();

  useEffect(() => {
    if (showVideoModal && videoContainerRef.current) {
      // eslint-disable-next-line no-inner-declarations
      function handleResize() {
        const rect = videoContainerRef.current.getBoundingClientRect();
        setRect(rect);
      }

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [showVideoModal]);

  return (
    <>
      <Box position="relative">
        <ActionIcon
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: "translate(-50%,-50%)"
          }}
          sx={{
            p: 0,
            height: "auto"
          }}
          onClick={() => {
            setShowVideoModal(true);
          }}
        >
          <CirclePlayIcon size={110} />
        </ActionIcon>
        <Modal
          show={showVideoModal}
          close={() => {
            setShowVideoModal(false);
          }}
          mode={["fullScreen", "fullScreen", "centered"]}
          maxWidth={1538}
          bg="transparent"
          closeButton
          headerProps={{
            p: [20, 20, 40]
          }}
          innerProps={{
            display: "flex",
            flexDirection: "column",
            overflow: "unset",
            height: "100%"
          }}
          outerProps={{
            height: "100%"
          }}
        >
          <Center flexGrow={1} width="100%" ref={videoContainerRef}>
            <VimeoComponent
              key={`${rect?.height}.${rect?.width}`}
              height={rect?.height}
              width={rect?.width}
              url="https://vimeo.com/1057788267"
              play
              onStart={() => {}}
              onEnd={() => {}}
            />
          </Center>
        </Modal>
        <Image src={ConsultationImage} width="100%" maxWidth={580} />
      </Box>
    </>
  );
}

export default function Overview() {
  const theme = useTheme();

  const { prev: prevExpert, next: nextExpert, ref: connectWithAnExpertCarouselRef } = useCarousel();
  const { prev: prevTest, next: nextTest, ref: mostPopularTestsCarouselRef } = useCarousel();

  const { tests } = useMostPopularTests({}); // TODO - define most popular

  return (
    <Page>
      <ListTemplate.Switcher>
        <Center stacked gap={28} mx="auto" pt={[40, 40, 80]} pb={60}>
          <Box fontFamily="gilroyBold" fontSize={[40, 60, 68]} maxWidth={980} textAlign="center">
            Testing Made Simple
          </Box>
          <Box maxWidth={980} fontFamily="gilroyRegular" fontSize={[28, 28, 32]} textAlign="center">
            Expert Practitioners, Trusted Results
          </Box>
        </Center>
      </ListTemplate.Switcher>
      <ThemeProvider theme={practitionerSupportSectionTheme}>
        <Box px={20} pb={theme.spacing.section.pb}>
          <Group
            flexWrap="wrap"
            gap={0}
            maxWidth={1538}
            mx="auto"
            bg="partners"
            borderRadius={theme.radius.card}
            alignItems={["center", "center", "center", null]}
            justifyContent={[null, null, null, "space-between"]}
            pt={[40, 40, 60]}
            pb={[60, 60, 80]}
            px={[20, 20, 96]}
            flexDirection={["column", "column", "column", "row"]}
          >
            <Box maxWidth={548} pb={[60, 60, 60, 0]}>
              <PractitionerIcon size={80} />
              <Box fontFamily="gilroyBold" fontSize={36} lineHeight="46px" pt={16} pb={20}>
                Why might you need practitioner support?
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={18} lineHeight="28px" pb={40}>
                While our at-home functional blood and DNA tests provide a detailed view of your
                health and wellness, they may highlight areas where expert support can make all the
                difference. Partnering with one of our verified healthcare professionals unlocks
                access to exclusive advanced tests, offering deeper insights and tailored solutions
                for your needs
              </Box>
              <Group gap={20} flexWrap="wrap">
                <ButtonV2
                  withChevron
                  color="dark"
                  size={["sm", "sm", "md"]}
                  sx={{
                    letterSpacing: "unset",
                    textTransform: "unset",
                    fontSize: 16,
                    fontFamily: "gilroyMedium"
                  }}
                  as={LinkWrapper}
                  to={EXPERTS_URL}
                >
                  Find a practitioner
                </ButtonV2>
                <ButtonV2
                  withChevron
                  color="dark"
                  size={["sm", "sm", "md"]}
                  sx={{
                    letterSpacing: "unset",
                    textTransform: "unset",
                    fontSize: 16,
                    fontFamily: "gilroyMedium"
                  }}
                  as={LinkWrapper}
                  to={EXPERTS_SUPPORTED_TESTS_URL}
                >
                  Discover exclusive tests
                </ButtonV2>
              </Group>
            </Box>
            <Video />
          </Group>
        </Box>
      </ThemeProvider>
      <ThemeProvider theme={howItWorksSectionTheme}>
        <Box bg="haze" pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20}>
          <Box maxWidth={1538} mx="auto">
            <Center stacked>
              <Box fontFamily="gilroyBold" fontSize={[32, 32, 68]}>
                How it works
              </Box>
              <Box mt={20} fontFamily="gilroyRegular" fontSize={[18, 18, 24]} textAlign="center">
                Transform your health journey with the support of an expert.
              </Box>
              <Group gap={20} mt={40} flexWrap="wrap" justifyContent="center">
                <ButtonV2
                  color="dark"
                  withChevron
                  size={["sm", "sm", "md"]}
                  as={LinkWrapper}
                  to={EXPERTS_URL}
                >
                  search all practitioners
                </ButtonV2>
                <ButtonV2
                  color="dark"
                  withChevron
                  size={["sm", "sm", "md"]}
                  as={LinkWrapper}
                  to={EXPERTS_SUPPORTED_TESTS_URL}
                >
                  discover all tests
                </ButtonV2>
              </Group>
            </Center>
            <Grid
              gap={28}
              pt={80}
              gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
            >
              <HowItWorksCard
                title="Where to begin?"
                description="Start by exploring specific tests or finding a practitioner who specialises in your health needs."
              >
                <Center>
                  <Box
                    width={304}
                    height={304}
                    style={{
                      backgroundImage: `url(${LaptopTyping})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      borderRadius: "100%"
                    }}
                  />
                </Center>
              </HowItWorksCard>
              <HowItWorksCard
                title="Search your needs"
                description="Use our advanced filters to match you with tests or professionals that match your current need."
              >
                <Center>
                  <Box
                    width={304}
                    height={304}
                    style={{
                      backgroundImage: `url(${MagnifiedFiltersOverlay})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      borderRadius: "100%"
                    }}
                  />
                </Center>
              </HowItWorksCard>
              <HowItWorksCard
                title="Make a connection"
                description="Once you’ve chosen a test or practitioner, simply fill out the online form to request your consultation."
              >
                <Center>
                  <Box position="relative">
                    <Box bg="purple" width={304} height={304} borderRadius="100%" />
                    <Box
                      as="img"
                      src={RequestCallback}
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    />
                  </Box>
                </Center>
              </HowItWorksCard>
              <HowItWorksCard
                title="Unlock all tests"
                description="With your practitioner&#39;s support you can now access the full range of Regenerus' tests"
              >
                <Center>
                  <Box
                    width={304}
                    height={304}
                    style={{
                      backgroundImage: `url(${PractitionerOnlyProductBox})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundColor: theme.colors.purple,
                      borderRadius: "100%"
                    }}
                  />
                </Center>
              </HowItWorksCard>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
      <CarouselBlock
        title="Connect with an expert"
        description="Support is offered in person or online and often comes with a free initial consultation"
        buttonText="see all practitioners"
        buttonHref={EXPERTS_URL}
        bg="dark"
        topPadding
        bottomPadding
        prev={prevExpert}
        next={nextExpert}
      >
        <ConnectWithAnExpertCarousel ref={connectWithAnExpertCarouselRef} />
      </CarouselBlock>
      {!!tests?.length && (
        <TestProductOptionProvider
          testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
          testProductOptionFragmentName="ConsumerTestProductOptionFields"
          testProductOptionFragmentType="TestProductOptionType"
        >
          <TestProductModalProvider
            testProductQuery={CONSUMER_TEST_PRODUCT_DETAIL_QUERY}
            testProductFieldName="consumerTestProduct"
          >
            <CarouselBlock
              title="Most popular tests"
              description="We have a comprehensive series of functional tests to cover your every need."
              buttonText="see all tests"
              buttonHref={EXPERTS_SUPPORTED_TESTS_URL}
              bg="purple"
              bottomPadding
              prev={prevTest}
              next={nextTest}
              withArrows={tests.length > 1}
            >
              <CarouselContainer
                spacing={20}
                ref={mostPopularTestsCarouselRef}
                style={{
                  paddingLeft: 20,
                  paddingRight: 20
                }}
                bound
                align="prev"
              >
                {tests?.map(tp => (
                  <MostPopularTestCard
                    className="carouselCard"
                    key={tp.id}
                    testProduct={tp}
                    minWidth={424}
                    maxWidth={424}
                  />
                ))}
              </CarouselContainer>
            </CarouselBlock>
            <TestProductModal bg="white" />
          </TestProductModalProvider>
        </TestProductOptionProvider>
      )}
    </Page>
  );
}
