import { ExternalTextLink } from "@/components/Links";
import { getClientManagementTabUrl, getPartnerPatientTabUrl } from "@/core/urls";
import Group from "@/tpo/Group";
import {
  PAYMENT_STATUS_AWAITING_PAYMENT,
  PROGRESS_STATUS_CANCELLED,
  PROGRESS_STATUS_ORDERED,
  PROGRESS_STATUS_REVIEW_RESULTS,
  getStatusVariant
} from "@/tpo/shop/constants";

import StatusBadge from "./StatusBadge";

export default function OrderStatusBadges({
  order,
  showPaymentStatus = true,
  badgeProps,
  derivedPaymentStatusOverrides = {},
  ...props
}) {
  let paymentStatus = null;
  let paymentVariant = null;
  let progressStatus = null;
  let progressVariant = null;

  if (!order || !order.id) {
    return null;
  }

  if (order.derivedPaymentStatus) {
    paymentStatus =
      derivedPaymentStatusOverrides[order.derivedPaymentStatus] || order.derivedPaymentStatus;
    paymentVariant = getStatusVariant(paymentStatus);
  } else {
    paymentStatus = PAYMENT_STATUS_AWAITING_PAYMENT;
    paymentVariant = getStatusVariant(PAYMENT_STATUS_AWAITING_PAYMENT);
  }

  if (order.derivedProgressStatus) {
    progressStatus = order.derivedProgressStatus;
    progressVariant = getStatusVariant(order.derivedProgressStatus);
  } else {
    progressStatus = PROGRESS_STATUS_ORDERED;
    progressVariant = getStatusVariant(PROGRESS_STATUS_ORDERED);
  }

  if (paymentStatus === null && progressStatus === null) {
    return null;
  }

  let progressStatusBadgeProps = {
    variant: progressVariant,
    ...badgeProps
  };

  if (order.derivedProgressStatus === PROGRESS_STATUS_REVIEW_RESULTS.toLowerCase()) {
    progressStatusBadgeProps = {
      ...progressStatusBadgeProps,
      as: ExternalTextLink,
      href: getPartnerPatientTabUrl("new_results"),
      underline: false,
      onClick: e => {
        e.stopPropagation();
      }
    };
  }

  return (
    <Group gap={10} alignItems="center" alignSelf="flex-start" flexWrap="wrap" {...props}>
      {progressStatus && (
        <StatusBadge data-testid="progressStatusBadge" {...progressStatusBadgeProps}>
          {progressStatus}
        </StatusBadge>
      )}
      {showPaymentStatus && paymentStatus && progressStatus !== PROGRESS_STATUS_CANCELLED && (
        <StatusBadge variant={paymentVariant} {...badgeProps} data-testid="paymentStatusBadge">
          {paymentStatus}
        </StatusBadge>
      )}
    </Group>
  );
}
