import { useEffect, useState } from "react";

import Box from "@/components/Box";
import Grid from "@/components/Grid";
import OrganisationTabs from "@/components/organisations/OrganisationTabs";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { ORDER_RESEND_STATUS } from "@/core/constants";
import { theme } from "@/core/theme";
import {
  ORGANISATION_ORDER_BALANCE_QUERY,
  ORGANISATION_ORDERS_LIST_QUERY
} from "@/graphql/organisations/queries/orders";
import useListControls from "@/hooks/useListControls";
import useRefetch from "@/hooks/useRefetch";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Center from "@/tpo/Center";
import ChevronComponent from "@/tpo/Chevron";
import Currency from "@/tpo/Currency";
import Group from "@/tpo/Group";
import { List, ListContext } from "@/tpo/List";
import Modal from "@/tpo/Modal";
import PaginationWrapper from "@/tpo/Pagination";
import SortMenu from "@/tpo/SortMenu";
import Spacer from "@/tpo/Spacer";
import Stack from "@/tpo/Stack";
import Tab from "@/tpo/Tab";
import { Search } from "@/tpo/TextInput";
import OrderIcon from "@/tpo/orders/OrderIcon";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";

import OrganisationOrderDetails from "./shared/OrganisationOrderDetails";
import OrganisationOrderSummary from "./shared/OrganisationOrderSummary";
import Switcher from "./ui/Switcher";

export default function OrganisationListOrders({ statusFilters = [] }) {
  const [filterTab, setFilterTab] = useState("all");

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [page, setPage] = useState(1);

  // use the url state to determine if we should preselect a order id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("id");
    if (orderId) {
      setSelectedOrderId(orderId);
    }
  }, []);

  const controls = useListControls({
    defaultSort: "-checkout_date",
    sortOptions: [
      {
        label: "A - Z",
        value: "id"
      },
      {
        label: "Z - A",
        value: "-id"
      },
      {
        label: "Oldest To Newest",
        value: "checkout_date"
      },
      {
        label: "Newest To Oldest",
        value: "-checkout_date"
      }
    ]
  });

  const { organisation } = useOrganisationContext();

  const { data: orderBalanceData, refetch: refetchOrderBalance } = useQuery(
    ORGANISATION_ORDER_BALANCE_QUERY,
    {
      variables: {
        organisation: parseInt(organisation?.id)
      },
      cachePolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !organisation
    }
  );

  const {
    data,
    loading,
    refetch: refetchOrders
  } = useQuery(
    ORGANISATION_ORDERS_LIST_QUERY,
    {
      variables: {
        page,
        organisation: parseInt(organisation?.id),
        orderBy: controls.sort,
        search: controls.debouncedSearch,
        filter: filterTab
      },
      cachePolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !organisation
    },
    [organisation, page, controls.sort, controls.debouncedSearch, filterTab]
  );

  useRefetch(ORGANISATION_ORDERS_LIST_QUERY, refetchOrders);
  useRefetch(ORGANISATION_ORDER_BALANCE_QUERY, refetchOrderBalance);

  const orders = data?.organisationOrdersPagination?.items;
  const pageInfo = data?.organisationOrdersPagination?.pageInfo;
  const orderBalance = orderBalanceData?.orderBalance;

  const statusTabs = [
    {
      label: "all",
      slug: "all"
    },
    {
      label: "payment required",
      slug: "payment_required"
    },
    {
      label: "failed / retest",
      slug: "failed_retest"
    },
    {
      label: "results to review",
      slug: "results_to_review"
    }
  ];

  const filterTabs = [
    ...statusTabs,
    {
      label: "preparing",
      slug: "preparing"
    },
    {
      label: "dispatched",
      slug: "dispatched"
    },
    {
      label: "accessioned (sample received)",
      slug: "accessioned"
    },
    {
      label: "at lab",
      slug: "at_lab"
    },
    {
      label: "from stock",
      slug: "from_stock"
    },
    {
      label: "stock order",
      slug: "stock_order"
    }
  ];

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        <Center>
          <Switcher />
        </Center>
        <Box>
          <PanelBoxV2
            outer={{
              bg: "haze",
              display: ["none", "none", "block"]
            }}
            inner={{
              display: "flex",
              overflowX: "auto",
              pl: 20
            }}
          >
            {statusTabs.map(tab => (
              <Tab
                key={tab.slug}
                borderRadiusBottom={false}
                borderRadiusTop
                tab={tab}
                selectedTab={filterTab}
                tabs={statusTabs}
                py={16}
                px={20}
                onClick={() => setFilterTab(tab.slug)}
                withBorder
                height={40}
                size={null}
                fontSize={12}
              />
            ))}
          </PanelBoxV2>
          <Box
            bg="white"
            borderRadius={5}
            maxWidth={1280}
            pt={theme.spacing.section.pt}
            pb={theme.spacing.section.pb}
            px={[20, 20, 40]}
          >
            <Box maxWidth={1020} mx="auto">
              <Group gap={20}>
                <OrderIcon width={[30, 30, 40]} />
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Order History
                </Box>
              </Group>
              <Spacer py={[2, 2, 20]} />
              <Grid gridTemplateColumns="1fr">
                <SortMenu
                  value={filterTab || null}
                  setValue={v => setFilterTab(v)}
                  open={showFilterMenu}
                  setOpen={setShowFilterMenu}
                  options={filterTabs.map(({ label, slug }) => ({
                    label: label.toUpperCase(),
                    value: slug
                  }))}
                  styles={{
                    button: {
                      color: "dark",
                      marginRight: "auto",
                      gridRowStart: ["unset", "unset", "1"],
                      gridRowEnd: ["unset", "unset", "1"],
                      order: "0",
                      sx: {
                        color: "white"
                      }
                    }
                  }}
                  placeholder="Filter by status"
                />
                <SortMenu
                  value={controls.sort}
                  setValue={controls.setSort}
                  open={controls.sortMenuOpen}
                  setOpen={controls.setSortMenuOpen}
                  options={controls.sortOptions}
                  styles={{
                    button: {
                      color: "dark",
                      marginTop: [-2, 0],
                      marginRight: ["auto", "auto", 0],
                      gridRowStart: ["unset", "unset", "1"],
                      gridRowEnd: ["unset", "unset", "1"],
                      order: ["1", "1", "2"],
                      justifyContent: "flex-start",
                      sx: {
                        color: "white"
                      }
                    }
                  }}
                  placeholder="Sort By"
                />
                <Search
                  value={controls.search}
                  onChange={controls.setSearch}
                  maxWidth={[null, null, 350]}
                  minWidth={310}
                  width="100%"
                  bg="haze"
                  styles={{
                    wrapper: {
                      gridRowStart: ["unset", "unset", "1"],
                      gridRowEnd: ["unset", "unset", "1"],
                      order: ["2", "2", "1"]
                    }
                  }}
                />
              </Grid>
              <Spacer py={[2, 2, 30]} />
              {orders?.length === 0 ? (
                <Stack
                  gap={20}
                  pb={20}
                  borderBottomColor="haze"
                  borderBottomStyle="solid"
                  borderBottomWidth={1}
                >
                  <Center>
                    <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                      No orders available
                    </Box>
                  </Center>
                </Stack>
              ) : (
                <List items={orders || []} loading={loading}>
                  <ListContext.Consumer>
                    {items =>
                      items?.map(order => (
                        <Box
                          key={order.id}
                          borderBottomStyle="solid"
                          borderBottomWidth={1}
                          borderBottomColor="haze"
                          onClick={e => {
                            if (
                              e.clickWithinOrganisationOrderSummaryComponent ||
                              e.resendOrderEmailButton
                            )
                              return;
                            setSelectedOrderId(order.id);
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                          data-component-name="Order"
                        >
                          <OrganisationOrderSummary order={order} />
                          <Stack alignItems="flex-end" gap={0}>
                            <Currency
                              prefix="TOTAL"
                              symbol={order.sourceBasket ? order.sourceBasket.currencySymbol : "£"}
                              value={
                                order.sourceBasket && order.status !== ORDER_RESEND_STATUS
                                  ? order.sourceBasket.basketTotalPrice.toFixed(2)
                                  : (order.discountedTotal + order.postageCosts).toFixed(2)
                              }
                              fontSize={44}
                              data-testid="orderTotal"
                            />
                            <ButtonV2
                              rightIcon={<ChevronComponent fill="black" />}
                              variant="transparent"
                              onClick={() => setSelectedOrderId(order.id)}
                              pr={2}
                              sx={{
                                ml: "auto",
                                fontSize: 10
                              }}
                            >
                              order details
                            </ButtonV2>
                          </Stack>
                        </Box>
                      ))
                    }
                  </ListContext.Consumer>
                </List>
              )}
              <Spacer py={[2, 2, 20]} />
              <PaginationWrapper
                variant={["mobile", "mobile", "default"]}
                pageInfo={pageInfo}
                onChange={setPage}
              />
            </Box>
          </Box>
        </Box>
        <Box
          bg="partners"
          borderRadius={5}
          maxWidth={1280}
          pt={theme.spacing.section.pt}
          pb={theme.spacing.section.pb}
          px={[20, 20, 40]}
        >
          <Box maxWidth={1020} mx="auto">
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Your order balance
            </Box>
            <Spacer py={[2, 2, 30]} />
            <Group justifyContent="flex-end">
              <Currency
                value={orderBalance}
                prefix="TOTAL"
                fontSize={[36, 36, 44]}
                showTrailingZeros
              />
            </Group>
            <Spacer py={[2, 2, 30]} />
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              The above balance is the total of all of your outstanding orders that require payment.
              We will take payment for each of the individual invoices once they are due. Any
              refunds will be automatically deducted from invoices and repaid directly to your
              account.
            </Box>
          </Box>
        </Box>
      </Stack>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={!!selectedOrderId}
        close={() => setSelectedOrderId()}
        mode={["fullScreen", "fullScreen", "centered"]}
        data-component-name="OrderDetailsModal"
      >
        <Box bg="white" borderRadius={5} maxWidth={1280} px={[20, 20, 40]}>
          {selectedOrderId && <OrganisationOrderDetails id={selectedOrderId} />}
        </Box>
      </Modal>
    </>
  );
}
