import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Box from "@/components/Box";
import Circle from "@/components/Circle";
import { spinAnimation } from "@/components/Loading";
import Minus from "@/images/Minus";
import Plus from "@/images/Plus";
import CrossIcon from "@/images/cross.svg?react";
import RefreshIcon from "@/images/refresh";
import TickIcon from "@/images/tick.svg?react";
import ChevronComponent from "@/tpo/Chevron";
import getResponsiveProps from "@/utils/getResponsiveProps";
import classNames from "classnames";
import styled, { keyframes } from "styled-components";

import classes from "./Buttons.module.css";

const ButtonV2 = forwardRef(
  (
    {
      children,
      color,
      variant,
      leftIcon,
      rightIcon: _rightIcon,
      sx,
      size: sizeProp,
      disabled,
      "data-component-name": dataComponentName,
      withChevron,
      ...rest
    },
    ref
  ) => {
    let props = {};

    if (variant !== "link") {
      props = {
        ...props,
        borderRadius: 5
      };
    }

    let sizeProps = {};

    const sizes = {
      xs: {
        fontSize: 10,
        height: 28,
        px: 12,
        py: "8px"
      },
      sm: {
        fontSize: 12,
        height: 40,
        px: 20,
        py: 12
      },
      md: {
        fontSize: 12,
        height: 48,
        py: 20,
        px: 32
      }
    };

    if (variant !== "circle") {
      sizeProps = getResponsiveProps(sizes, sizeProp);
    } else {
      sizeProps = getResponsiveProps(
        {
          sm: {
            height: 40,
            width: 40,
            minWidth: 40,
            minHeight: 40
          },
          md: {
            height: 48,
            width: 48,
            minWidth: 48,
            minHeight: 48
          }
        },
        sizeProp
      );
    }

    if (variant === "filled") {
      props = {
        ...props,
        bg: color,
        color: "white"
      };
    } else if (variant === "outline") {
      props = {
        ...props,
        borderColor: color,
        borderStyle: "solid",
        borderWidth: 2,
        color: color
      };
    } else if (variant === "transparent") {
      props = {
        ...props,
        ...sizeProps
      };
    } else if (variant === "link") {
      props = {
        ...props,
        bg: "transparent",
        className: classNames(classes.link, props.className)
      };
    } else if (variant === "pill") {
      props = {
        ...props,
        bg: color,
        color: "white",
        py: 16,
        px: 20,
        borderRadius: "100px",
        borderWidth: "2px"
      };
    } else if (variant === "circle") {
      props = {
        ...props,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        px: 0,
        py: 0
      };
    }

    let rightIcon = _rightIcon;

    if (withChevron) {
      rightIcon = <ChevronComponent fill={sx?.color || props.color || color} />;
    }

    if (leftIcon || rightIcon) {
      props = {
        ...props,
        gap: 5
      };
    }

    props = {
      ...props,
      ...rest,
      ...sizeProps
    };

    if (disabled) {
      props = {
        ...props,
        opacity: 0.5,
        cursor: "not-allowed",
        disabled
      };
    }

    return (
      <Box as="button" ref={ref} data-component-name={dataComponentName} {...props} {...sx}>
        {leftIcon}
        {children}
        {rightIcon}
      </Box>
    );
  }
);

ButtonV2.defaultProps = {
  letterSpacing: ["1.5px", "1.5px", "2.8px"],
  textTransform: "uppercase",
  fontFamily: "gilroyBold",
  textAlign: "center",
  variant: "filled",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  size: "md",
  lineHeight: "70%",
  whiteSpace: "nowrap"
};

export default ButtonV2;

const scaleAnimation = keyframes`
  0%   { transform: scale(10); }
  50%  { transform: scale(0.2); }
  70%  { transform: scale(1.2); }
  90%  { transform: scale(0.7); }
  100% { transform: scale(1); }
`;

const AnimatedBackgroundButton = styled(ButtonV2)`
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 5px;
    border: 0;
    background: #2ecc71;
  }

  ${props =>
    props.pending
      ? `
  background-color: ${props.pendingColor};
	&:before {
		width: 100%;
		transition: width 1s linear;
	}`
      : ""};

  .indicator {
    position: absolute;
    animation: ${scaleAnimation} 0.5s linear;
  }
`;

const Spinner = styled(Box)`
  animation: ${spinAnimation} 1200ms linear infinite;
`;

export const IndicativeButton = forwardRef(
  (
    {
      children,
      defaultColor,
      successColor,
      failureColor,
      pendingColor,
      rightIcon,
      onClick,
      size,
      resetTimeout = 5000,
      "data-testid": dataTestId,
      "data-component-name": dataComponentName,
      disabled,
      sx
    },
    ref
  ) => {
    const [pending, setPending] = useState(false);
    const [success, setSuccessful] = useState(undefined);

    let color = defaultColor;

    if (pending) {
      color = pendingColor;
    } else if (success) {
      color = successColor;
    } else if (success === false) {
      color = failureColor;
    }

    useImperativeHandle(
      ref,
      () => ({
        setSuccessful,
        setPending
      }),
      []
    );

    useEffect(() => {
      if (success !== undefined) {
        const timeout = setTimeout(() => {
          setSuccessful(undefined);
        }, resetTimeout);
        return () => clearTimeout(timeout);
      }
    }, [success, setSuccessful, resetTimeout]);

    return (
      <AnimatedBackgroundButton
        pending={pending}
        pendingColor={pendingColor}
        // color={wasFailure ? "error" : "#2ecc71"}
        color={color}
        onClick={e => {
          setPending(true);
          onClick && onClick(e);
        }}
        // onTransitionEnd={() => {
        //   // setSpinning(false);
        //   // setWasFailure(true);
        // }}
        rightIcon={pending === false && success === undefined && rightIcon}
        data-testid={dataTestId}
        dataComponentName={dataComponentName}
        size={size}
        disabled={disabled}
        {...sx}
      >
        <Box color="inherit" visibility={pending || success !== undefined ? "hidden" : "visible"}>
          {children}
        </Box>
        {pending && (
          <Spinner
            position="absolute"
            style={{
              transformOrigin: "center center"
            }}
          >
            <RefreshIcon color="white" height={30} width={30} />
          </Spinner>
        )}
        {!pending && success && <TickIcon className="indicator" fill="white" width={20} />}
        {!pending && success === false && (
          <CrossIcon className="indicator" fill="white" width={20} />
        )}
      </AnimatedBackgroundButton>
    );
  }
);

export const ActionIcon = forwardRef(({ children, ...props }, ref) => (
  // Likely need further consideration of sizing / padding options later on
  // This was implemented for the "back button" originally which has no padding per the
  // design
  <ButtonV2 px={0} py={0} type="button" ref={ref} {...props}>
    {children}
  </ButtonV2>
));

ActionIcon.defaultProps = {
  variant: "transparent"
};

export function CloseButton({ color, onClick, height, width, ...props }) {
  return (
    <ActionIcon onClick={onClick} {...props}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5553 18.8746L3.12242 3.4417C2.49963 2.81891 2.51621 1.80312 3.15968 1.15966C3.80315 0.516188 4.81894 0.499601 5.44173 1.12239L20.8746 16.5553C21.4974 17.1781 21.4808 18.1939 20.8374 18.8373C20.1939 19.4808 19.1781 19.4974 18.5553 18.8746Z"
          fill={color}
        />
        <path
          d="M20.8746 3.76403L5.4417 19.1969C4.81891 19.8197 3.80312 19.8031 3.15966 19.1597C2.51619 18.5162 2.4996 17.5004 3.12239 16.8776L18.5553 1.44472C19.1781 0.821931 20.1939 0.838519 20.8373 1.48199C21.4808 2.12545 21.4974 3.14124 20.8746 3.76403Z"
          fill={color}
        />
      </svg>
    </ActionIcon>
  );
}

CloseButton.defaultProps = {
  height: 20,
  width: 25
};

export const PageButton = forwardRef(({ label, bg, color, width, ...rest }, ref) => (
  <ButtonV2
    ref={ref}
    sx={{
      minWidth: width,
      maxWidth: width,
      width: width,
      maxHeight: width,
      minHeight: width,
      height: width,
      bg: bg,
      borderColor: bg,
      color: color,
      fontSize: 18,
      px: 0,
      py: 0,
      ...rest
    }}
  >
    {label}
  </ButtonV2>
));

PageButton.defaultProps = {
  width: [40, 40, 48]
};

export function ThermostatButton({
  "data-testid": dataTestId,
  value,
  onDecrease,
  onIncrease,
  disabled,
  sx
}) {
  return (
    <ButtonV2
      disabled={disabled}
      leftIcon={
        <Circle
          bg="midGrey"
          fontFamily="gilroyBold"
          size={30}
          onClick={value > 0 ? onDecrease : undefined}
          data-testid="thermostat-button-decrease"
        >
          <Minus color="white" />
        </Circle>
      }
      rightIcon={
        <Circle
          bg="midGrey"
          fontFamily="gilroyBold"
          onClick={onIncrease}
          size={30}
          data-testid="thermostat-button-increase"
        >
          <Plus color="white" />
        </Circle>
      }
      sx={{
        bg: "#e5e5e5",
        color: "dark",
        p: 2,
        gap: 10,
        fontSize: 18,
        fontFamily: "gilroyBold",
        "data-component-name": "ThermostatButton",
        "data-testid": dataTestId,
        ...sx
      }}
    >
      {value}
    </ButtonV2>
  );
}

export const QuestionButton = forwardRef(({ bg, color }, ref) => (
  <ActionIcon
    ref={ref}
    bg={bg}
    size={null}
    height={16}
    width={16}
    minWidth={16}
    minHeight={16}
    variant="circle"
    fontSize={10}
    sx={{
      color,
      justifyContent: "center",
      alignItems: "center",
      letterSpacing: 0
    }}
  >
    ?
  </ActionIcon>
));
