import Flicking from "@egjs/react-flicking";

import styled from "styled-components";

const CarouselContainer = styled(Flicking)`
  .carouselCard:not(:last-child) {
    margin-right: ${({ spacing }) => spacing}px;
  }
`;

CarouselContainer.defaultProps = {
  spacing: 16
};

export default CarouselContainer;
