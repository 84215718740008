import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import { theme } from "@/core/theme";
import { EVENT_QUERY } from "@/graphql/knowledgeCenter/webinars";
import Center from "@/tpo/Center";
import ChevronComponent from "@/tpo/Chevron";
import Stack from "@/tpo/Stack";
import convertOrderedLists from "@/utils/convertOrderedLists";
import ButtonV2 from "@/v2/Buttons";
import { useQuery } from "@apollo/client";

import classes from "./richText.module.css";

export default function Event() {
  const { slug } = useParams();

  const { data: eventData, loading: loadingEvent } = useQuery(EVENT_QUERY, {
    variables: {
      slug
    }
  });

  const event = eventData?.event;

  if (!loadingEvent && !event) return <Redirect to="/404" />;

  return (
    <>
      <Box bg="white" pb={theme.spacing.section.pb} px={20} pt={theme.spacing.pageHeader.pt}>
        <Center stacked gap={0}>
          <Stack gap={28} pt={[60, 60, 80]} pb={[40, 40, 60]}>
            <Box
              textAlign="center"
              fontFamily="gilroyBold"
              fontSize={[40, 40, 68]}
              maxWidth={1024}
              mx="auto"
              lineHeight="100%"
            >
              {event?.title}
            </Box>
          </Stack>
          <Stack gap={10} alignItems="center">
            {!!event?.registerNowUrl && (
              <ButtonV2
                color="dark"
                mx="auto"
                size={["sm", "sm", "md"]}
                sx={{
                  pt: 20,
                  pb: 20
                }}
                rightIcon={<ChevronComponent />}
                as={ExternalTextLink}
                underline={false}
                href={event?.registerNowUrl}
              >
                register now
              </ButtonV2>
            )}
            <Box fontFamily="gilroyMedium" color="purple">
              {event?.location}
            </Box>
            <Box fontFamily="gilroyBold" color="purple">
              {new Date(event?.startTime).toLocaleDateString(undefined)}
            </Box>
            <Box fontFamily="gilroyBold" color="purple">
              {new Date(event?.startTime).toLocaleTimeString(undefined, {
                timeZoneName: "short"
              })}
            </Box>
          </Stack>

          {!!event?.description && (
            <Box
              pt={theme.spacing.section.pt}
              dangerouslySetInnerHTML={{
                __html: event?.description
                  ? convertOrderedLists(event.description)
                  : event?.description
              }}
              className={classes.richText}
              maxWidth={720}
              width="100%"
            />
          )}
        </Center>
      </Box>
    </>
  );
}
