import {
  constructGradientFromTerms,
  getChartRanges,
  getInterpretationColor,
  getInterpretationColorFromChartConfiguration
} from "@/utils/shared";
import { round } from "lodash";

export function buildGradientGraphConfiguration({
  userDatapoints,
  userResults,
  chartConfiguration: {
    normalisedRange,
    chartRange: { range },
    terminology
  }
}) {
  if (userDatapoints && userResults) {
    throw new Error("Cannot use both userDatapoints and userResults");
  }

  if (normalisedRange.range.length !== range.length) return null;

  const { minimum, maximum, fullRange } = getChartRanges({
    range,
    normalisedRange,
    precision: 2,
    withLabels: true
  });

  const background = constructGradientFromTerms(terminology.join(","), "180deg");

  let points = [];
  let tooltips = [];
  let colors = [];

  if (userDatapoints) {
    [points, tooltips, colors] = userDatapoints.reduce(
      ([points, tooltips, colors], userDatapoint, index) => {
        const {
          dateExtracted,
          chartConfiguration: {
            normalisedRange: { value }
          }
        } = userDatapoint;

        let y;
        let xValue = new Date(dateExtracted).getTime();

        const segmentSize = 1 / (fullRange.length - 1);

        const boundedValue = round(Math.max(minimum, Math.min(value, maximum)), 2);
        const upperLimitIndex = fullRange.findIndex(point => boundedValue <= point.value);
        const lowerLimitIndex = upperLimitIndex > 0 ? upperLimitIndex - 1 : 0;

        if (upperLimitIndex === lowerLimitIndex) {
          y = upperLimitIndex;
        } else {
          const min = fullRange[lowerLimitIndex].value;
          const max = fullRange[upperLimitIndex].value;
          const ratio = (boundedValue - min) / (max - min);
          const distance = (upperLimitIndex - lowerLimitIndex) * segmentSize;
          y = lowerLimitIndex * segmentSize + distance * ratio;
        }

        points.push([xValue, y]);

        const roundedValue = round(userDatapoint.value, 2);

        const stack = [
          `<div class='value_and_units'>${`${roundedValue} ${
            userDatapoint.datapoint.units || ""
          }`}</div>`,
          `<div class='interpretation' style='color: ${getInterpretationColor(
            userDatapoint.chartConfiguration.interpretation
          )};'>${userDatapoint.chartConfiguration.interpretation}</div>`,
          `<div class='date'>${new Date(xValue).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
            year: "numeric"
          })}</div>`
        ];

        if (
          userDatapoints.length - 1 === index &&
          userDatapoint.dateExpired &&
          new Date(userDatapoint.dateExpired) <= new Date()
        ) {
          stack.push("<div class='retest'>(Due for retest)</div>");
        }

        tooltips.push(stack);

        colors.push(
          new Date(userDatapoint.dateExpired) <= new Date()
            ? "#818181"
            : getInterpretationColor(userDatapoint.chartConfiguration.interpretation)
        );

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  } else if (userResults) {
    [points, tooltips, colors] = userResults.reduce(
      ([points, tooltips, colors], userResult) => {
        const {
          userSnapshot: { snapshotDate },
          chartConfiguration
        } = userResult;

        const value = chartConfiguration.normalisedRange.value;

        let y;
        let xValue = new Date(snapshotDate).getTime();

        const boundedValue = round(Math.max(minimum, Math.min(value, maximum)), 2);
        const upperLimitIndex = fullRange.findIndex(point => boundedValue <= point.value);
        const lowerLimitIndex = upperLimitIndex > 0 ? upperLimitIndex - 1 : 0;

        if (upperLimitIndex === lowerLimitIndex) {
          y = upperLimitIndex;
        } else {
          const min = fullRange[lowerLimitIndex].value;
          const max = fullRange[upperLimitIndex].value;
          const ratio = (boundedValue - min) / (max - min);
          const distance = upperLimitIndex - lowerLimitIndex;
          y = lowerLimitIndex + distance * ratio;
        }

        points.push([xValue, y]);

        const created = new Date(xValue).toLocaleDateString(undefined, {
          day: "numeric",
          month: "long",
          year: "numeric"
        });

        const color = getInterpretationColorFromChartConfiguration(chartConfiguration);

        colors.push(color);

        const stack = [
          `<div class='interpretation' style='color: ${color};'>${chartConfiguration.interpretation}</div>`,
          `<div class='date'>${created}</div>`
        ];

        tooltips.push(stack);

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  }

  return {
    fullRange,
    points,
    tooltips,
    colors,
    background
  };
}
