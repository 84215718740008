import { Fragment, useMemo, useState } from "react";

import ArrowCircle from "@/components/ArrowCircle";
import Box from "@/components/Box";
import CircleButton from "@/components/CircleButton";
import Divider from "@/components/Divider";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import { ORGANISATION_PAYOUTS_QUERY } from "@/graphql/organisations/queries/commission";
import useRefetch from "@/hooks/useRefetch";
import Badge from "@/tpo/Badge";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Center from "@/tpo/Center";
import Currency from "@/tpo/Currency";
import { List, ListContext } from "@/tpo/List";
import Stack from "@/tpo/Stack";
import Table from "@/tpo/Table";
import { useQuery } from "@apollo/client";

export default function OrganisationListPayouts() {
  const { organisation } = useOrganisationContext();

  const [startingAfter, setStartingAfter] = useState("");
  const [endingBefore, setEndingBefore] = useState("");

  const {
    data,
    loading,
    refetch: refetchPayouts
  } = useQuery(ORGANISATION_PAYOUTS_QUERY, {
    variables: {
      organisation: parseInt(organisation?.id),
      startingAfter,
      endingBefore
    },
    skip: !organisation
  });

  let hasPrevious;
  let hasNext;

  if (startingAfter === "" && endingBefore === "") {
    hasPrevious = false;
    hasNext = false;

    if (data?.organisationPayoutsPagination?.hasMore !== undefined) {
      hasNext = data?.organisationPayoutsPagination?.hasMore;
    }
  }

  if (startingAfter) {
    hasPrevious = true;
    hasNext = false;
    if (data?.organisationPayoutsPagination?.hasMore !== undefined) {
      hasNext = data?.organisationPayoutsPagination?.hasMore;
    }
  }

  if (endingBefore) {
    hasPrevious = false;
    hasNext = true;
    if (data?.organisationPayoutsPagination?.hasMore !== undefined) {
      hasPrevious = data?.organisationPayoutsPagination?.hasMore;
    }
  }

  const items = data?.organisationPayoutsPagination?.items;

  const nextPage = () => {
    const lastItem = items[items.length - 1];
    setStartingAfter(lastItem.id);
    setEndingBefore("");
  };
  const prevPage = () => {
    const firstItem = items[0];
    setStartingAfter("");
    setEndingBefore(firstItem.id);
  };

  useRefetch(ORGANISATION_PAYOUTS_QUERY, refetchPayouts);

  const columns = useMemo(
    () => [
      {
        id: "initiated",
        accessor: ({ initiated }) => new Date(initiated).toLocaleDateString(),
        label: "Initiated"
      },
      {
        id: "estPayout",
        accessor: ({ estPayout }) => new Date(estPayout).toLocaleDateString(),
        label: "Est payout"
      },
      {
        id: "status",
        accessor: ({ status }) => {
          const statusMap = {
            paid: {
              bg: "green",
              color: "white"
            },
            pending: {
              bg: "black",
              color: "white"
            },
            in_transit: {
              bg: "black",
              color: "white"
            },
            canceled: {
              bg: "red",
              color: "white"
            },
            failed: {
              bg: "red",
              color: "white"
            }
          };
          return (
            <Badge size="xs" {...statusMap[status]}>
              {status.replace("_", " ")}
            </Badge>
          );
        },
        label: "Status"
      },
      {
        id: "description",
        accessor: "description",
        label: "Description"
      },
      {
        id: "amount",
        accessor: ({ amount, currencySymbol }) => {
          return <Currency value={amount} symbol={currencySymbol} />;
        },
        label: "Amount"
      }
    ],
    []
  );

  return (
    <PanelBoxV2 maxWidth={1200}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5
        }}
        stacked
        gap={20}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
          Payouts
        </Box>
        <Box display={["none", "none", "block"]}>
          <Table columns={columns} loading={loading} rows={items} />
        </Box>
        <List items={items} loading={loading} display={["block", "block", "none"]}>
          <ListContext.Consumer>
            {items =>
              items?.map(
                ({ id, initiated, estPayout, amount, currencySymbol, description, status }) => (
                  <Fragment key={id}>
                    <Box py={2}>
                      <Box py={15}>
                        <Stack gap={20}>
                          <Box>
                            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                              Initiated
                            </Box>
                            <Box>{new Date(initiated).toLocaleDateString()}</Box>
                          </Box>
                          <Box>
                            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                              Est payout
                            </Box>
                            <Box>{new Date(estPayout).toLocaleDateString()}</Box>
                          </Box>
                          <Box>
                            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                              Status
                            </Box>
                            <Box>{columns[2].accessor({ status })}</Box>
                          </Box>
                          <Box>
                            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                              Description
                            </Box>
                            <Box>{description}</Box>
                          </Box>
                          <Box>
                            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                              Amount
                            </Box>
                            <Box>
                              <Currency
                                value={amount}
                                symbol={currencySymbol}
                                fontSize={16}
                                lineHeight="150%"
                              />
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Divider />
                  </Fragment>
                )
              )
            }
          </ListContext.Consumer>
        </List>
        <Center gap={10}>
          <CircleButton
            iconHeight={15}
            iconWidth={15}
            direction="left"
            onClick={() => {
              prevPage();
            }}
            disabled={!hasPrevious}
          />
          <CircleButton
            iconHeight={15}
            iconWidth={15}
            direction="right"
            onClick={() => {
              nextPage();
            }}
            disabled={!hasNext}
          />
        </Center>
      </PanelBoxV2>
    </PanelBoxV2>
  );
}
