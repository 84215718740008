export default function PractitionerIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 76.7568C60.3002 76.7568 76.7568 60.3002 76.7568 40C76.7568 19.6998 60.3002 3.24324 40 3.24324C19.6998 3.24324 3.24324 19.6998 3.24324 40C3.24324 60.3002 19.6998 76.7568 40 76.7568ZM40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_3646_34040)">
        <path
          d="M53.2491 46.0633H43.46C39.7301 46.0633 36.709 49.0844 36.709 52.8143C36.709 56.5443 39.7301 59.5654 43.46 59.5654H53.2491C56.979 59.5654 60.0001 56.5443 60.0001 52.8143C60.0001 49.0844 56.979 46.0633 53.2491 46.0633ZM39.0719 52.8143C39.0719 50.3941 41.0398 48.4262 43.46 48.4262H47.1731V57.2025H43.46C41.0398 57.2025 39.0719 55.2346 39.0719 52.8143ZM53.2491 57.2025H49.536V48.4262H53.2491C55.6693 48.4262 57.6373 50.3941 57.6373 52.8143C57.6373 55.2346 55.6693 57.2025 53.2491 57.2025Z"
          fill="#1A1A1A"
        />
        <path
          d="M20 39.481V49.27C20 53 23.0211 56.0211 26.7511 56.0211C30.481 56.0211 33.5021 53 33.5021 49.27V39.481C33.5021 35.7511 30.481 32.73 26.7511 32.73C23.0211 32.73 20 35.7544 20 39.481ZM26.7511 53.6582C24.3308 53.6582 22.3629 51.6903 22.3629 49.27V45.557H31.1392V49.27C31.1392 51.6903 29.1713 53.6582 26.7511 53.6582ZM31.1392 39.481V43.1941H22.3629V39.481C22.3629 37.0608 24.3308 35.0928 26.7511 35.0928C29.1713 35.0928 31.1392 37.0608 31.1392 39.481Z"
          fill="#1A1A1A"
        />
        <path
          d="M44.6783 22.9781L37.755 29.9013C35.1188 32.5376 35.1188 36.811 37.755 39.4473C40.3913 42.0835 44.6648 42.0835 47.301 39.4473L54.2242 32.524C56.8605 29.8878 56.8605 25.6143 54.2242 22.9781C51.588 20.3418 47.3145 20.3418 44.6783 22.9781ZM39.4259 37.7764C37.7145 36.065 37.7145 33.2802 39.4259 31.5722L42.0521 28.946L48.2563 35.1502L45.6302 37.7764C43.9188 39.4878 41.134 39.4878 39.4259 37.7764ZM52.5567 30.8532L49.9306 33.4793L43.7264 27.2751L46.3525 24.6489C48.0639 22.9376 50.8487 22.9376 52.5567 24.6489C54.2648 26.3603 54.2681 29.1451 52.5567 30.8532Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3646_34040">
          <rect width="40" height="38.5654" fill="white" transform="translate(20 21)" />
        </clipPath>
      </defs>
    </svg>
  );
}
