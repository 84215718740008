import Circle from "@/components/Circle";
import { theme } from "@/core/theme";
import { SUPPORT_DOCUMENT_CONNECTIONS_QUERY } from "@/graphql/knowledgeCenter/webinars";
import PageIcon from "@/images/PageIcon";

import ListTemplate from "./ListTemplate";
import SupportDocumentCard from "./SupportDocumentCard";

export default function SupportDocuments() {
  return (
    <ListTemplate
      query={SUPPORT_DOCUMENT_CONNECTIONS_QUERY}
      connectionName="documentConnections"
      objectsPlural="support documents"
      Card={SupportDocumentCard}
      pageTitle="Support Documents"
      icon={
        <Circle bg="transparent" size={80} border="2px solid black">
          <PageIcon fill={theme.colors.dark} size={40} />
        </Circle>
      }
      filtersTitle="Support Document Filters"
      sortOptions={[
        {
          label: "Title asc",
          value: "title"
        },
        {
          label: "Title desc",
          value: "-title"
        }
      ]}
    />
  );
}
