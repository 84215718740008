import { useAppState } from "@/components/AppStateProvider";
import Box from "@/components/Box";
import { useBasketContext } from "@/contexts/BasketContext";
import { useTestProductModalContext } from "@/contexts/TestProductModalContext";
import { useTestProductOptionContext } from "@/contexts/TestProductOptionContext";
import { theme } from "@/core/theme";
import { PanelBoxV2 } from "@/tpo/Boxes";
import { GilroyFontCurrency } from "@/tpo/Currency";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import BiomarkersAndSymptomsList from "@/tpo/shop/BiomarkersAndSymptomsList";
import Carousel from "@/tpo/shop/Carousel";
import MainDetails from "@/tpo/shop/MainDetails";
import RelatedPdfs from "@/tpo/shop/RelatedFiles";
import TestProductInformationPanels from "@/tpo/shop/TestProductInformationPanels";
import TestProductOptions from "@/tpo/shop/TestProductOptions";
import { DISPLAY_CONTEXT_ALWAYS_VISIBLE, DISPLAY_CONTEXT_B2B_ONLY } from "@/tpo/shop/constants";

import RelatedFileClasses from "../../RelatedFiles.module.css";
import getCarouselImages from "../utils/getCarouselImages";

export default function Truncated() {
  const { testProduct, selectTestProductId } = useTestProductModalContext();
  const { selectTestProductOptionId } = useTestProductOptionContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  const carouselImages = getCarouselImages(testProduct);

  const practitionerSupportRequired = testProduct.productAudience === "B2B";

  const options = testProduct.options.length ? (
    <TestProductOptions>
      {testProduct.options.map((option, index) => {
        const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
        return (
          <TestProductOptions.Option
            key={option.id}
            id={option.id}
            status={testProduct.status}
            compositeId={option.compositeId}
            name={option.testProductOptionFamily.name}
            description={option.testProductOptionFamily.description}
            price={
              hasClinics ? (
                <Stack gap={0}>
                  <GilroyFontCurrency
                    prefix="FROM"
                    value={option.consumerClinicsInfo.fromPrice}
                    stacked
                    prefixFontSize={14}
                  />
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Includes clinic fee
                  </Box>
                </Stack>
              ) : (
                option.rrpCurrentPrice
              )
            }
            practitionerSupportRequired={testProduct.productAudience === "B2B"}
            lastOption={index === testProduct.options.length - 1}
            hasClinics={hasClinics}
            onFindNearestClinic={() => {
              selectTestProductOptionId({
                id: option.id,
                paymentIsoCode: option.paymentIsoCode,
                organisation: option.organisation
              });
            }}
            onAddToBasket={() => {
              setBasketOpen(true);
              selectTestProductId(undefined);
              addItemToBasket({
                compositeId: option.compositeId
              });
            }}
          />
        );
      })}
    </TestProductOptions>
  ) : null;

  const informationPanels = testProduct.content.informationPanels.filter(
    panel =>
      panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
      panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
  );

  return (
    <>
      <Box display="none" data-component-name="TruncatedProductDetail" />
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "white",
          pt: carouselImages.length ? 0 : theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40]
        }}
        stacked
        gap={[20, 20, 40]}
      >
        {!!carouselImages.length && (
          // parent is a flexbox with gap defined so mb takes this into account
          <Box bg="haze" p={20} mb={[20]}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        )}
        <Box>
          <MainDetails
            testProduct={testProduct}
            status={testProduct.status}
            practitionerSupportRequired={practitionerSupportRequired}
            name={testProduct.name}
            description={testProduct.content.description}
            categories={testProduct.content.categories.map(cat => cat.name)}
            sampleTypes={testProduct.sampleTypes}
            numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
            processingTime={testProduct.content.processingTime}
            options={options}
            priceElement={
              <Group gap={10}>
                {testProduct.rrpFullPrice !== undefined &&
                  testProduct.rrpFullPrice > testProduct.rrpCurrentPrice && (
                    <GilroyFontCurrency
                      value={testProduct.rrpFullPrice}
                      color="red"
                      strikethrough
                    />
                  )}
                <GilroyFontCurrency value={testProduct.rrpCurrentPrice} />
              </Group>
            }
            productOptionCardStackBg="white"
            testType={testProduct.content.testType}
            onAddToBasket={() => {
              addItemToBasket({
                compositeId: testProduct.compositeId
              });
              setBasketOpen(true);
              selectTestProductId(undefined);
            }}
          />
        </Box>
      </PanelBoxV2>
      {!!(
        testProduct.content.relatedSymptoms?.length || testProduct.content.biomarkersTested?.length
      ) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "partners",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={testProduct.content.relatedSymptoms}
            biomarkersTested={testProduct.content.biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {!!informationPanels?.length && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "haze",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40]
          }}
          stacked
          gap={40}
        >
          <TestProductInformationPanels panels={informationPanels} />
        </PanelBoxV2>
      )}
      {(!!testProduct.instructions?.length || !!testProduct.sampleLabReports?.length) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "white",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40],
            className: RelatedFileClasses.container
          }}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Download files
          </Box>
          {!!testProduct.sampleLabReports?.length && (
            <RelatedPdfs sectionName="Sample Reports" pdfs={testProduct.sampleLabReports} />
          )}
          {!!testProduct.instructions?.length && (
            <RelatedPdfs sectionName="Instructions" pdfs={testProduct.instructions} />
          )}
        </PanelBoxV2>
      )}
    </>
  );
}
