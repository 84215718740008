export default function InfinityIcon() {
  return (
    <svg width="38" height="19" viewBox="0 0 38 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12553_21443)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2393 13.7455L15.3112 15.7202C9.95802 21.2072 0.666016 17.3634 0.666016 9.4997C0.666016 1.63754 9.95802 -2.20785 15.3112 3.27754L24.736 12.9367C27.6719 15.9477 32.8252 13.872 32.8252 9.49978C32.8252 5.13082 27.6736 3.05048 24.7344 6.06123L22.8063 8.03596L20.0922 5.25402L22.0203 3.2777C27.3751 -2.21097 36.6655 1.6425 36.6655 9.49986C36.6655 17.362 27.3735 21.2074 22.0203 15.722L12.5955 6.06283C9.65962 3.05187 4.50634 5.12754 4.50634 9.49978C4.50634 13.872 9.65954 15.9491 12.5971 12.9383L14.5237 10.9636L17.2393 13.7455Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12553_21443">
          <rect width="37" height="19" fill="white" transform="translate(0.333008)" />
        </clipPath>
      </defs>
    </svg>
  );
}
