import { CSSTransition } from "react-transition-group";

import Box from "@/components/Box";

const DEFAULT_PROPS = {
  timeout: 300,
  mountOnEnter: true,
  unmountOnExit: true
};

export function FadeTransition({ children, sx, ...props }) {
  props = { ...DEFAULT_PROPS, ...props };

  let style = props.style || {};
  delete props.style;

  let delay = props.delay || 0;
  delete props.delay;

  let timeout = props.timeout || 300;

  let easing = props.easing || "ease-in-out";
  delete props.easing;

  let baseStyle = {
    ...style,
    transition: `all ${timeout}ms ${easing} ${delay}ms`,
    opacity: 0
  };

  let transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  return (
    <CSSTransition {...props}>
      {state => (
        <Box {...sx} style={{ ...baseStyle, ...transitionStyles[state] }}>
          {children}
        </Box>
      )}
    </CSSTransition>
  );
}

export function FadeGrowTransition({ children, heights, style, ...props }) {
  props = { ...DEFAULT_PROPS, ...props };

  if (heights.length !== 2) {
    throw new Error("FadeGrowTransition requires exactly two heights");
  }

  let baseStyle = {
    ...style,
    transition: `all ${props.timeout}ms ease-in-out`,
    opacity: 0,
    height: heights[0]
  };

  let transitionStyles = {
    entering: { opacity: 1, height: `${heights[1]}px` },
    entered: { opacity: 1, height: `${heights[1]}px` },
    exiting: { opacity: 0, height: `${heights[0]}px` },
    exited: { opacity: 0, height: `${heights[0]}px` }
  };

  return (
    <CSSTransition {...props}>
      {state => <Box style={{ ...baseStyle, ...transitionStyles[state] }}>{children}</Box>}
    </CSSTransition>
  );
}

export function FadeSlideTransition({ children, offsetX = 0, offsetY = 0, ...props }) {
  props = { ...DEFAULT_PROPS, ...props };

  let style = props.style || {};
  delete props.style;

  let delay = props.delay || 0;
  delete props.delay;

  let timeout = props.timeout || 300;

  let easing = props.easing || "ease-in-out";
  delete props.easing;

  let baseStyle = {
    ...style,
    transition: `all ${timeout}ms ${easing} ${delay}ms`,
    opacity: 0,
    transform: `translate(${offsetX}px, ${offsetY}px)`
  };

  let transitionStyles = {
    entering: { opacity: 1, transform: "translateY(0px)" },
    entered: { opacity: 1, transform: "translateY(0px)" },
    exiting: { opacity: 0, transform: `translate(${offsetX}, ${offsetY})` },
    exited: { opacity: 0, transform: `translate(${offsetX}, ${offsetY})` }
  };

  return (
    <CSSTransition {...props}>
      {state => <div style={{ ...baseStyle, ...transitionStyles[state] }}>{children}</div>}
    </CSSTransition>
  );
}
