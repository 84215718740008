export default function TestOrderIcon({ color, width, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      width={width}
      fill={color}
      style={style}
    >
      <g fillRule="evenodd">
        <path d="M12.557 10.66v14.096l11.372-4.88V5.685zM0 19.78l11.371 4.915V10.6L5.568 8.053v8.453l-1.184-.516V7.58L.002 5.686zM11.964 9.595l-5.508-2.37L17.532 2.43l5.507 2.37zM11.964 0L.891 4.798l4.086 1.777 11.075-4.797z" />
      </g>
    </svg>
  );
}
