export default function TestTubeIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9935_26025)">
        <g clipPath="url(#clip1_9935_26025)">
          <path
            d="M12.1128 10.7849L19.2424 3.65526L26.3338 10.7466L22.9429 14.1375L12.1094 10.7883L12.1128 10.7849ZM29.7143 11.3518L18.6477 0.285214C18.2652 -0.0973501 17.6461 -0.0973501 17.2635 0.285214C16.881 0.667779 16.881 1.28684 17.2635 1.6694L17.8652 2.27107L2.03748 18.0953C-0.682202 20.815 -0.678724 25.2249 2.03748 27.9446C4.75717 30.6643 9.16709 30.6643 11.8868 27.9446L27.711 12.1204L28.3266 12.7359C28.7092 13.1185 29.3282 13.1185 29.7108 12.7359C30.0934 12.3534 30.0934 11.7343 29.7108 11.3518H29.7143Z"
            fill="#1A1A1A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9935_26025">
          <rect width="30" height="30" fill="white" />
        </clipPath>
        <clipPath id="clip1_9935_26025">
          <rect width="30" height="29.9861" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

TestTubeIcon.defaultProps = {
  size: 30
};
