import ChevronComponent from "@/tpo/Chevron";
import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import Circle from "./Circle";

function ArrowCircle({ size, backgroundColor, arrowColor, chevronWidth, direction, ...props }) {
  //#endregion

  return (
    <Circle backgroundColor={backgroundColor} size={size} {...props}>
      <ChevronComponent
        width={chevronWidth}
        fill={arrowColor}
        direction={direction}
        height={chevronWidth}
        style={{
          margin: 0
        }}
      />
    </Circle>
  );
}

ArrowCircle.defaultProps = {
  backgroundColor: "rgba(0,0,0,0.08)",
  arrowColor: "dark",
  size: 40,
  chevronWidth: 10,
  direction: "right"
};

ArrowCircle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  backgroundColor: propTypes.color.backgroundColor,
  arrowColor: PropTypes.string,
  chevronWidth: PropTypes.number,
  direction: PropTypes.string
};

export default ArrowCircle;
